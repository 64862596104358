<ng-container *ngIf="!loginDisplay">
    <!-- <app-home-header></app-home-header> -->
    <div class="page">
        <!-- <app-home-header></app-home-header> -->
    <!--header end-->
<ng-container *ngIf="selectedPage==='/home'">
        <!-- preloader start -->
        <!-- <div id="preloader">
          <div id="status">&nbsp;</div>
        </div> -->
        <!-- preloader end -->
    <!-- <div class="main-carousel">
        <owl-carousel-o [options]="mainOptions" class="team-slide video-slide">
            <ng-template carouselSlide>
                <div class="video-container">
                <video class="video-fluid video-cls" autoplay loop muted>
                    <source src="https://mdbootstrap.com/img/video/Tropical.mp4" type="video/mp4" />
                  </video>
                  </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="video-container">

                <video class="video-fluid video-cls" autoplay loop muted>
                    <source src="https://mdbootstrap.com/img/video/forest.mp4" type="video/mp4" />
                  </video>
                  </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="video-container">

                <video class="video-fluid video-cls" autoplay loop muted>
                    <source src="https://mdbootstrap.com/img/video/Tropical.mp4" type="video/mp4" />
                  </video>
                  </div>
            </ng-template>
        </owl-carousel-o> 
    </div>    -->
  
    <section class="asp_nav_bar_section" style="margin-top: 6%;" id="home">
        <div class="asp_container">
            <nav class="navbar navbar-expand-lg asp_navtag_v">
                <div class="container">
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
    
                    <div class="collapse navbar-collapse asp_collapse_nav" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0 asp_main_nav_ul_section">
                            <li class="nav-item asp_main_li_v">
                                <a class="nav-link active asp_nav_a_v" aria-current="page" routerLink="/home" routerLinkActive='active'>Home</a>
                            </li>
                            <li class=" dropdown nav-item asp_main_li_v myDIV">
                                <a class=" dropbtn nav-link asp_nav_a_v myDIV"  href='#'>Reports</a>
                                <ul class=" dropdown-content home-sub-menu-bar hide">
                                    <li onclick='window.open ( "https://vswsonline.ap.gov.in/#/csp-service-report")'><a style="pointer-events: none"  href=''  target="_blank"  download="AP SEVA Services Dashboard">AP SEVA Services Dashboard</a></li>
                                    <li onclick='window.open ( "https://app.powerbi.com/view?r=eyJrIjoiMGNjMDkyZjAtMWMzOS00MTNmLTllODctNWVjYTY5Zjk5NzYwIiwidCI6IjI3ZjAxMGQwLTk2YWQtNGNhNC1iNjJkLWFlNzhhNGEyYTQzMCJ9&pageName=ReportSection")'><a style="pointer-events: none" href='' download="Analytics Dashboard">Analytics Dashboard</a></li>
                                    <li onclick='window.open ( "https://app.powerbi.com/view?r=eyJrIjoiZGZjNzkwMDctZjhmMy00MTVlLTlkNTUtZWQwOTAyM2Q0YzA0IiwidCI6IjI3ZjAxMGQwLTk2YWQtNGNhNC1iNjJkLWFlNzhhNGEyYTQzMCJ9")'><a style="pointer-events: none" href='' download="Sub SLA Dashboard">Sub SLA Dashboard</a></li>
                                  </ul>
                            </li>
                            <!-- <li class=" dropdown nav-item asp_main_li_v myDIV">
                                <a class=" dropbtn nav-link asp_nav_a_v myDIV"  href='#'>Downloads</a>
                                <ul class=" dropdown-content home-sub-menu-bar hide">
                                    <li onclick='window.location.href = "../../assets/User-Manuals/EoDB SOP final.pdf";'><a style="pointer-events: none"  href=''  target="_blank"  download="SOPs.pdf">Ease Of Living SOPs</a></li>
                                    <li onclick='window.location.href = "../../assets/User-Manuals/Birth-and-Death-Certificate.pdf"'><a style="pointer-events: none" href='' download="Birth-and-Death-Certificate.pdf">Birth and Death Certificate</a></li>
                                    <li onclick='window.location.href = "../../assets/User-Manuals/CSP-User-Manual.pdf"'><a style="pointer-events: none" href='' download="CSP-User-Manual.pdf">CSP User Manual</a></li>
                                    <li onclick='window.location.href = "../../assets/User-Manuals/Driving-License.pdf"'><a style="pointer-events: none" href='' download="Driving-License.pdf">Driving License</a></li>
                                    <li onclick='window.location.href = "../../assets/User-Manuals/Electricity-Connection.pdf"'><a style="pointer-events: none" href='' download="Electricity-Connection.pdf">Electricity Connection</a></li>
                                    <li onclick='window.location.href = "../../assets/User-Manuals/Encumbrance-Certificate.pdf"'><a style="pointer-events: none" href='' download="Encumbrance-Certificate.pdf">Encumbrance Certificate</a></li>
                                    <li onclick='window.location.href = "../../assets/User-Manuals/Health-CardArogyasri.pdf"'><a style="pointer-events: none" href='' download="Health-CardArogyasri.pdf">Health Card Arogyasri</a></li>
                                    <li onclick='window.location.href = "../../assets/User-Manuals/Marriage-Certificate.pdf"'><a style="pointer-events: none" href='' download="Marriage-Certificate.pdf">Marriage Certificate</a></li>
                                    <li onclick='window.location.href = "../../assets/User-Manuals/Property-Registration.pdf"'><a style="pointer-events: none" href='' download="Property-Registration.pdf">Property Registration</a></li>
                                    <li onclick='window.location.href = "../../assets/User-Manuals/Water-Connection.pdf"'><a style="pointer-events: none" href='' download="Water-Connection.pdf">Water Connection</a></li>
                                    <li onclick='window.location.href = "https://gswsattendance.ap.gov.in/PortalUploads/Others/Notification.pdf"'><a style="pointer-events: none" target="_blank" href="" download="Notification.pdf">Notification</a></li>
                                    <li onclick='window.location.href = "https://gswsattendance.ap.gov.in/PortalUploads/Others/Ordinance.pdf"'><a style="pointer-events: none" target="_blank" href="" download="Ordinance.pdf">Ordinance</a></li>
                                    <li onclick='window.location.href = "https://gswsattendance.ap.gov.in/PortalUploads/Others/GOMs75.pdf"'><a style="pointer-events: none" target="_blank" href="" download="GOMs 75.pdf">GOMs</a></li>
                                </ul>
                            </li> -->
                            <li class="nav-item asp_main_li_v">
                                <a class="nav-link asp_nav_a_v"  target="_blank"   href="https://gsws-nbm.ap.gov.in/NBM/Home/Main"  >Citizen Schemes Portal</a>
                               
                            </li>
                            <!-- <li class="nav-item asp_main_li_v">
                                <a class="nav-link asp_nav_a_v" href="#"  (click)="showPoliciesPopup('informationWizard')" >Information Wizard For Citizen</a>
                               
                            </li> -->
                            
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </section>
    

    <div id="demo" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="../../assets/home-images/banner_1.svg" alt="slider_1" class="d-block" style="width:100%;transform: scale(1.1);">
            </div>
        </div>



    </div>

    <!-- about_section -->
    <app-home-header></app-home-header>
 
    
    <!-- <section class="asp_about_section">
        <div class="container ">
            <div class="asp_about_container">
                <div class="row asp_row_section">
                    <div class="col-md-4 d-flex align-items-center">
                        <div class="asp_about_img">
                            <img src="../../assets/home-images/about_gsws_image.svg" alt="about_info">
                        </div>
                    </div>
                    <div class="col-md-8" id="aboutus">
                        <div class="asp_text_section">
                            <div class="asp_text_info_img">
                                <img src="../../assets/home-images/headings_style.svg" alt="asp_heading_img">
                                <h6 style="font-weight: 600 !important;">About GSWS</h6>
                            </div>
                            <h2 style="text-align: left; font-size: 24px;padding-top: 5px;color: #000;" class="title">గ్రామ, వార్డు సచివాలయాల ద్వారా ఇంటి ముంగిటకే పాలన</h2>
                            <div class="asp_text">
                                  <p style="font-size: 15px;color: #000;">దేశంలో ఇప్పటి వరకు ఎక్కడా లేని విధంగా ఆంధ్రప్రదేశ్ ప్రభుత్వం గ్రామ, వార్డు సచివాలయాల వ్యవస్థను తెచ్చింది. అవినీతికి కానీ, వివక్షతకు కానీ తావు ఇవ్వకూడదని, పరిపాలన అన్నది ప్రజలకు చేరువ కావాలన్న ప్రధాన ఉద్దేశంతో ఈ సచివాలయ వ్యవస్థకు శ్రీకారం చుట్టారు. గ్రామ,వార్డు సచివాలయాల్లో దాదాపు 35 ప్రభుత్వ శాఖలకు సంబంధించి 500 సేవలు అందుబాటులో ఉంటాయి.

                                </p>
                                <p style="font-size: 15px;color: #000;" >
                                    పింఛన్ కావాలన్నా..రేషన్ కార్డు కావాలన్నా.. ఇంటి పట్టాలు కావాలన్నా.. తాగునీటి సరఫరా సమస్య ఉన్నా.. సివిల్ పనులకు సంబంధించిన పనులు ఉన్నా.. వైద్యం కానీ.. ఆరోగ్యం కానీ.. రెవిన్యూ కానీ.. భూముల సర్వేకానీ.. శిశు సంక్షేమం కానీ.. డెయిరీ కానీ, పౌల్టీరు రంగాల సేవలు కానీ.. ఇలాంటివెన్నో గ్రామ సచివాలయాల్లో అర్జీ పెట్టుకున్న 72 గంటలోనే సమస్యను పరిష్కరిస్తారు.

                                </p>
                               
                                <p style="font-size: 15px;color: #000;">అర్హత ఉన్న ఏ ఒక్కరూ తమకు ప్రభుత్వ సంక్షేమ ఫలాలు అందలేదని బాధపడే సమస్య ఇకపై ఉండదు.ప్రతి పేదవాడి ముఖంలో చిరునవ్వు చూడాలన్నదే ఈ ప్రభుత్వ లక్ష్యం. ప్రజల కోసం ఏర్పాటు చేసిన ఈ గ్రామ సచివాలయాలు ప్రజలు బాగా సద్వినియోగం చేసుకోవాలి. ఇది ప్రజల ప్రభుత్వం! ప్రజా సంక్షేమమే ఈ ప్రభుత్వ లక్ష్యం!!
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </section> -->

        <!-- <mdb-carousel class="carousel slide" [animation]="'slide'">
            <mdb-carousel-item>
              <div class="view">-->
                <!-- <video class="video-fluid" autoplay loop [muted]="true">
                  <source src="https://gswsattendance.ap.gov.in/PortalUploads/Others/Slide1.mp4" type="video/mp4" />
                </video> -->
             <!-- </div>
          
              <div class="carousel-caption">
                <div class="animated fadeInDown">
                  <p class="card-text telugu_p">అవినీతికి తావులేకుండా.. కుల, మత, వర్గ, లింగ, వర్ణ, రాజకీయ వివక్ష లేకుండా అర్హుడైన ప్రతి చివరి వ్యక్తి  వరకు ప్రభుత్వ సంక్షేమ పథకాలు
                    అందాలి. </p>
                    <div class="sign-container">
                        <div class="signature-cls"></div>
                        <div class="pull-right cm_sign">-వై.ఎస్. జగన్ మోహన్ రెడ్డి, <br/>
                        <span style="padding-left:20px;">ఆంధ్ర ప్రదేశ్ రాష్ట్ర ముఖ్యమంత్రి.</span></div>
                    </div>
                </div>
              </div>
            </mdb-carousel-item>
             <mdb-carousel-item>
              <div class="view">
                <video class="video-fluid" autoplay loop [muted]="true">
                  <source src="https://gswsattendance.ap.gov.in/PortalUploads/Others/Slide2.mp4" type="video/mp4" />
                </video>
              
              </div>
          
              <div class="carousel-caption">
                <div class="animated fadeInDown">
                    <p class="card-text telugu_p">గాంధీగారు కలలు కన్న గ్రామ స్వరాజ్యం సచివాలయ వ్యవస్థతోనే సాధ్యం. గ్రామీణ పాలనలో ఇది ఒక నూతన అధ్యాయం.</p>
                        <div class="sign-container">
                            <div class="signature-cls"></div>
                            <div class="pull-right cm_sign">-వై.ఎస్. జగన్ మోహన్ రెడ్డి, <br/>
                            <span style="padding-left:20px;">ఆంధ్ర ప్రదేశ్ రాష్ట్ర ముఖ్యమంత్రి.</span></div>
                        </div>
                </div>
              </div>
            </mdb-carousel-item> 
            <mdb-carousel-item>
              <div class="view">
                <video class="video-fluid" autoplay loop>
                  <source src="https://mdbootstrap.com/img/video/Tropical.mp4" type="video/mp4" />
                </video>
                <div class="mask rgba-black-strong waves-light" mdbWavesEffect></div>
              </div>
          
              <div class="carousel-caption">
                <div class="animated fadeInDown">
                    <p class="card-text telugu_p">పక్క రాష్ట్రాలు సైతం ఇవ్వాళ మనవైపు చూస్తున్నాయి. మనం అమలు చేస్తున్న గ్రామ, వార్డు సచివాలయ వ్యవస్థను తమ ప్రాంతాల్లో కూడా అమలు చేయాలని రాష్ట్రానికి వచ్చి అధ్యయనం చేస్తున్నాయి.</p>
                        <div class="sign-container">
                            <div class="signature-cls"></div>
                            <div class="pull-right cm_sign">-వై.ఎస్. జగన్ మోహన్ రెడ్డి, <br/>
                            <span style="font-size:14px;padding-left:20px;">ఆంధ్ర ప్రదేశ్ రాష్ట్ర ముఖ్యమంత్రి.</span></div>
                        </div>
                </div>
              </div>
            </mdb-carousel-item> 
          </mdb-carousel> -->
      <!-- </div> -->
    <!-- <div class="Slider-slide show" style="background-image:url('../../assets/home-images/mainbg-01.jpg');"></div>
    <div class="Slider-slide" style="background-image:url('../../assets/home-images/mainbg-02.jpg');"></div>
    <div class="Slider-slide" style="background-image:url('../../assets/home-images/mainbg-03.jpg');"></div>
    <div class="Slider-slide" style="background-image:url('../../assets/home-images/mainbg-04.jpg');"></div> -->
<!-- </div> -->


<section class="ttm-row team-section clearfix" style="  margin-top:-63px;" >
  
             
        </section>
        <!-- <section class="ttm-row team-section clearfix">
            <div class="introduction-section px-5">సదరం ధ్రువపత్రాలు పొందడం కొరకు 2022 అక్టోబర్, నవంబర్ మరియు డిసెంబర్ నెలలో పరీక్షలు చేయించుకొనుటకు ముందస్తు స్లాట్ లు కొరకు తేది 06.10.2022 ఉదయం 10 గంటల నుండి గ్రామ/ వార్డు సచివాలయాల్లో మరియు మీ సేవ కేంద్రాల్లో నమోదు చేయించుకోవచ్చు.</div>
        </section> -->
       
            <!-- <section class="ttm-row introduction-section clearfix">
            <div class="container">
                <div class="row">
                <div class="col-lg-6 col-xs-12">
                        
                        <div class="ttm_single_image-wrapper text-right">
                            <img class="img-fluid" src="../../assets/home-images/single-img-twel.png" alt="">
                        </div>
                        
                    </div>
                    <div class="col-lg-6 col-xs-12">
                        <div class="res-991-pt-0">
                            
                            <div class="section-title clearfix">
                                <div class="title-header">
                                    <h5 style="font-family: Roboto-Regular!important;">About <strong>GSWS</strong></h5>
                                    <h2 class="title">గ్రామ, వార్డు సచివాలయాల ద్వారా ఇంటి ముంగిటకే పాలన</h2>
                                </div>
                            </div>
                            <div class="mb-30 clearfix">
                                <p>దేశంలో ఇప్పటి వరకు ఎక్కడా లేని విధంగా ఆంధ్రప్రదేశ్ ప్రభుత్వం గ్రామ, వార్డు సచివాలయాల వ్యవస్థను తెచ్చింది. అవినీతికి కానీ, వివక్షతకు కానీ తావు ఇవ్వకూడదని, పరిపాలన అన్నది ప్రజలకు చేరువ కావాలన్న ప్రధాన ఉద్దేశంతో రాష్ట్ర ముఖ్యమంత్రి శ్రీ వై యస్ జగన్ మోహన్ రెడ్డి గారు ఈ సచివాలయ వ్యవస్థకు శ్రీకారం చుట్టారు. గ్రామ,వార్డు సచివాలయాల్లో దాదాపు 35 ప్రభుత్వ శాఖలకు సంబంధించి 500 సేవలు అందుబాటులో ఉంటాయి.</p>
<p>
పింఛన్ కావాలన్నా..రేషన్ కార్డు కావాలన్నా.. ఇంటి పట్టాలు కావాలన్నా.. తాగునీటి సరఫరా సమస్య ఉన్నా.. సివిల్ పనులకు సంబంధించిన పనులు ఉన్నా.. వైద్యం కానీ.. ఆరోగ్యం కానీ.. రెవిన్యూ కానీ.. భూముల సర్వేకానీ.. శిశు సంక్షేమం కానీ.. డెయిరీ కానీ, పౌల్టీరు రంగాల సేవలు కానీ.. ఇలాంటివెన్నో గ్రామ సచివాలయాల్లో అర్జీ పెట్టుకున్న 72 గంటలోనే సమస్యను పరిష్కరిస్తారు.</p>
<p>
అర్హత ఉన్న ఏ ఒక్కరూ తమకు ప్రభుత్వ సంక్షేమ ఫలాలు అందలేదని బాధపడే సమస్య ఇకపై ఉండదు.ప్రతి పేదవాడి ముఖంలో చిరునవ్వు చూడాలన్నదే ఈ ప్రభుత్వ లక్ష్యం. ప్రజల కోసం ఏర్పాటు చేసిన ఈ గ్రామ సచివాలయాలు ప్రజలు బాగా సద్వినియోగం చేసుకోవాలి. ఇది ప్రజల ప్రభుత్వం! ప్రజా సంక్షేమమే ఈ ప్రభుత్వ లక్ష్యం!!</p>
                            </div>
                          
                            <div class="row">
                                
                                
                                <div class="col-lg-12">
                                    <div class="mt-25 res-991-mt-0 res-991-mb-40">
                                        <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-black mr-15 mt-15" href="#">VIEW MORE</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section> -->


    <!--site-main start-->
    <div class="ttm-row cta-section  clearfix">
        <section class="asp_okm_section">
            <div class="container ">
                <div class="asp_okm_container">
                    <div class="asp_text_info_img key-met">
                        <img src="../../assets/home-images/headings_style.svg" alt="asp_heading_img">
                        <h6 style="font-size: 16px;font-weight: 600 !important; height: 18px">Our key metrics</h6>
                    </div>
                    <div class="row asp_okm_row_section">
                        <div class="col-md-6 col-lg-3 d-flex justify-content-center">
                            <div class="asp_oky_mds asp_oky_mobile_1">
                                <img src="../../assets/home-images/our_metrics_icon_1.svg" alt="icon">
                                <div class="asp_oky_text">
                                    <h5 class="mar-tp">15,004</h5>
                                    <p>Total <br> Secretariats</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 d-flex justify-content-center">
                            <div class="asp_oky_mds asp_oky_mobile_2">
                                <img src="../../assets/home-images/our_metrics_icon_2.svg" alt="icon">
                                <div class="asp_oky_text">
                                    <h5 class="mar-tp">1,30,694          </h5>
                                    <p>Village - Ward <br> Secretariat Staff                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 d-flex justify-content-center">
                            <div class="asp_oky_mds asp_oky_mobile">
                                <img src="../../assets/home-images/our_metrics_icon_3.svg" alt="icon">
                                <div class="asp_oky_text">
                                    <h5 class="mar-tp">2,48,779 </h5>
                                    <p>Village- ward <br>  Volunteers</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 d-flex justify-content-center">
                            <div class="asp_oky_mds asp_oky_mobile">
                                <img src="../../assets/home-images/our_metrics_icon_4.svg" alt="icon">
                                <div class="asp_oky_text">
                                    <h5 class="mar-tp">540</h5>
                                    <p> Total<br>Services</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row asp_okm_row_section">
                        <div class="col-md-6 col-lg-3 d-flex justify-content-center">
                            <div class="asp_oky_mds asp_oky_mobile_1">
                                <img src="../../assets/home-images/our_metrics_icon_5.svg" alt="icon">
                                <div class="asp_oky_text">
                                    <h5 class="mar-tp">35</h5>
                                    <p>Total <br> Departments</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 d-flex justify-content-center">
                            <div class="asp_oky_mds asp_oky_mobile_2">
                                <img src="../../assets/home-images/our_metrics_icon_6.svg" alt="icon">
                                <div class="asp_oky_text">
                                    <h5 class="mar-tp">14,91,155</h5>
                                    <p>Average monthly <br> Services Requests </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 d-flex justify-content-center">
                            <div class="asp_oky_mds asp_oky_mobile">
                                <img src="../../assets/home-images/our_metrics_icon_7.svg" alt="icon">
                                <div class="asp_oky_text">
                                    <h5 class="mar-tp">99%</h5>
                                    <p>Services<br> 
                                        Delivered</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 d-flex justify-content-center">
                            <div class="asp_oky_mds asp_oky_mobile">
                                <img src="../../assets/home-images/our_metrics_icon_8.svg" alt="icon">
                                <div class="asp_oky_text">
                                    <h5 class="mar-tp">94%</h5>
                                    <p>Services Closed<br> 
                                        With in SLA</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
    
        <!-- services-section -->
        <section class="services-section">
            <div class="container">
                <div class="services-main-box ">
                    <div class="row" *ngIf="topTenServices && topTenServices?.length > 0">
                        <div class="d-flex justify-content-start" style="position:absolute;">
                            <div class="heading">
                                <img src="../../assets/home-images/headings_style.svg" alt="heading-bg">
                                <p  style="    font-size: 16px; font-weight: 700 !important; height: 18px;  left: 25px; top: 44%;">Top 10 Services</p>
    
                            </div>
                        </div>
    
                        <div class="col-md-4 col-lg-2 marg-tp mrt" style="width: 25%;left:5%;flex: 0 0 17.666667%;max-width: 25%;padding: 0px;" *ngFor="let service of topTenServices;let i = index;">
                            <div class="baloon-box">
                                <div class="d-flex justify-content-center">
                                    <img src="../../assets/home-images/to_10_services_component_{{i+1}}.svg" alt="baloon-img">
                                </div>
                                <div id="{{cSSid[i]}}" class="baloon-content text-center">
                                    <p style="margin-bottom: 0px;">{{i+1}}</p>
                                    <p style="font-size: 20px;margin-bottom: 0px;">{{service.toT_REQ }}</p>
                                    <p style="font-size: 13px;">{{service.servicename}}</p>
                                </div>
                            </div>
                        </div>
                       
                        <div class="col-md-2 d-lg-none"></div>
                        <div class="col-lg-1 mt-4"></div>
                    </div>
                </div>
    
            </div>
        </section>
    
        <!-- <section>
            <div class="container">
                <div class="announcement-box">
                    <div class="d-flex justify-content-center">
                        <img class="announce" src="../../assets/home-images/Announcements_Icon.svg" alt="announce-img">
                    </div>
    
                    <div class="asp_text_info_img key-met">
                        <img src="../../assets/home-images/headings_style.svg" alt="asp_heading_img">
                        <h6>Announcements</h6>
                    </div>
                    <p class="announce-discription">Find latest Announcements and Important updates from Grama Sachivalayam
                        and Ward Sachivalayam Department</p>
    
                    <div class="stay-tuned-box d-flex justify-content-center my-3">
                          <h2>Stay Tuned...</h2>
                    </div>
                </div>
            </div>
        </section>
     -->
    




</div>

   


     









 <!-- <section class="ttm-row team-section clearfix">
<div class="section-title clearfix">
                                    <div class="title-header" >
                                        <h2 class="title" style="font-weight:bold; margin-top:-50px;  padding-left:25px;" > <img src="../../assets/home-images/Vector 7.png"/><br>Video<strong> Library</strong></h2>
                                    </div>
                                </div>
                                <div class="videoLib">
                                    <owl-carousel-o [options]="videoOptions" class="team-slide video-slide">
                                        <ng-template carouselSlide>
                                          
                                            <div class="video-thumb-block"><img u="image" src="../../assets/home-images/citizen-thumb.PNG" /><div class="ttm-video-btn">
                                                <div class="ttm-play-btn ttm_prettyphoto" (click)="openVideoPopup(videoModal, 'Video1.mp4')">
                                                    <span class="ttm-btn-play"><i class="fa fa-play"></i></span>
                                                </div>
                                            </div></div>
                                        </ng-template>
                                        <ng-template carouselSlide>
                                         
                                            <div class="video-thumb-block"><img u="image" src="../../assets/home-images/etiquette-thumb.PNG" /><div class="ttm-video-btn">
                                                <div class="ttm-play-btn ttm_prettyphoto" (click)="openVideoPopup(videoModal, 'Video3.mp4')">
                                                    <span class="ttm-btn-play"><i class="fa fa-play"></i></span>
                                                </div>
                                            </div></div>
                                        </ng-template>
                                        <ng-template carouselSlide>
                                           
                                            <div class="video-thumb-block"><img u="image" src="../../assets/home-images/ideal-sec-thumb.png" /><div class="ttm-video-btn">
                                                <div class="ttm-play-btn ttm_prettyphoto" (click)="openVideoPopup(videoModal, 'Video2.mp4')">
                                                    <span class="ttm-btn-play"><i class="fa fa-play"></i></span>
                                                </div>
                                            </div></div>
                                        </ng-template>
                                    </owl-carousel-o>
                                </div>
    </section> -->
    
    
    
    
        













 

<div class="clear-fix"></div>


        <!-- testimonial-section -->
        <!-- <section class="ttm-row broken-section  clearfix">
      
            <div class="container-fluid">
            
                <div class="row">
                    
                    <div class="col-md-12" style="padding-top:50px; padding-bottom:50px;">
                        <div class="">
                            <div class="layer-content">
                            <div class="section-title clearfix">
                            <div class="title-header">
                              <h2 class="title" style="margin-top:10px;     padding-left:25px;" > <img src="../../assets/home-images/Vector 7.png"/><br> Success <strong>Stories</strong></h2>
                                     
                                    </div>
                                    </div>
                                <owl-carousel-o [options]="testmonialOptions" class="testimonial-slide">
                                        <ng-template carouselSlide>
                                   
                                    <div class="testimonials ttm-testimonial-box-view-style1">
                                        <div class="testimonial-avatar">
                                            <div class="testimonial-img">
                                                <img class="img-fluid" src="../../assets/home-images/testimonial/01.jpg" alt="testimonial-img">
                                            </div>
                                             
                                        </div>
                                        <div class="testimonial-content">
                                            <blockquote class="ttm-testimonial-text"><span class="imp">&#10077;</span>మా ఊరు రొద్దం మండలకేంద్రానికి 7కి. మీ దూరంలో ఉంది. సచివాలయాలు రాకముందు ప్రతిదానికి మండలకేంద్రానికి వెళ్లాల్సి వచ్చేది. దానికోసం కూలి పని కూడా మానుకోవాల్సి వచ్చేది. ఒక్కోసారి రెండు మూడు సార్లు తిరగాల్సి వచ్చేది. ఇప్పుడా పరిస్థితి లేదు. అన్నీ ఇంటి వద్దకే అందుతున్నాయి. ఇంతమంచి ప్రజా వ్యవస్థను రూపొందించిన ప్రభుత్వానికి ధన్యవాదాలు</blockquote>
                                            
                                            <div class="testimonial-caption">
                                                <h5 style="float:left;">-బి. అంజినమ్మ,</h5>
                                                <p> లబ్దిదారులు, పాతర్లపల్లి, రొద్దం (మం) అనంతపురం జిల్లా. </p>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <div class="testimonials ttm-testimonial-box-view-style1"> 
                                        <div class="testimonial-avatar">
                                            <div class="testimonial-img">
                                                <img class="img-fluid" src="../../assets/home-images/testimonial/02.jpg" alt="testimonial-img">
                                            </div>
                                            
                                        </div>
                                        <div class="testimonial-content">
                                            <blockquote class="ttm-testimonial-text"><span class="imp"  style="color:#E83D4E">&#10077;</span>మాది పేద కుటుంబం. నాకు 46 సంవత్సరాలు. ఇప్పటిదాక బియ్యం కార్డు లేదు. గతంలో ఎన్నోసార్లు అధికారుల చుట్టూ తిరిగాను. ఎంతోమందిని కలిశాను. బియ్యం కార్డు మాత్రం మంజూరు కాలేదు. గ్రామ, వార్డు సచివాలయాల వ్యవస్థ ఏర్పాటైన తర్వాత వాలంటీర్ల ద్వారా బియ్యం కార్డు దరఖాస్తు చేసిన వారం రోజుల్లో వచ్చేసింది. బియ్యం కార్డు ఉండటం వల్ల అమ్మఒడికి కూడా నేను అర్హురాలినయ్యాను. పిల్లలను పోషించుకోవడమే కష్టంగా ఉన్న సమయంలో నాకు ఈ ప్రభుత్వం ఆదుకుంది. ధన్యవాదాలు. </blockquote>
                                             <div class="testimonial-caption">
                                              <h5 style="float:left;">-జయమ్మ,</h5>
                                                <p> స్వరాజ్య నగర్ 2, ప్రొద్దుటూరు, కడపజిల్లా.</p>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <div class="testimonials ttm-testimonial-box-view-style1"> 
                                        <div class="testimonial-avatar">
                                            <div class="testimonial-img">
                                                <img class="img-fluid" src="../../assets/home-images/testimonial/03.png" alt="testimonial-img">
                                            </div>
                                            
                                        </div>
                                        <div class="testimonial-content">
                                            <blockquote class="ttm-testimonial-text"><span class="imp">&#10077;</span>నాది పేద కుటుంబం. 23 ఏళ్ల క్రితం భర్త చనిపోయారు. ఇలా ఇంటి దిక్కును కోల్పోయిన వారికి పింఛను ఇస్తారని నాకు ఎవరూ చెప్పలేదు. నేను చిన్న చిన్న పనులు చేసుకుంటూ అరకొర తింటూ బతుకు నెట్టు కొస్తున్నాను. ఈ ప్రభుత్వం వచ్చాక మా వాలంటీర్ ఇంటి దాక వచ్చి నా పరిస్థితి చూసి పింఛనుకు అప్లయ్ చేయించారు. ఇప్పుడు ప్రతినెలా నాకు ఒకటో తేదీనే ఠంచనుగా పింఛను అందుతోంది. నాలాంటి వారికి అంతకుమించిన ఆనందం ఇంకేముంటుంది?</blockquote>
                                             <div class="testimonial-caption">
                                              <h5 style="float:left;">-గాలంకి కుమారి, </h5>
                                                <p>పేరూరు, ముదినేపల్లి, కృష్ణా జిల్లా. </p>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <div class="testimonials ttm-testimonial-box-view-style1"> 
                                        <div class="testimonial-avatar">
                                            <div class="testimonial-img">
                                                <img class="img-fluid" src="../../assets/home-images/testimonial/04.png" alt="testimonial-img">
                                            </div>
                                            
                                        </div>
                                        <div class="testimonial-content">
                                            <blockquote class="ttm-testimonial-text"><span class="imp" style="color:#E83D4E">&#10077;</span>కొన్ని సంవత్సరాల క్రితం నా భర్త చనిపోయారు. కుటుంబ భారం మొత్తం నా మీదే ఉంది. నాకు ముగ్గురు పిల్లలు. వారిని స్కూల్లో చేర్చాను కానీ వారి చదువులు ఎప్పుడు ఆగిపోతాయో తెలియని స్థితి. పిల్లలు స్కూలు ఫీజును గుర్తు చేసినప్పుడల్లా గుండె కలుక్కుమంటుంది. ఇలాంటి పరిస్థితిలో ఈ ప్రభుత్వం ’జగనన్న అమ్మ ఒడి’’ పథకాన్ని ప్రవేశపెట్టి నా నెత్తిన పాలు పోసింది. ప్రభుత్వం ఏటా నా ఖాతాల్లో డబ్బులు జమ చేస్తుండటంతో పిల్లలను చదివించుకోవడం కొంత సులువుగా మారింది. ధన్యవాదాలు.</blockquote>
                                             <div class="testimonial-caption">
                                              <h5 style="float:left;">-బొడ్డపల్లి నాగమణి,</h5>
                                                <p> వాలిమెరక పంచాయితీ, పెందుర్తి మండలం, విశాఖపట్నం.</p>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                </owl-carousel-o>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         
        </section> -->
        <!-- testimonial-section end-->

        <!--client-section-->
        
        <!--client-section end-->


        <!--blog-section-->
       <!-- <section class="ttm-row blog-section clearfix" style="
	background-image:url(../../assets/home-images/footer-bg.jpg); background-repeat:no-repeat; background-position:top;">
            <div class="container">
                // row 
                <div class="row">
                    <div class="col-lg-9 col-md-12">
                        // section-title 
                        <div class="section-title with-desc clearfix">
                            <div class="title-header">
                               
                                <h2 class="title" style="font-weight:bold; margin-top:-50px; text-align:left;"> GSWS Announcements</h2>
                            </div>
                            <div class="title-desc">Find latest Announcements and Important updates from
                                Grama Sachivalayam and Ward Sachivalayam Department</div>
                        </div>
                        // section-title end 
                    </div>
                    <div class="col-lg-3 col-md-12">
                        <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-black res-991-mt-0 float-right" href="#">VIEW ALL</a>
                    </div>
                </div>
                // row end 
                
                
                <div class="">
            <div class=" container foot_bg">
                <div class="row">
                    <div class="col-md-8 col-sm-12">
                 <h3 class="widget-title">Latest</h3>
                	<div class="row clearfix">
                     
                    	//service-block
                    	<div class="service-block col-md-6 col-sm-6 col-sm-12">
                        	<div class="inner-box">
                            	//icon-box
                            	<div class="icon-box">
                          	I
                                </div>
                                <h3><a href="https://drive.google.com/file/d/14PRSCpahfCMyhYlUGXTNTreQPf8yeq-H/view?usp=sharing" target="_blank">Instructions on COP to Village/Ward Secretariats for the month of January 2022</a></h3>
                                <div class="text">Half day training workshop for all functionaries and volunteers on COP for the month of January 2022.</div>
                                
                            </div>
                        </div>
                        //service-block
                        <div class="service-block col-md-6 col-sm-6 col-sm-12">
                        	<div class="inner-box">
                            	//icon-box
                            	<div class="icon-box">
                                N
                                </div>
                                <h3><a href="https://drive.google.com/file/d/1Qtq5VQqySPdTSaxx48_dwxvkKiD7j9j4/view?usp=sharing" target="_blank">News on EBC Nestham Launch</a></h3>
                                <div class="text">Eenadu news clipping on EBC Nestham launch on 25th January by Honourable Chief Minister.</div>
                                
                            </div>
                        </div>
                        <div class="service-block col-md-6 col-sm-6 col-sm-12">
                        	<div class="inner-box">
                            	<div class="icon-box">
                                	R
                                </div>
                                <h3><a href="https://drive.google.com/uc?export=download&id=1StwHc3m_qy69E-EKiHi16ThZp95h9r4W" target="_blank">Revised instructions on salaries linked with biometric attendance</a></h3>
                                <div class="text">Payment of salaries linked with Bio Metric attendance to the Village / Ward Secretariat functionaries - certain revised instructions issued.</div>
                                
                            </div>
                        </div>
                        <div class="service-block col-md-6 col-sm-6 col-sm-12">
                        	<div class="inner-box">
                            	<div class="icon-box">
                                M
                                </div>
                                <h3><a href="https://drive.google.com/uc?export=download&id=1PSD6LJdKZUm_Bj16Ay9r7Mn1JSp6oCEX" target="_blank">Mahila Police Service Rules</a></h3>
                                <div class="text">Andhra Pradesh Mahila  Police (Subordinate Service) Rules.</div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
                    
                    
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 widget-area">
                      <h3 class="widget-title">Important</h3>
                        <div class="row clearfix">
                     
                    	<div class="service-block col-md-12 col-sm-12 col-sm-12">
                        	<div class="inner-box">
                            	<div class="icon-box"  style="background-color:#003459; color:#fff;" >
                                	C
                                </div>
                                <h3 style="color:#003459"><a href="https://drive.google.com/uc?export=download&id=1swCGJrpYto0m4DggzY-XaQjIzlfXBYLa" target="_blank">Covid-19 ex gratia for an amount of rupees 50,000</a></h3>
                                <div class="text">Covid-19 ex gratia for an amount of rupees 50,000 to the next king of the deceased persons due to COVID-19 from funds of state disaster response fund (SDRF).</div>
                            </div>
                        </div>
                        <div class="service-block col-md-12 col-sm-12 col-sm-12">
                        	<div class="inner-box">
                            	<div class="icon-box" style="background-color:#003459; color:#fff;">
                                S
                                </div>
                                <h3 style="color:#003459"><a href="https://drive.google.com/file/d/1XAvJv-vc2qt-IwDJqRC3uTKdHBvM6Wtx/view?usp=sharing" target="_blank">Special dispensation for frontline workers who succumbed to COVID-19</a></h3>
                                <div class="text">Special dispensation for providing compassionate employment to the eligible dependents of deceased Government employees who were front line workers and succumbed to Covid-19.</div>
                                
                            </div>
                        </div>
                        
                    </div>
                    </div>
                </div>
            </div>
        </div>
            </div>
        </section> -->
        <!--blog-section end-->


<div class="clear-fix"></div>
</ng-container>
   
<ng-container *ngIf="!loginDisplay && selectedPage==='/about-us'">
    <app-about-us *ngIf="!loginDisplay"></app-about-us>
</ng-container>
<ng-container *ngIf="!loginDisplay && selectedPage === '/apseva-feedback'">
    <div class="main_logo_section text-center mt-3 mb-3">
        <img src="../../assets/home-images/ap_govt_logo.svg" alt="ap_logo" style="width: 100px;">
    </div>
    <app-gsws-feedback></app-gsws-feedback>
</ng-container>
<ng-container *ngIf="!loginDisplay && selectedPage === '/meeseva-feedback'">
    <div class="main_logo_section text-center mt-3 mb-3">
        <img src="../../assets/home-images/ap_govt_logo.svg" alt="ap_logo" style="width: 100px;">
    </div>
    <app-meeseva-feedback></app-meeseva-feedback>
</ng-container>
<ng-container *ngIf="!loginDisplay && selectedPage === '/feedback-status'">
    <div class="main_logo_section text-center mt-3 mb-3">
        <img src="../../assets/home-images/ap_govt_logo.svg" alt="ap_logo" style="width: 100px;">
    </div>
    <app-feedback-status></app-feedback-status>
</ng-container>
<ng-container *ngIf="!loginDisplay && selectedPage==='/csp-service-report'">
    <app-home-header></app-home-header>
    <app-csp-service-report *ngIf="!loginDisplay"></app-csp-service-report>
</ng-container>
<ng-container *ngIf="!loginDisplay && selectedPage==='/csp-payment-report'">
    <app-home-header></app-home-header>
    <app-csp-payment-report *ngIf="!loginDisplay"></app-csp-payment-report>
</ng-container>
<ng-container *ngIf="!loginDisplay && selectedPage==='/save-designations-positions'">
    <!-- <app-home-header></app-home-header> -->
    <app-save-designations-positions *ngIf="!loginDisplay"></app-save-designations-positions>
</ng-container>
<!-- <ng-container *ngIf="!loginDisplay && selectedPage==='/jagananna-schedule-search'">
    <app-home-header></app-home-header>
    <app-jagananna-schedule-search *ngIf="!loginDisplay"></app-jagananna-schedule-search>
</ng-container> -->
</div>
<!-- <app-footer></app-footer> -->
<footer class="pt-4 footer-box" *ngIf="!loginDisplay && (selectedPage !== '/apseva-feedback' && selectedPage !== '/meeseva-feedback' && selectedPage !== '/feedback-status')">
    <div class="container">
        <div class="row pb-3">
            <div class="col-md-3 d-flex ">
                <div class="logo-box">
                    <img src="../../assets/home-images/footer_ap_govt_logo_card-new.svg" alt="ap-logo">
                </div>
            </div>
            <div class="col-md-2">
                <div class="sitemap-box">
                    <h5>Sitemap</h5>
                    <a (click)="scrollhome() ">Home </a> <br>
                    <!-- <a (click)="scrollabus() ">About us</a> <br> -->
                    <a target="_blank" href='https://app.powerbi.com/view?r=eyJrIjoiMGNjMDkyZjAtMWMzOS00MTNmLTllODctNWVjYTY5Zjk5NzYwIiwidCI6IjI3ZjAxMGQwLTk2YWQtNGNhNC1iNjJkLWFlNzhhNGEyYTQzMCJ9&pageName=ReportSection'>Dashboard</a> <br>
                </div>
                <div class="contact-box">
                    <br>  <h5>Policies</h5>
                    <a href="#"> <span (click)="showPoliciesPopup('termsConditions')">Terms & Conditions</span></a> <br>
                    <a href="#"> <span (click)="showPoliciesPopup('refundCancel')">Refund & Cancellation </span></a> <br>
                    <a href="#"> <span (click)="showPoliciesPopup('privacyPolicy')">Privacy Policy</span></a> <br>
                   
                </div>
            </div>
            <div class="col-md-3">
                <div class="sitemap-box">
                    <h5>Useful Links</h5>
                    <a target="_blank" href='https://gsws-nbm.ap.gov.in/NBM/Home/Main#'>NBM Portal	</a> <br>
                    <a target="_blank" href='https://meekosam.ap.gov.in/'>Public Grievance Residencial System</a> <br>
                    <a target="_blank" href='https://www.ap.gov.in/#/'>AP State Portal</a> <br>

                </div>

            </div>
            <div class="col-md-4">
                <div class="Visit-box">
                    <h5>Visit Us</h5>
                    <p>Visit Us at. Plot No 11 & 12, Nirmaan Bhavan, APIIC Colony, Jawahar Auto Nagar, Vijayawada -
                        520 007, Krishna District, Andhra Pradesh</p>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3825.435705744872!2d80.67097991465761!3d16.50408688861357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35fbb949235441%3A0xcd3533a2c840d318!2sGSWS%20DEPARTMENT!5e0!3m2!1sen!2sin!4v1659346681463!5m2!1sen!2sin" style="border:0;padding-left:0px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                <div class="contact-box">
                    <h5>Contact Us</h5>
                    <a href="mailto:info@gsws.ap.gov.in">info[at]gsws[dot]ap[dot]gov[dot]in</a>
                </div>

            </div>
        </div>

        <div class="copy-right-box row py-3">
            <div class=" col-md-4 left-copy-right">
                <p>Copyright © <span id="footer-year">2024</span>. All Rights Reserverd by GSWS</p>
            </div>
            <div class="col-md-4"></div>
            <div class=" col-md-4 right-copy-box d-flex">
                <p>Designed, Developed & Maintained by</p>
                <img src="../../assets/home-images/Tata_Consultancy_Services_Logo.svg" alt="tcs-logo">
            </div>
        </div>


    </div>
</footer>

<footer class="footer-btm mt-2" *ngIf="!loginDisplay && (selectedPage == '/apseva-feedback' || selectedPage == '/meeseva-feedback' || selectedPage == '/feedback-status')">
    <div class="container">
        <div class="row py-3">
            <div class="col-md-4">
                <span>Copyright © 2024</span>
            </div>
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <span>Designed, Developed & Maintained by&nbsp;&nbsp;
                    <img src="../../assets/home-images/Tata_Consultancy_Services_Logo.svg" alt="tcs-logo">
                </span>
            </div>
        </div>
    </div>
</footer>




<ng-container *ngIf="!loginDisplay && selectedPage==='/download-file'">
    <app-certificate-download *ngIf="!loginDisplay" [queryParmData]="queryParmData" [selectedPage]="selectedPage"> </app-certificate-download>
</ng-container>
<!-- external payment page -->

<ng-container *ngIf="!loginDisplay && selectedPage==='/Epayment'">
    <app-external-payment *ngIf="!loginDisplay" [queryParmData]="queryParmData" [selectedPage]="selectedPage"> </app-external-payment>
</ng-container>


<ng-container *ngIf="!loginDisplay && selectedPage==='/external-payment-gateway'">
    <app-external-payment-gateway *ngIf="!loginDisplay" [queryParmData]="queryParmData"> </app-external-payment-gateway>
</ng-container>

<ng-container *ngIf="!loginDisplay && selectedPage==='/external-payment-response'">
    <app-external-payment-response *ngIf="!loginDisplay" [queryParmData]="queryParmData"> </app-external-payment-response>
</ng-container>
<!-- <ng-container *ngIf="!loginDisplay && selectedPage==='/home' && showAadudam">
    <div class="modal-backdrop fade show"></div>
    <div class="aadudamPopupCls">
        <a (click)="showAadudam = false;">X</a>
        <img src="../../assets/aadudham-brochure.png" width="800px">
    </div>
</ng-container> -->

  <!--back-to-top start-->
  <a *ngIf="selectedPage==='/csp-service-report'" id="totop" style="background:#000 !important; color:#fff;" class="top-visible" (click)="goToTop()">
    <i class="fa fa-angle-up"></i>
</a>
<!--back-to-top end-->


</ng-container>



<ng-container *ngIf="loginDisplay">
    <app-common-dashboard *ngIf="role === 'Village Revenue Officer' || role === 'Ward Revenue Secretary' || role === 'Revenue Inspector' || role === 'Tahsildar' || role === 'Deputy Tahsildar'
     || role === 'Revenue Divisonal Officer' || role === 'District Revenue Officer' || role === 'Joint Collector' || role === 'Collector' || role === 'Joint Collector Development'
     || role === 'Welfare & Educational Assistant' || role === 'Ward Welfare & Development Secretary' || role === 'Mandal Parishad Development Officers' || role === 'Municipal Commissioner'||
        role === 'Superintendent' || role === 'Assistant Secretary' || role === 'CEO, WAQF board'
     || role === 'Mandal Revenue Officer'|| role === 'HandloomDC' ||role === 'FisheryDC' || role === 'Fisheries Development Officer' ||role === 'ExciseDC' 
     ||role === 'SCWELDC' || role === 'Assistant Social Welfare Officer' ||role === 'DMO' ||  role === 'Aarogyasri District Coordinator' || role === 'Panchayat Secretary DDO' || role === 'Panchayat Secretary' || role === 'Ward Admin Secretary'|| role === 'Village surveyor' || role === 'Mandal surveyor' ||
      role === 'District Civil Supplies Officer' || role === 'District Civil Supplies' || role === 'Assistant Civil Supplies Officer' || role === 'HandloomDO'">
    </app-common-dashboard>
    <app-land-assignment-to-soldiers  *ngIf="role === 'Zilla Sainik Welfare Officer'"></app-land-assignment-to-soldiers>
    <app-dashboard *ngIf="role === 'Digital Assistant' || role === 'Ward Education Secretary' || role === 'Citizen' || role === 'Meeseva' || role === 'IT Minister'"></app-dashboard>
    <app-reports *ngIf="role === 'Reports Admin'"></app-reports>
    <app-payment-reconciliation-approval *ngIf="role === 'Auditor'"></app-payment-reconciliation-approval>
    <app-aarogya-suraksha-doctor-assign-dhmo *ngIf="role === 'District Medical & Health Officer'"></app-aarogya-suraksha-doctor-assign-dhmo>
    <app-vbsy-inventory *ngIf="role === 'VBSY-DNO'"></app-vbsy-inventory>
</ng-container>

<ng-template #videoModal let-modal>
    <!-- <div class="modal-header">
     <h5 class="modal-title">Transaction Details</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
     </button>
    </div> -->
    
    <div class="modal-body">
        <video class="video-fluid" controls width="100%" height="480px">
            <source [src]="videoSrc" type="video/mp4" />
        </video>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss()">Close</button>
    </div>
</ng-template>

<ng-container *ngIf="loginDisplay">
    <app-reconsulation-payment-status *ngIf="role === 'TechAdmin'"> </app-reconsulation-payment-status>
</ng-container>

