import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewricecardviewComponent} from './newricecard/newricecard.component';
import { CivilsuppliesViewRoutingModule} from './civilsupplies-view.routing.module';
import { ViewMemberaddtionComponent } from './view-memberaddtion/view-memberaddtion.component';
import { ViewMemberdeletionComponent } from './view-memberdeletion/view-memberdeletion.component';
import { ViewSpillitingricecardComponent } from './view-spillitingricecard/view-spillitingricecard.component';
import{ ViewSurrenderricecardComponent} from './view-surrenderricecard/view-surrenderricecard.component';
import { ViewCorrectionOfWrongAadharSeedingComponent } from './view-correction-of-wrong-aadhar-seeding/view-correction-of-wrong-aadhar-seeding.component';
import { ViewChangeOfRiceCardComponent } from './view-change-of-rice-card/view-change-of-rice-card.component';
import { FpShopViewComponent } from './fp-shop-view/fp-shop-view.component'

@NgModule({
  declarations: [
    NewricecardviewComponent,
    ViewMemberaddtionComponent,
    ViewMemberdeletionComponent,
    ViewSpillitingricecardComponent,
    ViewSurrenderricecardComponent,
    ViewCorrectionOfWrongAadharSeedingComponent,
    ViewChangeOfRiceCardComponent,
    FpShopViewComponent
   
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CivilsuppliesViewRoutingModule,
    
  ],
  exports: [
    CommonModule,
    NewricecardviewComponent,
    ViewMemberaddtionComponent,
    ViewMemberdeletionComponent,
    ViewSpillitingricecardComponent,
    ViewSurrenderricecardComponent,
    ViewCorrectionOfWrongAadharSeedingComponent,
    ViewChangeOfRiceCardComponent,
    FpShopViewComponent
 ]
})
export class CivilsuppliesViewModule { 

}
