import { CommonConstants } from '../../constants/common-constants.component';
import { CommonService,CustomAdapter,CustomDateParserFormatter,WhitespaceValidator,AadharValidator } from '../../shared/common.service';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal, NgbModalConfig,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerPopupComponent } from '../../shared/pdf-viewer-popup/pdf-viewer-popup.component';
import { CommonMsgModalComponent } from '../../shared/common-msg-modal/common-msg-modal.component';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray} from '@angular/forms';
import * as moment from 'moment';
import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DigitalSignService } from 'src/shared/digital-sign.service';
import { DatePipe } from '@angular/common';
import { fileExtensionValidator,fileSizeValidator } from 'src/shared/validators/file-validator';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { Thumbs } from 'swiper';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EnvironmentService } from '../../shared/environment.service';
import { IncomeUpdate } from '../revenue-view/income-certificate-view/income-certificate-view.component';
import { AccountDetailsPopupComponent } from '../../shared/account-details-popup/account-details-popup.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CommonFormViewComponent } from 'src/shared/common-form-view/common-form-view.component';
import { IntegratedCertificateViewComponent } from '../revenue-view/integrated-certificate-view/integrated-certificate-view.component';


@Component({
  selector: 'app-common-dashboard',
  templateUrl: './common-dashboard.component.html',
  styleUrls: ['./common-dashboard.component.css'],
  providers: [DatePipe,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]

})


export class CommonDashboardComponent implements OnInit, OnChanges {
  consent: Boolean = false;
  fdoConsent: Boolean = false;
  officerData: any;
  @ViewChild('closebutton') closebutton: any;
  @Input() incomeUpdated: IncomeUpdate | any;
  //this.closebutton.nativeElement.click();
  isVerifiedDocument:HTMLInputElement | undefined;
  incomeUpdate:any
  modelTitle: string = "";
  modelMessage: string = "";
  modelbtnCancelText: string = 'Proceed';
  modelbtnOkText: string = 'Cancel';
  maxDate: any;
  minDate: any;
  landavailableforgrantvalue: any;
  enabledisablebutton: any;
  vacantlandvalue: any;
  verifytknSerialNo : string = "";
  currentDate = new Date();
  modalFormName : string = "";
  modalFormButtonName : string = "";
  modalFormActionCode : number = 0;
  buttonStatus : string = "";
  showCSdata:boolean = true;
  isForwardAvailable: Boolean = false;
  qualifications: any[] = [];
  religions: any[] = [];
  imgSrc:any;
  showTable: boolean = false;
  //emudhra start
  emudhraformGroup: FormGroup ;
  aadharformGroup: FormGroup ;
  dateTimeformGroup: FormGroup ;
  rectificationForm : FormGroup;
  sumotoForm: FormGroup;
  sumotoFormForRI: FormGroup;
  sumotoFormForTahsildar: FormGroup;
  workflowstepcode: any;
  isspecialcasteforsuomoto: boolean = false;
  public submitted: Boolean = false;
  public eMudhraSubmitted: Boolean = false;
  public aadharSubmitted: Boolean = false;
  public dateTimeFormSubmitted: Boolean = false;
  public showFields:Boolean=false;
  public isGenerate:Boolean=false;
  isSigning : boolean = false;
  isCSclick : boolean = true;
  isAadharValidated : boolean = false;
  isNative : Boolean = true;
  isDOBCorrect: Boolean = true;
  requsitionID : string = "";
  JSON: any;
  tokenDetails = '';
  tokenList :any;
  loggedInMandalCode: any;
  certificateList :any;
  certificateTokenId = '';
  keyStorePassphrase = '';
  sumotoverificationDocforRIBlob: any;
  sumotoverificationDocforTahsildarBlob: any;
  isSentOTP : boolean = false;
  isVerifiedOTP : boolean = false;
  approveFlag: Boolean = false;
  rejectFlag: Boolean = false;
  isTidcoOptionValue: Boolean = false;
  @Input('digitalSign') digiSignPopUp = true;
  @Output() digitalSignChange = new EventEmitter<boolean>();
  @Output() docGenChange = new EventEmitter<any>();
  @Output() docSubChange = new EventEmitter<any>();
  //emudhra end 
  commonConstants : any = CommonConstants;
  page = 1;
  pageSize = 10;
  popupPage = 1;
  popupPageSize = 10;
  totalPageCount: any;
  currentRole: any;
  nextRole: any;
  departmentCode : string='';
  //items : { SNo: number, requestId: string, serviceName : string, department : string, serviceCategory : string, citizenName: string, mobileNo: number, appliedDate: string, status: string }[] = [];
  basicInfo: any = [];
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    classList: {
      inputBox: "my-super-box-class",
      input: "my-super-class",
      inputFilled: "my-super-filled-class",
      inputDisabled: "my-super-disable-class",
      inputSuccess: "my-super-success-class",
      inputError: "my-super-error-class"
    }
  }
  isApproveExist: Boolean = false;
  isForwardExist: Boolean = false;
  receivedRequestionId : string = '';
  downloadCertId : string = '';
  certificateTypeName : string = '';
  certificateInfo: any = [];
  sumotocasteInfo: any = [];
  citizenAadharNumber : string = '';
  fileList: any = [];
  docList: any = [];
  mainRef : any;
  fileContents : Blob | undefined;
  closeResult: string ="";
  mailId: string = '';
  role: string = '';
  vroSelected: Boolean | undefined;
  RISelected: Boolean | undefined;
  serviceList: any[] = [];
  items: any[] = [];
  appVillages: any[] = [];
  revenueVillages: any[] = [];
  showRequisitionInfo: Boolean = false;
  submits: Boolean = false; 
  sumotoSubmits: Boolean = false;
  sumotoSubmitsforTahsildar: Boolean = false;
  sumotoSubmitsforRI: Boolean = false;
  submitTable: Boolean = false;
  serviceName: any;
  showCluster :Boolean = false;
  reqId: any;
  taskId: any;
  fpShopValiditydate:any;
  sumotoid: any;
  form: FormGroup;
  clusterForm!: FormGroup;
  certificateName: string | undefined;
  checklist: any = [];
  checklistForm!: FormGroup;
  remarksForm!: FormGroup;
  testForm!: FormGroup;
  fisheriesForm: FormGroup;
  dappuArtistsForm: FormGroup;
  traditionalCobblerform: FormGroup;
  handloomWeaverForm: FormGroup;
  weaverADOHDTOForm: FormGroup;
  zillasainikwelfareform!: FormGroup;
  zillasainikwelfareformforJC: FormGroup;
  zillasainikwelfareformforJC2!: FormGroup;
  // submitted: Boolean = false;

  VSHistory: any = [];
  MSHistory: any = [];
  TSHistory: any = [];
  VROHistory: any = [];
  RIHistory: any = [];
  TEHHistory: any = [];
  DTHistory: any= [];
  RDOHistory: any = [];
  JCHistory: any = [];
  PSHistory: any = [];
  AS2History: any = [];
  SPT3History: any = [];
  CEOWAQFHistory: any = [];
  remarksOptions: any = [];
  remarksReasonsOptions: any = [];
  remarksReasonsOptionsDynamic: any = [];
  beyondSLAItems: any = [];
  withInSLAItems: any = [];
  totalCount: Number = 0;
  workListName: string = 'Total Requests';
  presentAddr: any = [];
  permanentAddr: any = [];
  commonFormAddedFields : any = {
    aadharNum : ''
  };
  aadharUID : string="";
  aadharTransactionID: string="";
  availableActions: any = [];
  isApprovalAuthority: Boolean = false;
  currentUser: any;
  CSDistrictCode: any;
  fdoDocumentBlob: any;
  copyOfBankEntryBlob: any;
  copyOfLicenceBlob: any;
  loomWorkingConditionBlob: any;
  undertakingFromPHWCBlob: any;
  productionAtPHWCBlob: any;
  wagesProofBlob: any;
  undertakingFromMasterBlob: any;
  yarnPurchaseBlob: any;
  fPShopPhotoBlob:any;
  sumotoverificationDocBlob: any;
  filterfileforverificationdocument: any;
  filterfileforsupportingdocument: any;
  @ViewChild('fdoDocumentLabel')
  fdoDocumentLabel!: ElementRef;
  @ViewChild('verificationDocLabel')
  verificationDocLabel!: ElementRef;
  @ViewChild('copyOfBankEntryLabel')
  copyOfBankEntryLabel!: ElementRef;
  @ViewChild('copyOfLicenceLabel')
  copyOfLicenceLabel!: ElementRef;
  @ViewChild('casteVerificationDocLabel')
  casteVerificationDocLabel!: ElementRef;
  @ViewChild('loomWorkingConditionLabel')
  loomWorkingConditionLabel!: ElementRef;
  @ViewChild('undertakingFromPHWCLabel')
  undertakingFromPHWCLabel!: ElementRef;
  @ViewChild('productionAtPHWCLabel')
  productionAtPHWCLabel!: ElementRef;
  @ViewChild('wagesProofLabel')
  wagesProofLabel!: ElementRef;
  @ViewChild('undertakingFromMasterLabel')
  undertakingFromMasterLabel!: ElementRef;
  @ViewChild('yarnPurchaseLabel')
  yarnPurchaseLabel!: ElementRef;
  @ViewChild('sumotoverificationDocLabel')
  sumotoverificationDocLabel!:ElementRef;
  @ViewChild('supportingDocumentsLabel')
  supportingDocumentsLabel!: ElementRef;
  @ViewChild('sumotoverificationDocforRILabel')
  sumotoverificationDocforRILabel!: ElementRef; 
  @ViewChild('sumotoverificationDocforTahsildarLabel')
  sumotoverificationDocforTahsildarLabel!: ElementRef;
  reasonDocList: any = [];
  selectedItems: any = [];
  dropdownSettings:IDropdownSettings = {};
  //civilSupplies
  sixstepForm!: FormGroup;
  fpShopForm!:FormGroup
  myDate = new Date();
  relations: any;
  genders: any;
  clusterData: any []=[];
  aarogyaSriAction:any;
  aarogyaSriActionCode:any;
  aarogySriTargetModal:any;
  aarogyaSriconfirmationOTPModal:any;
  
  requisitionDate: any;
  divisionName: any;
  householdId:any;
  public landHoldingFlag :Boolean=false;
  public incomeTaxFlag :Boolean=false;
  public electricityFlage :Boolean=false;
  public fourWheelerFlag :Boolean=false;
  public govtempFlag :Boolean=false;
  public municipalFlag :Boolean=false;
  public reasonFlag :Boolean=false;
  public isFpShopApplication :Boolean=false;
  clusterDetails: any;
  Dob: any;
  serviceAllList:any = [];
  servicesData :any =[]; 
  @Output() shareSixStepDataForPrint = new EventEmitter<any>();
  radioClick: any;
  VillageId: any;
  fpshop: any;
  fpshopData: any;
  keyValue: any;
  arrayCluster: any=[];
  transactionID: string = '';
  userIdData : string = '';
  userName : string = '';
  tempItems: any = [];
  isHouseholdSplitApp: Boolean = false;
  isRectification:Boolean = false;
  
  // tempItems: any;
  // pension
  WEAWWDSHistory: any =[];
  MROHistory: any =[];
  OtherDCHistory: any =[];
  fisheryDCHistory: any =[];
  FDOHistory: any =[];
  ASWOHistory: any = [];
  ADODHTOHistory: any = [];
  scweldcDCHistory: any =[];
  exciseDCHistory: any =[];
  dmoDCHistory: any =[];
  details:any;
  // pension
  time = {hour: 13, minute: 30};
  meridian = true;
  // pensions
  userInformation: any;
  loggedinDetails: any;
  loggedinuserId: any;
  certificateResult: any;
  loggedinsecid: any;
  habitationData: any;
  houseHoldId: any;
  panchayatData: any;
  // casteCategory: any;
  // pensions
  
  dashbardVroCounts: any = [];
  dashbardpopupTitle: any = [];
  sixStepPostData:any;
  eMudhradisplayName: any;
  getId: any;
  pensionId: any;
  isActionBtn: Boolean = false;
  isFormValid: Boolean = false;
  ispostedDepartment: boolean=false;
  showBiometricPopup: Boolean = false;
  windowObjectReference: any;
  autoBiometricId: any;
  encrtyptedAadhar: any;
  UHID:any;
  isProdEnv: Boolean = false; 

  isSavedToCosmos:Boolean=false;
  SLA:any;
  isUrban: boolean = false;
  secCode: any;
  sumsalary: number = 0;
  certificate:any;
  casteCategory: any[] = [];
  subCasteData: any[] =[];
  updatebuttonflag: boolean = false;
  updatebuttonflagforRI: boolean = false;
  updatebuttonflagforTahsildar: boolean = false;
  suomotodataforalllogins: any = [];
  constructor(private commonService: CommonService, private modalService: NgbModal,private datePipe: DatePipe, private formBuilder: FormBuilder,  private digiSign: DigitalSignService,private utils: CommonService, config: NgbModalConfig,
    private environmentService: EnvironmentService, private router: Router, private currentRoute: ActivatedRoute, private commonformview: CommonFormViewComponent, private icviewComponent: IntegratedCertificateViewComponent) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'documentName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };
    this.showCSdata = true;
    // this.verifytknSerialNo = "16282201";
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
    this.emudhraformGroup = this.formBuilder.group({
      ddTokens : [null, [Validators.required]],
      ddCertificates : [null, [Validators.required]],
      ddPassword : ['', [Validators.required]],
    });
    this.aadharformGroup = this.formBuilder.group({
      ddAadharNo : ['' ], //, [Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate,]],
      // ddOTP : ['', Validators.required],
      authenticationType: ['OTP']
    });
    this.dateTimeformGroup = this.formBuilder.group({
      ddDate : [null, [Validators.required]],
      ddTime : [this.time, Validators.required],
    });
    this.remarksForm = this.formBuilder.group({
      remarks: [null, [Validators.required]],
      remarksReasons: [null],
      docName:[this.selectedItems],
      otherRemarks: ['', [Validators.pattern(this.commonConstants.alphanumericPattern)]],
      verificationDoc:  [],
      supportingDocuments: [],
      casteVerificationDoc: [],
      fdoDocument: [null],
      districtforsuomotovro: [null],
      mandalforsuomotovro: [null],
      secretariatforsuomotovro: [null],
      districtforsuomotori: [null],
      mandalforsuomotori: [null],
      secretariatforsuomotori: [null],
      districtforsuomototeh: [null],
      mandalforsuomototeh: [null],
      secretariatforsuomototeh: [null],
      districtforsuomotordo: [null],
      mandalforsuomotordo: [null],
      secretariatforsuomotordo: [null],
      districtforsuomotojc: [null],
      mandalforsuomotojc: [null],
      secretariatforsuomotojc: [null]
    });
    this.rectificationForm = this.formBuilder.group({
      // Grievance: [null],
      remarksByVS: [null ,[WhitespaceValidator.whiteSpaceValidate]],
    });
    this.sumotoForm = this.formBuilder.group({
      caste: [null],
      subCaste: [null],
      religion: [null],
      sumotoverificationDoc: [null],
      maritalStatus: [null],
      fathername: [null],
      mobile: [null],
      revenuevillage: [null],
      email: [null],
      qualification: [null],
      dob: [null],
      statusofhouseholdVRO: [null]
    });
    this.sumotoFormForRI = this.formBuilder.group({
      casteforsumotoRI: [null],
      subCasteforsumotoRI: [null],
      religionforsumotoRI: [null],
      maritalStatusforsumotoRI: [null],
      fathernameforsumotoRI: [null],
      mobileforsumotoRI: [null],
      revenuevillageforsumotoRI: [null],
      emailforsumotoRI: [null],
      qualificationforsumotoRI: [null],
      dobforsumotoRI: [null],
      sumotoverificationDocforRI: [null],
      statusofhouseholdRI: [null]
    });
    this.sumotoFormForTahsildar = this.formBuilder.group({
      casteforsumotoTahsildar: [null],
      subCasteforsumotoTahsildar: [null],
      religionforsumotoTahsildar: [null],
      maritalStatusforsumotoTahsildar: [null],
      fathernameforsumotoTahsildar: [null],
      mobileforsumotoTahsildar: [null],
      revenuevillageforsumotoTahsildar: [null],
      emailforsumotoTahsildar: [null],
      qualificationforsumotoTahsildar: [null],
      dobforsumotoTahsildar: [null],
      sumotoverificationDocforTahsildar: [null],
      statusofhouseholdTEH: [null]
    })
    this.form = this.formBuilder.group({
      panchayatName: [''],
      DisASONo: [''],
      DisHeadNo: [''],
      panchayatType: ['Grampanchayat']
    });
    // this.clusterForm = this.formBuilder.group({
     
    // });

    this.fisheriesForm = this.formBuilder.group({
      isFishingProfession: [null],
      noOfYears: [null],
      typeOfFishermen: [null],
      marineFCSMember: [null],
      marineAdmissionNo: [null],
      marineDateOfAdmission: [null],
      validBiometricId: [null],
      mfidNo: [null],
      banOnFishingProf: [null],
      copyOfBankEntry: [null],
      inlandFCSMember: [null],
      inlandAdmissionNo: [null],
      inlandDateOfAdmission: [null],
      inlandExtent: [null],
      inlandNoOfTanks: [null],
      individualLicenceHolder: [null],
      copyOfLicence: [null]
    });
    this.dappuArtistsForm = this.formBuilder.group({
      instrumentInhouse: [null],
      playDappu: [null],
      dappuType: [null],
      noOfYears: [null],
      lastProgDate: [null],
      address: [null],
      existPension: [null],
      pensionId: [null]
    });
    this.traditionalCobblerform = this.formBuilder.group({  
      isCobblingMainOcc: [null],
      isHavingCobblerShop: [null],
      noOfYearsIntoCobbling: [null],
      receivingCobblerPension: [null],
      pensionId: [null],
      cobblingAddress: [null]
 	 	});
    this.handloomWeaverForm = this.formBuilder.group({  
      isLoomPresentInHouse: [null],
      loomType: [null],
      loomWorkingCondition: [null],
      loomWorkingConditionDocument: [],
      noOfYearsIntoWeaving: [null],
      workingUnder: [null],
      benifittedUnderNethannaNestham: [null],
      receivingWeaverPension: [null],
      pensionId: [null],
    });
    this.zillasainikwelfareform = this.formBuilder.group({
      remarksByVroZswo: [null],
      lpmnumbers: [[]],
      landavailableforgrant: [null],
      vacantland: [null],
      tableCheckbox: [null],
      proposedextent: [null],
    })
    this.zillasainikwelfareformforJC = this.formBuilder.group({
      allotmentoflandjc:[null],
      recommendationofrdoandjc: [null],
     
    })
    this.zillasainikwelfareformforJC2 = this.formBuilder.group({
      assignmentapproveddistJC: [null],
      grantofassignmentlandjc: [null],
      ror1bJC: [null]
    })

    this.weaverADOHDTOForm = this.formBuilder.group({
      admissionDate: [null],
      undertakingFromPHWC: [null],
      undertakingFromPHWCDocument: [],
      productionAtPHWC: [null],
      productionAtPHWCDocument: [],
      workingDate: [null],
      wagesProofDocument: [],
      undertakingMasterDocument: [],
      erectionLoomDate: [null],
      yarnPurchaseDocument: [],
      yarnPassBookOrBills: [null],
      isWeaverConsuming: [null],
      loomFunctioningEnquired: [null],
      varitiesProduced: [null]
    });
    this.sixstepForm = this.formBuilder.group({
      cluster : [null],
      fpshop : [null],
      socialAudit:[null],
      // remarksCivilSupplies:[null,[Validators.required]],
      // otherRemarksCivilSupplies:[null,[Validators.required]],


      tableData: this.formBuilder.array([this.addFormGroup()]),
    })
    this.fpShopForm = this.formBuilder.group({
       latofFpShop:[null],
       longofFpShop:[null],
       fpShopPhoto:[null],
       isSameLocation:[null]
    })
    this.isCSclick = true
    this.getServiceListData();
    this.formatAMPM()

    if (/^vswsonline.ap.gov.in/.test(window && window.location && window.location.hostname)) { 
          this.isProdEnv = true;
    }
   }

  //  approveRequest(){
  //   // this.sendToApprove_OR_Reject_OR_Forward(this.yPostData,this.yAction,this.yActionCode ,this.yTargetModal)
  // }

   alertDateTimeZone : any;
   formatAMPM() {
    var date = new Date(); 
      var hours : any = date.getHours();
      var minutes : any= date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime =  date.getDate() + "/"  + (date.getMonth()+1)  + "/"  + date.getFullYear() + ' '+ hours + ':' + minutes + ' ' + ampm;
      this.alertDateTimeZone =  date.getDate() + "/" + (date.getMonth()+1)  + "/" + date.getFullYear() + ' '+ hours + ':' + minutes + ' ' + ampm;
      return strTime; 
   }
  
timerRun :any
  display: any;
  timer(minute: number) {
    // let minute = 1;
    let seconds: number = minute * 30;
    let textSec: any = "0";
    let statSec: number = 30;

    const prefix = minute < 10 ? "0" : "";

    this.timerRun = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 29;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 30)}:${textSec}`;

      if (seconds == 0) {
        this.isSentOTP = false;
        //console.log("finished");
        clearInterval(this.timerRun);
      }
    }, 1000);
  }

   addFormGroup(): FormGroup {
    return this.formBuilder.group({ 
      name: [''],
      mobileNo:[''],
      gender:[''],
      relation:[''],
      migration:[''],
      income:[''],
      statusIncome:[''],
      // statusIncome:['',[Validators.required]],
      govtEmploye:[''],
      statusEmployee:[''],
      incomeTax:[''],
      fourWheeler:[''],
      statusWheeler:[''],
      landHolding:[''],
      // statusLand:['', [Validators.required]],
      statusLand:[''],

      muncipalProperty:[''],
      statusMunicipal:[''],
      electricity:[''],
      // statusElectricity:['',[Validators.required]],
      statusElectricity:[''],

    })
  }
  createSixstep(){
    this.tableDatas.push(
      this.formBuilder.group({ 
      name: [''],
      mobileNo:[''],
      gender:[''],
      relation:[''],
      migration:[''],
      income:[''],
      statusIncome:[''],
      // statusIncome:['',[Validators.required]],
      govtEmploye:[''],
      incomeTax:[''],
      fourWheeler:[''],
      landHolding:[''],
      // statusLand:['', [Validators.required]],
      statusLand:[''],

      muncipalProperty:[''],
      electricity:[''],
      // statusElectricity:['',[Validators.required]],
      statusElectricity:[''],
      statusEmployee:[''],
      statusWheeler:[''],
      statusMunicipal:['']



      // socialAudit:[null,[Validators.required]],

      })
    );
  }

  landavailableforgrant(event: any) {
    this.landavailableforgrantvalue = event.target.value;
    this.z1.vacantland.patchValue(null);
    this.vacantlandvalue = false;
    this.showTable = false;
    this.z1.lpmnumbers.patchValue(null);
    this.lpnumberdatafromtable = [];
  }

  vacantland(event: any) {
    this.vacantlandvalue = event.target.value;
    this.showTable = false;
    this.z1.lpmnumbers.patchValue(null);
  }
  
  get tableDatas() {
    return this.sixstepForm.get('tableData') as FormArray;
  }
   openModal(targetModal: any) {
    this.mainRef = 
    this.modalService.open(targetModal, 
      { size: 'lg' ,
        centered: true,
        backdrop: 'static'
      }
    );
  }
  submitCluster(event:any){

    this.keyValue = (event.target.value);
    console.log("split",this.keyValue);
 
    this.submits=true;
    this.showCluster =false;
    this.getfpshopDetails();
    this.k.fpshop.patchValue(null); 
       if(this.sixstepForm.controls['cluster'].value && this.sixstepForm.controls['fpshop'].value){

    this.showCluster =true;
    this.submits=false;
    }
    else{
      this.showCluster =false;

    }

  }

  updateSumotoData(action: any, actionCode: any,targetModal: any,confirmationOTPModal: any, from?:any) {
    // this.isFormValid = data.isValid;
    this.sumotoFormControls.caste.setValidators([Validators.required]);
    this.sumotoFormControls.caste.updateValueAndValidity();
    this.sumotoFormControls.subCaste.setValidators([Validators.required]);
    this.sumotoFormControls.subCaste.updateValueAndValidity();
    this.sumotoFormControls.sumotoverificationDoc.setValidators([Validators.required]);
    this.sumotoFormControls.sumotoverificationDoc.updateValueAndValidity();
    this.sumotoFormControls.fathername.setValidators([Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]);
    this.sumotoFormControls.fathername.updateValueAndValidity();
    this.sumotoFormControls.mobile.setValidators([Validators.required,Validators.pattern(this.commonConstants.mobilePattern)]);
    this.sumotoFormControls.mobile.updateValueAndValidity();
    this.sumotoFormControls.revenuevillage.setValidators([Validators.required]);
    this.sumotoFormControls.revenuevillage.updateValueAndValidity();
    this.sumotoFormControls.religion.setValidators([Validators.required]);
    this.sumotoFormControls.religion.updateValueAndValidity();
    this.sumotoFormControls.email.setValidators([Validators.pattern(this.commonConstants.emailPattern)]);
    this.sumotoFormControls.email.updateValueAndValidity();
    this.sumotoFormControls.statusofhouseholdVRO.setValidators([Validators.required]);
    this.sumotoFormControls.statusofhouseholdVRO.updateValueAndValidity();
    this.sumotoSubmits = true;
    this.updatebuttonflag = false;
if(this.sumotoForm.valid) {
  this.sumotoFormControls.caste.clearValidators();
  this.sumotoFormControls.subCaste.clearValidators();
  this.sumotoFormControls.sumotoverificationDoc.clearValidators();
  this.sumotoFormControls.fathername.clearValidators();
  this.sumotoFormControls.mobile.clearValidators();
  this.sumotoFormControls.revenuevillage.clearValidators();
  this.sumotoFormControls.email.clearValidators();
  this.sumotoFormControls.religion.clearValidators();
  this.sumotoSubmits = true;
  let postData = {
    requisitionId: this.sumotoid,
    departmentCode: this.basicInfo.department,
    mobilenumber: this.sumotoFormControls.mobile.value,
    doorno: this.suomotodataforalllogins.doorNo,
    streetName: this.suomotodataforalllogins.streetName,
    clusterId: this.suomotodataforalllogins.clusterid,
    "approverData": {
      caste: this.sumotoFormControls.caste.value,
      subcaste: this.sumotoFormControls.subCaste.value,
      relationName: this.sumotoFormControls.fathername.value,
      revenuevillage: this.sumotoFormControls.revenuevillage.value,
      email: this.sumotoFormControls.email.value,
      maritalStatus: this.sumotoFormControls.maritalStatus.value,
      qualification: this.sumotoFormControls.qualification.value,
      birthDate: (this.sumotoFormControls.dob.value) ? (this.commonService.formatDate(this.sumotoFormControls.dob.value)) : '1900-01-01',
      religion: this.sumotoFormControls.religion.value,
      mobilenumber: this.sumotoFormControls.mobile.value,
      headOfHouseHold: this.sumotoFormControls.statusofhouseholdVRO.value
    }
    
  }
  console.log(postData, this.basicInfo, "postData");
  this.commonService.postRequest(this.commonConstants.updateCosmosSumotoData, postData).subscribe({
    next: (responseData: any) => {
      if(responseData.result.isSuccess) {
        this.updatesumotoFile('sumotoverificationDoc',action, actionCode,targetModal,confirmationOTPModal,from);
        // this.commonService.commonErrorPopup('Alert', 'Data Updated Successfully', 'md')
      
      }
      else if(responseData.result.isSuccess == false) {
        this.commonService.commonErrorPopup('Alert', 'Data not submitted please try again', 'md')
      } 
    },
    error: (error) => {
      console.log('client side', error)
    }
  });
}
this.sumotoFormControls.caste.updateValueAndValidity();
this.sumotoFormControls.subCaste.updateValueAndValidity();
this.sumotoFormControls.sumotoverificationDoc.updateValueAndValidity();
this.sumotoFormControls.fathername.updateValueAndValidity();
this.sumotoFormControls.mobile.updateValueAndValidity();
this.sumotoFormControls.revenuevillage.updateValueAndValidity();
this.sumotoFormControls.email.updateValueAndValidity();
this.sumotoFormControls.religion.updateValueAndValidity();
}

updateSumotoDataForRI(action: any, actionCode: any,targetModal: any,confirmationOTPModal: any, from?:any) {
  this.sumotoFormControlsForRI.casteforsumotoRI.setValidators([Validators.required]);
  this.sumotoFormControlsForRI.casteforsumotoRI.updateValueAndValidity();
  this.sumotoFormControlsForRI.subCasteforsumotoRI.setValidators([Validators.required]);
  this.sumotoFormControlsForRI.subCasteforsumotoRI.updateValueAndValidity();
  this.sumotoFormControlsForRI.fathernameforsumotoRI.setValidators([Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]);
  this.sumotoFormControlsForRI.fathernameforsumotoRI.updateValueAndValidity();
  this.sumotoFormControlsForRI.mobileforsumotoRI.setValidators([Validators.required,Validators.pattern(this.commonConstants.mobilePattern)]);
  this.sumotoFormControlsForRI.mobileforsumotoRI.updateValueAndValidity();
  this.sumotoFormControlsForRI.revenuevillageforsumotoRI.setValidators([Validators.required]);
  this.sumotoFormControlsForRI.revenuevillageforsumotoRI.updateValueAndValidity();
  this.sumotoFormControlsForRI.religionforsumotoRI.setValidators([Validators.required]);
  this.sumotoFormControlsForRI.religionforsumotoRI.updateValueAndValidity();
  this.sumotoFormControlsForRI.emailforsumotoRI.setValidators([Validators.pattern(this.commonConstants.emailPattern)]);
  this.sumotoFormControlsForRI.emailforsumotoRI.updateValueAndValidity();
  this.sumotoFormControlsForRI.statusofhouseholdRI.setValidators([Validators.required]);
  this.sumotoFormControlsForRI.statusofhouseholdRI.updateValueAndValidity();
  // if (this.fileAutoUploaded) {
  //   this.docList.push({
  //     fileName: this.certificateResult.fileList[0].fileName,
  //     documentType: this.certificateResult.fileList[0].documentType,
  //     blobRef: this.certificateResult.fileList[0].blobRef
  //   });
  //   this.sumotoFormControlsForRI.sumotoverificationDocforRI.clearValidators();
  //   this.sumotoFormControlsForRI.sumotoverificationDocforRI.updateValueAndValidity();
  // } else {
    // this.sumotoFormControlsForRI.sumotoverificationDocforRI.setValidators([Validators.required]);
    // this.sumotoFormControlsForRI.sumotoverificationDocforRI.updateValueAndValidity();
  //}
  this.sumotoSubmitsforRI = true;
  this.updatebuttonflagforRI = false;
if(this.sumotoFormForRI.valid) {
this.sumotoFormControlsForRI.casteforsumotoRI.clearValidators();
this.sumotoFormControlsForRI.subCasteforsumotoRI.clearValidators();
this.sumotoFormControlsForRI.fathernameforsumotoRI.clearValidators();
this.sumotoFormControlsForRI.mobileforsumotoRI.clearValidators();
this.sumotoFormControlsForRI.revenuevillageforsumotoRI.clearValidators();
this.sumotoFormControlsForRI.emailforsumotoRI.clearValidators();
this.sumotoFormControlsForRI.religionforsumotoRI.clearValidators();
this.sumotoFormControlsForRI.sumotoverificationDocforRI.clearValidators();
this.sumotoFormControlsForRI.statusofhouseholdRI.clearValidators();
this.sumotoSubmitsforRI = true;
let postData = {
  requisitionId: this.sumotoid,
  departmentCode: this.basicInfo.department,
  mobilenumber: this.sumotoFormControls.mobile.value,
  doorno: this.suomotodataforalllogins.doorNo,
  streetName: this.suomotodataforalllogins.streetName,
  clusterId: this.suomotodataforalllogins.clusterid,
  "approverData": {
    mobilenumber: this.sumotoFormControlsForRI.mobileforsumotoRI.value,
    caste: this.sumotoFormControlsForRI.casteforsumotoRI.value,
    subcaste: this.sumotoFormControlsForRI.subCasteforsumotoRI.value,
    relationName: this.sumotoFormControlsForRI.fathernameforsumotoRI.value,
    revenuevillage: this.sumotoFormControlsForRI.revenuevillageforsumotoRI.value,
    email: this.sumotoFormControlsForRI.emailforsumotoRI.value,
    maritalStatus: this.sumotoFormControlsForRI.maritalStatusforsumotoRI.value,
    qualification: this.sumotoFormControlsForRI.qualificationforsumotoRI.value,
    birthDate: (this.sumotoFormControlsForRI.dobforsumotoRI.value) ? (this.commonService.formatDate(this.sumotoFormControlsForRI.dobforsumotoRI.value)) : '1900-01-01',
    religion: this.sumotoFormControlsForRI.religionforsumotoRI.value,
    headOfHouseHold: this.sumotoFormControlsForRI.statusofhouseholdRI.value
  }, 
}
this.commonService.postRequest(this.commonConstants.updateCosmosSumotoData, postData).subscribe({
  next: (responseData: any) => {
    if(responseData.result.isSuccess) {
      this.updatesumotoFile('sumotoverificationDocforRI',action, actionCode,targetModal,confirmationOTPModal,from);
      // this.commonService.commonErrorPopup('Alert', 'Data Updated Successfully', 'md');
    }
    else if(responseData.result.isSuccess == false) {
      this.commonService.commonErrorPopup('Alert', 'Data not submitted please try again', 'md')
    } 
  },
  error: (error) => {
    console.log('client side', error)
  }
});
}
this.sumotoFormControlsForRI.casteforsumotoRI.updateValueAndValidity();
this.sumotoFormControlsForRI.subCasteforsumotoRI.updateValueAndValidity();
this.sumotoFormControlsForRI.fathernameforsumotoRI.updateValueAndValidity();
this.sumotoFormControlsForRI.mobileforsumotoRI.updateValueAndValidity();
this.sumotoFormControlsForRI.revenuevillageforsumotoRI.updateValueAndValidity();
this.sumotoFormControlsForRI.emailforsumotoRI.updateValueAndValidity();
this.sumotoFormControlsForRI.religionforsumotoRI.updateValueAndValidity();
this.sumotoFormControlsForRI.sumotoverificationDocforRI.updateValueAndValidity();
this.sumotoFormControlsForRI.statusofhouseholdRI.updateValueAndValidity();
}

updateSumotoDataForTahsildar(action: any, actionCode: any,targetModal: any,confirmationOTPModal: any, from?:any) {
this.sumotoFormControlsForTahsildar.casteforsumotoTahsildar.setValidators([Validators.required]);
this.sumotoFormControlsForTahsildar.casteforsumotoTahsildar.updateValueAndValidity();
this.sumotoFormControlsForTahsildar.subCasteforsumotoTahsildar.setValidators([Validators.required]);
this.sumotoFormControlsForTahsildar.subCasteforsumotoTahsildar.updateValueAndValidity();
this.sumotoFormControlsForTahsildar.fathernameforsumotoTahsildar.setValidators([Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]);
this.sumotoFormControlsForTahsildar.fathernameforsumotoTahsildar.updateValueAndValidity();
this.sumotoFormControlsForTahsildar.mobileforsumotoTahsildar.setValidators([Validators.required,Validators.pattern(this.commonConstants.mobilePattern)]);
this.sumotoFormControlsForTahsildar.mobileforsumotoTahsildar.updateValueAndValidity();
this.sumotoFormControlsForTahsildar.revenuevillageforsumotoTahsildar.setValidators([Validators.required]);
this.sumotoFormControlsForTahsildar.revenuevillageforsumotoTahsildar.updateValueAndValidity();
this.sumotoFormControlsForTahsildar.religionforsumotoTahsildar.setValidators([Validators.required]);
this.sumotoFormControlsForTahsildar.religionforsumotoTahsildar.updateValueAndValidity();
this.sumotoFormControlsForTahsildar.emailforsumotoTahsildar.setValidators([Validators.pattern(this.commonConstants.emailPattern)]);
this.sumotoFormControlsForTahsildar.emailforsumotoTahsildar.updateValueAndValidity();
this.sumotoFormControlsForTahsildar.statusofhouseholdTEH.setValidators([Validators.required]);
this.sumotoFormControlsForTahsildar.statusofhouseholdTEH.updateValueAndValidity();
// this.sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.setValidators([Validators.required]);
// this.sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.updateValueAndValidity();
this.sumotoSubmitsforTahsildar = true;
this.updatebuttonflagforTahsildar = false;
if(this.sumotoFormForTahsildar.valid) {
this.sumotoFormControlsForTahsildar.casteforsumotoTahsildar.clearValidators();
this.sumotoFormControlsForTahsildar.subCasteforsumotoTahsildar.clearValidators();
this.sumotoFormControlsForTahsildar.fathernameforsumotoTahsildar.clearValidators();
this.sumotoFormControlsForTahsildar.mobileforsumotoTahsildar.clearValidators();
this.sumotoFormControlsForTahsildar.revenuevillageforsumotoTahsildar.clearValidators();
this.sumotoFormControlsForTahsildar.emailforsumotoTahsildar.clearValidators();
this.sumotoFormControlsForTahsildar.religionforsumotoTahsildar.clearValidators();
this.sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.clearValidators();
this.sumotoSubmitsforTahsildar = true;
let postData = {
  requisitionId: this.sumotoid,
  departmentCode: this.basicInfo.department,
  mobilenumber: this.sumotoFormControls.mobile.value,
  doorno: this.suomotodataforalllogins.doorNo,
  streetName: this.suomotodataforalllogins.streetName,
  clusterId: this.suomotodataforalllogins.clusterid,
  "approverData": {
    mobilenumber: this.sumotoFormControlsForTahsildar.mobileforsumotoTahsildar.value,
    caste: this.sumotoFormControlsForTahsildar.casteforsumotoTahsildar.value,
    subcaste: this.sumotoFormControlsForTahsildar.subCasteforsumotoTahsildar.value,
    relationName: this.sumotoFormControlsForTahsildar.fathernameforsumotoTahsildar.value,
    revenuevillage: this.sumotoFormControlsForTahsildar.revenuevillageforsumotoTahsildar.value,
    email: this.sumotoFormControlsForTahsildar.emailforsumotoTahsildar.value,
    maritalStatus: this.sumotoFormControlsForTahsildar.maritalStatusforsumotoTahsildar.value,
    qualification: this.sumotoFormControlsForTahsildar.qualificationforsumotoTahsildar.value,
    birthDate: (this.sumotoFormControlsForTahsildar.dobforsumotoTahsildar.value) ? (this.commonService.formatDate(this.sumotoFormControlsForTahsildar.dobforsumotoTahsildar.value)) : '1900-01-01',
    religion: this.sumotoFormControlsForTahsildar.religionforsumotoTahsildar.value,
    headOfHouseHold: this.sumotoFormControlsForTahsildar.statusofhouseholdTEH.value
  }
}
this.commonService.postRequest(this.commonConstants.updateCosmosSumotoData, postData).subscribe({
  next: (responseData: any) => {
    if(responseData.result.isSuccess) {
      this.updatesumotoFile('sumotoverificationDocforTahsildar',action, actionCode,targetModal,confirmationOTPModal,from);
      // this.commonService.commonErrorPopup('Alert', 'Data Updated Successfully', 'md')
    }
    else if(responseData.result.isSuccess == false) {
      this.commonService.commonErrorPopup('Alert', 'Data not submitted please try again', 'md')
    } 
  },
  error: (error) => {
    console.log('client side', error)
  }
});
}
this.sumotoFormControlsForTahsildar.casteforsumotoTahsildar.updateValueAndValidity();
this.sumotoFormControlsForTahsildar.subCasteforsumotoTahsildar.updateValueAndValidity();
this.sumotoFormControlsForTahsildar.fathernameforsumotoTahsildar.updateValueAndValidity();
this.sumotoFormControlsForTahsildar.mobileforsumotoTahsildar.updateValueAndValidity();
this.sumotoFormControlsForTahsildar.revenuevillageforsumotoTahsildar.updateValueAndValidity();
this.sumotoFormControlsForTahsildar.emailforsumotoTahsildar.updateValueAndValidity();
this.sumotoFormControlsForTahsildar.religionforsumotoTahsildar.updateValueAndValidity();
this.sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.updateValueAndValidity();
}

subcastevalueIC: any;



  sumotoChange() {
    this.updatebuttonflag = false;
  }

  sumotoChangeForRI() {
    this.updatebuttonflagforRI = false;
  }


  sumotoChangeForTahsildarspecialcaste() {
    this.checkspecialcasteforsumoto();
    this.updatebuttonflagforTahsildar = false;
  }

  sumotoChangeForTahsildar() {
    this.updatebuttonflagforTahsildar = false;
  }




  showInfo : Boolean = false;
  sixstepBoolean: Boolean= false;
  submitfpshop(data:any){
    this.sixStepPostData1.application_Details.application.cluster_id = this.k['cluster'].value;
    this.sixStepPostData1.application_Details.application.shop_no = this.k['fpshop'].value;
    let obj = {
      spandana_application_id: this.getId,
      //"T209070664",
     
      cluster_id: this.k['cluster'].value,
      shop_no: data.target.value.split(': ')[1],
      password : "11169d3abc20724bb857cc5ea59e3c42",
      hts : "12",
      aadharNumber: this.commonService.RSAEncrypt(this.applicantAadhar),
      createdBy: this.loggedinuserId,
      requestNumber: this.getId,
      applicationType : this.appType,
    };
    this.commonService
      .postRequest(this.commonConstants.getClusterFpShopResult, obj)
      .subscribe({
        next: (responseData: any) => {
     
          if(responseData.result.status)
          {
           

            this.SubmitSixStep();
        // Removed this.varDataincome == 0  as per client requirement
            if( this.varDataemp ==0 && this.varDatafourWheel == 0 && this.varDataland == 0 && this.varDataelectricity == 0 && this.varDatacdma == 0 || this.sixStepGridData <= 0){
              // if(this.varData ===1){
              //   console.log("approved");
                
              // alert("Approve");
              this.sixstepBoolean=true;
              this.showInfo=true;
              // this.sixstepForm.controls['socialAudit'].setValidators(Validators.required);
              // this.sixstepForm.controls['socialAudit'].updateValueAndValidity();
              // this.sixstepForm.controls['socialAudit'].clearValidators();
              // this.sixstepForm.controls['socialAudit'].updateValueAndValidity();
            }
            
            else{
              // console.log("rejected");
              // alert("Reject")
              this.sixstepBoolean= false;
              this.showInfo=false;
              
              // this.sixstepForm.controls['socialAudit'].clearValidators();
              // this.sixstepForm.controls['socialAudit'].updateValueAndValidity();
          }
          
            // this.wFlowGetSLAData("CS",responseData.result.status,obj,1, "Reject")

            }
          
          // else
          // {
          //   const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          //   // modalRef.componentInstance.reqId = id;
          //   modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
          //   modalRef.componentInstance.message = 'Not approved';
          //   modalRef.result.then((result: any) => {
          //     if (result) {
          //      // console.log(result);
          //     }
          //   });
          // }
        },
      });
     
    this.submits=true;
    this.showCluster =false;
    this.getfpshopDetails()
    // if (data.target.value || data.target.value === 'Select') {
    //   this.k.socialAudit.setValidators([Validators.required]);
    //   this.k.socialAudit.updateValueAndValidity();

    
    // }
    // else {
    //   this.k.socialAudit.clearValidators();
    //   this.k.socialAudit.updateValueAndValidity();
    
    // }
    console.log('cluster value fp shop val',this.sixstepForm.controls['cluster'].value ,this.sixstepForm.controls['fpshop'].value)
    if(this.sixstepForm.controls['cluster'].value && this.sixstepForm.controls['fpshop'].value){

    this.showCluster =true;
    this.submits=false;

    }
    else{
      this.showCluster =false;

    }

  }
  varData:any; 
  dryland:any;
  wetland:any;
  cdma:any;
  varDataemp:Number=0;
  varDatacdma:Number=0;
  varDataincome: Number = 0;
  varDataland:Number=0;
  varDataelectricity:Number=0;
  varDatafourWheel:Number=0;
  SubmitSixStep(){

  // this.varData = ;
  this.dryland=0; 
  this.wetland=0;
  this.cdma = 0;
  this.varDataemp =0;
  this.varDatacdma =0;
  this.varDataincome = 0;
  this.varDataland =0;
  this.varDataelectricity =0;
  this.varDatafourWheel =0;
  this.sumsalary = 0;
 
    // console.log("varData",varData);

// console.log(this.sixStepGridData);
if(this.sixStepGridData && this.sixStepGridData?.length > 0) {
  this.sumsalary = this.sixStepGridData.map((a: any) => Number(a?.salary)).reduce(function (a: any, b: any) {
    return a+b ;
     });
}

this.sixStepGridData.forEach((element: any,index:any)=>{
        // console.log("element.incomeTax.value",this.tableDatas.value[i].incomeTax);
        // console.log(this.tableDatas.value[i].statusIncome.value);
        
// console.log("split",element.cdma.split(".")[0]);



// console.log("cdma",(Math.floor(element.cdma)));
this.cdma = Math.floor(element.cdma);
this.dryland = Math.floor(element.dryLand);
this.wetland = Math.floor(element.wetLand);

//console.log(this.varDataincome);
 //=== "Y"

 // Previously we restricted If income tax is YES , then after  we removed this validation as per client requirement
  //  if(element.incomeTax !== 'NO' && element.incomeTax !== null){  
  //     this.varDataincome = 1;
  // //  console.log("1",this.varDataincome);

  //  }


  if(element.empStatus !== 'NO'&& (element.empStatus === 'YES' && ((element.empCategory == 'Govt Employee' ||  (element.empCategory == 'Pension' &&  this.sumsalary >= 10000)) || ((element.empCategory != 'Govt Employee' ||  element.empCategory != 'Pension')  && ((this.isUrban && this.sumsalary >= 12000)||(!this.isUrban && this.sumsalary >= 10000))))) && element.empStatus !== null){
    this.varDataemp=1;
    // console.log("2",this.varDataemp);

   }   //!== null
   if(element.fourWheeler !== 'NO' && element.fourWheeler !== null){  
   
    this.varDatafourWheel=1;
    // console.log("3",this.varDatafourWheel);

   }
  if(this.dryland >= 3 && this.wetland >= 7){
    this.varDataland=1;
    // console.log("4",this.varDataland);

   }
   if(element.energyLast6MonthsUnits >= 300){
     this.varDataelectricity=1;
    //  console.log("5",this.varDataelectricity);

   }
  //  console.log("muncipal",this.cdma)
   if(this.cdma >= 1000){
     this.varDatacdma=1;
     console.log("6",this.varDatacdma);
     
   }

  //  console.log("6",this.varData);
   

  })





  }

  // showAge: number = 0;

  // ageCalculator(date: any) {
  //   const convertAge = new Date(this.commonService.formatDate(date));
  //   const timeDiff = Math.abs(Date.now() - convertAge.getTime());
  //   this.showAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
  //   return this.showAge;
  // }
  // mobileNo:any;
  // name:any;
  // dob:any;
  // familyIncome:any;
  // pincode:any;
  selectedRowValue:any;
  applicantAadhar : any;
  // district_code:any;
  // csAadhar:any;
  // villageWard:any;
  // religion:any;
  // riceCardNo:any;
  // gender:any;
  // addGender:any;
  // occupation:any;
  // occType:any;
  // caste:any;
  // casteNic:any;
   postCivilSuppliesWeblandRequestData(){
    // console.log(this.requisitionID,' this.requisitionID')
    let postData = {
      RequisitionId: this.getId,
      RequisitionType: this.serviceName,
      IncludeFileUploadInfo:true,
      IncludePaymentDetails:false
      }
    this.commonService.postRequest(this.commonConstants.getcivilsuppliesbyId, postData, true ).subscribe({
      next: (responseData: any) => { 
       console.log(responseData,'responseData')
       this.basicInfo = responseData.result?.requisitionHeader;
       this.secCode = responseData.result?.requisitionHeader?.applicantInfo.addresses[0]?.villageWard;
      //  this.pincode = responseData.result?.requisitionData.riceCardAddress.pinCode;
      //  this.familyIncome = responseData.result?.requisitionData.familyIncome;
      //  this.dob = responseData.result?.requisitionHeader.applicantInfo.birthDate;
      //  this.name = responseData.result?.requisitionHeader.applicantInfo.personNames[0].firstName;
      //  this.mobileNo = responseData.result?.requisitionHeader.applicantInfo.mobileNumber;
      //  this.district_code = responseData.result?.requisitionData.riceCardAddress.district;
      //  this.csAadhar = this.decryptAadharNum(responseData.result?.requisitionHeader.applicantInfo.aadhaarNumber);
      //  this.villageWard = responseData.result?.requisitionData.riceCardAddress.villageWard;
      //  this.religion = responseData.result?.requisitionHeader.applicantInfo.religion;
      //  this.riceCardNo = responseData.result?.requisitionData.riceCardNumber;
      //  this.caste = responseData.result?.requisitionHeader.applicantInfo.caste;

      //  this.gender = responseData.result?.requisitionHeader.applicantInfo.gender;
      
      // this.occupation = responseData.result?.requisitionData.occupation;

      this.getCSDistrictCode(responseData.result?.requisitionHeader?.applicantInfo?.addresses[0]?.district, 'District');
         //  this.presentAddr = this.basicInfo?.applicantInfo?.addresses.filter((item: { addressType: string; }) => item.addressType === 'present');
            //  this.permanentAddr = this.basicInfo?.applicantInfo?.addresses.filter((item: { addressType: string; }) => item.addressType === 'permanent');
          let civilReqDetails = responseData.result;
          let presentAddr = responseData.result?.requisitionHeader?.applicantInfo?.addresses[0];
          if(this.basicInfo?.requisitionType === "CorrectionofWrongAadhaar")
          {
            this.selectedRowValue = this.commonService.RSADecrypt(responseData.result?.requisitionData?.riceCardFamilyInfoDataList[0]?.aAdharNumber);
            console.log(this.selectedRowValue , "selectedRowValue")
          }  
          else
          {this.selectedRowValue = ''}
          if(this.basicInfo?.applicantInfo?.aadhaarNumber.length !== 12)
          {
          this.commonService
          .getRequest(this.commonConstants.cryptoDecryptValue + encodeURIComponent(this.basicInfo?.applicantInfo?.aadhaarNumber))
          .subscribe({
            next: (responseData: any) => {
              console.log(responseData, 'decrypt value respose data');
              this.applicantAadhar  = this.commonService.RSADecrypt(responseData.result.rawValue);
              // console.log(this.aadharNum,'this.commonFormAddedFields.aadharNo')
              this.getByEntitycodeAsync(presentAddr?.district, 'District', presentAddr, civilReqDetails);

            },
            error: (error) => {
              console.log('client side', error);
            },
          });
          }
          else if(this.basicInfo?.applicantInfo?.aadhaarNumber.length === 12)
          {
            this.applicantAadhar = this.basicInfo?.applicantInfo?.aadhaarNumber;
            this.getByEntitycodeAsync(presentAddr?.district, 'District', presentAddr, civilReqDetails);
          }
      },
      error: (error) => {
        console.log('client side', error);
      }
    })
    
  }
  getCSDistrictCode(entityCode: any, entityType:any): any {
    this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode + '&EntityType=' + entityType).subscribe({  
    next: (responseData: any) => { 
      this.commonService.getRequest(this.commonConstants.getCSDistrictCode + responseData.result[0]?.id).subscribe({
        next: (response: any) => {
            this.CSDistrictCode = response.result[0]?.entityCode;
          },
          error: (error) => {
            console.log('client side', error);
          },
        });
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  civilDistrict:any;
  civilMandal:any;
  civilVillage:any;
  getByEntitycodeAsync(entityCode: any, entityType: any, presentAddr: any, result: any) {
    this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode + '&EntityType=' + entityType).subscribe({  
      next: (responseData: any) => { 
          let name =  responseData.result[0]?.name;
          if (entityType === 'District' ) {
            this.civilDistrict = name;
            this.getByEntitycodeAsync(presentAddr?.mandalMunicipality, 'Mandal', presentAddr, result)
          }else if (entityType === 'Mandal') {
            this.civilMandal = name;
            this.getByEntitycodeAsync(presentAddr?.villageWard, 'Secretariat', presentAddr, result)
          } 
          else if (entityType === 'Secretariat') {
            this.civilVillage = name;
            this.getPaymentDetails(result);
          } 
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    
  }

  getDetail:any[]=[];
  hofName : any;
  getPaymentDetails(response:any){
    if(response?.requisitionData?.riceCardNumber?.includes("T") || response?.requisitionData?.riceCardNumber?.length > 10)
    {
      console.log("navaskam flow")
      for(let i = 0 ; i < response?.requisitionData?.riceCardMembersDataList.length ; i++)
      {
        if(response?.requisitionData?.riceCardMembersDataList[i].relationShip.includes("SELF"))
        {
          this.hofName = response?.requisitionData?.riceCardMembersDataList[i].englishName;
          console.log(this.hofName , "hofName1")
        }
      }
      this.weblandpostSurrender(response);
    }
    else{
    let obj =
    {
      rice_card_no: response?.requisitionData?.riceCardNumber
    }
    this.commonService.postRequest(this.commonConstants.getRiceCardMemberDetails, obj).subscribe({
      next: (responseData: any) => {
        console.log(responseData.result, "riceCardDetails ....");
        this.getDetail = responseData['result'].rice_card_mem_details;
        for(let i = 0; i<this.getDetail.length ; i++)
        {
          if(this.getDetail[i].relation.includes("SELF"))
          {
            this.hofName = this.getDetail[i].name
            console.log(this.hofName , "hofName2")
          }
        }
        this.weblandpostSurrender(response);

      }
    })
    }
  }

  appType:any;
  changedname:any;
  aadharNoPost:any;
  relationCdePost:any;
  sixStepPostData1:any;
  weblandpostSurrender(response:any){
     let dataGrid: any =[];
      var hofAlternate : any;
      if(this.getDetail){
        if(response?.requisitionHeader?.requisitionType === "MemberAdditionRiceCard"){
          this.appType = "3"
        }
        if(response?.requisitionHeader?.requisitionType === "MemberDeletionRiceCard"){ 
          this.appType = "4"
        }
        if(response?.requisitionHeader?.requisitionType === "SurrenderRiceCard"){
          this.appType = "5"
        }
        if(response?.requisitionHeader?.requisitionType === "CorrectionofWrongAadhaar"){
          this.appType ="32"
        }
        //splitting
        if(response?.requisitionHeader?.requisitionType === "SplittingRiceCard"){
      
        if(response?.requisitionData?.splitSingleMemberType === "normalSplit" || response?.requisitionData?.splitSingleMemberType === "widowSplit" || response?.requisitionData?.splitSingleMemberType === "divorceeSplit")
        { 
          this.appType = "2";
        }

        else if(response?.requisitionData?.singleMemberType === "Widower (male) and never had any children"){
          this.appType= "12"
        }
        else if( response?.requisitionData?.singleMemberType === "Widow (female) and never had any children"){
          this.appType= "11"
        }
        else if( response?.requisitionData?.singleMemberType === "Transgender"){
          this.appType= "13"
        }
        else if(response?.requisitionData?.singleMemberType === "Unmarried and above 50 years of age"){
          this.appType= "14"
        }
        }

      //New rice cardS
        if(response?.requisitionHeader?.requisitionType === "NewRiceCard"){
        if(
          response?.requisitionData?.memberType == 'multipleMember' && response?.requisitionData?.singleMemberType == ''){
          this.appType = "1";               
        }
        else if(response?.requisitionData?.singleMemberType === "Widow (female) and never had any children" && response?.requisitionData?.memberType =='singleMember'){
          this.appType= "6"
        }
        else if(response?.requisitionData?.singleMemberType === "Widower (male) and never had any children"  && response?.requisitionData?.memberType =='singleMember'){
          this.appType= "7"
        }
        else if(response?.requisitionData?.singleMemberType === "Destitue and no other family member is alive"  && response?.requisitionData?.memberType =='singleMember'){
          this.appType= "10"
        }
        else if(response?.requisitionData?.singleMemberType === "Transgender"  && response?.requisitionData?.memberType =='singleMember'){
          this.appType= "8"
        }
        else if(response?.requisitionData?.singleMemberType === "Unmarried and above 50 years of age"  && response?.requisitionData?.memberType =='singleMember'){
          this.appType= "9"
        }

        //true Ineligible, false eligible
        if(response?.requisitionData?.singleMemberType === "Widower (male) and never had any children"  && response?.requisitionData?.memberType == "false"){
          this.appType= "28"
        }
        else if(response?.requisitionData?.singleMemberType === "Widow (female) and never had any children" && response?.requisitionData?.memberType == "false"){
          this.appType= "27"
        }
        else if(response?.requisitionData?.singleMemberType === "Destitue and no other family member is alive"  && response?.requisitionData?.memberType == "false"){
          this.appType= "31"
        }
        else if(response?.requisitionData?.singleMemberType === "Transgender"  && response?.requisitionData?.memberType == "false"){
          this.appType= "29"
        }
        else if(response?.requisitionData?.singleMemberType === "Unmarried and above 50 years of age"  && response?.requisitionData?.memberType == "false"){
          this.appType= "30"
        }
        else if( (response?.requisitionData?.memberType == "true" ||  response?.requisitionData?.memberType == "false") && response?.requisitionData?.singleMemberType == ''  ){
          this.appType= "25"
        }
        }

        //occupation
        let occType:any;
        if(response?.requisitionData?.occupation === "AGRICULTURE LABOUR"){
          occType = "1";               
        }
        if(response?.requisitionData?.occupation === "AUTO/TAXI/BUS/PRIVATE DRIVER"){
          occType = "54";               
        }
        if(response?.requisitionData?.occupation === "BARBER"){
          
          occType = "15";
        }
        if(response?.requisitionData?.occupation === "BEGGAR"){
          occType = "55"
        }
        if(response?.requisitionData?.occupation === "BLACKSMITH"){
          occType = "16"
        }
        if(response?.requisitionData?.occupation === "BUSINESS"){
          occType = "2"
        }
        if(response?.requisitionData?.occupation === "COBBLER"){
          occType ="17"
        }
        if(response?.requisitionData?.occupation === "CONSTRUCTION TECHNICIAN"){
          occType= "18"
        }
        if(response?.requisitionData?.occupation === "CONTRACTOR"){
          occType= "19"
        }
        if(response?.requisitionData?.occupation === "COOK"){
          occType= "20"
        }
        if(response?.requisitionData?.occupation === "DHOBI / WASHERMAN"){
          occType= "21"
        }
        if(response?.requisitionData?.occupation === "ENTERPRENEOUR"){
          occType= "22"
        }
        if(response?.requisitionData?.occupation === "EX-NRI"){
          occType= "23"
        }
        if(response?.requisitionData?.occupation === "FACTORY OWNER/ INDUSTRIALIST "){
          occType= "24"
        }
        if(response?.requisitionData?.occupation === "FARMER/ZAMINDAR/Small/ Marginal Farmer"){
          occType= "3"
        }
        if(response?.requisitionData?.occupation === "FISHERMAN"){
          occType= "25"
        }


        if(response?.requisitionData?.occupation === "FREEDOM FIGHTER PENSIONER"){
          occType= "26"
        }
        if(response?.requisitionData?.occupation === "GARDENER "){
          occType= "27"
        }
        if(response?.requisitionData?.occupation === "GOLDSMITH"){
          occType= "28"
        }
        if(response?.requisitionData?.occupation === "GOVT CASUAL LABOURER"){
          occType= "29"
        }
        if(response?.requisitionData?.occupation === "GOVT PENSIONER"){
          occType= "30"
        }
        if(response?.requisitionData?.occupation === "GOVT. SERVICE"){
          occType= "4"
        }
        if(response?.requisitionData?.occupation === "HANDICRAFT ARTISANS/Technician"){
          occType= "31"
        }
        
        if(response?.requisitionData?.occupation === "HOUSE MAID"){
          occType= "32"
        }
        if(response?.requisitionData?.occupation === "HOUSE WIFE"){
          occType= "5"
        }
        if(response?.requisitionData?.occupation === "HOUSE/SHOP LENDER"){
          occType= "33"
        }
        if(response?.requisitionData?.occupation === "JOURNALIST/ MEDIA EMPLOYEE"){
          occType= "34"
        }
        if(response?.requisitionData?.occupation === "LABOUR/Cooli/Porter/ Hand Card Puller"){
          occType= "6"
        }
        
        if(response?.requisitionData?.occupation === "LOCAL VENDOR"){
          occType= "56"
        }
        if(response?.requisitionData?.occupation === "MECHANIC"){
          occType= "35"
        }
        if(response?.requisitionData?.occupation === "MONEY LENDER"){
          occType= "57"
        }
        if(response?.requisitionData?.occupation === "NRI"){
          occType= "36"
        }
        if(response?.requisitionData?.occupation === "OTHER"){
          occType= "14"
        }

        if(response?.requisitionData?.occupation === "PHOTOGRAPHER"){
          occType= "37"
        }
        if(response?.requisitionData?.occupation === "PRIEST"){
          occType= "38"
        }
        if(response?.requisitionData?.occupation === "PRIVATE CONTRACT LABOURER"){
          occType= "39"
        }
        if(response?.requisitionData?.occupation === "PRIVATE SERVICE"){
          occType= "7"
        }
        if(response?.requisitionData?.occupation === "PROFESSIONALIST"){
          occType= "40"
        }
        
        if(response?.requisitionData?.occupation === "PSU EMPLOYEE"){
          occType= "41"
        }
        if(response?.requisitionData?.occupation === "REAL ESTATE"){
          occType= "42"
        }
        if(response?.requisitionData?.occupation === "RELIGIOUS EMPLOYEE"){
          occType= "43"
        }
        if(response?.requisitionData?.occupation === "RETD. PSU EMPLOYEE"){
          occType= "44"
        }
        if(response?.requisitionData?.occupation === "RETD. PVT. COMPANY"){
          occType= "45"
        }
        
        if(response?.requisitionData?.occupation === "RETIRED"){
          occType= "8"
        }
        if(response?.requisitionData?.occupation === "RICKSHAW PULLER"){
          occType= "46"
        }
        if(response?.requisitionData?.occupation === "SALESMAN"){
          occType= "47"
        }
        if(response?.requisitionData?.occupation === "SCAVENGER"){
          occType= "58"
        }
        if(response?.requisitionData?.occupation === "SECURITY / WATCHMAN"){
          occType= "48"
        }
        
        
        if(response?.requisitionData?.occupation === "SELF EMPLOYED"){
          occType= "9"
        }
        if(response?.requisitionData?.occupation === "SHOP KEEPER"){
          occType= "10"
        }
        if(response?.requisitionData?.occupation === "STUDENT"){
          occType= "49"
        }
        if(response?.requisitionData?.occupation === "TAILOR"){
          occType= "50"
        }
        if(response?.requisitionData?.occupation === "TEACHER"){
          occType= "11"
        }
        
        if(response?.requisitionData?.occupation === "TRANSPORT LABOURER"){
          occType= "51"
        }
        if(response?.requisitionData?.occupation === "TRANSPORT OWNER"){
          occType= "52"
        }
        if(response?.requisitionData?.occupation === "UNEMPLOYED"){
          occType= "12"
        }
        if(response?.requisitionData?.occupation === "WEAVER"){
          occType= "53"
        }


        //caste
        let casteNic: any;
        if(response?.requisitionHeader?.applicantInfo?.caste === "BC-A"){
          casteNic= "11"
        }
        if(response?.requisitionHeader?.applicantInfo?.caste === "BC-B"){
          casteNic= "12"
        }
        if(response?.requisitionHeader?.applicantInfo?.caste === "BC-C"){
          casteNic= "13"
        }
        if(response?.requisitionHeader?.applicantInfo?.caste === "BC-D"){
          casteNic= "14"
        }
        if(response?.requisitionHeader?.applicantInfo?.caste === "BC-E"){
          casteNic= "15"
        }
        if(response?.requisitionHeader?.applicantInfo?.caste === "OC"){
          casteNic= "1"
        }
        if(response?.requisitionHeader?.applicantInfo?.caste === "Others"){
          casteNic= "16"
        }
        if(response?.requisitionHeader?.applicantInfo?.caste === "SC"){
          casteNic= "3"
        }
        if(response?.requisitionHeader?.applicantInfo?.caste === "ST"){
          casteNic= "4"
        }
        let relationGrid: any;
        response?.requisitionData?.riceCardMembersDataList.forEach((element:any,englishName:any,aadhaarNumber:any,gender:any)=>{
          if(element.relationShip === "DAUGHTER"){
            relationGrid= "9"
          }
          if(element.relationShip === "DAUGHTER IN LAW"){
            relationGrid= "48"
          }
          if(element.relationShip === "FATHER"){
            relationGrid= "5"
          }
          if(element.relationShip === "FATHER IN LAW"){
            relationGrid= "18"
          }
          if(element.relationShip === "GRAND DAUGHTER"){
            relationGrid= "33"
          }
          if(element.relationShip === "GRAND FATHER"){
            relationGrid= "66"
          }
          if(element.relationShip === "GRAND MOTHER"){
            relationGrid= "65"
          }
          if(element.relationShip === "GRAND SON"){
            relationGrid= "34"
          }
          if(element.relationShip === "HUSBAND"){
            relationGrid= "7"
          }
          if(element.relationShip === "MOTHER"){
            relationGrid= "4"
          }
          if(element.relationShip === "MOTHER IN LAW"){
            relationGrid= "17"
          }
          if(element.relationShip === "SELF/HOF" || element.relationShip === "SELF"){
            
            relationGrid= "1"
          }
          if(element.relationShip === "SON"){
            relationGrid= "8"
          }
          if(element.relationShip === "SON IN LAW"){
            relationGrid= "49"
          }
        if(element.relationShip === "NA" || element.relationShip === "WIFE"){
              relationGrid= "51"
            }


          if(response?.requisitionHeader?.requisitionType === "MemberAdditionRiceCard" || response?.requisitionHeader?.requisitionType === "MemberDeletionRiceCard")
          {
            console.log('this.getDetail', this.getDetail)
            this.changedname= this.hofName;
            this.aadharNoPost = this.getDetail[0]?.adhar_number
            this.relationCdePost =relationGrid;

          }else{
          

          if(element.relationShip === "DAUGHTER"){
            let curdate = this.currentDate.getDate() + '-' + (this.currentDate.getMonth() + 1) + '-' + this.currentDate.getFullYear()
            let details =element.dateOfBirth
            var a = details.split(" ");
              
            var x :any= curdate.split("-")[2];
            var y :any= details.split("-")[0];
            
            let age = x - y;
            if(age >= 18){
            this.relationCdePost="1"; 
            this.changedname= element.englishName;
          this.aadharNoPost= this.commonService.RSADecrypt(element.aadhaarNumber);
          
            }
            if(age <= 18){
              this.relationCdePost=relationGrid;
              this.changedname= element.englishName;
            this.aadharNoPost= this.commonService.RSADecrypt(element.aadhaarNumber);
            
              }

          }


          else if((element.relationShip.includes("SELF") || 
          element.relationShip === "WIFE" ||
          element.relationShip.includes("DAUGHTER") || 
          element.relationShip.includes("MOTHER")) ||
          response?.requisitionData?.singleMemberType != '')
          {
            hofAlternate = element.englishName
            this.changedname= element.englishName;
            this.aadharNoPost= this.commonService.RSADecrypt(element.aadhaarNumber);
            this.relationCdePost="1";
          }
          
          else{ 
            // this.changedname=  response?.requisitionData?.riceCardMembersDataList[0]?.englishName;
            // this.aadharNoPost= response?.requisitionData?.riceCardMembersDataList[0]?.aadhaarNumber;
          //   console.log(this.aadharNoPost);
            this.relationCdePost =relationGrid;
            // console.log("else",this.relationCdePost); 
          }
        }
        // console.log(this.relationCdePost);

          let addGender:any;
          let DOB: any = element.dateOfBirth.split('T')[0];
          if(element.gender === "1" || element.gender === 'MALE' ){
            addGender="1";
            // console.log("gender",this.addGender);
            
          }
          if(element.gender === "2" || element.gender ===  'FEMALE' ){
            addGender="2";
            // console.log("gender",this.addGender);
          }
          if(element.gender === "4" || element.gender ===  'TRANSGENDER' ){
            addGender="4";
            // console.log("gender",this.addGender);
          }
          if(this.appType == "4"){
            if(element.gender == "3" || element.gender ===  'NOT CAPTURED' ){
              addGender="3";
            }
            if(element.gender == "101" || element.gender ===  'NA' ){
              addGender="101";
            }
          }

          dataGrid.push({
            age: Number(element.age)  ? Number(element.age) :0,
            annual_income:  Number(response?.requisitionData?.familyIncome ? response?.requisitionData?.familyIncome : 0),
        
            citizen_name: element.englishName ? element.englishName : "",
            citizen_name_ll:element.teluguName ? element.teluguName : element.englishName,
            dob_dt:DOB  ? DOB : "",
            dob_dtSpecified: true,
            mapping_id: 0,
            mobile_number: element.mobileNumber ?  element.mobileNumber:response?.requisitionHeader?.applicantInfo?.mobileNumber,
            
            gender: Number(addGender),
            occupation_type: Number(occType),
            relationship_hoh:Number(this.relationCdePost),
            uid_no:  element.aadhaarNumber ? this.commonService.RSAEncrypt(element.aadhaarNumber) : " ",
          });
        })

              console.log(this.changedname , "changedName 2")
              console.log(this.hofName , "hofName 2")
              console.log(hofAlternate , "hofAlternate")
              if(!this.hofName)
              {
                for(let i = 0 ; i < response?.requisitionData?.riceCardMembersDataList.length ; i++)
                {
                  if(response?.requisitionData?.riceCardMembersDataList[i].relationShip.includes("SELF"))
                  {
                    this.hofName = response?.requisitionData?.riceCardMembersDataList[i].englishName;
                    console.log(this.hofName , "hofName1")
                  }
                }
              }
              var finalUid;
              if(response?.requisitionHeader?.requisitionType === 'CorrectionofWrongAadhaar')
              {
                finalUid = this.selectedRowValue
              }
              else if(response?.requisitionHeader?.requisitionType === 'SplittingRiceCard')
              {
                finalUid = this.aadharNoPost
              }
              else
              {
                finalUid = this.applicantAadhar
              }
              this.sixStepPostData1 = {
                application_Details: {
                  app_address: {
                    city: this.civilDistrict,
                    line1:this.civilMandal, 
                    line2: this.civilVillage,
                    pincode:response.requisitionHeader?.applicantInfo?.addresses[0].pinCode,
                    state_code: "28",
                  },
                  app_member_details: dataGrid,
                  application: {
                    application_type: this.appType,
                    caste:Number(casteNic)  ?  Number(casteNic): " ",
                    cluster_id:  "0",
                    district_code: this.CSDistrictCode,
                    hof_name: response?.requisitionHeader?.requisitionType == 'SplittingRiceCard' || (response?.requisitionHeader?.requisitionType == 'NewRiceCard' && response?.requisitionData?.memberType == "true" ) ? hofAlternate : this.hofName,
                    household_id: "101",
                    mapping_id: 0,
                    no_of_units: Number(response?.requisitionData?.riceCardMembersDataList?.length ? response?.requisitionData?.riceCardMembersDataList?.length : " "),
                    religion: response?.requisitionHeader?.applicantInfo?.religion ? response?.requisitionHeader?.applicantInfo?.religion : " ",
                    rice_card_no: response?.requisitionData?.riceCardNumber,
                    secretariat_code: response?.requisitionData?.riceCardAddress?.villageWard ? response.requisitionData?.riceCardAddress?.villageWard : " ",
                    shop_no: '',
                    spandana_application_code: response?.id,
                    uid_no: this.commonService.RSAEncrypt(finalUid),
                    volunteer_uid: ''
                  }
                },
                password:"11169d3abc20724bb857cc5ea59e3c42",
                hts:"12",
                aadharNumber: this.commonService.RSAEncrypt(this.applicantAadhar),
                createdBy: this.userIdData,
                requestNumber: response?.id,
                applicationType : Number(this.appType),
                workflowUpdate : {
                  taskId : this.taskId,
                  action : 5,
                  comment : JSON.stringify({ remarks:this.f2.remarks.value,reasons:this.f2.remarksReasons.value,clsuter:this.k['cluster'].value,fpshopData:this.k['fpshop'].value,socialAudit:this.k['socialAudit'].value ? this.k['socialAudit'].value : '-'})
                }
              }
            
          
          
            console.log(this.sixStepPostData1,'sixStepPostData Validation');
      
        
          }
      
    
  }

  get e() { return this.emudhraformGroup.controls; }

  get o() { return this.aadharformGroup.controls; }
  

  // ngOnChanges(changes: SimpleChanges): void {
  //   throw new Error('Method not implemented.');
  // }

  ngOnChanges(): void {
    // if (this.digiSignPopUp) {
    //   this.loadTokens();
    // }
  }
  get a() {
    return this.clusterForm.controls;
  }
   get f() {
     return this.form.controls;
   }
   get f1() {
    return this.checklistForm.controls;
  }
  get f2() {
    return this.remarksForm.controls;
  }

  get z1() {
    return this.zillasainikwelfareform.controls;
  }

  get z2() {
    return this.zillasainikwelfareformforJC.controls;
  }

  get z3() {
    return this.zillasainikwelfareformforJC2.controls;
  }

  get k() {
    return this.sixstepForm.controls;
  }
  get f3() {
    return this.fisheriesForm.controls;
  }
  get d() {
    return this.dappuArtistsForm.controls;
  }
  get f4() {
    return this.traditionalCobblerform.controls;
  }
  get f5() {
    return this.handloomWeaverForm.controls;
  }
  get f6() {
    return this.weaverADOHDTOForm.controls;
  }
  get fp(){
    return this.fpShopForm.controls;
  }
  ngOnInit(): void {
//     let timeDiff = Math.abs(Date.now() - this.Dob);
// let age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
// console.log(age);
    // this.getClusterDetails();
    this.getRelation();
    this.getGender();
    this.getCaste();
    this.getMandalCode();
    this.getQualifications();
    this.getReligions();
    this.JSON = JSON;
    this.isVerifiedOTP = false;
    this.isSentOTP = false;
    // this.getTokenKeyDetails();


    // }
    // if (this.digiSignPopUp) {
    //   this.loadTokens();
    // }
    let userInfo = localStorage.getItem('userInfo');
  //  console.log(userInfo,'userInfo')
    if (userInfo && userInfo !== null) {
      let userData = JSON.parse(userInfo);
      this.mailId = userData.mail !== null ? userData.mail : userData.userPrincipalName;
      this.role = userData.jobTitle;
      this.userIdData = userData.userPrincipalName
      this.userName = userData.displayName
      
     // console.log(this.eMudhradisplayName,this.capitalizeWords( this.eMudhradisplayName),'this.eMudhradisplayName')
    }
    //this.vroSelected = true;
    this.getWorklist();
    if(this.role === 'Panchayat Secretary DDO') {
      this.verifySignExist();
    }

    
    // pensions
    this.userInformation = localStorage.getItem('userInfo');
    this.loggedinDetails = JSON.parse(this.userInformation);
    this.loggedinuserId = this.loggedinDetails.userPrincipalName;
    var str = this.loggedinuserId.split("-")[0]?.replace('U', '')
    if(this.loggedinDetails.userPrincipalName.length > 0){
      this.getUserIdSerialNoAndAadharNo()
    }
    this.loggedinsecid = str;
   
    
    
    //Dashbaord APIs
    this.getVRODashBoardDetails();
    
    
    
    //this.getVRODashBoardDetailsList();
    // this.getPanchayatData();
    // pensions
  }

  capitalizeWords(text : any){
    return text.replace(/(?:^|\s)\S/g,(res : any)=>{ return res.toUpperCase();})
  };

  getUserIdSerialNoAndAadharNo(){
    //console.log(this.loggedinuserId,'this.loggedinuserId')
    this.commonService.getRequest(this.commonConstants.getUserIdDetails).subscribe({
      next: (responseData: any) => {
        if(responseData && responseData.result) {
          responseData.result.forEach((item: any) => {
            item.aadhaarNo = this.commonService.RSADecrypt(item.aadhaarNo);
          });
        }
        this.officerData = responseData.result[0];
        //console.log(responseData,'getUserIdSerialNoAndAadharNo')
        this.aadharUID = responseData.result[0]?.aadhaarNo;
        // this.o.ddAadharNo.patchValue(this.aadharUID)
        if(this.aadharUID!= null){
          if (this.aadharUID?.length > 12) {
            this.decryptAadharNum(this.aadharUID, 'otp');
          } else {
             this.maskAadhar(this.aadharUID)
          }
        }
       
        this.verifytknSerialNo = responseData.result[0]?.cert_SerialNo ? responseData.result[0]?.cert_SerialNo : "";       // console.log(responseData.result[0].cert_SerialNo,'cert_SerialNo')
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  maskAadhar(cardNumber : any){
    var splifubc = this.aadharUID.split('')
   // console.log(splifubc)
      let aadharFinalNum :any;
      var strArray = Array.from(this.aadharUID)
   //  console.log(strArray,'strArray');
      for (let index = 0; index < strArray.length; index++) {
        if(index > 7){
          aadharFinalNum = aadharFinalNum +  strArray[index]
        }else if (index == 0){
          aadharFinalNum =  'X'
        }else {
          aadharFinalNum = aadharFinalNum + 'X'
        }
        
      }
    //  console.log(aadharFinalNum,'aadharFinalNum');
      this.o.ddAadharNo.patchValue(aadharFinalNum)
  }
  
  searchTransaction() {
    let value = this.transactionID.trim()?.toLowerCase();
  //  console.log('id', value)
      if (value !== null && value !== '') {
        let filteredItems = this.tempItems.filter((item: any) => item.requisitionId?.toLowerCase().match(value) || item.ServiceCode?.toLowerCase().match(value) || item.DepartmentCode?.toLowerCase().match(value) || item.ApplicantName?.toLowerCase().match(value) || item.RequestedDate?.toLowerCase().match(value));
        // if (filteredItems.length > 0) {
        //   let i=1;
        //   filteredItems.forEach((element: any) => {
        //     element.SNo = i++;
        //   });
        // }
        // console.log('filteredItems', filteredItems);
        // console.log('total', this.tempItems);
        this.items = filteredItems;
        this.page = 1;
      } else {
        this.items = this.tempItems;
      }
  }
  zswovrodata: any = {};
  getTaskDetails() {
    this.docList = [];
    this.VROHistory = [];
    this.RIHistory = [];
    this.TEHHistory = [];
    this.DTHistory = [];
    this.RDOHistory = [];
    this.JCHistory = []; 
    this.PSHistory = [];

    //rectification
    this.VSHistory = [];
    this.MSHistory = [];
    this.TSHistory = [];
    // pension
    this.WEAWWDSHistory =[];
    this.MROHistory = [];
    this.ADODHTOHistory = [];
    this.OtherDCHistory =[];
    this.fisheryDCHistory =[];
    this.FDOHistory = [];
    this.ASWOHistory = [];
    this.scweldcDCHistory =[];
    this.exciseDCHistory =[];
    this.dmoDCHistory =[];
    // pension
    //Muslim Marriage Certificate
    this.AS2History = [];
    this.SPT3History = [];
    this.CEOWAQFHistory = [];
    //Muslim Marriage Certificate
    this.checklist = [];
    // console.log("taskid",this.taskId);
    if(this.certificateName == 'IntegratedCertificate-SuoMoto' && this.role == 'Tahsildar') {
      this.checkspecialcasteforsumoto();
    }
    this.commonService.getRequest(this.commonConstants.getTaskDetails + this.taskId).subscribe({
      next: (responseData: any) => {
      let currentStep: any = responseData.result?.task?.workflowStepCode;
      this.workflowstepcode = currentStep;
      this.currentRole = currentStep?.split('-')[0];
      this.availableActions = responseData.result?.availableActions;
      console.log(this.availableActions[0], "available actions");
      this.prepareActionButtons();
       this.checklist = responseData.result?.checklist?.questions;
      //  let checklistCode = responseData.result?.checklist?.checkListCode?.split('-');
      //  this.currentUser = checklistCode[1]?.trim();
       this.checklistForm = this.formBuilder.group({});
       if (this.checklist && this.checklist.length > 0) {
        this.checklist.forEach((question: { id: string; }) => {
          this.checklistForm.addControl(question.id, this.formBuilder.control(null));
        });
       }
       let historyItems = responseData.result?.history;
       if (historyItems && historyItems.length > 0) {
        //  console.log('history', historyItems);

        let VSObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'VS-Approval');
        if(VSObj !== undefined) {
         this.VSHistory.push(JSON.parse(VSObj?.comment));
         console.log(this.VSHistory,'VSHISTORY');
        } 
  
        let MSObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'MS-Approval');
        if(MSObj !== undefined) {
         this.MSHistory.push(JSON.parse(MSObj?.comment));
         console.log(this.MSHistory,'MSHISTORY');
        } 

         let VROObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'VRO-Approval'|| item.workflowStepCode === 'VRO-Submitted');
         if(VROObj !== undefined) {
          this.VROHistory.push(JSON.parse(VROObj?.comment)); 
          if(this.isFpShopApplication){
            this.viewFile(this.VROHistory[0].fpShopData.fpShopPhoto.blobRef);
          }
          this.filterfileforverificationdocument = JSON.parse(VROObj.comment).fileList.filter((item: any) => item.documentType == 'verificationDoc').length;
          this.filterfileforsupportingdocument = JSON.parse(VROObj.comment).fileList.filter((item: any) => item.documentType == 'supportingDocuments').length;
          this.zswovrodata = (JSON.parse(VROObj?.comment)).zswovroData;
         } 
         let RIObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'RI-Approval');
         if(RIObj !== undefined) {
          this.RIHistory.push(JSON.parse(RIObj?.comment)) ;
         } 
         let TEHObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'TEH-Approval'|| item.workflowStepCode === 'Tahsildar-Submitted' || item.workflowStepCode === 'MC-Approval'|| item.workflowStepCode === 'ASO-Approval' || item.workflowStepCode === 'TEH-Submitted');
         if(TEHObj !== undefined) {
          this.TEHHistory.push(JSON.parse(TEHObj?.comment)) ;
          console.log("TEH history in RDO & collector", this.TEHHistory);
          if(this.isFpShopApplication){
            this.viewFile(this.VROHistory[0].fpShopData.fpShopPhoto.blobRef);
          }
         } 
         let DTObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'DEPTEH-Approval');
         if(DTObj !== undefined) {
          this.DTHistory.push(JSON.parse(DTObj?.comment)) ;
         } 
         let RDOObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'RDO-Approval' || item.workflowStepCode === 'RDO-Verify'|| item.workflowStepCode === 'RDO-Submitted');
         if(RDOObj !== undefined) {
          this.RDOHistory.push(JSON.parse(RDOObj?.comment)) ;
         }
         let JCObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'JC-Approval');
         if(JCObj !== undefined) {
           this.JCHistory.push(JSON.parse(JCObj?.comment)) ;
         } 
         let PSObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'PS-Approval');
         if(PSObj !== undefined) {
          this.PSHistory.push(JSON.parse(PSObj?.comment)) ;
         } 
        // Muslim Marriage

        let SPT3Obj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'SPT-Approval');
         if(SPT3Obj !== undefined) {
          this.SPT3History.push(JSON.parse(SPT3Obj?.comment)) ;
         }

         let AS2Obj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'AS-Approval');
        if(AS2Obj !== undefined) {
         this.AS2History.push(JSON.parse(AS2Obj?.comment)) ;
        }

          let CEOWAQFObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'CEOWAQF-Approval');
         if(CEOWAQFObj !== undefined) {
          this.CEOWAQFHistory.push(JSON.parse(CEOWAQFObj?.comment)) ;
         }

        // Muslim Marriage
        //  pension 
        let WEAWWDSObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'WEA-Approval');
        if(WEAWWDSObj !== undefined) {
         this.WEAWWDSHistory.push(JSON.parse(WEAWWDSObj?.comment));
        } 
        let MROObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'MRO-Approval');
        if(MROObj !== undefined) {
         this.MROHistory.push(JSON.parse(MROObj?.comment)) ;
        } 
        let adodhtoObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'HandloomDO-Approval');
        if(adodhtoObj !== undefined) {
         this.ADODHTOHistory.push(JSON.parse(adodhtoObj?.comment)) ;
        }
        let OtherDCObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'HandloomDC-Approval');
        if(OtherDCObj !== undefined) {
         this.OtherDCHistory.push(JSON.parse(OtherDCObj?.comment)) ;
        } 
        let fisheryDCObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'FisheryDC-Approval');
        if(fisheryDCObj !== undefined) {
         this.fisheryDCHistory.push(JSON.parse(fisheryDCObj?.comment)) ;
        } 
        let fdoObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'FDO-Approval');
        if(fdoObj !== undefined) {
         this.FDOHistory.push(JSON.parse(fdoObj?.comment)) ;
        } 
        let aswoObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'ASWO-Approval');
        if(aswoObj !== undefined) {
         this.ASWOHistory.push(JSON.parse(aswoObj?.comment)) ;
        } 
        let scweldcDCObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'SCWELDC-Approval');
        if(scweldcDCObj !== undefined) {
         this.scweldcDCHistory.push(JSON.parse(scweldcDCObj?.comment)) ;
        } 
        let exciseDCObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'ExciseDC-Approval');
        if(exciseDCObj !== undefined) {
         this.exciseDCHistory.push(JSON.parse(exciseDCObj?.comment)) ;
        } 
        let dmoDCObj = historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'DMO-Approval');
        if(dmoDCObj !== undefined) {
         this.dmoDCHistory.push(JSON.parse(dmoDCObj?.comment)) ;
        }
        if((this.certificateName === 'Pensions@2' || this.certificateName === 'Pensions@V2' ||  this.certificateName === 'Pensions-NEW@2' || this.certificateName === 'Pensions-NEW@V2') && 
          (this.role === 'HandloomDO' || this.role === 'HandloomDC') && (this.WEAWWDSHistory[0]?.handloomWeaverData?.isLoomPresentInHouse === 'Yes' && this.WEAWWDSHistory[0]?.handloomWeaverData?.loomWorkingCondition === 'Yes')) {
          this.f6.loomFunctioningEnquired.setValidators([Validators.required]);
          this.f6.varitiesProduced.setValidators([Validators.required]);
          this.f6.loomFunctioningEnquired.updateValueAndValidity();
          this.f6.varitiesProduced.updateValueAndValidity();
          let HWCSControls: any = ['admissionDate', 'undertakingFromPHWC', 'productionAtPHWC'];
            let MasterWeaverControls: any = ['workingDate', 'wagesProofDocument', 'undertakingMasterDocument'];
            let IndividualControls: any = ['erectionLoomDate', 'yarnPurchaseDocument', 'yarnPassBookOrBills', 'isWeaverConsuming'];
            if (this.WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder === 'HWCS') {
              HWCSControls.forEach((control: any) => {
                this.f6[control].setValidators([Validators.required]);
                this.f6[control].updateValueAndValidity();
              });
            } else {
              HWCSControls.forEach((control: any) => {
                this.f6[control].clearValidators();
                this.f6[control].updateValueAndValidity();
              });
            }
            if(this.WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder === 'Master Weaver') {
              MasterWeaverControls.forEach((control: any) => {
                this.f6[control].setValidators([Validators.required]);
                this.f6[control].updateValueAndValidity();
              });
            } else {
              MasterWeaverControls.forEach((control: any) => {
                this.f6[control].clearValidators();
                this.f6[control].updateValueAndValidity();
              });
            }
            if(this.WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder === 'Individual') {
              IndividualControls.forEach((control: any) => {
                this.f6[control].setValidators([Validators.required]);
                this.f6[control].updateValueAndValidity();
              });
            } else {
              IndividualControls.forEach((control: any) => {
                this.f6[control].clearValidators();
                this.f6[control].updateValueAndValidity();
              });
            }
        } else {
          let weaversADODHTOControls: any = ['admissionDate', 'undertakingFromPHWC', 'undertakingFromPHWCDocument', 'productionAtPHWC', 'productionAtPHWCDocument',
            'workingDate', 'wagesProofDocument', 'undertakingMasterDocument', 'erectionLoomDate', 'yarnPurchaseDocument',
            'yarnPassBookOrBills', 'isWeaverConsuming', 'loomFunctioningEnquired', 'varitiesProduced'];
            weaversADODHTOControls.forEach((control: any) => {
              this.f6[control].clearValidators();
              this.f6[control].updateValueAndValidity();
            });
        }
        //  pension
        // this.VROHistory[0] = JSON.parse(historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'VRO-Approval')?.comment);
        // this.RIHistory[0] = JSON.parse(historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'RI-Approval')?.comment);
        // this.TEHHistory[0] = JSON.parse(historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'TEH-Approval')?.comment);
        // this.DTHistory[0] = JSON.parse(historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'DEPTEH-Approval')?.comment);
        // this.RDOHistory[0] = JSON.parse(historyItems.find((item: { workflowStepCode: string; }) => item.workflowStepCode === 'RDO-Approval')?.comment);
        // console.log('rdo', this.RDOHistory)
       }
       //this.remarksOptions = this.role === 'Tahsildar' ? this.commonConstants.tahsildarRemarks : this.commonConstants.VRORIRemarks;
       
      // this.setVROChecklist(history);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  sumotosubcasteflagfortahsildar: any;

  checkspecialcasteforsumoto() {
    if(this.sumotosubcasteflagfortahsildar == 'sumotosubcasteonload') {
      this.subcastevalueIC = (this.certificateInfo?.suoMotoApproverData?.riData?.subcaste ? this.certificateInfo?.suoMotoApproverData?.riData?.subcaste : null)
      this.sumotosubcasteflagfortahsildar = 'notsumotosubcasteonload'
    }
    else {
      this.subcastevalueIC = this.sumotoFormControlsForTahsildar.subCasteforsumotoTahsildar.value
    }
      let postDataspecialcaste = '?CastCategory=' + this.sumotoFormControlsForTahsildar.casteforsumotoTahsildar?.value?.replace('-', '') +'&CastClaimed=' + this.subcastevalueIC + '&DistrictCode=' + this.sumotocasteInfo.addresses[0].district +'&MandalCode=' + this.sumotocasteInfo.addresses[0].mandalMunicipality
      this.commonService.getRequest(this.commonConstants.validateSumotoSpecialCaste + postDataspecialcaste).subscribe({
        next: (resData: any) => {
          if(resData.result.specialCasteRow){
            this.isspecialcasteforsuomoto = true;
          }
          else {
            this.isspecialcasteforsuomoto = false;
          }
      },
      error: (error) => {
        console.log('client side', error);
      }
    })
  }

  prepareActionButtons() {
    this.isApprovalAuthority = this.availableActions.includes(3) ? true : false;
    this.isForwardAvailable = this.availableActions.includes(1) ? true : false;
    this.remarksOptions = this.isApprovalAuthority ? this.commonConstants.tahsildarRemarks : this.commonConstants.VRORIRemarks;
    this.remarksReasonsOptions = this.commonConstants.remarksReasonsOptions;
    // pension
    if(this.departmentCode === "RD"){
      this.isApprovalAuthority = this.availableActions.includes(3) ? true : false;
      this.isForwardAvailable = this.availableActions.includes(1) ? true : false;
      this.remarksOptions =this.isApprovalAuthority ? this.commonConstants.tahsildarRemarks : this.commonConstants.tahsildarRemarks;
      this.remarksReasonsOptions = this.commonConstants.remarksReasonsOptions;
      if((this.role === 'Village Revenue Officer' || this.role === 'Ward Revenue Secretary') && !this.isFpShopApplication) {
        this.f2.casteVerificationDoc.setValidators([Validators.required]);
      } else {
        this.f2.casteVerificationDoc.clearValidators();
      }
      this.f2.casteVerificationDoc.updateValueAndValidity();
    } else {
      this.f2.casteVerificationDoc.clearValidators();
      this.f2.casteVerificationDoc.updateValueAndValidity();
    }
    if((this.certificateName === 'Pensions@7' || this.certificateName === 'Pensions@V7') && (this.role === 'Welfare & Educational Assistant' || this.role === 'Ward Welfare & Development Secretary' ||
    this.role === 'FisheryDC' || this.role === 'Fisheries Development Officer')) {
      this.f3.isFishingProfession.setValidators([Validators.required]);
    } else {
      this.f3.isFishingProfession.clearValidators();
    }
    this.f3.isFishingProfession.updateValueAndValidity();
    this.d.pensionId.clearValidators();
    this.d.pensionId.updateValueAndValidity();
    let dappuCheckListControls: any = ['instrumentInhouse','playDappu','dappuType', 'noOfYears', 'lastProgDate', 'address', 'existPension'];
    if((this.certificateName === 'Pensions@10' || this.certificateName === 'Pensions@V10' || this.certificateName === 'Pensions-NEW@10' || this.certificateName === 'Pensions-NEW@V10') && (this.role === 'Welfare & Educational Assistant' || this.role === 'Ward Welfare & Development Secretary' ||
    this.role === 'SCWELDC' || this.role === 'Assistant Social Welfare Officer')) {
       dappuCheckListControls.forEach((control: any) => {
          this.d[control].setValidators([Validators.required]);
          this.d[control].updateValueAndValidity();
       });
    } else {
      dappuCheckListControls.forEach((control: any) => {
        this.d[control].clearValidators();
        this.d[control].updateValueAndValidity();
     });
    }
    this.f4.pensionId.clearValidators();
    this.f4.pensionId.updateValueAndValidity();
    let cobblerCheckListControls: any = ['isCobblingMainOcc','isHavingCobblerShop','noOfYearsIntoCobbling', 'receivingCobblerPension', 'cobblingAddress'];
    if((this.certificateName === 'Pensions@9' || this.certificateName === 'Pensions@V9' || this.certificateName === 'Pensions-NEW@9' || this.certificateName === 'Pensions-NEW@V9') && 
      (this.role === 'Welfare & Educational Assistant' || this.role === 'Ward Welfare & Development Secretary' || this.role === 'SCWELDC' || this.role === 'Assistant Social Welfare Officer')) {
        cobblerCheckListControls.forEach((control: any) => {
          this.f4[control].setValidators([Validators.required]);
          this.f4[control].updateValueAndValidity();
        });
    } else {
      cobblerCheckListControls.forEach((control: any) => {
        this.f4[control].clearValidators();
        this.f4[control].updateValueAndValidity();
     });
    }
    this.f5.pensionId.clearValidators();
    this.f5.pensionId.updateValueAndValidity();
    if((this.certificateName === 'Pensions@2' || this.certificateName === 'Pensions@V2' ||  this.certificateName === 'Pensions-NEW@2' || this.certificateName === 'Pensions-NEW@V2') && 
      (this.role === 'Welfare & Educational Assistant' || this.role === 'Ward Welfare & Development Secretary')) {
        this.f5['isLoomPresentInHouse'].setValidators([Validators.required]);
        this.f5['isLoomPresentInHouse'].updateValueAndValidity();
    } else {
      this.f5['isLoomPresentInHouse'].clearValidators();
      this.f5['isLoomPresentInHouse'].updateValueAndValidity();
    }

    if((this.isForwardAvailable && (this.certificateName === 'HouseholdSplit' || this.certificateName === 'HouseholdSplit@1' || this.certificateName === 'HouseholdSplit@2')) || (this.isApprovalAuthority && this.certificateName === 'ClaimsUnderDottedLands') ) {
      this.isHouseholdSplitApp = true;
      this.f2.verificationDoc.setValidators([Validators.required]);
    } else if(this.certificateName === 'RectificationOfResurveyedRecords') {
      this.isRectification = true;
      this.f2.verificationDoc.setValidators([Validators.required]);
    } else if(this.certificateName === 'IntegratedCertificate-SuoMoto') {
      this.f2.verificationDoc.setValidators([Validators.required]); 
      this.f2.verificationDoc.updateValueAndValidity();
    }
    else if(this.certificateName === 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && ((this.role == 'Tahsildar' && this.workflowstepcode !== 'TEH-Verify') || this.role === 'Village Revenue Officer' || this.role === 'Ward Revenue Secretary' || this.role == 'Revenue Divisonal Officer' || this.role == 'Joint Collector')) {
      this.f2.verificationDoc.setValidators(Validators.required); 
      this.f2.verificationDoc.updateValueAndValidity();
    } 
    else {
      this.isHouseholdSplitApp = false;
      this.isRectification = false;
      this.f2.verificationDoc.clearValidators();
    }
    this.f2.verificationDoc.updateValueAndValidity();
    console.log(this.remarksReasonsOptions, "remarksReasonsOptions prepareActionButtons")
    // pension
  }
  // setVROChecklist(data:any) {
  //   if(this.role === 'Revenue Inspector') {
  //     data.list.array.forEach((element: { id: string | number; answer: any; }) => {
  //     this.f1[element.id].patchValue(element.answer);
  //     });
  //   }
  // }
  validateForm(data: any) {
    this.isFormValid = data.isValid;
    //console.log(' this.isFormValid',  this.isFormValid);
    this.approveRejectNew(data.action, data.actionCode, data.targetModal, data.confirmationOTPModal, 'other');
  }
  viewFile(blobRef:any,fileName?:any) {
    this.commonService.getRequest(this.commonConstants.viewFile + blobRef).subscribe({
      next: (responseData: any) => { 
        let fileInfo =  responseData.result.fileContents;
        if(this.isFpShopApplication){
                  this.imgSrc = 'data:image/png;base64,' +fileInfo;
                  this.showImg=true;
        }else{        
        const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
        modalRef.componentInstance.sourceFile = fileInfo;
        modalRef.componentInstance.showPrintDownload = true;
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  downloadFile(file:any) {
    this.commonService.getRequest(this.commonConstants.fileDownload + file.blobRef).subscribe({
      next: (responseData: any) => { 
          this.commonService.downloadPdfFile(responseData.result.fileContents, file.fileName)
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  getWorklist() {
    // let userInfo = localStorage.getItem('userInfo');
    // let mailId: string;
    // if (userInfo && userInfo !== null) {
    //   let userData = JSON.parse(userInfo);
    //   mailId = userData.mail !== null ? userData.mail : userData.userPrincipalName;
    // }
    this.items = [];
    this.tempItems = [];
    this.transactionID='';
    this.page = 1;
    this.workListName = 'Total Requests';
    let apiurl = this.commonConstants.getWorklist;
    if(this.router.url === '/suomoto'){
      apiurl = this.commonConstants.getCertificateDataForWorklist + '?DepartmentCode=' +'RV' + '&RequisitionType=' + 'IntegratedCertificate-SuoMoto';
    }
    this.commonService.getRequest(apiurl).subscribe({
      next: (responseData: any) => { 
        // console.log('grid responseData', responseData);
        // console.log('prop',responseData['result'].items)
        this.serviceList = responseData['result'].items;
        if (this.serviceList && this.serviceList.length > 0) {
          // this.serviceList = this.serviceList.map((item: { extendedProperties: string; }) => {
          //   item.extendedProperties = JSON.parse(item.extendedProperties);
          //   return item;
          // });
          // this.serviceList.sort((x, y) => +new Date(y.extendedProperties?.requestedDate) - +new Date(x.extendedProperties?.requestedDate));
          // this.serviceList.reverse();
          // console.log('reverse array',this.serviceList)
          let i=1;
          this.serviceList.forEach((element: any) => {
            let temp: any = {};
            let reqId: any[] = element.title.split(' ');
            temp.SNo = i++;
            temp.id = element.id;
            temp.requisitionId = element.extendedProperties.requisitionId;
            temp.title = element.title;
            temp.ApplicantName = element.extendedProperties.applicantName;
           // temp.DateApplied = element.extendedProperties.DateApplied;
            temp.DepartmentCode = element.extendedProperties.departmentCode;
            temp.RequestedDate = element.extendedProperties.requestedDate;
            temp.ServiceCode = element.extendedProperties.serviceCode;
            temp.isSelected = false;
            temp.createOn = element.createOn;
            temp.slaDays = element.slaDays;


          
            //temp.serviceFullName = this.getServiceName(element.extendedProperties.serviceCode);
          
            this.items.push(temp);
            this.tempItems.push(temp);

          });
          this.totalCount = this.items.length;
          //this.beyondSLAItems = this.items.filter(item => this.commonService.isBeyondSLA(item));
          //this.withInSLAItems = this.items.filter(item => !this.commonService.isBeyondSLA(item));
          // console.log('beyondSLAItems', this.beyondSLAItems);
          // console.log('responseItems', this.items );

          // this.items.sort((x, y) => +new Date(y.RequestedDate) - +new Date(x.RequestedDate));
            this.getCertificateDetails(this.items[0]);

        }else{
          if(this.serviceList.length ==0){
            this.totalCount = 0;
          this.beyondSLAItems = 0;
          this.withInSLAItems = 0;
          }
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  getServiceName(reqType:any){
       let serviceFullName =  this.serviceAllList.find((item: any) => item.serviceCode ===  reqType)?.serviceName;
       return (serviceFullName !== "" && serviceFullName !== undefined) ? serviceFullName : reqType;
  }
  getBeyondSLA() {
    this.workListName = 'Open Beyond SLA';
    if (this.beyondSLAItems && this.beyondSLAItems.length !== 0) {
      this.items = this.beyondSLAItems;
      this.setSequence();
    }
  }
  getWithInSLA() {
    this.workListName = 'Open Within SLA';
    if (this.withInSLAItems && this.withInSLAItems.length !== 0) {
      this.items = this.withInSLAItems;
      this.setSequence();
    }
  }
  getOpenRequests(from?:any) {
    let slaStatus;
    if(from === 'within') {
      slaStatus = 'Within-SubSLA';
    } else {
      slaStatus = 'Beyond-SubSLA';
    }
    let inputStr = '?UserId='+this.loggedinuserId+"&AppStatus=Open&SlaStatus="+slaStatus+'&Flag=D';
    this.commonService
    .getRequest( this.commonConstants.getOpenRequests + inputStr )
    .subscribe({
      next: (responseData: any) => {
       let resultItems: any = responseData?.result?.result;
       if(from === 'within') {
        this.workListName = 'Open Within SLA';
        this.withInSLAItems = this.tempItems.filter((item:any) => resultItems.findIndex((el:any)=> item.requisitionId?.trim() === el.requisitioN_ID?.trim()) > -1);
        this.items = this.withInSLAItems;
       } else {
        this.workListName = 'Open Beyond SLA';
        this.beyondSLAItems = this.tempItems.filter((item:any) => resultItems.findIndex((el:any)=> item.requisitionId?.trim() === el.requisitioN_ID?.trim()) > -1);
        this.items = this.beyondSLAItems;
       }
       this.setSequence();
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  setSequence() {
    this.page = 1;
    let i = 1;
    this.items = this.items.map(item =>{
      item.SNo = i++;
      return item;
    });
    this.getCertificateDetails(this.items[0]);
  }

  value: any;
  answers: any = [];
  onChangeChecklist(event?:any) {
      this.answers[event?.target?.id] = event?.target?.value;
    if((this.certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && this.role == 'Joint Collector') && (this.f2.remarks.value)) {
      if(this.f2.remarks.value == 'Recommended' && (this.answers['ror1bJC'] == 'Yes') && (this.answers['grantofassignmentlandjc'] === 'Yes') && (this.answers['assignmentapproveddistJC'] == 'Yes')) {
        this.enabledisablebutton = true;
      }
      else if(this.f2.remarks.value != 'Recommended'){
        this.enabledisablebutton = false;
      }
      else {
        this.enabledisablebutton = null;
      }
    }
  }

  onChangeLPMNumber() {
  }

  fetchLPMDetailstoken() {
      this.commonService.postRequestForWebLand(this.commonConstants.zswotoken,"").subscribe({
        next: (responseData: any) => {
          if(responseData.Code == 100) {
            let token = 'Bearer ' +responseData.Data;
            this.fetchLPMDetails('RC',token)
          }
        },
        error: (error) => {
        console.log('client side', error);
        }
    });

  }
  lpNumberSurveyNumber: any = [];
  fetchLPMDetails(data: any, token: any) {
    let postData: any = {};
      postData.ProgramType = data;
      postData.VillageCode = this.certificateInfo.village;
      this.commonService.postRequestForWebLand(this.commonConstants.zswolpmnumberfetch, postData, 'resurvey',token).subscribe({
        next: (responseData: any) => {
          if(responseData.Code==100 && (responseData.Data)[0].LPMNO){
          this.lpNumberSurveyNumber=(responseData.Data).map((item: any) => item.LPMNO);
          } else if(responseData.Code==100 && (responseData.Data)[0].Survey_No){
            this.lpNumberSurveyNumber=(responseData.Data).map((item: any) => item.Survey_No);
          }
          else {
            this.lpNumberSurveyNumber = [];
          }
        },
        error: (error: any) => {
        console.log('client side', error);
        }
    }); 
  }
  generateLPtoken() {
    this.z1.proposedextent.disable();
    this.commonService.postRequestForWebLand(this.commonConstants.zswotoken,"").subscribe({
      next: (responseData: any) => {
        if(responseData.Code == 100) {
          let token = 'Bearer ' +responseData.Data;
          this.lpNumberWise('RC',token)
        }
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  lpsyobj: any = [];
  id: any;
  getlpsysubmitted: boolean = false;
  lpNumberWise(data: any, token: any) {
    // this.makeVisible = false;
    // this.submitted = true;
    this.showTable = false;
    if(this.z1.lpmnumbers?.value?.length) {
      this.z1.lpmnumbers.clearValidators();
      this.z1.lpmnumbers.updateValueAndValidity();
      this.showTable=true;
      this.lpsyobj = [];
      this.z1.lpmnumbers.value.forEach((item: any) => {
        let postData: any = {};
        postData.ProgramType = data;
        postData.VillageCode = this.certificateInfo.village;
        postData.LPMorSurveyNo = item;
        this.commonService.postRequestForWebLand(this.commonConstants.zswolpmnumberfetch, postData, 'resurvey',token).subscribe({
          next: (responseData: any) => {
            if(responseData.Code==100){
              this.lpsyobj.push(responseData.Data[0]);
              this.zillasainikwelfareform.addControl(this.lpsyobj.length, new FormControl(false));
              this.zillasainikwelfareform.addControl(this.lpsyobj.length +'Value', new FormControl({value: '', disabled: true}));
            }
            else {
              this.lpsyobj = [];
            }
          },
          error: (error: any) => {
          console.log('client side', error);
          }
      }); 
      });
      this.z1.lpmnumbers.value.forEach((item: any, index: any) => {
        this.zillasainikwelfareform.get((index+1).toString())?.reset();
        this.zillasainikwelfareform.get(`${index+1}Value`.toString())?.reset();
        this.zillasainikwelfareform.get(`${index+1}Value`.toString())?.disable();
        this.lpnumberdatafromtable = [];
      })
    }
    else {
      this.z1.lpmnumbers.setValidators([Validators.required]);
      this.z1.lpmnumbers.updateValueAndValidity();
      this.getlpsysubmitted = true;
      this.showTable = false;
      return;
    }
    this.z1.lpmnumbers.updateValueAndValidity();
   }

   eventcheckboxchecked: boolean = false;
   checkboxstate: boolean = false;
   lpnumberdatafromtable: any = [];
   singlerowdata: any = [];
    proposedextentvalue: any;
    checkvalueindex: any;
    checkvaluechecked: any;
   checkValue(lpNumbertabledata: any, event: any, index: any){
    const checkboxControl = this.zillasainikwelfareform.get(index.toString());
    const valueControl = this.zillasainikwelfareform.get(`${index}Value`.toString());
    this.checkvalueindex = index;
    this.checkvaluechecked = checkboxControl;
    this.singlerowdata = lpNumbertabledata;

    if(checkboxControl?.value == true) {
      this.lpnumberdatafromtable.push(lpNumbertabledata);
      valueControl?.enable();
    }
    else {
      if(this.isResurveyVillage) {
        this.zswoinsertindex = this.lpnumberdatafromtable.findIndex((item: any) => item.LPMNO ==  lpNumbertabledata.LPMNO);
        }
        else {
          this.zswoinsertindex = this.lpnumberdatafromtable.findIndex((item: any) => item.Survey_No == lpNumbertabledata.Survey_No);
        }
        if(this.lpnumberdatafromtable[this.zswoinsertindex].proposedextentvalue !== 0 && (this.zswoinsertindex !== -1)) {
        this.lpnumberdatafromtable[this.zswoinsertindex].proposedextentvalue = undefined;
        }
        this.lpnumberdatafromtable = this.lpnumberdatafromtable.filter((i: any) => i != lpNumbertabledata);
        valueControl?.disable();
        valueControl?.reset();
    }
   
    valueControl?.reset();
  }

  textbox: any = false;
  indexofzswoprposedextent: any;
  zswoinsertindex: any;

  proposedextentChange(lpNumbertabledata: any, index: any) {
    const checkboxControl = this.zillasainikwelfareform.get((index).toString());
    const valueControl = this.zillasainikwelfareform.get(`${index}Value`.toString());
    this.indexofzswoprposedextent = index;

      if(this.isResurveyVillage) {
      this.zswoinsertindex = this.lpnumberdatafromtable.findIndex((item: any) => item.LPMNO ==  lpNumbertabledata.LPMNO);
      }
      else {
        this.zswoinsertindex = this.lpnumberdatafromtable.findIndex((item: any) => item.Survey_No == lpNumbertabledata.Survey_No);
      }
      if(valueControl?.value) {
        this.lpnumberdatafromtable[this.zswoinsertindex].proposedextentvalue=valueControl?.value;
        this.zillasainikwelfareform.get(index.toString())?.setValidators([Validators.required]);
        this.zillasainikwelfareform.get(`${index}Value`.toString())?.setValidators([Validators.pattern(/^[0-9]+([.][0-9]+)?$/)]);
        this.zillasainikwelfareform.get(index.toString())?.updateValueAndValidity();
        this.zillasainikwelfareform.get(`${index}Value`.toString())?.updateValueAndValidity();
      }
      else if(valueControl?.value == "" || valueControl?.value == '' || valueControl?.value == undefined || valueControl?.value == null) {
        this.lpnumberdatafromtable[this.zswoinsertindex].proposedextentvalue=undefined;
        this.zillasainikwelfareform.get(index.toString())?.setValidators([Validators.required]);
        this.zillasainikwelfareform.get(`${index}Value`.toString())?.setValidators([Validators.pattern(/^[0-9]+([.][0-9]+)?$/)]);
        this.zillasainikwelfareform.get(index.toString())?.updateValueAndValidity();
        this.zillasainikwelfareform.get(`${index}Value`.toString())?.updateValueAndValidity();
        }
      else {
        this.zillasainikwelfareform.get(index.toString())?.clearValidators();
        this.zillasainikwelfareform.get(index.toString())?.updateValueAndValidity();
        this.zillasainikwelfareform.get(`${index}Value`.toString())?.clearValidators();
        this.zillasainikwelfareform.get(`${index}Value`.toString())?.updateValueAndValidity();
      }
    this.errorCount = 0;
    this.errorCountforgreater = 0;
  }

//Civil Supplies
wFlowUserInfo:any
wFlowSLA:any
wFlowGetSLAData(ServiceCode:any,weblandResponseStatus: any , weblandPostData : any,webValue:any, statusToUpdate:any){
  this.commonService
  .getRequest(this.commonConstants.getSLAData + "?ServiceCode="+ServiceCode)
  .subscribe({
    next: (responseData: any) => {
      if(responseData.result != ""){
        if(responseData.result === "No applicable workflow found"){
          this.wFlowSLA = "15Minutes" ;
        }else{
          this.wFlowSLA ="21"
        }
        console.log('this.SLA',this.wFlowSLA)
        this.workflowStatusUpdate(weblandResponseStatus,weblandPostData,webValue,statusToUpdate)
      }
     },
    error: (error) => {
      console.log('client side', error);
    },
  });
}


isResurveyVillage:Boolean=false;

checkResurveyVillage() {
    this.isResurveyVillage = false;
    let postData: any = {};
    postData.Ptype = 'RVSN';
    postData.DistrictCode = this.certificateInfo.district;
    postData.MandalCode = this.certificateInfo.mandal;
    postData.VillageCode = this.certificateInfo.village;
    postData.LPM = '';
  this.commonService.postRequestForWebLand(this.commonConstants.mutationResurveyToken, '').subscribe({
      next: (responseData: any) => {
        if(responseData.Code == 100) {
          let token = 'Bearer ' +responseData.Data;
          console.log('token', token);
          this.commonService.postRequestForWebLand(this.commonConstants.mutationResurveyMaster, postData, 'resurvey', token).subscribe({
            next: (responseData: any) => {
              if(responseData.Code == 100) {
                if(responseData.Data[0]?.FrCode == 2) {
                  this.isResurveyVillage = true;
                } else if(responseData.Data[0]?.FrCode == 1) {
                  this.isResurveyVillage = false;
                }
              } else {
                this.isResurveyVillage = false;
                this.commonService.commonErrorPopup('Alert', responseData?.Message, 'md');
              }
            },
            error: (error: any) => {             
        console.log('client side', error);
            }
        });
        }
      },
      error: (error) => {
    console.log('client side', error);
      }
  });


}

workflowStatusUpdate(weblandResponseStatus: string , weblandPostData : any,webValue:any,statusToUpdate:any) {
  console.log("name",this.loggedinuserId);
  let noStatus = '';
  let year=  this.currentDate.getFullYear()
  let month= this.currentDate.getMonth() + 1
  let day=  this.currentDate.getDate()
  let dateFormat = day +"-"+  month +"-"+ year 
  let createdDate = moment(dateFormat,"DD-MM-YYYY").format("YYYY-MM-DDThh:mm:ss")
  let reqType =  this.basicInfo.requisitionType
  let dpCode  =this.basicInfo.department
  let resID =  this.getId
  let weblandMsg :any =  '3';
  let weblandStatus:any;
// console.log("success",weblandResponseStatus);
    if(webValue == 1){
      if(weblandResponseStatus){
        weblandMsg = '2';
        weblandStatus = 'Forwarded to CS DEPT Team'
      }else{
        weblandMsg = '-2';
        weblandStatus = 'Not Forwarded to CS DEPT Team'
      }
      if(this.f2.remarks.value != null && this.isBtnClicked == true){
        if(weblandResponseStatus === '200' || weblandResponseStatus === '201'){
          weblandMsg = '3';
          weblandStatus = 'Final Status - ' + statusToUpdate + ' forwarded to CS DEPT Team'
        }else{
          weblandMsg = '-3';
          weblandStatus = 'Final Status - ' + statusToUpdate + ' not forwarded to CS DEPT Team'
        }
        // if (this.f2.remarks.value == 'Recommended' && weblandResponseStatus === '200'){
        //   weblandMsg = '3';
        //   weblandStatus = 'Final Approve forwarded to CS DEPT Team'
        // }else  if (this.f2.remarks.value == 'Not Recommended' && weblandResponseStatus === '200'){
        //   weblandMsg = '-3';
        //   weblandStatus = 'Final Approve not forwarded to CS DEPT Team'
        // }else if(weblandResponseStatus === '200' || weblandResponseStatus === '201' ){
        //   if(this.f2.remarks.value == 'Not Recommended'){
        //     weblandMsg = '-3';
        //     weblandStatus = 'Final Approve not forwarded to CS DEPT Team'
        //   }else{
        //     weblandMsg = '3';
        //     weblandStatus = 'Final Approve Forwarded to CS DEPT Team'
        //   }
        // }
        // else{
        //   weblandMsg = '-3';
        //   weblandStatus = 'Final Approve not forwarded to CS DEPT Team' 
        // }
      }
    
  }else{

    if(weblandResponseStatus === '200' || weblandResponseStatus === '201'){
      weblandMsg = '3';
      weblandStatus = 'Final Status - ' + statusToUpdate + ' forwarded to CS DEPT Team'
    }else{
      weblandMsg = '-3';
      weblandStatus = 'Final Status - ' + statusToUpdate + ' not forwarded to CS DEPT Team'
    }
    // if (this.f2.remarks.value == 'Recommended' && weblandResponseStatus === '200'){
    //   weblandMsg = '3';
    //   weblandStatus = 'Final Approve forwarded to CS DEPT Team'
    // }else  if (this.f2.remarks.value == 'Not Recommended' && weblandResponseStatus === '200'){
    //   weblandMsg = '-3';
    //   weblandStatus = 'Final Approve not forwarded to CS DEPT Team'
    // }else if(weblandResponseStatus === '200' || weblandResponseStatus === '201' ){
    //   if(this.f2.remarks.value == 'Not Recommended'){
    //     weblandMsg = '-3';
    //     weblandStatus = 'Final Approve not forwarded to CS DEPT Team'
    //   }else{
    //     weblandMsg = '3';
    //     weblandStatus = 'Final Approve Forwarded to CS DEPT Team'
    //   }
      
    // }
    // else{
    //   weblandMsg = '-3';
    //   weblandStatus = 'Final Approve not forwarded to CS DEPT Team' 
    // }
  }

  let stringToHash = resID + '&' + 
  dpCode + '&' + 
  '' + '&' + '' + '&'+ '' + '&' + weblandResponseStatus  + '-' + this.nicMessege + '&' +  reqType + '&' +
  this.wFlowSLA+ '&' + weblandMsg + '&' + weblandStatus + '&' +''+'&' + this.loggedinuserId + '&' + createdDate;

  // this.wFlowSLA+ '&' + '1&Forwarded to Webland Team&' +''+'&' + this.userInfo.userPrincipalName + '&' + createdDate;
  let postData: any = {};
  postData = {
    strToHash : stringToHash
  }
//   console.log(postData);
// console.log( this.basicInfo.department);
// console.log( this.basicInfo.department);


  console.log('update', postData);
  // this.commonService.postRequest(this.commonConstants.postWorkFlowGetHmacHash, postData).subscribe({
  //   next: (responseData:any) => { 
  //     console.log('response from GetHmacHash', responseData)
  //     if(responseData['result'].reason == 'Success'){
       /* let workFlowPostData : any = {}
        workFlowPostData = {
          // workFlowStatusData: {
            id: 0,
            requisitionId: resID,
            departmentCode: dpCode,
            requisitionType: reqType,
            subRequisitionType: '',
            statusCode: weblandMsg,
            statusDesc: weblandStatus, //'Forwarded to Webland Team',
            requisitionStatus: this.nicMessege != undefined || null ? (weblandResponseStatus  + '-' + this.nicMessege) : '',
            createdBy: this.loggedinuserId,
            created: createdDate,
            lastModifiedBy: this.loggedinuserId,
            lastModified: createdDate,
            slaDays: '',
            officerId: '',
            officerName: '',
            officerMobileNumber: '',
            additionalInfo1: JSON.stringify(weblandPostData),
            additionalInfo2: '',
            additionalInfo3: ''
          // },
          // hash: '',  //responseData['result'].hashRes
        }*/
          console.log('req body from workFlowPostData', weblandMsg)

          if(weblandMsg=== '3' )  {
            
                if(statusToUpdate === "Approve"){
                 this.statusUpdateForCivilSuppliesWorkflow(5,"Approve");
                  this.statusUpdateApprove();
                }

                if(statusToUpdate === "Reject"){
                  this.statusUpdateForCivilSuppliesWorkflow(4,"Reject");
                  this.statusUpdateRejectVro();
                }
        
         }else if(weblandMsg=== '-3'){
          console.log('-3 issue',this.commonConstants.postWorkFlowStatusUpdate)
          // this.commonService.postRequest(this.commonConstants.postWorkFlowStatusUpdate, workFlowPostData).subscribe({
          //   next: (responseData:any) => { 
          //     console.log('response from postWorkFlowStatusUpdate', responseData)
          //     if(responseData['result'].reason == 'Success'){
          //     }
          //   },
          //   error: (error) => {
          //     console.log('client side', error);
          //   }
          // });
          let workFlowPostData12 : any = {}
          workFlowPostData12 = {
            workFlowStatusData: {
              id: 0,
              requisitionId: resID,
              departmentCode: dpCode,
              requisitionType: reqType,
              subRequisitionType: '',
              statusCode: weblandMsg,
              statusDesc: weblandStatus, //'Forwarded to Webland Team',
              requisitionStatus: this.nicMessege != undefined || null ? weblandResponseStatus  + '-' + this.nicMessege : '',
              createdBy: this.loggedinuserId,
              created: createdDate,
              lastModifiedBy: this.loggedinuserId,
              lastModified: createdDate,
              slaDays: this.wFlowSLA,
              officerId: '',
              officerName: '',
              officerMobileNumber: '',
              additionalInfo1: JSON.stringify(weblandPostData),
              additionalInfo2: '',
              additionalInfo3: ''
            },
            hash: '',  //responseData['result'].hashRes
          }
            console.log('-3 issue',this.commonConstants.postWorkFlowStatusUpdate, workFlowPostData12)
            this.commonService.postRequest(this.commonConstants.postWorkFlowStatusUpdate, workFlowPostData12).subscribe({
              next: (responseData:any) => { 
                console.log('response from postWorkFlowStatusUpdate', responseData)
                if(responseData['result'].reason == 'Success'){
                  const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                  modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
                  modalRef.componentInstance.message = this.nicMessege != undefined || null ? "Error message : " + weblandResponseStatus  + '-' + this.nicMessege : '';
                  modalRef.result.then((result: any) => {
                  });
                }else{
                  const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                  modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
                  modalRef.componentInstance.message = this.nicMessege != undefined || null ? "Error Message : " + weblandResponseStatus  + '-' + this.nicMessege + '& Department Table shows - ' + responseData['result'].reason :  "Error from CS DEPT Team : " + responseData['result'].reason;
                  modalRef.result.then((result: any) => {
                  });
                }
              },
              error: (error) => {
                console.log('client side', error);
              }
            });
         }
         else{

          let workFlowPostData1 : any = {}
          workFlowPostData1 = {
            workFlowStatusData: {
              id: 0,
              requisitionId: resID,
              departmentCode: dpCode,
              requisitionType: reqType,
              subRequisitionType: '',
              statusCode: weblandMsg,
              statusDesc: weblandStatus, //'Forwarded to Webland Team',
              requisitionStatus: this.nicMessege != undefined || null ? weblandResponseStatus  + '-' + this.nicMessege : '',
              createdBy: this.loggedinuserId,
              created: createdDate,
              lastModifiedBy: this.loggedinuserId,
              lastModified: createdDate,
              slaDays: this.wFlowSLA,
              officerId: '',
              officerName: '',
              officerMobileNumber: '',
              additionalInfo1: JSON.stringify(weblandPostData),
              additionalInfo2: '',
              additionalInfo3: ''
            },
            hash: '',  //responseData['result'].hashRes
          }

             this.commonService.postRequest(this.commonConstants.postWorkFlowStatusUpdate, workFlowPostData1).subscribe({
                next: (responseData:any) => { 
                  console.log('response from postWorkFlowStatusUpdate', responseData)
                  if(responseData['result'].reason == 'Success'){
                  }
                },
                error: (error) => {
                  console.log('client side', error);
                }
              });
          }
  //     }
      
  //   },
  //   error: (error) => {
  //     console.log('client side', error);
  //   }
  // });
  
}

authenticationForCivilsupplies(confirmationOTPModal:any){
  if(!this.sixstepForm.valid){

  }
  else{

    
if(this.remarksForm.valid){
  this.otpModalRef = this.modalService.open(confirmationOTPModal, 
    {   
      size: 'lg' ,
      centered: true,
      backdrop: 'static'
    }
  );

}
  }
}
nicStatus:any;
nicMessege:any;
isDisabled:Boolean=false;
isBtnClicked : boolean = false;
  btnSubmitCS(): any{
    this.submitTable = true;
   console.log('btnsubmit')
    this.isBtnClicked = false;
    this.submits = true;
    this.submitted=true;
    this.sumotoSubmits=false;
    this.isCSclick = false

    let selectedRemarks;
    let clusterDatas;
    let fpshopDatas;
    let socialAuditStatus;
    let remarks;
    let reasons;
  // selectedRemarks= this.f2.remarks.value === 'Others' ? this.f2.otherRemarks.value : this.f2.remarks.value;  
  clusterDatas = this.k['cluster'].value;
  fpshopDatas = this.k['fpshop'].value;
  remarks = this.f2.remarks.value;
  reasons = this.f2.remarksReasons.value;
  socialAuditStatus = this.k['socialAudit'].value ? this.k['socialAudit'].value : '-';
  if(!this.consent) {
      return false;
  }
  console.log(this.showInfo , 'show info',this.EkycResponse ,'EkycResponse', this.EkycDescription ,'EkycDescription' , this.f2.remarks?.value,'remarks' ,this.f2.remarksReasons?.value)
    if(this.f2.remarks.value == 'Recommended' || this.f2.remarks.value == null){ 
      if(!this.showInfo ){
        this.commonService.commonErrorPopup("Alert","Six Step Validation Failed. Please Reject the Application. Citizen can apply Six Step Grievance to clear Six step Validation Problem.","md")
      }
      if(this.showInfo) {
        this.sixstepForm.controls['socialAudit'].setValidators(Validators.required);
        this.sixstepForm.controls['socialAudit'].updateValueAndValidity();
      }
      this.sixstepForm.controls['cluster'].setValidators(Validators.required);
      this.sixstepForm.controls['cluster'].updateValueAndValidity();
      this.sixstepForm.controls['fpshop'].setValidators(Validators.required);
      this.sixstepForm.controls['fpshop'].updateValueAndValidity();
    }else{
              this.sixstepForm.controls['socialAudit'].clearValidators();
              this.sixstepForm.controls['socialAudit'].updateValueAndValidity();
              this.sixstepForm.controls['socialAudit'].setValue(false);
            //   this.sixstepForm.controls['cluster'].clearValidators();
            // this.sixstepForm.controls['cluster'].updateValueAndValidity();
            // this.sixstepForm.controls['fpshop'].clearValidators();
            // this.sixstepForm.controls['fpshop'].updateValueAndValidity();
            // this.sixstepForm.controls['socialAudit'].setValidators(Validators.required);
            // this.sixstepForm.controls['socialAudit'].updateValueAndValidity();
            this.sixstepForm.controls['cluster'].setValidators(Validators.required);
            this.sixstepForm.controls['cluster'].updateValueAndValidity();
            this.sixstepForm.controls['fpshop'].setValidators(Validators.required);
            this.sixstepForm.controls['fpshop'].updateValueAndValidity();
               
            
    }
 


    if(!this.sixstepForm.valid){
      this.isCSclick = true
    }
    else{
      console.log(this.sixstepForm.valid,'else caase 1')
      console.log(this.ekycErrorMsg,'ekyc error msg')
      this.isBtnClicked = true;
      console.log(this.showInfo , 'show info',this.EkycResponse ,'EkycResponse', this.EkycDescription ,'EkycDescription' , this.f2.remarks?.value,'remarks' ,this.f2.remarksReasons?.value)
      console.log(this.sixstepForm.valid,'else caase 2')
      if(this.remarksForm.valid ){
        console.log(this.sixstepForm.valid,'else caas 3e')
        if(this.f2.remarks.value == 'Recommended'){
          if(this.showInfo && this.EkycResponse == 6 && (this.EkycDescription == "Ekyc Completed" || this.EkycDescription == "EkycCompleted")){
            // this.sixStepPostData1.application_Details.application.cluster_id = clusterDatas;
            // this.sixStepPostData1.application_Details.application.shop_no = fpshopDatas,
        //     var postApporveData={
        //       application_Details: {
        //     app_address: {
        //       city: this.civilDistrict,
        //       line1:this.civilMandal, 
        //       line2: this.civilVillage,
        //       pincode: this.pincode,
        //       state_code: "28",
        //     },
        //     app_member_details:  [{
        //     age: this.ageCalculator(this.dob),
        //     annual_income:  Number(this.familyIncome ? this.familyIncome : 0),       
        //     citizen_name:  this.name,
        //     citizen_name_ll : this.name,
        //     dob_dt: this.commonService.formatDate(this.dob.split('T')[0]),
        //     dob_dtSpecified: true,
        //     mapping_id: 0,
        //     mobile_number: this.mobileNo,
        //     gender: Number(this.addGender),
        //     occupation_type: Number(occType),
        //     relationship_hoh: 1,
        //     uid_no:  this.applicantAadhar
        // }],
        //     application: {
        //       application_type: this.appType,
        //       caste:Number(casteNic)  ?  Number(casteNic): " ",
        //       cluster_id:  clusterDatas,
        //       district_code: this.district_code,
        //       hof_name: this.name,
        //       household_id: "101",
        //       mapping_id: 0,
        //       no_of_units: 1,
        //       religion: this.religion ? this.religion : "",
        //       rice_card_no: this.riceCardNo ? this.riceCardNo : "",
        //       secretariat_code: this.villageWard ? this.villageWard : "",
        //       shop_no: fpshopDatas,
        //       spandana_application_code: this.getId,
        //       uid_no: this.applicantAadhar,
        //       volunteer_uid: ""
        //     }
        //   },
        //   password:"11169d3abc20724bb857cc5ea59e3c42",
        //   hts:"12",
        //   aadharNumber: this.aadharUID,
        //   createdBy: this.loggedinuserId,
        //   requestNumber: this.getId,
        //   applicationType : Number(this.appType)
        //     }
      
            this.sixStepPostData1.workflowUpdate.comment = JSON.stringify({ remarks:this.f2.remarks.value,reasons:this.f2.remarksReasons.value,clsuter:this.k['cluster'].value,fpshopData:this.k['fpshop'].value,socialAudit:this.k['socialAudit'].value ? this.k['socialAudit'].value : '-'});
            console.log('posting to nic in VRO',this.sixStepPostData1)
            //this.commonConstants.requestCSActionStatus

            this.commonService.commonErrorPopup('Alert', "Success", 'md');

            this.commonService.postRequest(this.commonConstants.postNICdataVRO, this.sixStepPostData1).subscribe({
              next: (responseData:any) => {  
                console.log('new externalChangeOfAddress responseData',responseData.result)
                this.nicStatus =responseData.result.status;
                this.nicMessege = responseData.result.message ? responseData.result.message : '';
                if(responseData.result?.response_code !== 200 && responseData.result?.response_code !== 201 &&
                  responseData.result?.response_code !== '200' && responseData.result?.response_code !== '201'){
                  console.log('if', responseData.result?.response_code)
                  const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                  modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
                  if(this.nicMessege.toLowerCase().includes('validation issue with tcs team')){
                    modalRef.componentInstance.message = 'Error in External API please try again';
                  }else{
                    //modalRef.componentInstance.message = this.nicMessege!= undefined || null ? this.nicMessege : 'Error in External API please try again';
                    if(this.nicMessege!= undefined && this.nicMessege != null && this.nicMessege != ''){
                    
                      if(this.nicMessege == 'Ekyc was not Done.'){
                        modalRef.componentInstance.message = 'eKYC is completed successfully but yet to forward to Tahsildar login';
                      }
                      else{
                        modalRef.componentInstance.message = this.nicMessege;
                      }
                  } else if(responseData.result?.status_flag !== undefined && responseData.result?.status_flag !== null) {
                    modalRef.componentInstance.message = responseData.result?.status_flag;
                    } else {
                    modalRef.componentInstance.message = 'Error in External API please try again';
                    }
                  }
                  //   modalRef.result.then((result: any) => {
                  // });
                }else{
                  console.log('else..')
                  //this.SubmitSixStep();
                  // this.commonService.commonErrorPopup('Alert', 'Application approved successfully.', 'md');
                  // this.getWorklist();
                  this.commonMsgPopup('Application approved successfully.');
                }
                // if(this.nicStatus == 200 || this.nicStatus == 201){
                //   //this.statusUpdate(5,"Approve");
                //   //this.statusUpdateApprove();
                //   this.wFlowGetSLAData("CS",responseData.result.status,postApporveData,1,"Approve")
                //   console.log(responseData,'Ekyc Completed approve post respose data');
                // }else{
                //   const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                //   modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
                //   modalRef.componentInstance.message = this.nicMessege!= undefined || null ? this.nicMessege : 'Error in External API please try again';
                //   modalRef.result.then((result: any) => {
                //   });
                // }
              },
              error: (error) => {
                this.isSigning = false;
                console.log('client side', error);
              }
            });
          }
          else {
            if(!this.showInfo ){
              this.commonService.commonErrorPopup("Alert","Six Step Validation Failed. Please Reject the Application. Citizen can apply Six Step Grievance to clear Six step Validation Problem.","md")
            }
            if(this.EkycResponse == 6 && this.EkycDescription == "Ekyc Rejected"){
              console.log('ekycStatusAlert  Ekyc Rejected')

              this.ekycStatusAlert();
            }
            if(this.ekycErrorMsg.length > 1){
              this.ekycStatusAlert();
              console.log('ekycStatusAlert  > 1')
              // this.ekycStatusAlert();
            }
            
            
           
          }
          // else{
          //   var postApporveData={
          //     approval_Status_Bean: {
          //       application_id: this.getId,
          //       district_code: "0",
          //       status: "2"
          //     },
          //     password: "11169d3abc20724bb857cc5ea59e3c42",
          //     hts: "12"
          //   }
          //   this.commonService.postRequest(this.commonConstants.requestCSActionStatus, postApporveData).subscribe({
          //     next: (responseData:any) => { 
          //       console.log(responseData,'Ekyc Rejected post respose data');
          //         this.nicStatus =responseData.result.status;
          //         this.nicMessege = responseData.result.message;
          //         if(responseData.result.status == 201 || responseData.result.status == 200 ){
          //           this.statusUpdate(4,"Reject");
          //           this.statusUpdateReject();
          //           this.wFlowGetSLAData("CS",responseData.result.status,postApporveData,2)
          //         }
          //       else{
          //         const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          //         modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
          //         modalRef.componentInstance.message = this.nicMessege;
          //         modalRef.result.then((result: any) => {
          //         });
          //       }
          //     },
          //     error: (error) => {
          //       this.isSigning = false;
          //       console.log('client side', error);
          //     }
          //   });
          // }
        }
        else {
          console.log(this.sixstepForm.valid,'reject case (not reccommend)')
          //this.SubmitSixStep();
          this.statusUpdateForCivilSuppliesWorkflow(4,"Reject")
        }
      }else {
          if(this.ekycErrorMsg.length > 1){
            this.ekycStatusAlert();
          }
          // if(!this.showInfo ){
          //   this.commonService.commonErrorPopup("Alert","Six Step Validation Failed. Please Reject the Application. Citizen can apply Six Step Grievance to clear Six step Validation Problem.","md")
          // }
          if(this.EkycResponse == 6 && this.EkycDescription == "Ekyc Rejected"){
            this.ekycStatusAlert();
          }
          
      }

        // if(this.showInfo && this.EkycResponse == 6 && this.EkycDescription == "Ekyc Completed" && this.f2.remarks.value == 'Recommended'){
        //   var postApporveData={
        //     approval_Status_Bean: {
        //       application_id: this.getId,
        //       district_code: "0",
        //       status: "1"
        //   },
        //   password: "11169d3abc20724bb857cc5ea59e3c42",
        //   hts: "12"
        
          
        //   }
        //   this.commonService.postRequest(this.commonConstants.requestCSActionStatus, postApporveData).subscribe({
        //     next: (responseData:any) => { 
        //       this.nicStatus =responseData.result.status;
        //       this.nicMessege = responseData.result.message;
        //       if(this.nicStatus == 200 || this.nicStatus == 201){
        //       this.statusUpdate(5,"Approve");
        //       this.statusUpdateApprove();
        //       this.wFlowGetSLAData("CS",responseData.result.status,postApporveData,1)
        //       console.log(responseData,'Ekyc Completed approve post respose data');
        //       }else{
        //         const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        //         modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
        //         modalRef.componentInstance.message = this.nicMessege;
        //         modalRef.result.then((result: any) => {
        //         });
        //       }
              

        //     },
        //     error: (error) => {
        //       this.isSigning = false;
              
        //       console.log('client side', error);
        //     }
        //   });
        // }
        // else if(this.showInfo && this.EkycResponse == 6 && this.EkycDescription == "Ekyc Rejected" && this.f2.remarks.value == 'Recommended'){
        //   var postApporveData={
        //     approval_Status_Bean: {
        //       application_id: this.getId,
        //       district_code: "0",
        //       status: "2"
        //   },
        //   password: "11169d3abc20724bb857cc5ea59e3c42",
        //   hts: "12"
        
          
        //   }
        //   this.commonService.postRequest(this.commonConstants.requestCSActionStatus, postApporveData).subscribe({
        //     next: (responseData:any) => { 
        //       console.log(responseData,'Ekyc Rejected post respose data');
        //         this.nicStatus =responseData.result.status;
        //         this.nicMessege = responseData.result.message;
        //         // This Application Already Exist .
        //         // if(responseData.result.status == 200 || responseData.result.status == 'Application ID is already Rejected .'){
        //         if(responseData.result.status == 201 || responseData.result.status == 200 ){
        //             this.statusUpdate(4,"Reject");
        //             this.statusUpdateReject();
        //             this.wFlowGetSLAData("CS",responseData.result.status,postApporveData,2)
        //         }
        //       // }
        //       else{
        //         const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        //         modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
        //         modalRef.componentInstance.message = this.nicMessege;
        //         modalRef.result.then((result: any) => {
        //         });
        //       }
        //     },
        //     error: (error) => {
        //       this.isSigning = false;
        //       console.log('client side', error);
        //     }
        //   });
        // }
        // else if(this.f2.remarks.value == 'Not Recommended'){
        //   var postApporveData={
        //     approval_Status_Bean: {
        //       application_id: this.getId,
        //       district_code: "0",
        //       status: "2"
        //   },
        //   password: "11169d3abc20724bb857cc5ea59e3c42",
        //   hts: "12"
        //   }
        //   this.commonService.postRequest(this.commonConstants.requestCSActionStatus, postApporveData).subscribe({
        //     next: (responseData:any) => { 
        //       console.log(responseData,'not recommended post respose data');
        //       // alert("Rejected");
        //       // if(!this.showInfo){
        //         this.nicStatus =responseData.result.status;
        //         this.nicMessege = responseData.result.message;

        //         // if(responseData.result.status == 200 || responseData.result.status == 'Application ID is already Rejected .'){
        //           if(responseData.result.status == 201 || responseData.result.status == 200){
        //             this.statusUpdate(4,"Reject");
        //             this.statusUpdateRejectVro();
        //             this.wFlowGetSLAData("CS",responseData.result.status,postApporveData,2)

        //         }
        //         else{
        //           const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        //           modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
        //           modalRef.componentInstance.message = this.nicMessege;
        //           modalRef.result.then((result: any) => {
        //           });
        //         }
        //     },
        //     error: (error) => {
        //       this.isSigning = false;
              
        //       console.log('client side', error);
        //     }
        //   });

        // }

        // else{
  
        // var postApporveData={
        //   approval_Status_Bean: {
        //     application_id: this.getId,
        //     district_code: "0",
        //     status: "2"
        // },
        // password: "11169d3abc20724bb857cc5ea59e3c42",
        // hts: "12"
      
        
        // }
        // this.commonService.postRequest(this.commonConstants.requestCSActionStatus, postApporveData).subscribe({
        //   next: (responseData:any) => { 
        //     console.log(responseData,' else post respose data');
        //     this.nicStatus =responseData.result.status;
        //     this.nicMessege = responseData.result.message;

        //     if(!this.sixstepBoolean){
        //       // if(responseData.result.status == 200 || responseData.result.status == 'Application ID is already Rejected .'){
        //       if(responseData.result.status == 201 || responseData.result.status == 200){
        //         this.statusUpdate(4,"Reject");
        //         this.modalSixStep();
        //         this.wFlowGetSLAData("CS",responseData.result.status,postApporveData,2)
        //       }
        //       else{
        //         const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        //         modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
        //         modalRef.componentInstance.message = this.nicMessege;
        //         modalRef.result.then((result: any) => {
        //         });
        //       }
        //     }else{
              
        //         this.ekycStatusAlert();
        //         this.wFlowGetSLAData("CS",responseData.result.status,postApporveData,2)
        //     }
           

        //     },
        //     error: (error) => {
        //       this.isSigning = false;
              
        //       console.log('client side', error);
        //     }
        //   });
      
        // }
  
      // }

  }
    
  }

  

  modalSixStep() {
    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
    modalRef.componentInstance.message = 'The Application has failed in six step validation. If any obligations, please raise the Grievance in Six step Grievance Application.D';
    modalRef.result.then((result: any) => {
      // if (result) {
      //   console.log(result);
      // }
    });
  }
  statusUpdateApprove(){
    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
    modalRef.componentInstance.message = "Application successfully moved to Tahsildar";
    modalRef.result.then((result: any) => {
      // if (result) {
      //   console.log(result);
      // }
    });

  }
  statusUpdateRejectVro(){
    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
    modalRef.componentInstance.message = "Application has been rejected";
    modalRef.result.then((result: any) => {
      // if (result) {
      //   console.log(result);
      // }
    });
  }

  statusUpdateReject(){
    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
    modalRef.componentInstance.message = "Rejected the Application as they failed in ekyc";
    modalRef.result.then((result: any) => {
      // if (result) {
      //   console.log(result);
      // }
    });

  }
  ekycStatusAlert(){
    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
    modalRef.componentInstance.message ="EKYC Not yet completed or Rejected for the Request " + " " + this.receivedRequestionId;
    modalRef.result.then((result: any) => {
      // if (result) {
      //   console.log(result);
      // }
    });

  }


  alertMessageModal(title : string,message: string){
    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.result.then((result: any) => {
      // if (result) {
      //   console.log(result);
      // }
    });

  }

  statusUpdateForCivilSuppliesWorkflow(actioncode:any,statusName : string){   
    // alert(this.k['fpshop'].value); 
    //   let selectedRemarks;
    //   let clusterDatas;
    //   let fpshopDatas;
    //   let socialAuditStatus;
    //   let remarks;
    //   let reasons;
    // clusterDatas = this.k['cluster'].value;
    // fpshopDatas = this.k['fpshop'].value;
    // remarks = this.f2.remarks.value;
    // reasons = this.f2.remarksReasons.value
    
    // socialAuditStatus = this.k['socialAudit'].value ? this.k['socialAudit'].value : '-';
    // console.log("bbb",this.taskId);

    let postData: any ={
      approval_Status_Bean: {
        application_id: this.sixStepPostData1.requestNumber,
        district_code: this.CSDistrictCode,
        message: "Rejected",
        status: "2"
      },
      password:"11169d3abc20724bb857cc5ea59e3c42",
      hts:"12",
      aadharNumber: this.applicantAadhar,
      createdBy: this.userIdData,
      requestNumber: this.sixStepPostData1.requestNumber,
      applicationType : Number(this.appType),
      workflowUpdate : {
        taskId : this.taskId,
        action : actioncode,
        comment : JSON.stringify({ remarks:this.f2.remarks.value,reasons:this.f2.remarksReasons.value,clsuter:this.k['cluster'].value,fpshopData:this.k['fpshop'].value,socialAudit:this.k['socialAudit'].value ? this.k['socialAudit'].value : '-'})
      }
  }
  this.commonService.postRequest(this.commonConstants.postNICReject, postData).subscribe({
    next: (responseData:any) => {  
      if(responseData.result?.status == 200 || responseData.result?.status == 201 || responseData.result?.status == '200' || responseData.result?.status == '201') {
          this.commonMsgPopup('Application rejected successfully.');
        } else {
          let msg: any = responseData.result?.message;
          if(msg !== undefined && msg !== null && msg !== '') {
            this.commonService.commonErrorPopup('Alert' + this.alertDateTimeZone, msg , 'md');
          } else {
            this.commonService.commonErrorPopup('Alert' + this.alertDateTimeZone,'Error in External API please try again', 'md');
          }
        }
    },
    error: (error) => {
      console.log('client side', error);
    }
  });
    //   let postData: any = {};postData.taskId = this.taskId; //f6d78366-5193-4b35-a6cb-75aaf68014f7; 
    //    postData.comment = JSON.stringify({ remarks:remarks,reasons:reasons,clsuter:clusterDatas,fpshopData:fpshopDatas,socialAudit:socialAuditStatus});
    // console.log(postData);
    
     // postData.action = actioncode;  
      //postData.IsWorkFlowStatusAudit=true;  
      //postData.WorkFlowStatusData= civilSuppliesWorkflowStatusUpdate;  
    console.log("testapprove", postData);
      
    // this.commonService.putRequest(this.commonConstants.approveReject,postData).subscribe({next:(resData:any) =>{
  
    //    console.log(resData,'approve and reject');
    //    let msg:string
    // if(typeof(resData.result) !== 'string'){
    //  let responseData = resData.result;
  
    //   // const modalRef=this.modalService.open(CommonMsgModalComponent,{size:'md'});
    //   // modalRef.componentInstance.message = 'Application ' + statusName +'ed successfully.'     
    //  // this.getWorklist();
    //  this.commonMsgPopup('Application ' + statusName +'ed successfully.')
  
    // }else if(resData.result == 'Please provide valid workflow status data'){
    //   const modalRef=this.modalService.open(CommonMsgModalComponent,{size:'md'});
    //   modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
    //   modalRef.componentInstance.message = 'Error in Updating the Workflow. Please Refresh and Submit the request again.';
    // }else{
    //   const modalRef=this.modalService.open(CommonMsgModalComponent,{size:'md'});
    //   modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
    //   modalRef.componentInstance.message = resData.result != undefined || null ? resData.result : 'Error in Updating the Workflow';
    // }
    // },
    //    error:(error)=> { console.log('client side', error);  }});
  }
 
statusUpdate(actioncode:any,statusName : string){   
  // alert(this.k['fpshop'].value); 
    let selectedRemarks;
    let clusterDatas;
    let fpshopDatas;
    let socialAuditStatus;
    let remarks;
    let reasons;
  // selectedRemarks= this.f2.remarks.value === 'Others' ? this.f2.otherRemarks.value : this.f2.remarks.value;  
  clusterDatas = this.k['cluster'].value;
  fpshopDatas = this.k['fpshop'].value;
  remarks = this.f2.remarks.value;
  reasons = this.f2.remarksReasons.value
  
  socialAuditStatus = this.k['socialAudit'].value ? this.k['socialAudit'].value : '-';
  console.log("bbb",this.taskId);
  
 
    let postData: any = {};postData.taskId = this.taskId; //f6d78366-5193-4b35-a6cb-75aaf68014f7; 
     postData.comment = JSON.stringify({ remarks:remarks,reasons:reasons,clsuter:clusterDatas,fpshopData:fpshopDatas,socialAudit:socialAuditStatus});
  // console.log(postData);
  
    postData.action = actioncode;  
  // console.log("testapprove", postData);
    
  this.commonService.putRequest(this.commonConstants.approveReject,postData).subscribe({next:(resData:any) =>{

     console.log(resData,'approve and reject');
     let msg:string
  if(typeof(resData.result !== 'string')){
   let responseData = resData.result;

    const modalRef=this.modalService.open(CommonMsgModalComponent,{size:'md'});
    modalRef.componentInstance.message = 'Application' + statusName +'successfully.'
    this.getWorklist();

  }
  },
     error:(error)=> { console.log('client side',error);}});
}
/*Fishery workflow Changes started on 4-Jan-2024 starts here*/

onChangeFisheryProf() {
  let controls: any;
  if(this.f3.isFishingProfession.value === 'Yes') {
    controls = ['noOfYears', 'typeOfFishermen'];
    controls.forEach((name: any)=> {
      this.f3[name].setValidators([Validators.required]);
      this.f3[name].updateValueAndValidity();
    });
  } else {
    controls = ['noOfYears', 'typeOfFishermen', 'marineFCSMember', 'marineAdmissionNo', 'marineDateOfAdmission', 'validBiometricId', 'mfidNo',
    'banOnFishingProf', 'copyOfBankEntry', 'inlandFCSMember', 'inlandAdmissionNo', 'inlandDateOfAdmission', 'inlandExtent', 'inlandNoOfTanks', 'individualLicenceHolder', 'copyOfLicence'];
    controls.forEach((name: any)=> {
      this.f3[name].reset();
      this.f3[name].clearValidators();
      this.f3[name].updateValueAndValidity();
    });
  }
}
onChangeFishermenType() {
  let controls: any;
  let clearControls: any;
  if(this.f3.typeOfFishermen.value === 'Marine') {
    controls = ['marineFCSMember', 'validBiometricId', 'banOnFishingProf'];
    clearControls = ['inlandFCSMember', 'individualLicenceHolder'];
  } else {
    controls = ['inlandFCSMember', 'individualLicenceHolder'];
    clearControls = ['marineFCSMember', 'validBiometricId', 'banOnFishingProf'];
  }
  controls.forEach((name: any)=> {
    this.f3[name].setValidators([Validators.required]);
    this.f3[name].updateValueAndValidity();
  });
  clearControls.forEach((name: any)=> {
    this.f3[name].reset();
    this.f3[name].clearValidators();
    this.f3[name].updateValueAndValidity();
  });
}
onChangeMarineFCSMember() {
  let controls: any = ['marineAdmissionNo', 'marineDateOfAdmission'];
  if(this.f3.marineFCSMember.value === 'Yes') {
    controls.forEach((name: any) => {
      this.f3[name].setValidators([Validators.required]);
      this.f3[name].updateValueAndValidity();
    });
  } else {
    controls.forEach((name: any)=> {
      this.f3[name].reset()
      this.f3[name].clearValidators();
      this.f3[name].updateValueAndValidity();
    });
  }
  
}
onChangeBiometricId() {
  if(this.f3.validBiometricId.value === 'Yes') {
      this.f3.mfidNo.setValidators([Validators.required]);
  } else {
    this.f3.mfidNo.reset();
    this.f3.mfidNo.clearValidators();
  }
  this.f3.mfidNo.updateValueAndValidity();
}
onChangeBanFishing() {
  if(this.f3.banOnFishingProf.value === 'Yes') {
    this.f3.copyOfBankEntry.setValidators([Validators.required]);
  } else {
    this.f3.copyOfBankEntry.reset();
    this.f3.copyOfBankEntry.clearValidators();
    this.copyOfBankEntryLabel.nativeElement.innerText = 'No File Choosen';
  }
  this.f3.copyOfBankEntry.updateValueAndValidity();
}

controls = ['noOfYears', 'typeOfFishermen', 'marineFCSMember', 'marineAdmissionNo', 'marineDateOfAdmission', 'validBiometricId', 'mfidNo',
'banOnFishingProf', 'copyOfBankEntry', 'inlandFCSMember', 'inlandAdmissionNo', 'inlandDateOfAdmission', 'inlandExtent', 'inlandNoOfTanks', 'individualLicenceHolder', 'copyOfLicence'];
onCopyBankEntrySelected(fileEvent: any) {
  const file: File | null = fileEvent.target.files[0];
    this.copyOfBankEntryLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0]?.name : 'No File Choosen';
      this.f3.copyOfBankEntry.setValidators([
        Validators.required,
        fileExtensionValidator('pdf'),
        fileSizeValidator(fileEvent),
      ]);
    this.f3.copyOfBankEntry.updateValueAndValidity();
    if (this.f3.copyOfBankEntry.valid)
      this.fileUpload(file, this.f3.copyOfBankEntry.value, fileEvent.target.files[0]?.name,'copyOfBankEntry','copyOfBankEntry','copyOfBankEntryLabel');
}
onChangeInlandFCSMember() {
  let controls: any = ['inlandAdmissionNo', 'inlandDateOfAdmission', 'inlandExtent', 'inlandNoOfTanks'];
  if(this.f3.inlandFCSMember.value === 'Yes') {
    this.f3.inlandAdmissionNo.setValidators([Validators.required]);
    this.f3.inlandDateOfAdmission.setValidators([Validators.required]);
    this.f3.inlandExtent.setValidators([Validators.required]);
    this.f3.inlandNoOfTanks.setValidators([Validators.required]);
  } else {
    controls.forEach((name: any) => {
      this.f3[name].reset();
      this.f3[name].clearValidators();
    });
  }
  controls.forEach((name: any) => {
    this.f3[name].updateValueAndValidity();
  });
  
}
onChangeLicenceHolder() {
  if(this.f3.individualLicenceHolder.value === 'Yes') {
    this.f3.copyOfLicence.setValidators([Validators.required]);
  } else {
    this.f3.copyOfLicence.reset();
    this.f3.copyOfLicence.clearValidators();
    this.copyOfLicenceLabel.nativeElement.innerText = 'No File Choosen';
  }
  this.f3.copyOfLicence.updateValueAndValidity();
}
onCopyOfLicenceSelected(fileEvent: any) {
  const file: File | null = fileEvent.target.files[0];
  this.copyOfLicenceLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0]?.name : 'No File Choosen';
    this.f3.copyOfLicence.setValidators([
      Validators.required,
      fileExtensionValidator('pdf'),
      fileSizeValidator(fileEvent),
    ]);
  this.f3.copyOfLicence.updateValueAndValidity();
  if (this.f3.copyOfLicence.valid)
    this.fileUpload(file, this.f3.copyOfLicence.value, fileEvent.target.files[0]?.name,'copyOfLicence','copyOfLicence','copyOfLicenceLabel');
}
onFDODocSelected(fileEvent: any) {
  const file: File | null = fileEvent.target.files[0];
  this.fdoDocumentLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0]?.name : 'No File Choosen';
    this.f2.fdoDocument.setValidators([
      Validators.required,
      fileExtensionValidator('pdf'),
      fileSizeValidator(fileEvent),
    ]);
  this.f2.fdoDocument.updateValueAndValidity();
  if (this.f2.fdoDocument.valid)
    this.fileUpload(file, this.f2.fdoDocument.value, fileEvent.target.files[0]?.name,'fdoDocument','fdoDocument','fdoDocumentLabel');
}
/*Fishery workflow Changes started on 4-Jan-2024 Ends here*/
/*Dappu Artists workflow changes on 9-Jan-2024 starts */
onChangeExistsPension() {
  if(this.d.existPension.value === 'Yes') {
    this.d.pensionId.setValidators([Validators.required])
  } else {
    this.d.pensionId.clearValidators();
  }
  this.d.pensionId.updateValueAndValidity();
}
/*Dappu Artists workflow changes on 9-Jan-2024 ends */

/*traditional cobbler pension workflow changes starts */
onChangeExistsCobblerPension() {
  if(this.f4.receivingCobblerPension.value === 'Yes') {
    this.f4.pensionId.setValidators([Validators.required])
  } else {
    this.f4.pensionId.clearValidators();
  }
  this.f4.pensionId.updateValueAndValidity();
}
/*traditional cobbler pension workflow changes ends */

/* Handloom Weaver Pension workflow changes starts */

onChangeIsLoomPresentInHouse() {
  let weaversCheckListControls: any = ['loomType','loomWorkingCondition'];
  if(this.f5.isLoomPresentInHouse.value === 'Yes') {
    weaversCheckListControls.forEach((control: any) => {
      this.f5[control].setValidators([Validators.required]);
      this.f5[control].updateValueAndValidity();
    });
  } else {
    weaversCheckListControls.forEach((control: any) => {
      this.f5[control].clearValidators();
      this.f5[control].updateValueAndValidity();
    });
  }
}

onChangeLoomWorkingCondition() {
  let weaversCheckListControls: any = ['loomWorkingConditionDocument',
      'noOfYearsIntoWeaving', 'workingUnder', 'benifittedUnderNethannaNestham', 'receivingWeaverPension'];
  if(this.f5.loomWorkingCondition.value === 'Yes') {
    weaversCheckListControls.forEach((control: any) => {
      this.f5[control].setValidators([Validators.required]);
      this.f5[control].updateValueAndValidity();
    });
  } else {
    this.f5.loomWorkingConditionDocument.reset();
    if (this.loomWorkingConditionLabel != undefined) {
      this.loomWorkingConditionLabel.nativeElement.innerText = 'No File Choosen';
    }
    weaversCheckListControls.forEach((control: any) => {
      this.f5[control].clearValidators();
      this.f5[control].updateValueAndValidity();
    });
  }
}

loomWorkingConditionDocSelected(fileEvent: any) {
  const file: File | null = fileEvent.target.files[0];
  const fileExtensionType = fileEvent.target.files[0]?.name.split('.').pop();
  if (fileExtensionType.toLowerCase() === 'jpg') {
    this.validExt = 'jpg';
  } else if (fileExtensionType.toLowerCase() === 'jpeg') {
    this.validExt = 'jpeg';
  }
  this.loomWorkingConditionLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0]?.name : 'No File Choosen';
  this.f5.loomWorkingConditionDocument.setValidators([
    Validators.required,
    fileExtensionValidator(this.validExt),
    fileSizeValidator(fileEvent),
  ]);
  this.f5.loomWorkingConditionDocument.updateValueAndValidity();
  if (this.f5.loomWorkingConditionDocument.valid)
    this.fileUpload(file, this.f5.loomWorkingConditionDocument.value, fileEvent.target.files[0]?.name,'loomWorkingConditionDocument','loomWorkingConditionDocument','loomWorkingConditionLabel');
}

sumotoDocSelected(fileEvent: any) {
  this.updatebuttonflag = false;
  const file: File | null = fileEvent.target.files[0];
  const fileExtensionType = fileEvent.target.files[0]?.name.split('.').pop();
  if (fileExtensionType?.toLowerCase() === 'pdf') {
    this.validExt = 'pdf';
  }
  this.sumotoverificationDocLabel.nativeElement.innerText = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name : 'No File Choosen';
  this.sumotoFormControls.sumotoverificationDoc.setValidators([Validators.required, fileExtensionValidator(this.validExt), fileSizeValidator(fileEvent)]);
  this.sumotoFormControls.sumotoverificationDoc.updateValueAndValidity();
  if(this.sumotoFormControls.sumotoverificationDoc.valid) {
    this.fileUpload(file,this.sumotoFormControls.sumotoverificationDoc.value,fileEvent.target.files[0]?.name,'sumotoverificationDoc','sumotoverificationDoc','sumotoverificationDocLabel');
  }
}

sumotoDocSelectedforRI(fileEvent: any) {
  this.updatebuttonflagforRI = false;
  //this.fileAutoUploaded = false;
  const file: File | null = fileEvent.target.files[0];
  const fileExtensionType = fileEvent.target.files[0]?.name.split('.').pop();
  if (fileExtensionType?.toLowerCase() === 'pdf') {
    this.validExt = 'pdf';
  }
  this.sumotoverificationDocforRILabel.nativeElement.innerText = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name : 'No File Choosen';
  // this.sumotoFormControlsForRI.sumotoverificationDocforRI.setValidators([Validators.required, fileExtensionValidator(this.validExt), fileSizeValidator(fileEvent)]);
  // this.sumotoFormControlsForRI.sumotoverificationDocforRI.updateValueAndValidity();
  if(this.sumotoFormControlsForRI.sumotoverificationDocforRI.valid) {
    this.fileUpload(file,this.sumotoFormControlsForRI.sumotoverificationDocforRI.value,fileEvent.target.files[0]?.name,'sumotoverificationDocforRI','sumotoverificationDocforRI','sumotoverificationDocforRILabel');
  }
}

sumotoDocSelectedforTahsildar(fileEvent: any) {
  this.updatebuttonflagforTahsildar = false;
  const file: File | null = fileEvent.target.files[0];
  const fileExtensionType = fileEvent.target.files[0]?.name.split('.').pop();
  if (fileExtensionType?.toLowerCase() === 'pdf') {
    this.validExt = 'pdf';
  }
  this.sumotoverificationDocforTahsildarLabel.nativeElement.innerText = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name : 'No File Choosen';
  // this.sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.setValidators([Validators.required, fileExtensionValidator(this.validExt), fileSizeValidator(fileEvent)]);
  // this.sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.updateValueAndValidity();
  if(this.sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.valid) {
    this.fileUpload(file,this.sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.value,fileEvent.target.files[0]?.name,'sumotoverificationDocforTahsildar','sumotoverificationDocforTahsildar','sumotoverificationDocforTahsildarLabel');
  }
}

onChangeExistsWeaverPension() {
  if(this.f5.receivingWeaverPension.value === 'Yes') {
    this.f5.pensionId.setValidators([Validators.required])
  } else {
    this.f5.pensionId.clearValidators();
  }
  this.f5.pensionId.updateValueAndValidity();
}

onChangeUndertakingFromPHWC() {
  if(this.f6.undertakingFromPHWC.value === 'Yes') {
    this.f6.undertakingFromPHWCDocument.setValidators([Validators.required]);
  } else {
    this.f6.undertakingFromPHWCDocument.reset();
    this.f6.undertakingFromPHWCDocument.clearValidators();
    if (this.undertakingFromPHWCLabel != undefined) {
      this.undertakingFromPHWCLabel.nativeElement.innerText = 'No File Choosen';
    }
  }
  this.f6.undertakingFromPHWCDocument.updateValueAndValidity();
}

onUndertakingFromPHWCDocSelected(fileEvent: any) {
  const file: File | null = fileEvent.target.files[0];
  this.undertakingFromPHWCLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0]?.name : 'No File Choosen';
  this.f6.undertakingFromPHWCDocument.setValidators([
    Validators.required,
    fileExtensionValidator('pdf'),
    fileSizeValidator(fileEvent),
  ]);
  this.f6.undertakingFromPHWCDocument.updateValueAndValidity();
  if (this.f6.undertakingFromPHWCDocument.valid)
    this.fileUpload(file, this.f6.undertakingFromPHWCDocument.value, fileEvent.target.files[0]?.name,'undertakingFromPHWCDocument','undertakingFromPHWCDocument','undertakingFromPHWCLabel');
}

onChangeProductionAtPHWC() {
  if(this.f6.productionAtPHWC.value === 'Yes') {
    this.f6.productionAtPHWCDocument.setValidators([Validators.required]);
  } else {
    this.f6.productionAtPHWCDocument.reset();
    this.f6.productionAtPHWCDocument.clearValidators();
    if (this.productionAtPHWCLabel != undefined) {
      this.productionAtPHWCLabel.nativeElement.innerText = 'No File Choosen';
    }
  }
  this.f6.productionAtPHWCDocument.updateValueAndValidity();
}

onProductionAtPHWCDocSelected(fileEvent: any) {
  const file: File | null = fileEvent.target.files[0];
  this.productionAtPHWCLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0]?.name : 'No File Choosen';
  this.f6.productionAtPHWCDocument.setValidators([
    Validators.required,
    fileExtensionValidator('pdf'),
    fileSizeValidator(fileEvent),
  ]);
  this.f6.productionAtPHWCDocument.updateValueAndValidity();
  if (this.f6.productionAtPHWCDocument.valid)
    this.fileUpload(file, this.f6.productionAtPHWCDocument.value, fileEvent.target.files[0]?.name,'productionAtPHWCDocument','productionAtPHWCDocument','productionAtPHWCLabel');
}

onWagesProofDocSelected(fileEvent: any) {
  const file: File | null = fileEvent.target.files[0];
  this.wagesProofLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0]?.name : 'No File Choosen';
  this.f6.wagesProofDocument.setValidators([
    Validators.required,
    fileExtensionValidator('pdf'),
    fileSizeValidator(fileEvent),
  ]);
  this.f6.wagesProofDocument.updateValueAndValidity();
  if (this.f6.wagesProofDocument.valid)
    this.fileUpload(file, this.f6.wagesProofDocument.value, fileEvent.target.files[0]?.name,'wagesProofDocument','wagesProofDocument','wagesProofLabel');
}

onUndertakingMasterDocSelected(fileEvent: any) {
  const file: File | null = fileEvent.target.files[0];
  this.undertakingFromMasterLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0]?.name : 'No File Choosen';
  this.f6.undertakingMasterDocument.setValidators([
    Validators.required,
    fileExtensionValidator('pdf'),
    fileSizeValidator(fileEvent),
  ]);
  this.f6.undertakingMasterDocument.updateValueAndValidity();
  if (this.f6.undertakingMasterDocument.valid)
    this.fileUpload(file, this.f6.undertakingMasterDocument.value, fileEvent.target.files[0]?.name,'undertakingMasterDocument','undertakingMasterDocument','undertakingFromMasterLabel');
}

onYarnPurchaseDocSelected(fileEvent: any) {
  const file: File | null = fileEvent.target.files[0];
  this.yarnPurchaseLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0]?.name : 'No File Choosen';
  this.f6.yarnPurchaseDocument.setValidators([
    Validators.required,
    fileExtensionValidator('pdf'),
    fileSizeValidator(fileEvent),
  ]);
  this.f6.yarnPurchaseDocument.updateValueAndValidity();
  if (this.f6.yarnPurchaseDocument.valid)
    this.fileUpload(file, this.f6.yarnPurchaseDocument.value, fileEvent.target.files[0]?.name,'yarnPurchaseDocument','yarnPurchaseDocument','yarnPurchaseLabel');
}

/* Handloom Weaver pension workflow changes ends */

  incomeAlertMessage : string = "";
  statusReason : string ="";
  confirmRef:any
  yAction:any
  yActionCode:any
  yTargetModal:any
  statusUpdatePostData : any={}
  errorCount: any = 0;
  errorCountforgreater: any = 0;
  g: any = 0;
  actionCodeforbuttons: any;

 

  
  approveRejectNew(action: any, actionCode: any,targetModal: any,confirmationOTPModal: any, from?:any): any {
    // if((actionCode === 3 || actionCode === 4) && (this.certificateName === 'HouseholdSplit' || this.certificateName === 'HouseholdSplit@1' || this.certificateName === 'HouseholdSplit@2')) {
    //     this.commonService.commonErrorPopup('Alert', 'This option has been temporarily disabled.', 'md');
    // } else {
  //  if((this.role === "Village Revenue Officer" || this.role === "Revenue Inspector") && this.certificateName === "IntegratedCertificate"){
  //     console.log("Integrated Certificate send back")
  //     let postData: any = {};
  //     postData.taskId = this.taskId;
  //     postData.comment = JSON.stringify({ remarks:'',reasons:''});
  //     postData.action = actionCode;  
  //     this.commonService.putRequest(this.commonConstants.approveReject, postData).subscribe({
  //       next: (resData:any) => {
  //       console.log(resData)
  //       }   
  //     })
  //  }

// if((this.role === "Aarogyasri District Coordinator") && (this.certificateName === "AarogyaSriNewCard")){
//   console.log("AarogyaSriNewCard send back")
//   this.submitted = true;
//   if(!this.consent || !this.remarksForm.valid ) {
//     return false;
// }

//   let Remarks;
//   let Reason;
//   let Doc;
//   Remarks = this.f2.remarks.value === 'Not Recommended' ? this.f2.remarks.value : 'Recommended';
//   Reason = this.f2.remarks.value === 'Not Recommended' ? this.f2.remarksReasons.value : '';
//   //console.log(Remarks,',Remarks.......')
//   //console.log(Reason,'Reason.......')
//   Doc = this.getSelectedDoc();

//   if(Remarks === 'Not Recommended' && Reason === null){
//     const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
//     mdRef.componentInstance.message = 'Valid Reason is required.'
//   }

//   //console.log(Remarks,'Remarks')
//   //console.log(Reason,'Reason')
//   else{
//     if(Remarks === 'Recommended'){ // to approve health-card , generate UHID first
//      this.uhidGenerationForHealthCard();
//     }
//   let postData: any = {};
//   postData.taskId = this.taskId;
//   postData.comment = JSON.stringify({ remarks:Remarks,reasons:Reason,docName: Doc});
//   postData.action = actionCode;  
//   if((Remarks === 'Not Recommended') ||(Remarks === 'Recommended' && this.UHID && this.isSavedToCosmos === true)){
//     this.commonService.putRequest(this.commonConstants.approveReject, postData).subscribe({
//   next: (resData:any) => {
//   console.log(resData,"resData")
//   let responseData = resData.result;
//   console.log(responseData.updatedTask.nextTaskId,"nextId")
//       //const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
//       if(responseData.updatedTask.nextTaskId)
//       {
//         // const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
//         // mdRef.componentInstance.message = 'Application sent back to previous level.'
//         // this.getWorklist();
//         this.commonMsgPopup('Application sent back to previous level.')
//       }
//        else if(responseData.updatedTask.actionTaken === 3)
//       {
//         // const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
//         // mdRef.componentInstance.message = 'Application is Approved.'
//         // this.getWorklist();
//         this.commonMsgPopup('Application is Approved.')
//       }

//       else if(responseData.updatedTask.actionTaken === 4)
//       {
//         // const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
//         // mdRef.componentInstance.message = 'Application is Rejected.'
//         // this.getWorklist();
//         this.commonMsgPopup('Application is Rejected.')
//       }
     
//   }   
// })}
// }
// } else{



if(this.certificateName == "AssignmentofLandstoEx-ServicepersonServingSoldiers" && (this.role == 'Ward Revenue Secretary' || this.role == 'Village Revenue Officer')) {
  if(this.zillasainikwelfareform.get(`${this.indexofzswoprposedextent}Value`.toString())?.status == 'INVALID') {
    this.commonService.commonErrorPopup("Alert","please enter the proposed extent in the numbers value ","md");
    return;
  }
  else {
  this.zillasainikwelfareform.get((this.lpsyobj.length).toString())?.clearValidators();
  this.zillasainikwelfareform.get((this.lpsyobj.length).toString())?.updateValueAndValidity();
  this.zillasainikwelfareform.get((`${this.lpsyobj}Value`).toString())?.clearValidators();
  this.zillasainikwelfareform.get((`${this.lpsyobj}Value`).toString())?.updateValueAndValidity();
  }
  }
  
if(!this.isRectification) {
  if(this.certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && this.workflowstepcode != 'TEH-Verify') {
    this.f2.remarks.setValidators([Validators.required]);
    this.f2.remarks.updateValueAndValidity();
  }
  else {
    this.f2.remarks.clearValidators();
    this.f2.remarks.updateValueAndValidity();
  }

  if(this.certificateName != 'AssignmentofLandstoEx-ServicepersonServingSoldiers') {
    this.f2.remarks.setValidators([Validators.required]);
    this.f2.remarks.updateValueAndValidity();
  }
  else {
    this.f2.remarks.clearValidators();
    this.f2.remarks.updateValueAndValidity();
  }

}

if((this.certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers') && (this.role == 'Village Revenue Officer' || this.role == 'Ward Revenue Secretary')) {
  if((this.landavailableforgrantvalue == 'Yes' || this.vacantlandvalue == 'Yes') && (this.lpnumberdatafromtable.length == 0) && (this.vacantlandvalue != 'No')) {
    if (this.errorCount == 0 || this.errorCountforgreater == 0 || this.lpnumberdatafromtable.length == 0) {
      this.commonService.commonErrorPopup("Alert", "Please select atleast one Lp number or survey number from the above field or table", "md");
      return;
    }
  }
}



if(this.certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && (this.role == 'Village Revenue Officer' || this.role == 'Ward Revenue Secretary') && (this.lpnumberdatafromtable.length > 0) && (this.landavailableforgrantvalue == 'Yes' || this.vacantlandvalue == 'Yes')) {
  this.errorCount = 0;
  if(this.lpnumberdatafromtable) {
    this.lpnumberdatafromtable.forEach((item: any) => {
        if(item.proposedextentvalue == 0 || item.proposedextentvalue > 0 ) {
        }
       if(item.proposedextentvalue == "" || item.proposedextentvalue == '' || item.proposedextentvalue == undefined || item.proposedextentvalue == null){
          this.errorCount++;
      }
    })
  }
  
  if (this.errorCount > 0) {
    this.commonService.commonErrorPopup("Alert", "Please enter the proposed extent value of selected row", "md");
    return;
  }
  this.errorCountforgreater = 0;
  this.errorCount = 0;
}

if(this.certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && (this.role == 'Village Revenue Officer' || this.role == 'Ward Revenue Secretary') && (this.lpnumberdatafromtable.length > 0)) {
  this.errorCountforgreater = 0;
  if(this.lpnumberdatafromtable) {
    this.lpnumberdatafromtable.forEach((item: any) => {
      if(this.isResurveyVillage) {
          if(item.proposedextentvalue) {
            if(JSON.parse(item.proposedextentvalue) > item.LPM_Extent) {
              this.errorCountforgreater++;
          }
        }
      }
      if(this.isResurveyVillage == false) {
        if(item.proposedextentvalue) {
          if(JSON.parse(item.proposedextentvalue) > item.Total_Extent) {
            this.errorCountforgreater++;
          }
        }
      }
    })
  }

  if(this.errorCountforgreater > 0) {
    this.commonService.commonErrorPopup('Alert', "Proposed extent must be less than Occupant extent", "md");
    return;
  }
  this.errorCountforgreater = 0;
  this.errorCount = 0;
}

this.indexofzswoprposedextent = 0;
this.zillasainikwelfareform.get(`${this.indexofzswoprposedextent}Value`.toString())?.patchValue(null);




  if(this.certificateName === 'IntegratedCertificate-SuoMoto' && (this.role == 'Village Revenue Officer' || this.role == 'Ward Revenue Secretary')) {
    this.submitted = true;
    this.f2.verificationDoc.setValidators([Validators.required]);
    this.f2.verificationDoc.updateValueAndValidity();
    this.f2.supportingDocuments.setValidators([Validators.required]);
    this.f2.supportingDocuments.updateValueAndValidity();
  } 
  else if((this.certificateName == 'IntegratedCertificate-SuoMoto' && (this.role == 'Revenue Inspector'))) {
    this.f2.verificationDoc.setValidators([Validators.required]);
    this.f2.verificationDoc.updateValueAndValidity();
  }
  else if((this.certificateName === 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && ((this.role == 'Tahsildar' && this.workflowstepcode != 'TEH-Verify') || this.role == 'Revenue Divisonal Officer' || this.role == 'Sub Collector' || this.role == 'Ward Revenue Secretary' || this.role == 'Village Revenue Officer' || this.role == 'Joint Collector'))) {
    this.f2.verificationDoc.setValidators(Validators.required);
    this.f2.verificationDoc.updateValueAndValidity();
    this.submitted = true;
  }

  // else {
  //   this.f2.verificationDoc.clearValidators();
  //   this.f2.supportingDocuments.clearValidators();
  // }
  this.f2.verificationDoc.updateValueAndValidity();
  this.f2.supportingDocuments.updateValueAndValidity();

    if(this.role === 'Tahsildar' && this.certificateName === 'FamilyMemberCertificate' && from !== 'other') {
      this.isActionBtn = true;
      let data: any = {};
      data.action = action;
      data.actionCode = actionCode;
      data.targetModal = targetModal;
      data.confirmationOTPModal = confirmationOTPModal;
      data.isActionBtn = this.isActionBtn;
      this.commonService.checkAction.next(data);
      return false;
    }
    // else if(this.role === 'Revenue Inspector' && (this.certificateName === 'IntegratedCertificateForStudents-RDO' || this.certificateName === 'IncomeCertificateForStudents' || this.certificateName === 'IntegratedCertificateForStudents')){
    //   if(this.fileList === null){
    //     this.commonService.commonErrorPopup('Alert ' + this.alertDateTimeZone, 'There are no documents for this application, Unable to proceed', 'md');
    //     return false;
    //    }
    // }
    let list: any = [];
    let selectedRemarks;
    let selectedReason;
    let selectedDoc;
    // console.log('this.checklistForm.valid');
    // this.o.ddAadharNo.patchValue(this.aadharUID)
    
    
    if(this.aadharUID != null || this.aadharUID != undefined){
      this.maskAadhar(this.aadharUID)
    }
    
    this.isSigning = false;
    this.isAadharValidated = false;
    this.buttonStatus = action;
    this.emudhraformGroup.reset();
   // console.log('this.checklistForm.valid', this.checklistForm.valid);
    // console.log('this.remarksForm.valid', this.remarksForm.valid);
    // console.log('this.remarksForm.value', this.remarksForm.value);
    // console.log('form', this.form.valid);
    this.modalFormActionCode = actionCode
    this.yAction = ""
    this.yActionCode ="" 
    this.yTargetModal = ""
    this.submitted = true;
    // console.log(actionCode,'actionCode')
    this.yAction = action
    this.yActionCode =actionCode 
    this.yTargetModal = targetModal
    // console.log('this.tknSerialNo',this.tknSerialNo,this.tknSerialNo.length)
    // console.log('this.verifytknSerialNo',this.verifytknSerialNo,this.verifytknSerialNo.length)
    if((this.certificateName === 'Pensions@7' || this.certificateName === 'Pensions@V7') && this.role === 'Fisheries Development Officer' && this.f2.remarks.value === 'Recommended') {
     this.f2.fdoDocument.setValidators([Validators.required]);
    } else {
      this.f2.fdoDocument.clearValidators();
    }
    this.f2.fdoDocument.updateValueAndValidity();



    if((this.certificateName === 'RectificationOfResurveyedRecords') && (this.role  === 'Village surveyor'|| this.role === 'Mandal surveyor' || this.role === 'Tahsildar' )) {
     // this.xyz.Grievance.setValidators([Validators.required]);
     
      this.f2.verificationDoc.setValidators([Validators.required,fileExtensionValidator('pdf')]);
        if(this.isVerifiedDocument){
          this.f2.verificationDoc.setValidators([
            fileSizeValidator(this.isVerifiedDocument)]);
        }
      this.f2.verificationDoc.updateValueAndValidity();
      this.xyz.remarksByVS.setValidators([Validators.required]);
     } else {
      //this.xyz.Grievance.clearValidators();
      this.xyz.remarksByVS.clearValidators();
     }
    // this.xyz.Grievance.updateValueAndValidity();
     this.xyz.remarksByVS.updateValueAndValidity();
     

     if((this.certificateName === 'AssignmentofLandstoEx-ServicepersonServingSoldiers') && (this.role === 'Village Revenue Officer' || this.role === 'Ward Revenue Secretary')) {
      if(this.landavailableforgrantvalue == 'Yes') {
        this.z1.lpmnumbers.setValidators([Validators.required]);
        this.z1.lpmnumbers.updateValueAndValidity();
        this.z1.proposedextent.setValidators([Validators.required, Validators.pattern(this.commonConstants.decimalPatternforland)]);
        this.z1.proposedextent.updateValueAndValidity();
        if(this.eventcheckboxchecked != true) {
          this.z1.proposedextent.disable();
          this.z1.proposedextent.updateValueAndValidity();
        }
      }
      else {
        this.z1.lpmnumbers.clearValidators();
        this.z1.lpmnumbers.updateValueAndValidity();
        this.z1.proposedextent.clearValidators();
        this.z1.proposedextent.updateValueAndValidity();
      }
        this.z1.proposedextent.updateValueAndValidity();
        this.z1.lpmnumbers.updateValueAndValidity();
     }
     else {
      this.z1.landavailableforgrant.clearValidators();
      this.z1.landavailableforgrant.updateValueAndValidity();
      this.z1.lpmnumbers.clearValidators();
      this.z1.lpmnumbers.updateValueAndValidity();
     }
     this.z1.landavailableforgrant.updateValueAndValidity();
     this.z1.lpmnumbers.updateValueAndValidity();

     if(this.certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && this.role == 'Joint Collector') {
        this.z2.allotmentoflandjc.setValidators([Validators.required]);
        this.z2.recommendationofrdoandjc.setValidators([Validators.required]);
        this.z2.allotmentoflandjc.updateValueAndValidity();
        this.z2.recommendationofrdoandjc.updateValueAndValidity();
        if(this.f2.remarks.value == 'Recommended') {
          this.z3.assignmentapproveddistJC.setValidators([Validators.required]);
          this.z3.grantofassignmentlandjc.setValidators([Validators.required]);
          this.z3.ror1bJC.setValidators([Validators.required]);
          this.z3.assignmentapproveddistJC.updateValueAndValidity();
          this.z3.grantofassignmentlandjc.updateValueAndValidity();
          this.z3.ror1bJC.updateValueAndValidity();
        }
        else {
          this.z3.assignmentapproveddistJC.clearValidators();
          this.z3.grantofassignmentlandjc.clearValidators();
          this.z3.ror1bJC.clearValidators();
          this.z3.assignmentapproveddistJC.updateValueAndValidity();
          this.z3.grantofassignmentlandjc.updateValueAndValidity();
          this.z3.ror1bJC.updateValueAndValidity();
        }
     }

     if(this.certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && (this.role == 'Joint Collector')) {
      if(this.zillasainikwelfareformforJC.invalid) {
        this.submitted = true
        return;
      }
      if(this.zillasainikwelfareformforJC2.invalid) {
        this.submitted = true
        return;
      }
     }


     if((this.certificateName === 'AssignmentofLandstoEx-ServicepersonServingSoldiers') && (this.role === 'Village Revenue Officer' || this.role === 'Ward Revenue Secretary')) {
      if(this.vacantlandvalue == 'No') {
        this.z1.remarksByVroZswo.setValidators([Validators.required]);
        this.z1.remarksByVroZswo.updateValueAndValidity();
        this.errorCount = 0
        this.errorCountforgreater = 0;
      }
      else {
        this.z1.remarksByVroZswo.clearValidators();
        this.z1.remarksByVroZswo.updateValueAndValidity();
      }
      this.z1.remarksByVroZswo.updateValueAndValidity();
     }

     if((this.certificateName === 'AssignmentofLandstoEx-ServicepersonServingSoldiers') && (this.role === 'Village Revenue Officer' || this.role === 'Ward Revenue Secretary')) {
      if(this.landavailableforgrantvalue == 'No') {
        this.z1.vacantland.setValidators([Validators.required]);
        this.z1.vacantland.updateValueAndValidity();
      }
      else {
        this.z1.vacantland.clearValidators();
        this.z1.vacantland.updateValueAndValidity();
      } 
      this.z1.vacantland.updateValueAndValidity();
      this.z1.landavailableforgrant.setValidators([Validators.required]);
      this.z1.landavailableforgrant.updateValueAndValidity();
      this.f2.verificationDoc.setValidators([Validators.required]);
      this.f2.verificationDoc.updateValueAndValidity();
     }
     else if((this.certificateName === 'AssignmentofLandstoEx-ServicepersonServingSoldiers') && (this.role === 'Village Revenue Officer' || this.role === 'Ward Revenue Secretary')) {
      this.f2.verificationDoc.clearValidators();
      this.f2.verificationDoc.updateValueAndValidity();
     }
     this.f2.verificationDoc.updateValueAndValidity();

     if(this.isFpShopApplication && (this.role === 'Village Revenue Officer'  || this.role === 'Ward Revenue Secretary')){
      this.fp.latofFpShop.setValidators([Validators.required,Validators.pattern(this.commonConstants.latLongPattern)]);
      this.fp.longofFpShop.setValidators([Validators.required,Validators.pattern(this.commonConstants.latLongPattern)]);
      this.fp.fpShopPhoto.setValidators([Validators.required]);
      this.fp.isSameLocation.setValidators([Validators.required]);
     }else {
      this.fp.latofFpShop.clearValidators();
      this.fp.longofFpShop.clearValidators();
      this.fp.fpShopPhoto.clearValidators();
      this.fp.isSameLocation.clearValidators();
     }
     this.fp.latofFpShop.updateValueAndValidity();
     this.fp.longofFpShop.updateValueAndValidity();
     this.fp.fpShopPhoto.updateValueAndValidity();
     this.fp.isSameLocation.updateValueAndValidity();    

    if((this.certificateName === 'Pensions@7' || this.certificateName === 'Pensions@V7') && (this.role === 'Welfare & Educational Assistant'|| this.role === 'Ward Welfare & Development Secretary' || this.role === 'Fisheries Development Officer' || this.role === 'FisheryDC') && (this.f3.noOfYears.value == 0 ||  this.f3.noOfYears.value > 99)) {
      return false;
    } else if((this.certificateName === 'Pensions@9' || this.certificateName === 'Pensions@V9' || this.certificateName === 'Pensions-NEW@9' || this.certificateName === 'Pensions-NEW@V9') && (this.role === 'Welfare & Educational Assistant'|| this.role === 'Ward Welfare & Development Secretary' || this.role === 'Assistant Social Welfare Officer' || this.role === 'SCWELDC') && (this.f4.noOfYearsIntoCobbling.value == 0 ||  this.f4.noOfYearsIntoCobbling.value > 99)) {
      return false;
    } else if((this.certificateName === 'Pensions@10' || this.certificateName === 'Pensions@V10' || this.certificateName === 'Pensions-NEW@10' || this.certificateName === 'Pensions-NEW@V10') && (this.role === 'Welfare & Educational Assistant'|| this.role === 'Ward Welfare & Development Secretary' || this.role === 'Assistant Social Welfare Officer' || this.role === 'SCWELDC') && (this.f3.noOfYears.value == 0 ||  this.f3.noOfYears.value > 99)) {
      return false;
    } else if((this.certificateName === 'Pensions@2' || this.certificateName === 'Pensions@V2' || this.certificateName === 'Pensions-NEW@2' || this.certificateName === 'Pensions-NEW@V2') && (this.role === 'Welfare & Educational Assistant'|| this.role === 'Ward Welfare & Development Secretary') && (!this.handloomWeaverForm.valid || (this.f5.noOfYearsIntoWeaving.value == 0 ||  this.f5.noOfYearsIntoWeaving.value > 99))) {
      return false;
    }
   
    if(this.certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && this.role == 'Tahsildar' && this.workflowstepcode == 'TEH-Verify') {
      if(!this.consent) {
      }
      else {
           return false;
      }
    }
    else {
      if(!this.consent) {
        return false
      }
      else {
      }
    }

    if(this.certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && this.role == 'Tahsildar' && this.workflowstepcode == 'TEH-Verify') {
      
    }
    else {
    if((this.checklist?.length > 0 && !this.checklistForm.valid) || !this.remarksForm.valid) {
      // console.log('if');
      return false;
    } else if((this.certificateName === 'Pensions@2' || this.certificateName === 'Pensions@V2' || this.certificateName === 'Pensions-NEW@2' || this.certificateName === 'Pensions-NEW@V2') && ((this.role === 'HandloomDO' || this.role === 'HandloomDC') && !this.weaverADOHDTOForm.valid )) {
      return false;
    } else if((this.certificateName === 'Pensions@7' || this.certificateName === 'Pensions@V7') && (!this.fisheriesForm.valid || (this.f2.remarks.value === 'Recommended' && this.role === 'Fisheries Development Officer' && !this.fdoConsent))) {
      return false;
    } else if((this.certificateName === 'Pensions@9' || this.certificateName === 'Pensions@V9' || this.certificateName === 'Pensions-NEW@9' || this.certificateName === 'Pensions-NEW@V9') && (!this.traditionalCobblerform.valid )) {
      return false;
    } else if((this.certificateName === 'Pensions@10' || this.certificateName === 'Pensions@V10' || this.certificateName === 'Pensions-NEW@10' || this.certificateName === 'Pensions-NEW@V10') && (!this.dappuArtistsForm.valid )) {
      return false;
    } else if(this.role  === 'Revenue Divisonal Officer' && this.certificateName === 'BirthDeathCertificate' && !this.form.valid) {
      // console.log('rdo role');
      return false;
    } else if(this.role  === 'Tahsildar' && this.certificateName === 'FamilyMemberCertificate' && !this.isFormValid) {
      // console.log('VRO FMB role', this.isFormValid);
      return false;
    } 
    else if((this.role  === 'Village surveyor'|| this.role === 'Mandal surveyor' || this.role === 'Tahsildar')&& this.certificateName === 'RectificationOfResurveyedRecords' && !this.rectificationForm.valid) {
      return false;
    }else if(this.isFpShopApplication && (this.role === 'Village Revenue Officer'  || this.role === 'Ward Revenue Secretary') && !this.fpShopForm.valid){
      return false;
    } else {
      // console.log('else');
      if (this.checklist && this.checklist?.length > 0) {
        for(const [key, value] of Object.entries(this.checklistForm.value)) {
          let temp: any = {};
          temp.id = key;
          temp.answer = value;
          temp.questionText = this.checklist.find((item: { id: string; }) => item.id === key).questionText;
          list.push(temp);
        }
      }
      selectedRemarks = this.f2.remarks.value === 'Others' ? this.f2.otherRemarks.value : this.f2.remarks.value;
      selectedReason = this.f2.remarks.value === 'Not Recommended' ? this.f2.remarksReasons.value : '';

      selectedDoc = this.getSelectedDoc();
  }
}
  this.statusUpdatePostData = {};
  this.statusUpdatePostData.taskId = this.taskId; //f6d78366-5193-4b35-a6cb-75aaf68014f7;
  // pensions Id storing in to DB
  if(this.departmentCode === "RD"){
    if((this.role ==='FisheryDC' || this.role ==='Fisheries Development Officer' || this.role === 'Welfare & Educational Assistant' || this.role === 'Ward Welfare & Development Secretary') && (this.certificateName === 'Pensions@7' || this.certificateName === 'Pensions@V7')) {
      let fisheriesData: any = {};
     console.log('this.fisheriesForm', this.fisheriesForm.value)
      fisheriesData.isFishingProfession = this.f3.isFishingProfession.value;
      fisheriesData.noOfYears = this.f3.noOfYears.value;
      fisheriesData.typeOfFishermen = this.f3.typeOfFishermen.value;
      fisheriesData.marineFCSMember = this.f3.marineFCSMember.value;
      fisheriesData.marineAdmissionNo = this.f3.marineAdmissionNo.value;
      fisheriesData.marineDateOfAdmission = this.f3.marineFCSMember.value === 'Yes' ? this.datePipe.transform(this.commonService.formatDate(this.f3.marineDateOfAdmission.value), 'dd/MM/yyyy') : null;
      fisheriesData.validBiometricId = this.f3.validBiometricId.value;
      fisheriesData.mfidNo = this.f3.mfidNo.value;
      fisheriesData.banOnFishingProf = this.f3.banOnFishingProf.value;
      fisheriesData.copyOfBankEntry = this.f3.banOnFishingProf.value === 'Yes' ? this.copyOfBankEntryBlob : null
      fisheriesData.inlandFCSMember = this.f3.inlandFCSMember.value;
      fisheriesData.inlandAdmissionNo = this.f3.inlandAdmissionNo.value;
      fisheriesData.inlandDateOfAdmission = this.f3.inlandFCSMember.value === 'Yes' ? this.datePipe.transform(this.commonService.formatDate(this.f3.inlandDateOfAdmission.value), 'dd/MM/yyyy') : null;
      fisheriesData.inlandExtent = this.f3.inlandExtent.value;
      fisheriesData.inlandNoOfTanks = this.f3.inlandNoOfTanks.value;
      fisheriesData.individualLicenceHolder = this.f3.individualLicenceHolder.value;
      fisheriesData.copyOfLicence = this.f3.individualLicenceHolder.value === 'Yes' ? this.copyOfLicenceBlob: null;
      fisheriesData.fdoDocument = (this.f2.remarks.value === 'Recommended' && this.role ==='Fisheries Development Officer') ? this.fdoDocumentBlob: null;
      this.statusUpdatePostData.comment =  JSON.stringify({list: list, remarks: selectedRemarks, reason: selectedReason, docName: selectedDoc, fileList: this.docList ,pensionId:this.pensionId, fisheriesData: fisheriesData});
    }else if((this.role ==='SCWELDC' || this.role ==='Assistant Social Welfare Officer' || this.role === 'Welfare & Educational Assistant' || this.role === 'Ward Welfare & Development Secretary') && (this.certificateName === 'Pensions@10' || this.certificateName === 'Pensions@V10' ||
    this.certificateName === 'Pensions-NEW@10' || this.certificateName === 'Pensions-NEW@V10')) {
      let dappuArtistsData: any = this.dappuArtistsForm.value;
      dappuArtistsData.lastProgDate = this.datePipe.transform(this.commonService.formatDate(dappuArtistsData.lastProgDate), 'dd/MM/yyyy');
      this.statusUpdatePostData.comment =  JSON.stringify({list: list, remarks: selectedRemarks, reason: selectedReason, docName: selectedDoc, fileList: this.docList ,pensionId:this.pensionId, dappuArtistsData: dappuArtistsData});
    } else if((this.role ==='SCWELDC' || this.role ==='Assistant Social Welfare Officer' || this.role === 'Welfare & Educational Assistant' || this.role === 'Ward Welfare & Development Secretary') && (this.certificateName === 'Pensions@9' || this.certificateName === 'Pensions@V9' || this.certificateName === 'Pensions-NEW@9' || this.certificateName === 'Pensions-NEW@V9')) {
      let traditionalCobblerData: any = this.traditionalCobblerform.value;
      this.statusUpdatePostData.comment =  JSON.stringify({list: list, remarks: selectedRemarks, reason: selectedReason, docName: selectedDoc, fileList: this.docList ,pensionId:this.pensionId, traditionalCobblerData: traditionalCobblerData});
    } else if (this.certificateName === 'Pensions@2' || this.certificateName === 'Pensions@V2' || this.certificateName === 'Pensions-NEW@2' || this.certificateName === 'Pensions-NEW@V2') {
      if (this.role === 'Welfare & Educational Assistant' || this.role === 'Ward Welfare & Development Secretary') {
        if ((this.f5['isLoomPresentInHouse']?.value === 'No' || this.f5['loomWorkingCondition']?.value === 'No') && this.f2.remarks.value === 'Recommended') {
          this.commonService.commonErrorPopup('Alert', this.f5['isLoomPresentInHouse']?.value === 'No' ? 'Loom present in house is No. Please select Not Recommended from Remarks options' : 'Loom working condition is No. Please select Not Recommended from Remarks options', 'md');
          return false;
        } else {
          let handloomWeaverData: any = this.handloomWeaverForm.value;
          handloomWeaverData.loomWorkingConditionDocument = this.f5.loomWorkingCondition.value === 'Yes' ? this.loomWorkingConditionBlob : null;
          this.statusUpdatePostData.comment = JSON.stringify({list: list, remarks: selectedRemarks, reason: selectedReason, docName: selectedDoc, fileList: this.docList ,pensionId:this.pensionId, handloomWeaverData: handloomWeaverData });
        }
      } else if (this.role === 'HandloomDC' || this.role === 'HandloomDO') {
        if ((this.WEAWWDSHistory[0]?.handloomWeaverData?.isLoomPresentInHouse === 'No' || this.WEAWWDSHistory[0]?.handloomWeaverData?.loomWorkingCondition === 'No') && this.f2.remarks.value === 'Recommended') {
          this.commonService.commonErrorPopup('Alert', this.WEAWWDSHistory[0]?.handloomWeaverData?.isLoomPresentInHouse === 'No' ? 'Loom present in house is No. Please select Not Recommended from Remarks options' : 'Loom working condition is No. Please select Not Recommended from Remarks options', 'md');
          return false;
        } else {
          let weaverADODHTOData: any = this.weaverADOHDTOForm.value;
          weaverADODHTOData.admissionDate = this.WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder === 'HWCS' && (this.f6.admissionDate.value != null || this.f6.admissionDate.value != undefined) ? this.datePipe.transform(this.commonService.formatDate(this.f6.admissionDate.value), 'dd/MM/yyyy') : null;
          weaverADODHTOData.workingDate = this.WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder === 'Master Weaver' && (this.f6.workingDate.value != null || this.f6.workingDate.value != undefined) ? this.datePipe.transform(this.commonService.formatDate(this.f6.workingDate.value), 'dd/MM/yyyy') : null;
          weaverADODHTOData.erectionLoomDate = this.WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder === 'Individual' && (this.f6.erectionLoomDate.value != null || this.f6.erectionLoomDate.value != undefined) ? this.datePipe.transform(this.commonService.formatDate(this.f6.erectionLoomDate.value), 'dd/MM/yyyy') : null;
          weaverADODHTOData.undertakingFromPHWCDocument = this.WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder === 'HWCS' && this.undertakingFromPHWCBlob != undefined ? this.undertakingFromPHWCBlob : null;
          weaverADODHTOData.productionAtPHWCDocument = this.WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder === 'HWCS' && this.productionAtPHWCBlob != undefined ? this.productionAtPHWCBlob : null;
          weaverADODHTOData.wagesProofDocument = this.WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder === 'Master Weaver' && this.wagesProofBlob != undefined ? this.wagesProofBlob : null;
          weaverADODHTOData.undertakingMasterDocument = this.WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder === 'Master Weaver'  && this.undertakingFromMasterBlob != undefined ? this.undertakingFromMasterBlob : null;
          weaverADODHTOData.yarnPurchaseDocument = this.WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder === 'Individual' && this.yarnPurchaseBlob != undefined ? this.yarnPurchaseBlob : null;
          this.statusUpdatePostData.comment = JSON.stringify({list: list, remarks: selectedRemarks, reason: selectedReason, docName: selectedDoc, fileList: this.docList ,pensionId:this.pensionId, weaverADODHTOData: weaverADODHTOData });
        }
      } else {
        if ((this.WEAWWDSHistory[0]?.handloomWeaverData?.isLoomPresentInHouse === 'No' || this.WEAWWDSHistory[0]?.handloomWeaverData?.loomWorkingCondition === 'No') && this.f2.remarks.value === 'Recommended') {
          this.commonService.commonErrorPopup('Alert', this.WEAWWDSHistory[0]?.handloomWeaverData?.isLoomPresentInHouse === 'No' ? 'Loom present in house is No. Please select Not Recommended from Remarks options' : 'Loom working condition is No. Please select Not Recommended from Remarks options', 'md');
          return false;
        } else {
          this.statusUpdatePostData.comment =  JSON.stringify({list: list, remarks: selectedRemarks, reason: selectedReason, docName: selectedDoc, fileList: this.docList ,pensionId:this.pensionId});
        }
      }
    } else {
      this.statusUpdatePostData.comment =  JSON.stringify({list: list, remarks: selectedRemarks, reason: selectedReason, docName: selectedDoc, fileList: this.docList ,pensionId:this.pensionId});
    }
    
  }
  else{
    //RECTIFICATION CONDITION
  if(this.certificateName ==='RectificationOfResurveyedRecords' && (this.role ==='Village surveyor' || this.role ==='Mandal surveyor'|| this.role ==='Tahsildhar')){
    let rectificationData: any = {};
    //rectificationData.ObservedGrievanceType = this.xyz.Grievance.value;
    rectificationData.RemarksbyVillageSurveyor = this.xyz.remarksByVS.value;
    this.statusUpdatePostData.comment =  JSON.stringify({list: list, remarks: selectedRemarks, reason: selectedReason, docName: selectedDoc, fileList: this.docList, rectificationData: rectificationData});
  }

  //RECTIFICATION END

  // sumoto Start for VRO

  else if((this.role === 'Village Revenue Officer' || this.role === 'Ward Revenue Secretary') && this.certificateName === 'IntegratedCertificate-SuoMoto') {
    let sumotoData: any = {};
    sumotoData.caste = this.sumotoFormControls.caste.value;
    sumotoData.subCaste = this.sumotoFormControls.subCaste.value;
    sumotoData.sumotoverificationDoc = this.sumotoverificationDocBlob;
    sumotoData.religion = this.sumotoFormControls.religion.value;
    sumotoData.maritalStatus = this.sumotoFormControls.maritalStatus.value;
    sumotoData.qualification = this.sumotoFormControls.qualification.value;
    sumotoData.email = this.sumotoFormControls.email.value;
    sumotoData.fathername = this.sumotoFormControls.fathername.value;
    sumotoData.mobile = this.sumotoFormControls.mobile.value;
    sumotoData.revenuevillage = this.sumotoFormControls.revenuevillage.value;
    sumotoData.birthDate = (this.sumotoFormControls.dob.value) ? ( new Date(this.commonService.formatDate(this.sumotoFormControls.dob.value))) : '1900-01-01',
    sumotoData.remarksdistrictvro = this.f2.districtforsuomotovro.value ? this.f2.districtforsuomotovro.value.districtName : null,
    sumotoData.remarksmandalvro = this.f2.mandalforsuomotovro.value ? this.f2.mandalforsuomotovro.value.mandalName : null,
    sumotoData.remarkssecratariatvro = this.f2.secretariatforsuomotovro.value ? this.f2.secretariatforsuomotovro.value.secretariatName : null,
    this.statusUpdatePostData.comment = JSON.stringify({list: list, remarks: selectedRemarks, reason: selectedReason, docName: selectedDoc, fileList: this.docList, sumotoData: sumotoData});
  }
  // sumoto end for VRO

  else if((this.role == 'Revenue Inspector' && this.certificateName === 'IntegratedCertificate-SuoMoto')) {
    let sumotoDataforri: any = {};
    sumotoDataforri.casteforri = this.sumotoFormControlsForRI.casteforsumotoRI.value,
    sumotoDataforri.subCasteforri = this.sumotoFormControlsForRI.subCasteforsumotoRI.value,
    sumotoDataforri.sumotoverificationDocforRI = this.sumotoverificationDocforRIBlob,
    sumotoDataforri.religion = this.sumotoFormControlsForRI.religionforsumotoRI.value,
    sumotoDataforri.matitalstatus = this.sumotoFormControlsForRI.maritalStatusforsumotoRI.value,
    sumotoDataforri.qualification = this.sumotoFormControlsForRI.qualificationforsumotoRI.value,
    sumotoDataforri.email = this.sumotoFormControlsForRI.emailforsumotoRI.value,
    sumotoDataforri.fathername = this.sumotoFormControlsForRI.fathernameforsumotoRI.value,
    sumotoDataforri.mobile = this.sumotoFormControlsForRI.mobileforsumotoRI.value,
    sumotoDataforri.revenuevillage = this.sumotoFormControlsForRI.revenuevillageforsumotoRI.value,
    sumotoDataforri.birthdate = (this.sumotoFormControlsForRI.dobforsumotoRI.value) ? (this.commonService.formatDate(this.sumotoFormControlsForRI.dobforsumotoRI.value)) : '1900-01-01',
    sumotoDataforri.remarksdistrictri = this.f2.districtforsuomotori.value ? this.f2.districtforsuomotori.value.districtName : null,
    sumotoDataforri.remarksmandalri = this.f2.mandalforsuomotori.value ? this.f2.mandalforsuomotori.value.mandalName : null,
    sumotoDataforri.remarkssecratariatri = this.f2.secretariatforsuomotori.value ? this.f2.secretariatforsuomotori.value.secretariatName : null,
    this.statusUpdatePostData.comment = JSON.stringify({list: list, remarks: selectedRemarks, reason: selectedReason, docName: selectedDoc, fileList: this.docList, sumotoDataforri: sumotoDataforri});
  }

  else if((this.role == 'Tahsildar' && this.certificateName == 'IntegratedCertificate-SuoMoto')) {
    let sumotoDataforteh: any = {};
    sumotoDataforteh.casteforteh = this.sumotoFormControlsForTahsildar.casteforsumotoTahsildar.value,
    sumotoDataforteh.subCasteforteh = this.sumotoFormControlsForTahsildar.subCasteforsumotoTahsildar.value,
    sumotoDataforteh.sumotoverificationDocforteh = this.sumotoverificationDocforTahsildarBlob,
    sumotoDataforteh.religionforteh = this.sumotoFormControlsForTahsildar.religionforsumotoTahsildar.value,
    sumotoDataforteh.matitalstatusforteh = this.sumotoFormControlsForTahsildar.maritalStatusforsumotoTahsildar.value,
    sumotoDataforteh.qualificationforteh = this.sumotoFormControlsForTahsildar.qualificationforsumotoTahsildar.value,
    sumotoDataforteh.emailforteh = this.sumotoFormControlsForTahsildar.emailforsumotoTahsildar.value,
    sumotoDataforteh.fathernameforteh = this.sumotoFormControlsForTahsildar.fathernameforsumotoTahsildar.value,
    sumotoDataforteh.mobileforteh = this.sumotoFormControlsForTahsildar.mobileforsumotoTahsildar.value,
    sumotoDataforteh.revenuevillageforteh = this.sumotoFormControlsForTahsildar.revenuevillageforsumotoTahsildar.value,
    sumotoDataforteh.birthdateforteh = (this.sumotoFormControlsForTahsildar.dobforsumotoTahsildar.value) ? (this.commonService.formatDate(this.sumotoFormControlsForTahsildar.dobforsumotoTahsildar.value)) : '1900-01-01',
    sumotoDataforteh.remarksdistrictforteh = this.f2.districtforsuomototeh.value ? this.f2.districtforsuomototeh.value.districtName : null,
    sumotoDataforteh.remarksmandalforteh = this.f2.mandalforsuomototeh.value ? this.f2.mandalforsuomototeh.value.mandalName : null,
    sumotoDataforteh.remarkssecratariatforteh = this.f2.secretariatforsuomototeh.value ? this.f2.secretariatforsuomototeh.value.secretariatName : null,
    this.statusUpdatePostData.comment = JSON.stringify({list: list, remarks: selectedRemarks, reason: selectedReason, docName: selectedDoc, fileList: this.docList, sumotoDataforteh: sumotoDataforteh});
  } 

  else if(this.role === 'Revenue Divisonal Officer' && this.certificateName == 'IntegratedCertificate-SuoMoto') {
    let sumotoDataforrdo: any = {};
    sumotoDataforrdo.remarksdistrictforrdo = this.f2.districtforsuomotordo.value ? this.f2.districtforsuomotordo.value.districtName : null,
    sumotoDataforrdo.remarksmandalforrdo = this.f2.mandalforsuomotordo.value ? this.f2.mandalforsuomotordo.value.mandalName : null,
    sumotoDataforrdo.remarkssecratariatforrdo = this.f2.secretariatforsuomotordo.value ? this.f2.secretariatforsuomotordo.value.secretariatName : null,
    this.statusUpdatePostData.comment = JSON.stringify({list: list, remarks: selectedRemarks, reason: selectedReason, docName: selectedDoc, fileList: this.docList, sumotoDataforrdo: sumotoDataforrdo});
  }

  else if(this.role === 'Collector' && this.certificateName == 'IntegratedCertificate-SuoMoto') {
    let sumotoDataforjc: any = {};
    sumotoDataforjc.remarksdistrictforjc = this.f2.districtforsuomotojc.value ? this.f2.districtforsuomotojc.value.districtName : null,
    sumotoDataforjc.remarksmandalforjc = this.f2.mandalforsuomotojc.value ? this.f2.mandalforsuomotojc.value.mandalName : null,
    sumotoDataforjc.remarkssecratariatforjc = this.f2.secretariatforsuomotojc.value ? this.f2.secretariatforsuomotojc.value.secretariatName : null,
    this.statusUpdatePostData.comment = JSON.stringify({list: list, remarks: selectedRemarks, reason: selectedReason, docName: selectedDoc, fileList: this.docList,sumotoDataforjc: sumotoDataforjc});
  }


  else if((this.role == 'Village Revenue Officer' || this.role == 'Joint Collector' || this.role === 'Ward Revenue Secretary') && this.certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers') {
    let zswovroData: any = {};
    zswovroData.landavailableforgrant = this.z1.landavailableforgrant.value;
    zswovroData.vacantland = this.z1.vacantland.value;
    zswovroData.lpmnumbers = this.z1.lpmnumbers.value;
    zswovroData.remarksByVroZswo = this.z1.remarksByVroZswo.value;
    zswovroData.tableData=this.lpnumberdatafromtable;
    zswovroData.allotmentoflandjc = this.z2.allotmentoflandjc.value;
    zswovroData.recommendationofrdoandjc = this.z2.recommendationofrdoandjc.value;
    zswovroData.assignmentapproveddistJC = this.z3.assignmentapproveddistJC.value;
    zswovroData.grantofassignmentlandjc = this.z3.grantofassignmentlandjc.value;
    zswovroData.ror1bJC = this.z3.ror1bJC.value;
    this.statusUpdatePostData.comment = JSON.stringify({list: list, remarks: selectedRemarks, reason: selectedReason, docName: selectedDoc, fileList: this.docList, zswovroData:zswovroData});
  }

  // fpShop Start for VRO or WRS
  else if(this.isFpShopApplication && (this.role === 'Village Revenue Officer'  || this.role === 'Ward Revenue Secretary')){
   let fpShopData :any ={};
   if((Number(this.fp.latofFpShop.value) >= 12.41 && Number(this.fp.latofFpShop.value) <= 19.07)  && (Number(this.fp.longofFpShop.value) >= 77 && Number(this.fp.longofFpShop.value) <= 84.40) ){
    fpShopData.latofFpShop = this.fp.latofFpShop.value;
    fpShopData.longofFpShop = this.fp.longofFpShop.value;
    fpShopData.fpShopPhoto =  this.fPShopPhotoBlob;
    fpShopData.isSameLocation = this.fp.isSameLocation.value;
    this.statusUpdatePostData.comment =  JSON.stringify({list: list, remarks: selectedRemarks, reason: selectedReason, docName: selectedDoc, fileList: this.docList, fpShopData: fpShopData});
    }else{
    this.commonService.commonErrorPopup('Alert', 'Please enter Valid Latitude and Longitude', 'md');
    return; 
  }
  }
 else{
    this.statusUpdatePostData.comment =  JSON.stringify({list: list, remarks: selectedRemarks, reason: selectedReason, docName: selectedDoc, fileList: this.docList});
  }
  }

  this.statusUpdatePostData.action = actionCode;
  
    if(this.certificateName ==='IncomeCertificate' || this.certificateName ==='IncomeCertificateForStudents'){
      let incomeData = localStorage.getItem('incomeUpdate');
      if (incomeData && incomeData !== null) {
        this.incomeUpdate = JSON.parse(incomeData);
        // console.log('incomeData',incomeData)
        // console.log(this.incomeUpdate,'income update')
        this.incomeAlertMessage = "";
        // console.log('certificateName',this.certificateName,this.incomeUpdate.buildingsIncome < 1000,this.incomeUpdate.buildingsIncome)
        // console.log((Number(this.incomeUpdate.buildingsIncome) !== 0),'buildingsIncome')
        // console.log((Number(this.incomeUpdate.businessIncome) !== 0),'businessIncome')
        // console.log((Number(this.incomeUpdate.familyIncome) !== 0),'familyIncome')
        // console.log((Number(this.incomeUpdate.labourIncome) !== 0),'labourIncome')
        // console.log((Number(this.incomeUpdate.landsIncome) !== 0),'landsIncome')
        // console.log((Number(this.incomeUpdate.otherIncome) !== 0),'otherIncome')
        if((Number(this.incomeUpdate.buildingsIncome) !== 0)){
            if((Number(this.incomeUpdate.buildingsIncome) < 1000))
            {
              this.incomeAlertMessage = "Please enter atleast 4 digits on Income on Buildings"
              if(this.incomeAlertMessage.length > 0 ){
                const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
                modalRef.componentInstance.message = this.incomeAlertMessage != undefined || null ? this.incomeAlertMessage : 'Error Occurs. Please try Again';  
              }
            }
        }
         if((Number(this.incomeUpdate.businessIncome) !== 0)){
          if((Number(this.incomeUpdate.businessIncome) < 1000))
            {
              this.incomeAlertMessage = "Please enter atleast 4 digits on Income on Business"
              if(this.incomeAlertMessage.length > 0 ){
                const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
                modalRef.componentInstance.message = this.incomeAlertMessage != undefined || null ? this.incomeAlertMessage : 'Error Occurs. Please try Again';
              }
            }

        } if((Number(this.incomeUpdate.familyIncome) !== 0)){
          if((Number(this.incomeUpdate.familyIncome) < 1000))
            {
              this.incomeAlertMessage = "Please enter atleast 4 digits on Salary of both Wife and Husband "
              if(this.incomeAlertMessage.length > 0 ){
                const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
                modalRef.componentInstance.message = this.incomeAlertMessage != undefined || null ? this.incomeAlertMessage : 'Error Occurs. Please try Again';
              }
            }

        } if((Number(this.incomeUpdate.labourIncome) !== 0)){
          if((Number(this.incomeUpdate.labourIncome) < 1000))
            {
              this.incomeAlertMessage = "Please enter atleast 4 digits on Income on Labour"
              if(this.incomeAlertMessage.length > 0 ){
                const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
                modalRef.componentInstance.message = this.incomeAlertMessage != undefined || null ? this.incomeAlertMessage : 'Error Occurs. Please try Again';
              }
            }

        } if((Number(this.incomeUpdate.landsIncome) !== 0)){
          if((Number(this.incomeUpdate.landsIncome) < 1000))
            {
              this.incomeAlertMessage = "Please enter atleast 4 digits on Income on Lands"
              if(this.incomeAlertMessage.length > 0 ){
                const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
                modalRef.componentInstance.message = this.incomeAlertMessage != undefined || null ? this.incomeAlertMessage : 'Error Occurs. Please try Again';
              }
            }

        } if((Number(this.incomeUpdate.otherIncome) !== 0)){
          if((Number(this.incomeUpdate.otherIncome) < 1000))
            {
              this.incomeAlertMessage = "Please enter atleast 4 digits on Income on Other sources"
              if(this.incomeAlertMessage.length > 0 ){
                const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
                modalRef.componentInstance.message = this.incomeAlertMessage != undefined || null ? this.incomeAlertMessage : 'Error Occurs. Please try Again';
              }
            }

        } 
        // if(Number(this.incomeUpdate.totalIncome)){
          if((Number(this.incomeUpdate.totalIncome) == 0))
          {
            this.incomeAlertMessage = "Please enter any one of the income values"
            if(this.incomeAlertMessage.length > 0 ){
              const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
              modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
              modalRef.componentInstance.message = this.incomeAlertMessage != undefined || null ? this.incomeAlertMessage : 'Error Occurs. Please try Again';
            }
          }
        // } 
        // console.log(this.incomeAlertMessage,'alertMessage')
        if(this.incomeAlertMessage.length == 0 ){
          let postData: any = {
            requisitionId: this.receivedRequestionId,
            departmentCode: this.departmentCode,
            incomeSources: {
              incomeOnLands: Number(this.incomeUpdate.landsIncome),
              incomeOnBusiness: Number(this.incomeUpdate.businessIncome),
              incomeOnBuildings: Number(this.incomeUpdate.buildingsIncome),
              incomeOnLabour: Number(this.incomeUpdate.labourIncome),
              incomeOfFamily: Number(this.incomeUpdate.familyIncome),
              otherIncome:Number(this.incomeUpdate.otherIncome),
            },
            totalIncome: this.incomeUpdate.totalIncome,
          };
          // postData.requisitionId = this.receivedRequestionId;
          // postData.departmentCode = this.departmentCode;
          // postData.isDob = this.isDOBCorrect;
          // postData.isNative = this.isNative;

        // console.log('update', postData);
          this.commonService.postRequest(this.commonConstants.updateIncomeReq, postData).subscribe({
            next: (responseData:any) => { 
            // console.log('response from income', responseData)
            if(responseData?.result?.isSuccess == true) {
            this.updateWorkFlowStatus(this.yAction,this.yActionCode ,this.yTargetModal, confirmationOTPModal)
            }else if(responseData?.result?.isSuccess == false){
              const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
              modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
              modalRef.componentInstance.message = responseData?.result?.error != undefined || null ? responseData?.result?.error : 'Error Occurs. Please try Again';
            } 
          },
            error: (error) => {
              console.log('client side', error);
            }
          });
          // this.updateWorkFlowStatus(this.yAction,this.yActionCode ,this.yTargetModal, confirmationOTPModal)
        }
      }
    }else{
      if (this.role === 'Tahsildar' && (this.certificateName === 'IntegratedCertificate' || this.certificateName === 'IntegratedCertificateForStudents')&& (actionCode === 3 || actionCode === 4 || actionCode === 1)) {
        this.updateIntegratedRequisition(confirmationOTPModal);
      } else if(this.departmentCode == 'HF'&&( this.certificateName === "AarogyaSriNewCard"  || this.certificateName === "UpdateAarogyaSriCardForImage" )&& actionCode === 3) {
       
        this.isActionBtn = true;        
        this.aarogyaSriAction=this.yAction;
        this.aarogyaSriActionCode=this.yActionCode;
        this.aarogySriTargetModal=this.yTargetModal;
        this.aarogyaSriconfirmationOTPModal=confirmationOTPModal;
        let data: any = {};
        data.id=this.reqId;
        data.uhid=this.certificateInfo?.uhid;
        data.isActionBtn = this.isActionBtn; 
        data.actionName = "updateNewAarogyasri";       
        this.commonService.checkAction.next(data); 

      }
      else if(this.departmentCode == 'MW' && this.certificateName === "MuslimMarriageCertificate" && actionCode === 3){
        let postdata = {
          requisitionId: this.reqId
        } 
        this.generateSNoMMC(postdata,this.yAction,this.yActionCode ,this.yTargetModal, confirmationOTPModal);
      }
      else if(this.departmentCode == 'RV' && (this.certificateName === 'AddressCertificateForAadharEnrollment' || this.certificateName === 'AddressCertificateForAadharEnrollment@1' ||
      this.certificateName === 'AddressCertificateForAadharEnrollment@2' || this.certificateName === 'AddressCertificateForAadharEnrollment@3') && actionCode === 3){
      this.isOfficersDetails(this.userIdData, this.yAction,this.yActionCode ,this.yTargetModal, confirmationOTPModal);
    }
      else if(((this.departmentCode == 'HF' || this.departmentCode == 'MW') && actionCode === 4) || (this.departmentCode == 'MW' && actionCode === 1)|| (this.departmentCode !== 'HF' && this.departmentCode !== 'MW')){
           
       //here if it is fpshop application and  approval from RDO or DSO need to call new API to calculate Validity date 
      if(this.isFpShopApplication &&  actionCode === 3){
            this.generateFPShopValidity(confirmationOTPModal);
       }else{
          this.updateWorkFlowStatus(this.yAction,this.yActionCode ,this.yTargetModal, confirmationOTPModal);
      
     } }
    }

    /* housesite update Is TIDCO data */
    if((this.certificateName === "HousingSiteApplication") && (this.role === "Tahsildar" || this.role === "Municipal Commissioner")){
      
     
      let postdata = {
        requisitionId: this.reqId,
        departmentCode: "RV",
        isTidco: this.isTidcoOptionValue
      }
      console.log("housesite update Is TIDCO data postdata",postdata)
       this.commonService.postRequest(this.commonConstants.updateHouseSiteCetificateInfo , postdata).subscribe({ 
        next: (responseData: any) => { 
          console.log(responseData, "testt")
        }
      })
    }
   // }
 // }
}
approveReject(action: any, actionCode: any,targetModal: any,confirmationOTPModal: any, from?:any): any{
  this.submitted = true;
  this.sumotoSubmits = true;
  this.sumotoSubmitsforRI = true;
  this.sumotoSubmitsforTahsildar = true;
  if(this.certificateName === 'IntegratedCertificate-SuoMoto' && (this.role === 'Village Revenue Officer' || this.role == 'Ward Revenue Secretary')) {
    if(this.f2.remarksReasons.value === 'Not Traceable') {
      this.sumotoFormControls.caste.clearValidators();
      this.sumotoFormControls.subCaste.clearValidators();
      // this.sumotoFormControls.sumotoverificationDoc.clearValidators();
      this.sumotoFormControls.fathername.clearValidators();
      this.sumotoFormControls.mobile.clearValidators();
      this.sumotoFormControls.revenuevillage.clearValidators();
      this.sumotoFormControls.email.clearValidators();
      this.sumotoFormControls.religion.clearValidators();
      // this.sumotoverificationDocLabel.nativeElement.innerText = '';
      // this.clearFileUpload('sumotoverificationDoc', 'sumotoverificationDocLabel');
      // this.sumotoFormControls.sumotoverificationDoc.reset();
      this.approveRejectNew(action, actionCode,targetModal,confirmationOTPModal,from);
    }
    else if(this.remarksForm.valid && this.sumotoForm.valid) {
      this.updateSumotoData(action, actionCode,targetModal,confirmationOTPModal,from);
    }
  }
  else if(this.certificateName === 'IntegratedCertificate-SuoMoto' && this.role === 'Revenue Inspector') {
    if(this.f2.remarksReasons.value === 'Not Traceable') {
      this.sumotoFormControlsForRI.casteforsumotoRI.clearValidators();
      this.sumotoFormControlsForRI.subCasteforsumotoRI.clearValidators();
      this.sumotoFormControlsForRI.fathernameforsumotoRI.clearValidators();
      this.sumotoFormControlsForRI.mobileforsumotoRI.clearValidators();
      this.sumotoFormControlsForRI.revenuevillageforsumotoRI.clearValidators();
      this.sumotoFormControlsForRI.emailforsumotoRI.clearValidators();
      this.sumotoFormControlsForRI.religionforsumotoRI.clearValidators();
      this.sumotoFormControlsForRI.sumotoverificationDocforRI.clearValidators();
      this.approveRejectNew(action, actionCode,targetModal,confirmationOTPModal,from);
      }
      else if(this.remarksForm.valid && this.sumotoFormForRI.valid) {
        this.updateSumotoDataForRI(action, actionCode,targetModal,confirmationOTPModal,from);
      }
  }
  else if(this.certificateName === 'IntegratedCertificate-SuoMoto' && this.role === 'Tahsildar') {
    if( this.f2.remarksReasons.value === 'Not Traceable') {
      this.sumotoFormControlsForTahsildar.casteforsumotoTahsildar.clearValidators();
      this.sumotoFormControlsForTahsildar.subCasteforsumotoTahsildar.clearValidators();
      this.sumotoFormControlsForTahsildar.fathernameforsumotoTahsildar.clearValidators();
      this.sumotoFormControlsForTahsildar.mobileforsumotoTahsildar.clearValidators();
      this.sumotoFormControlsForTahsildar.revenuevillageforsumotoTahsildar.clearValidators();
      this.sumotoFormControlsForTahsildar.emailforsumotoTahsildar.clearValidators();
      this.sumotoFormControlsForTahsildar.religionforsumotoTahsildar.clearValidators();
      this.sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.clearValidators();
      this.approveRejectNew(action, actionCode,targetModal,confirmationOTPModal,from);
    }
    else if(this.remarksForm.valid && this.sumotoFormForTahsildar.valid) {
      this.updateSumotoDataForTahsildar(action, actionCode,targetModal,confirmationOTPModal,from);
    }
  }
  else{
    this.approveRejectNew(action, actionCode,targetModal,confirmationOTPModal,from);
  }
  // else if(this.certificateName === 'IntegratedCertificate-SuoMoto' && this.role === 'Revenue Divisonal Officer') {
  //     this.approveRejectNew(action, actionCode,targetModal,confirmationOTPModal,from);
  // }
  // else if(this.certificateName === 'IntegratedCertificate-SuoMoto' && this.role === 'Collector') {
  //   this.approveRejectNew(action, actionCode,targetModal,confirmationOTPModal,from);
  // }
}
  updateIntegratedRequisition(confirmationOTPModal: any) {
    let postData: any = {};
    postData.requisitionId = this.receivedRequestionId;
    postData.departmentCode = this.departmentCode;
    postData.isDob = this.isDOBCorrect;
    postData.isNative = this.isNative;

   // console.log('update', postData);
    this.commonService.postRequest(this.commonConstants.updateIntegratedReq, postData).subscribe({
      next: (responseData:any) => { 
      this.updateWorkFlowStatus(this.yAction,this.yActionCode ,this.yTargetModal, confirmationOTPModal)
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
 
  generateSNoMMC(requisitionId: any, action: any, actionCode: any,targetModal: any, confirmationOTPModal : any){
    this.commonService
    .postRequest(this.commonConstants.updateMMCApprovedData, requisitionId)
    .subscribe({
      next: (responseData: any) => {
       if(responseData?.result?.isSuccess === true){
        this.updateWorkFlowStatus(action,actionCode ,targetModal,confirmationOTPModal);
       }
       else{
        const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        modalRef.componentInstance.title = "Error";
        modalRef.componentInstance.message = "Serial number is not generated successfully.";
       }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  isOfficersDetails(requisitionId: any, action: any, actionCode: any,targetModal: any, confirmationOTPModal : any){
    this.commonService
    .getRequest(this.commonConstants.getOfficersDetails + this.userIdData)
    .subscribe({
      next: (responseData: any) => {
       if(responseData?.result?.isSuccess === true){
         if(responseData.result?.isPhoneUpdated ===  true && responseData.result?.isUserNameUpdated ===  true ){
          this.updateWorkFlowStatus(action,actionCode ,targetModal,confirmationOTPModal);
         }
         else if(responseData.result?.isPhoneUpdated ===  false || responseData.result?.isUserNameUpdated ===  false ){
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = "Error";
          modalRef.componentInstance.message = "Please Update your Name or Phone Number in Profile Update Section";
         }
       }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  
  otpModalRef: any;
  workListCount :any
  updateWorkFlowStatus(action: any, actionCode: any,targetModal: any, confirmationOTPModal : any){
    this.modalFormName = "Digital Signature";
    this.modalFormButtonName = "Sign Document";
    if(action == 'approve' || action == 'reject'){
      this.workListCount = this.commonConstants.certificateList.filter((item: any) => item.certName === this.certificateName);
      if( this.workListCount.length > 1) {
        if(this.certificateName == '22AModification' || this.certificateName == 'FLineApplication'){
          if(this.buttonStatus.length > 0){
            if(this.certificateName == '22AModification'){
              this.workListCount.forEach((element: any) => {
                if (element.subServiceType === this.buttonStatus && this.certificateName == element.certName){
                  this.certificateTypeName = element.certID;
                 // console.log(this.certificateTypeName,'this.certificateTypeName 22AModification')
                }
              });
            }
            else if(this.certificateName == 'FLineApplication'){
              this.workListCount.forEach((element: any) => {
                if (element.subServiceType === this.buttonStatus && this.certificateName == element.certName){
                  this.certificateTypeName = element.certID;
                  // console.log(this.certificateTypeName,'this.certificateTypeName FLineApplication')
                }
              });
            }
          }
        }
      }
    }
   // console.log(this.certificateTypeName,'this.certificateTypeName')
   // console.log(actionCode,action,'actionCode')
    if (actionCode === 3 || actionCode === 4) {
      if(action === 'approve'){
        this.modalFormButtonName = "Sign and Approve";
        if(this.isApprovalAuthority && this.receivedRequestionId.length > 0){
          if (this.role  === 'Revenue Divisonal Officer' && this.certificateName === 'BirthDeathCertificate') {
            this.getRevenueMandal();
          }
          this.isApproveExist = true
        }
        this.isApproveExist = false
        // if(this.certificateTypeName.length > 0){
        if(this.departmentCode !== 'HF' && this.certificateName !== 'AddressCertificateForAadharEnrollment' && this.certificateName !== 'AddressCertificateForAadharEnrollment@1' && this.certificateName !== 'AddressCertificateForAadharEnrollment@2' && this.certificateName !== 'AddressCertificateForAadharEnrollment@3') {
          if (this.digiSignPopUp) {
            this.loadTokens();
          }
          // console.log(this.tokenList,'tokenlist')
          // if(this.tokenList.length > 0){
            this.isSigning = false;
            this.mainRef = this.modalService.open(targetModal, 
           {   size: 'lg' ,
               centered: true,
               backdrop: 'static'
             }
           );
        } else {
            this.postCertificate();
        }
          // }else{
          //   this.alertMessageModal('Access Denied !', 'Yor are not the Authorized user to perform this operation')
          // }
          
        // } 

      }else if(action === 'reject'){
        this.modalFormButtonName = "Sign and Reject";
        if(this.certificateName == '22AModification' || this.certificateName == 'FLineApplication'){
          // console.log(this.certificateTypeName,'reject this.certificateTypeName')
          if(this.certificateTypeName.length > 0){
            if (this.digiSignPopUp) {
              this.loadTokens();
            }
            // if(this.tokenList.length > 0){
              this.isSigning = false;
              this.mainRef = this.modalService.open(targetModal, 
              {   size: 'lg' ,
                  centered: true,
                  backdrop: 'static'
                }
              );
            // }else{
            //   this.alertMessageModal('Access Denied !', 'Yor are not the Authorized user to perform this operation')
            // }
          }else{
            if (this.digiSignPopUp) {
              this.loadTokens();
            }
            // if(this.tokenList.length > 0){
              this.isSigning = false;
              this.mainRef = this.modalService.open(targetModal, 
              {   size: 'lg' ,
                  centered: true,
                  backdrop: 'static'
                }
              );
            // }else{
            //   this.alertMessageModal('Access Denied !', 'Yor are not the Authorized user to perform this operation')
            // }
          }
        }else{
          if(this.departmentCode !== 'HF' && this.certificateName !== 'AddressCertificateForAadharEnrollment' && this.certificateName !== 'AddressCertificateForAadharEnrollment@1' && this.certificateName !== 'AddressCertificateForAadharEnrollment@2' && this.certificateName !== 'AddressCertificateForAadharEnrollment@3') {
          if (this.digiSignPopUp) {
            this.loadTokens();
          }
          // if(this.tokenList.length > 0){
            this.isSigning = false;
            this.mainRef = this.modalService.open(targetModal, 
            {   size: 'lg' ,
                centered: true,
                backdrop: 'static'
              }
            );
          } else {
            this.UpdateActionStatus();
          }
          // }else{
          //   this.alertMessageModal('Access Denied !', 'Yor are not the Authorized user to perform this operation')
          // }
        }
      }
    }else if (actionCode === 1) {
      // this.display =''
      // this.timer(0)
     // console.log(this.aadharUID ,'this.aadharUID ')
     //disabled aadhar authentication for jagananna suraksha for forward authorities
     this.UpdateForwardActionStatus();
      // if(this.aadharUID == null || this.aadharUID == undefined){
      //   this.UpdateForwardActionStatus()
      // }else if (this.role === 'Revenue Inspector'){
      //   this.UpdateForwardActionStatus()
      // }else{
      //   this.isSentOTP = false;
      //   this.isVerifiedOTP = false;
      //   this.showBiometricPopup = false;
      //   this.o.authenticationType.patchValue('OTP');
      //   // clearInterval(this.timerRun);
      //   this.otpModalRef = this.modalService.open(confirmationOTPModal, 
      //     {   
      //       size: 'lg' ,
      //       centered: true,
      //       backdrop: 'static'
      //     }
      //   );
      // }
      
     
    }
  }



  approveRequest(){
    this.confirmRef.close();
    this.requestUpdateApproveRejectForward(this.yAction,this.yActionCode ,this.yTargetModal)
  }

  requestUpdateApproveRejectForward(action: any, actionCode: any,targetModal: any): any{

    let list: any = [];
    let selectedRemarks;
    let selectedReason;
    let selectedDoc;
    // console.log('this.checklistForm.valid');
    this.isSigning = false;
    this.isAadharValidated = false;
    this.buttonStatus = action;
    this.emudhraformGroup.reset();
   // console.log('this.checklistForm.valid', this.checklistForm.valid);
    // console.log('this.remarksForm.valid', this.remarksForm.valid);
    // console.log('this.remarksForm.value', this.remarksForm.value);
    // console.log('form', this.form.valid);
    this.modalFormActionCode = actionCode
    if (actionCode === 1) {
      this.modalFormName = "Authentication";
      this.modalFormButtonName = "Authenticate";
    }
    else{
      this.modalFormName = "Digital Signature";
      this.modalFormButtonName = "Sign Document";
    }
    if(action == 'approve' || action == 'reject'){
      let count = this.commonConstants.certificateList.filter((item: any) => item.certName === this.certificateName);
      if(count.length > 1) {
        if(this.certificateName == '22AModification' || this.certificateName == 'FLineApplication'){
          if(this.buttonStatus.length > 0){
            if(this.certificateName == '22AModification'){
              count.forEach((element: any) => {
                if (element.subServiceType === this.buttonStatus && this.certificateName == element.certName){
                  this.certificateTypeName = element.certID;
                  // console.log(this.certificateTypeName,'this.certificateTypeName 22AModification')
                }
              });
            }
            else if(this.certificateName == 'FLineApplication'){
              count.forEach((element: any) => {
                if (element.subServiceType === this.buttonStatus && this.certificateName == element.certName){
                  this.certificateTypeName = element.certID;
                  //console.log(this.certificateTypeName,'this.certificateTypeName FLineApplication')
                }
              });
            }
          }
        }
        
      }
    }

    if((this.checklist?.length > 0 && !this.checklistForm.valid) || !this.remarksForm.valid) {
      //console.log('if');
      return false;
    } else if(this.role  === 'Revenue Divisonal Officer' && this.certificateName === 'BirthDeathCertificate' && !this.form.valid) {
     // console.log('rdo role');
      return false;
    } else {
     // console.log('else');
      if (this.checklist && this.checklist?.length > 0) {
        for(const [key, value] of Object.entries(this.checklistForm.value)) {
          let temp: any = {};
          temp.id = key;
          temp.answer = value;
          temp.questionText = this.checklist.find((item: { id: string; }) => item.id === key).questionText;
          list.push(temp);
        }
      }
     
      selectedRemarks = this.f2.remarks.value === 'Others' ? this.f2.otherRemarks.value : this.f2.remarks.value;
      selectedReason = this.f2.remarks.value === 'Not Recommended' ? this.f2.remarksReasons.value : '';
      selectedDoc = this.getSelectedDoc();
    }
    // let actionCode: any;
    // if (action === 'approve' && (this.role === 'Village Revenue Officer' || this.role === 'Revenue Inspector')) {
    //   actionCode = 1;
    // } else if (action === 'approve' && this.role === 'Tahsildar') {
    //   actionCode = 3;
    // } else if (action === 'reject' && (this.role === 'Village Revenue Officer' || this.role === 'Revenue Inspector')) {
    //   actionCode = 2;
    // } else if (action === 'reject' && this.role === 'Tahsildar') {
    //   actionCode = 4;
    // }
    let postData: any = {};
    postData.taskId = this.taskId; //f6d78366-5193-4b35-a6cb-75aaf68014f7;
    postData.comment = JSON.stringify({list: list, remarks: selectedRemarks, reason: selectedReason, docName: selectedDoc, fileList: this.docList});
    postData.action = actionCode;

    this.commonService.putRequest(this.commonConstants.approveReject, postData).subscribe({
      next: (resData:any) => { 
        // console.log(resData,'approve and reject');
        let msg:string;
        let responseData = resData.result;
        const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        if(typeof(responseData) === 'string') {
          modalRef.componentInstance.message = resData.result;
        } else if(action === 'approve' && typeof(responseData) !== 'string') {
         // console.log('receivedRequestionId , role ',this.receivedRequestionId,this.role === 'Tahsildar')
            if(this.isApprovalAuthority && this.receivedRequestionId.length > 0){
             // this.receivedRequestionId = "RV-IntegratedCertificate-130";
             if (this.role  === 'Revenue Divisonal Officer' && this.certificateName === 'BirthDeathCertificate') {
               this.getRevenueMandal();
             }
              this.isApproveExist = true
            }
            this.isApproveExist = false
          // if (this.role === 'Village Revenue Officer') {
          //     modalRef.componentInstance.message = 'Application forwarded to Revenue Inspector.'
          // } else if(this.role === 'Revenue Inspector') {
          //   modalRef.componentInstance.message = 'Application forwarded to Tahsildar.'
          // } 
          if (actionCode === 1) {
            // if (this.digiSignPopUp) {
            //   this.loadTokens();
            // }
             modalRef.close();
             this.isSigning = false;
            //  this.mainRef = this.modalService.open(targetModal, 
            // {   size: 'lg' ,
            //     centered: true,
            //     backdrop: 'static'
            //   }
            // );

            //24-11-2021
            const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            mdRef.componentInstance.message = 'Application forwarded to next level.'
              // modalRef.close();
              // console.log('worklist before ')
              this.getApprovers(this.taskId);
              // console.log('worklist done ')
              this.isForwardExist = true
          } else if(this.isApprovalAuthority) {
            if(this.certificateTypeName.length > 0){
              if (this.digiSignPopUp) {
                this.loadTokens();
              }
               //modalRef.componentInstance.message = 'Application approved successfully.'
               modalRef.close();
               this.isSigning = false;
               this.mainRef = this.modalService.open(targetModal, 
              {   size: 'lg' ,
                  centered: true,
                  backdrop: 'static'
                }
              );
            }
            else{
              modalRef.componentInstance.message = 'Application Approved successfully..'
              // 24-01-2022 pension
              if(this.departmentCode === "RD"){
                this.getWorklist();
              }
              // this.getWorklist();
              // // if(this.departmentCode === "RD"){
              // //   this.gettingHouseHoldId();
              // // }
              // 25-11-2021 pension
              this.isApproveExist = false
              // this.isApprovalAuthority = false
            }
            
           
            this.sendSMS('approve');
          }
        } else if(action === 'reject' && typeof(responseData) !== 'string') {
          // console.log(this.certificateName,'reject this.certificateName')
          if(this.certificateName == '22AModification' || this.certificateName == 'FLineApplication'){
            // console.log(this.certificateTypeName,'reject this.certificateTypeName')
            if(this.certificateTypeName.length > 0){
              if (this.digiSignPopUp) {
                this.loadTokens();
              }
               //modalRef.componentInstance.message = 'Application approved successfully.'
               modalRef.close();
               this.isSigning = false;
               this.mainRef = this.modalService.open(targetModal, 
              {   size: 'lg' ,
                  centered: true,
                  backdrop: 'static'
                }
              );
            }
          }
          modalRef.componentInstance.message = 'Application rejected successfully.'
          this.sendSMS('reject');
        }
        modalRef.componentInstance.reqId = '';
        modalRef.componentInstance.title = '';
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
    this.updatebuttonflag = false;
    this.sumotoSubmits = false;
    this.updatebuttonflagforRI = false;
    this.sumotoSubmitsforRI = false;
    this.updatebuttonflagforTahsildar = false;
    this.sumotoSubmitsforTahsildar = false;
  }



  openaadhar(targetModal: any){
    this.mainRef = this.modalService.open(targetModal, 
      {   size: 'lg' ,
          centered: true,
          backdrop: 'static'
        }
      );
  }

  otp : any;
   handeOtpChange(value: string[]): void {
   // console.log(value,'aadhar otp');
    this.otp = ''
    value.forEach(element => {
      if(element != null){
        if(this.otp == ''){ this.otp = element }
        else{ this.otp = this.otp + element  }
      }
     
    });
    
  }

  handleFillEvent(value: string): void {
    // console.log(value,'filled otp');
    this.otp = value;
    // console.log(Number(this.otp),'final otp');
  }
  
  
  mRef : any
  invalidOTP = false;
  btnAadharOTP() : any{
    this.aadharSubmitted = true;
    // this.otp = Number(this.otp);
    // console.log(this.otp.length,this.otp,'submit otp')
    if(this.otp.length ==6){
      this.invalidOTP  = false;
      if (!this.aadharformGroup.valid )
      {  return false; }
      else if(this.aadharformGroup.valid ){ 
        this.isVerifiedOTP = true
        // console.log(this.aadharUID.length,this.aadharTransactionID.length,this.otp.length ,'length of  otp')
        if(this.aadharUID.length == 12 && this.aadharTransactionID.length > 0 &&  this.otp.length == 6){
          this.verifyAndConfirmAaadharOTP()
        }
      }
    }else{this.invalidOTP  = true;}
    // console.log(this.invalidOTP,'invalidOTP otp')
    
  }

  aadharModalRef : any;
  verifyAndConfirmAaadharOTP(){
    
    //30-12-2021 sudhakr method
    var sendPostData={
      PIDXML: this.aadharTransactionID,
      otp : this.otp,
      uid: this.aadharUID, //'514492421388',//
      authentication_type: "OTPVALIDATE"
    }
    // console.log(sendPostData,'post data')
    //let postData: any = btoa(JSON.stringify(sendPostData));
    this.commonService.postRequestForAadhar(this.commonConstants.generateAadharOTP, sendPostData).subscribe({
      next: (responseData:any) => { 
        //console.log(responseData,'post respose data for showing aadharotp');
        if(responseData?.reason == 'OTP Verified Successfully'){
          // if(this.departmentCode === 'CS'){
          //   this.btnSubmitCS();
          // }else{
          // this.aadharModalRef.close();
          this.UpdateActionStatus();
          // }
        }else if(responseData?.reason == 'OTP validation failed'){
          // this.isVerifiedOTP = false;
          this.isSentOTP = false;
          this.aadharModalRef  = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          // const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
         this.aadharModalRef.componentInstance.message = 'Invalid OTP Entered. Please Enter Valid OTP.'
        }else if((responseData?.reason != 'OTP Verified Successfully' ) || (responseData?.reason != 'OTP validation failed' ) && responseData?.status != 200){
          this.aadharModalRef  = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          // const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
         this.aadharModalRef.componentInstance.message = responseData?.reason
        }
        //  this.getCertificate(responseData.result.certificateId,this.departmentCode)
      },
      error: (error: any) => {
        this.isSentOTP = false;
        console.log('client side', error);
      }
    });
    // this.UpdateActionStatus()

  }

  btnSendOTPandTimer(){
    this.timer(1);
    this.isSentOTP = true;
  this.isVerifiedOTP = true;

    // this.aadharUID ="514492421388"
    // var sendPostData={
    //   uId: this.aadharUID,
    // }
    // // console.log(sendPostData,'post data')
    // this.commonService.postRequest(this.commonConstants.generateAadharOTP, sendPostData).subscribe({
    //   next: (responseData:any) => { 
    //     // console.log(responseData,'post respose data for showing aadharotp');
    //     if(responseData?.result?.isSuccess == true){
    //       this.aadharTransactionID = responseData?.result?.result
    //     }
    //     //  this.getCertificate(responseData.result.certificateId,this.departmentCode)
    //   },
    //   error: (error) => {
    //     console.log('client side', error);
    //   }
    // });


    //30-12-2021 sudhakr method
    var sendPostData={
      uid: this.aadharUID,// '514492421388',//
      authentication_type: "OTPGENERATE"
    }
    // console.log(sendPostData,'post data')
    //let postData: any = btoa(JSON.stringify(sendPostData));
    this.commonService.postRequestForAadhar(this.commonConstants.generateAadharOTP, sendPostData).subscribe({
      next: (responseData:any) => { 
       // console.log(responseData,'post respose data for showing aadharotp');
        if(responseData?.reason == 'OTP Sent Successfully'){
          this.aadharTransactionID = responseData?.result
          // this.isSentOTP = false;
        }
        //  this.getCertificate(responseData.result.certificateId,this.departmentCode)
      },
      error: (error: any) => {
        this.isSentOTP = false;
        console.log('client side', error);
      }
    });
  }

  

  generateNoticeModal(targetModal: any){
    this.mainRef = this.modalService.open(targetModal, 
      {   size: 'lg' ,
        centered: true,
        backdrop: 'static'
      }
    );
  }
  getRelation() {
    this.commonService.getRequest(this.commonConstants.getRelationshipInEn).subscribe({
      next: (responseData: any) => { 
        this.relations =  this.commonService.sortData(responseData.result);
        // console.log(this.relations);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }



  // getGender() {
  //   this.commonService.getRequest(this.commonConstants.getGender).subscribe({
  //     next: (responseData: any) => { 
  //       this.genders =  this.commonService.sortData(responseData.result);
  //       // console.log(this.genders);
  //     },
  //     error: (error) => {
  //       console.log('client side', error);
  //     }
  // });
  // }

  getCaste() {
    this.commonService.getRequest(this.commonConstants.getCaste).subscribe({
      next: (responseData: any) => { 
        this.casteCategory = this.commonService.sortData(responseData.result);
        this.commonService.setMasterData('caste', this.casteCategory);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  scrollToElement($element: any): void {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
  lpNumbersVro: any = [];
  lpNoSyNofromworklist: any = []
  subcasteforsumototahsildarIC: any;
  getCertificateDetails(item: any) {
    this.enabledisablebutton = null; // zswo workflow 
    this.z1.vacantland.patchValue(null); // zswo workflow
    this.vacantlandvalue = false; // zswo workflow
    this.showTable = false; // zswo workflow
    this.z1.lpmnumbers.patchValue(null); // zswo workflow
    this.getlpsysubmitted = false; // zswo workflow
    this.zillasainikwelfareformforJC.reset(); // zswo workflow
    this.zillasainikwelfareform.reset(); // zswo workflow
    this.zillasainikwelfareformforJC2.reset();
    this.answers = [];
    this.isCSclick = true;
    this.submitted = false;
    this.showCluster = false;// civil supplies
    this.submitTable = false;// civil supplies
    this.submits = false;// civil supplies
    this.submitted=false;// civil supplies
    this.sumotoSubmits=false;//sumoto service
    this.sumotoSubmitsforRI=false; // sumoto service RI
    this.sumotoSubmitsforTahsildar = false;
    this.remarksForm.reset();
    this.fisheriesForm.reset();
    this.dappuArtistsForm.reset();
    this.traditionalCobblerform.reset();
    this.handloomWeaverForm.reset();
    this.weaverADOHDTOForm.reset();
    this.form.reset();
    this.rectificationForm.reset();
    this.sumotoForm.reset();
    this.fpShopForm.reset();
    this.isNative = true;
    this.isDOBCorrect = true;
    this.isActionBtn = false;
    this.showInfo = false;
    this.consent = false;
    this.fdoConsent = false;
    // this.verificationDocLabel.nativeElement.innerText = 'No File Choosen';
    this.f.panchayatType.patchValue('Grampanchayat');
    this.items = this.items.map(item => { 
      item.isSelected = false;
      return item;
    });
    this.isApproveExist = false
    this.isForwardExist = false
    item.isSelected = true;
    this.reqId = item.requisitionId;   
    this.requisitionDate = this.datePipe.transform(item.RequestedDate, 'dd/MM/yyyy');
  //  console.log(item.DepartmentCode,'item.DepartmentCode')
    this.departmentCode = item.DepartmentCode;
    this.taskId = item.id;
    // console.log('requisitionDate', this.requisitionDate);
    this.commonService.getRequest(this.commonConstants.getCertificateDetails + this.departmentCode + '&IncludeFileUploadInfo=true&IncludePaymentDetails=false&RequisitionId='+ this.reqId).subscribe({
      next: (responseData: any) => { 
        if(typeof(responseData?.result) !== 'string') {          
          this.showRequisitionInfo = true;
          this.receivedRequestionId = this.reqId;
          // console.log('responseData.result',responseData.result)
          // pensions
          this.certificateResult =responseData.result;
          // pensions
          this.basicInfo = responseData.result?.requisitionHeader;  
      
          this.certificateName = responseData.result?.requisitionHeader?.requisitionType;          
          this.citizenAadharNumber = responseData.result?.requisitionHeader?.applicantInfo?.aadhaarNumber;
        //  console.log('certificate type name ',responseData.result?.requisitionHeader?.requisitionType)   
          this.certificateInfo = responseData.result?.requisitionData;
          this.sumotocasteInfo = responseData.result?.requisitionHeader.applicantInfo;
          this.lpNumbersVro = this.certificateInfo.lpNoSYNo;
            
          if(this.role == 'Tahsildar' && this.certificateName == 'IntegratedCertificate-SuoMoto') {
              this.sumotosubcasteflagfortahsildar = 'sumotosubcasteonload';
            }
          
          if(this.certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && (this.role == 'Village Revenue Officer' || this.role == 'Ward Revenue Secretary')) {
           if(this.lpNumbersVro) {
            this.landavailableforgrantvalue = 'Yes'
            this.z1.landavailableforgrant.setValue('Yes')
           }
           else {
            this.landavailableforgrantvalue = 'No'
            this.z1.landavailableforgrant.setValue('No')
           }
          }
          if(this.certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers') {
            this.fetchLPMDetailstoken();
            this.checkResurveyVillage();
            if(this.certificateInfo?.lpNoSYNo.length > 0) {
                this.lpNoSyNofromworklist = this.certificateInfo?.lpNoSYNo
            }
            else {
              this.lpNoSyNofromworklist = 'Not Available'
            }
          }
          this.sumotoid = responseData.result?.id;
          this.getSubCaste();
          this.getId = responseData.result.id;
          if(this.departmentCode === 'CS' && (this.certificateName === 'FPSHOPRENEW-VSKP' || this.certificateName === 'FPSHOPRENEW-OTH' || this.certificateName === 'FPSHOPRENEW')){
            this.isFpShopApplication = true;
            this.fpShopValiditydate= responseData.result?.requisitionData?.fpShopDetailsDataList[0]?.fpShopValidityupto;
            if(this.isFpShopApplication && (this.role === 'Village Revenue Officer'  || this.role === 'Ward Revenue Secretary')){
              this.imgSrc=""
              this.fp?.fpShopPhoto?.patchValue('');
            }
          }else{
            this.isFpShopApplication = false;
          }
          if(this.departmentCode === 'RV' && this.certificateName === 'IntegratedCertificate-SuoMoto') {
            this.sumotoFormControls.sumotoverificationDoc.patchValue('');
            this.sumotoFormControls.sumotoverificationDoc.reset();
            if(this.sumotoverificationDocLabel?.nativeElement?.innerText) {
              this.sumotoverificationDocLabel.nativeElement.innerText = 'No File Choosen'
            }
          }
          if(this.departmentCode === 'RV' && this.certificateName === 'IntegratedCertificate-SuoMoto') {
            this.f2.supportingDocuments.patchValue('');
            this.f2.supportingDocuments.reset();
            if(this.supportingDocumentsLabel?.nativeElement?.innerText) {
              this.supportingDocumentsLabel.nativeElement.innerText = 'No File Choosen'
            }
          }
        //  console.log("basic",responseData.result?.requisitionHeader);
          
          // console.log('certificateInfo',responseData.result?.requisitionData);
          // console.log("ricecard",this.certificateInfo.riceCardNumber);

          this.getRejectedReason(this.certificateName)
          this.getSLAData(this.certificateName);
          this.fileList = responseData.result?.fileList;
          this.commonService.filesforsumotodata = responseData.result?.fileList;

          if(this.departmentCode.toLowerCase() == 'cs' && !this.isFpShopApplication){
             this.postCivilSuppliesWeblandRequestData();        
        //console.log(this.departmentCode,'cs department code')
          this.showCluster = false;
       // this.clusterForm.reset();
        this.sixstepForm.reset(); 
         this.sixStepGridData =[];  

this.tableDatas.clear();
this.ekycStatusCall(); 
this.getSixStepVAlidationData();
this.checkRuralUrban();
if(this.sixStepGridData.length >0){
  // this.sixstepForm.controls['cluster'].setValidators(Validators.required);
  // this.sixstepForm.controls['cluster'].['fpshop'].updateValueAndValidity();
  
  // console.log("value there");
      
  }
// else{
//   alert("else");
//   this.sixstepForm.controls['cluster'].clearValidators;
//   this.sixstepForm.controls['cluster'].updateValueAndValidity();
//   this.sixstepForm.controls['fpshop'].clearValidators;
//   this.sixstepForm.controls['fpshop'].updateValueAndValidity();
//   this.sixstepForm.controls['socialAudit'].clearValidators;
//   this.sixstepForm.controls['socialAudit'].updateValueAndValidity();
//   console.log("value  not there");

// }
 


// this.clusterForm = this.formBuilder.group({

//   cluster : [null,[Validators.required]],
//   fpshop : [null,[Validators.required]]
// });
          
          
          }
        //  console.log('this.fileList',this.fileList, responseData.result?.fileList)
        if(this.certificateName === 'FamilyMemberCertificate' || this.certificateName === 'SplittingRiceCard' || this.certificateName === 'MemberAdditionRiceCard' || this.certificateName === 'MemberDeletionRiceCard'
        || this.certificateName === 'CorrectionofWrongAadhaar') {
          this.commonService.commonAction.next(this.certificateInfo);
        }
        else if(this.certificateName === 'NewRiceCard')
        {
          this.commonService.commonAction.next(this.basicInfo);
        }
        else if(this.certificateName === 'AarogyaSriNewCard' || this.certificateName === 'UpdateAarogyaSriCardForImage' ){
          this.commonService.commonAction.next(this.certificateInfo);
        }
        // if(this.departmentCode === 'RV' && (this.certificateName === 'IntegratedCertificateForStudents-RDO' || this.certificateName === 'IncomeCertificateForStudents' || this.certificateName === 'IntegratedCertificateForStudents')){
        // if(this.fileList === null){
        // this.commonService.commonErrorPopup('Alert ' + this.alertDateTimeZone, 'There are no documents for this application, Unable to proceed', 'md');
        // }

        // }
         this.getServiceNameFromConfig(this.certificateName);
          this.presentAddr =this.basicInfo?.applicantInfo?.addresses.filter((item: { addressType: string; }) => (item.addressType === 'present') || (item.addressType === 'Present'));
          this.permanentAddr = this.basicInfo?.applicantInfo?.addresses.filter((item: { addressType: string; }) => (item.addressType === 'permanent') || (item.addressType === 'Permanent'));
     
          this.certificateTypeName = ""
          let count = this.commonConstants.certificateList.filter((item: any) => item.certName === this.certificateName);
          // console.log('count',count,count.length)
          // console.log(responseData.result?.requisitionData?.certificateType,'?.certificateType')
          if (count.length === 1) {
              this.certificateTypeName = count[0]?.certID;
          } else if(count.length > 1) {
            if(this.certificateName == '22AModification' || this.certificateName == 'FLineApplication'){
              // if(this.buttonStatus.length > 0){
              //   if(this.certificateName == '22AModification'){
              //     count.forEach((element: any) => {
              //       if (element.subServiceType === this.buttonStatus && this.certificateName == element.certName){
              //         this.certificateTypeName = element.certID;
                     // console.log(this.certificateTypeName,'this.certificateTypeName id')
              //       }
              //     });
              //    }else if(this.certificateName == 'FLineApplication'){
              //     count.forEach((element: any) => {
              //       if (element.subServiceType === this.buttonStatus && this.certificateName == element.certName){
              //         this.certificateTypeName = element.certID;
              //         console.log(this.certificateTypeName,'this.certificateTypeName id')
              //       }
              //     });
              //   }
              // } 
            }else{
              count.forEach((element: any) => {
                if (element.subServiceType === responseData.result?.requisitionData?.certificateType){
                  this.certificateTypeName = element.certID;
                 // console.log(this.certificateTypeName,'this.certificateTypeName id')
                }
              });
            }
            
          }
          // this.commonConstants.certificateList.forEach((e: any) => {
            
          //   if(this.certificateName == e.certName)
          //   {
              
          //    // console.log('e cert name ',e.certID)
          //     this.certificateTypeName =e.certID
          //   }
          // });
           console.log('this.certificateName for ',this.certificateTypeName)
          // console.log('this.certificateName length ',this.certificateTypeName.length)
          // console.log(this.permanentAddr[0].villageWard, 'Secretariat', 'permanent');
          
          
        
        //  this.VillageId=  this.permanentAddr[0].villageWard, 'Secretariat', 'permanent';
          
          
          // if(this.presentAddr?.length > 0) {
          //  this.getByEntitycode(this.presentAddr[0]?.district, 'District', 'present');
          //  this.getByEntitycode(this.presentAddr[0]?.mandalMunicipality, 'Mandal', 'present');
          //  this.getByEntitycode(this.presentAddr[0]?.villageWard, 'Secretariat', 'present');
          // }
          // if(this.permanentAddr?.length > 0) {
          //   this.getByEntitycode(this.permanentAddr[0]?.district, 'District', 'permanent');
          //    this.getByEntitycode(this.permanentAddr[0]?.mandalMunicipality, 'Mandal', 'permanent');
          //    this.getByEntitycode(this.permanentAddr[0]?.villageWard, 'Secretariat', 'permanent');
          // }    

            // this.presentAddr[0].district=null;
            // this.presentAddr[0].mandalMunicipality=null;
            // this.commonFormAddedFields.presentMandal=null;
            // this.commonFormAddedFields.presentDistrict=null;
            const presentAddrObservables = [];
            const permanentAddrObservables = [];
          
          if(this.presentAddr?.length > 0) {
            presentAddrObservables.push(this.getByEntitycode(this.presentAddr[0]?.district, 'District', 'present'))
            presentAddrObservables.push(this.getByEntitycode(this.presentAddr[0]?.mandalMunicipality, 'Mandal', 'present'))
            presentAddrObservables.push(this.getByEntitycode(this.presentAddr[0]?.villageWard, 'Secretariat', 'present'))
            forkJoin(presentAddrObservables).subscribe(()=>{

              if(this.certificateName === 'IntegratedCertificate-SuoMoto' && this.presentAddr?.length > 0 && this.presentAddr[0]?.addressType==='present') {
              
                if(this.presentAddr[0]?.villageWard){
                  if(!this.presentAddr[0]?.district || !this.presentAddr[0]?.mandalMunicipality){
                    this.getDistMandalcode(this.presentAddr[0]?.villageWard, 'present');
                  }
                }
             }
             if(this.certificateName === 'IntegratedCertificate-SuoMoto') {
              this.commonService.getRequest(this.commonConstants.getParentCodeByChildCode + 'Secretariat&EntityCode='+ this.basicInfo.applicantInfo?.addresses[0].villageWard +'&LanguageCode=EN-IN').subscribe({
                next: (responseData:any) => { 
                  this.loggedInMandalCode = responseData.result[0]?.id;
                  this.revenueVillagesForSumoto();
                },
                error: (error) => {
                  console.log('client side', error);
                }
              }); 
             }
            })
          }



           if(this.permanentAddr?.length > 0) {
            permanentAddrObservables.push(this.getByEntitycode(this.permanentAddr[0]?.district, 'District', 'permanent'))
            permanentAddrObservables.push(this.getByEntitycode(this.permanentAddr[0]?.mandalMunicipality, 'Mandal', 'permanent'))
            permanentAddrObservables.push( this.getByEntitycode(this.permanentAddr[0]?.villageWard, 'Secretariat', 'permanent'))
             forkJoin(permanentAddrObservables).subscribe(()=>{
                  if(this.certificateName === 'IntegratedCertificate-SuoMoto' && this.permanentAddr?.length > 0 && this.permanentAddr[0]?.addressType==='permanent') {
                if(this.permanentAddr[0]?.villageWard){
                  if(!this.permanentAddr[0]?.district || !this.permanentAddr[0]?.mandalMunicipality){
                    this.getDistMandalcode(this.permanentAddr[0]?.villageWard, 'permanent');
                  }
                }
              }
             })
           }   

         
          
          
        

          // console.log("caste",this.basicInfo.applicantInfo?.caste,'CastCategory');

          if (this.basicInfo?.applicantInfo?.aadhaarNumber?.length > 12) {
            this.decryptAadharNum(this.basicInfo?.applicantInfo?.aadhaarNumber)
          } else {
             this.maskAadhar(this.basicInfo?.applicantInfo?.aadhaarNumber)
          }
          
         
          // this.commonFormAddedFields.aadharNo  =  this.basicInfo?.aadhaarNumber
          this.getStatusOfService(item);
        //  this.getTaskDetails();
          // pensions
          if(this.verificationDocLabel) {
            this.verificationDocLabel.nativeElement.innerText = 'No File Choosen';
          }
          // pensions
        } else if(typeof(responseData?.result) === 'string') {
          this.receivedRequestionId = '';
          alert(responseData?.result);
        } else {
          this.receivedRequestionId = '';
          this.basicInfo = [];
          this.certificateInfo = [];
          this.fileList = [];
        }

        if(this.certificateName == 'IntegratedCertificate-SuoMoto') {
          this.commonformview.ngOnInit();
        }

        if(this.certificateName == 'IntegratedCertificate-SuoMoto' && this.role == 'Revenue Inspector') {
          this.sumotoFormControlsForRI.casteforsumotoRI.patchValue(this.certificateInfo.suoMotoApproverData?.vroData?.caste ? this.certificateInfo?.suoMotoApproverData?.vroData?.caste : null);
          this.sumotoFormControlsForRI.fathernameforsumotoRI.patchValue(this.certificateInfo.suoMotoApproverData?.vroData?.relationName ? this.certificateInfo?.suoMotoApproverData?.vroData?.relationName : null);
          if(this.certificateInfo?.suoMotoApproverData?.vroData?.caste) {
            this.getSubCaste(this.certificateInfo?.suoMotoApproverData?.vroData?.caste, 'RI-onload');
          }
          else {
            this.getSubCaste(this.certificateInfo?.castCategory, 'RI-onload');
          }
          this.sumotoFormControlsForRI.mobileforsumotoRI.patchValue(this.certificateInfo.suoMotoApproverData?.vroData?.mobilenumber ? this.certificateInfo.suoMotoApproverData?.vroData?.mobilenumber : null);
          this.sumotoFormControlsForRI.revenuevillageforsumotoRI.patchValue(this.certificateInfo.suoMotoApproverData?.vroData?.revenuevillage ? this.certificateInfo.suoMotoApproverData?.vroData?.revenuevillage : null);
          this.sumotoFormControlsForRI.emailforsumotoRI.patchValue(this.certificateInfo.suoMotoApproverData?.vroData?.email ? this.certificateInfo.suoMotoApproverData?.vroData?.email :  null);
          this.sumotoFormControlsForRI.religionforsumotoRI.patchValue(this.certificateInfo.suoMotoApproverData?.vroData?.religion ? this.certificateInfo.suoMotoApproverData?.vroData?.religion : null);
          this.sumotoFormControlsForRI.qualificationforsumotoRI.patchValue(this.certificateInfo.suoMotoApproverData?.vroData?.qualification ? this.certificateInfo.suoMotoApproverData?.vroData?.qualification : null);
          // this.sumotoFormControlsForRI.sumotoverificationDocforRI.patchValue(null);
          this.sumotoFormControlsForRI.maritalStatusforsumotoRI.patchValue(this.certificateInfo.suoMotoApproverData?.vroData?.maritalStatus ? this.certificateInfo.suoMotoApproverData?.vroData?.maritalStatus : null);
          this.sumotoFormControlsForRI.statusofhouseholdRI.patchValue(this.certificateInfo.suoMotoApproverData?.vroData?.headOfHouseHold == 'Deceasedvro' ? 'Deceasedri' : ((this.certificateInfo?.suoMotoApproverData?.vroData?.headOfHouseHold === 'Alivevro') ? 'Aliveri' : null))
          if(this.certificateInfo.suoMotoApproverData?.vroData?.birthDate) {
            let date = this.certificateInfo.suoMotoApproverData?.vroData?.birthDate?.includes('T') ? this.datePipe.transform((this.certificateInfo.suoMotoApproverData?.vroData?.birthDate)?.split('T')[0], 'dd-MM-yyyy') : this.certificateInfo.suoMotoApproverData?.vroData?.birthDate;
            this.sumotoFormControlsForRI.dobforsumotoRI.patchValue(date);
          }
          else {
            let date = this.basicInfo?.applicantInfo?.birthDate?.includes('T') ? this.datePipe.transform((this.basicInfo?.applicantInfo?.birthDate)?.split('T')[0], 'dd-MM-yyyy') : this.basicInfo?.applicantInfo?.birthDate;
            this.sumotoFormControlsForRI.dobforsumotoRI.patchValue(date);
          }
        }
        if(this.certificateName == 'IntegratedCertificate-SuoMoto' && this.role == 'Tahsildar') {
          this.sumotoFormControlsForTahsildar.casteforsumotoTahsildar.patchValue(this.certificateInfo.suoMotoApproverData?.riData?.caste ? this.certificateInfo.suoMotoApproverData?.riData?.caste : null);
          if(this.certificateInfo.suoMotoApproverData?.riData?.caste) {
            this.getSubCaste(this.certificateInfo?.suoMotoApproverData?.riData?.caste, 'RI-onloadteh');
          }
          else {
            this.getSubCaste( this.certificateInfo?.castCategory, 'RI-onloadteh');
          }
          this.sumotoFormControlsForTahsildar.fathernameforsumotoTahsildar.patchValue(this.certificateInfo?.suoMotoApproverData?.riData?.relationName ? this.certificateInfo?.suoMotoApproverData?.riData?.relationName : null);
          this.sumotoFormControlsForTahsildar.mobileforsumotoTahsildar.patchValue(this.certificateInfo.suoMotoApproverData?.riData?.mobilenumber ? this.certificateInfo.suoMotoApproverData?.riData?.mobilenumber : null);
          this.sumotoFormControlsForTahsildar.revenuevillageforsumotoTahsildar.patchValue(this.certificateInfo.suoMotoApproverData?.riData?.revenuevillage ? this.certificateInfo.suoMotoApproverData?.riData?.revenuevillage : null);
          this.sumotoFormControlsForTahsildar.emailforsumotoTahsildar.patchValue(this.certificateInfo.suoMotoApproverData?.riData?.email ? this.certificateInfo.suoMotoApproverData?.riData?.email : null);
          this.sumotoFormControlsForTahsildar.religionforsumotoTahsildar.patchValue(this.certificateInfo.suoMotoApproverData?.riData?.religion ? this.certificateInfo.suoMotoApproverData?.riData?.religion : null);
          this.sumotoFormControlsForTahsildar.qualificationforsumotoTahsildar.patchValue(this.certificateInfo.suoMotoApproverData?.riData?.qualification ? this.certificateInfo.suoMotoApproverData?.riData?.qualification : null);
          this.sumotoFormControlsForTahsildar.maritalStatusforsumotoTahsildar.patchValue(this.certificateInfo?.suoMotoApproverData?.riData?.maritalStatus ? this.certificateInfo?.suoMotoApproverData?.riData?.maritalStatus : null);
          this.sumotoFormControlsForTahsildar.statusofhouseholdTEH.patchValue(this.certificateInfo?.suoMotoApproverData?.riData?.headOfHouseHold == 'Deceasedri' ? 'Deceasedteh' : (this.certificateInfo?.suoMotoApproverData?.riData?.headOfHouseHold == 'Aliveri') ? 'Aliveteh' : null);
          if(this.certificateInfo.suoMotoApproverData?.riData?.birthDate) {
            let date = this.certificateInfo.suoMotoApproverData?.riData?.birthDate?.includes('T') ? this.datePipe.transform((this.certificateInfo.suoMotoApproverData?.riData?.birthDate)?.split('T')[0], 'dd-MM-yyyy') : this.certificateInfo.suoMotoApproverData?.riData?.birthDate;
            this.sumotoFormControlsForTahsildar.dobforsumotoTahsildar.patchValue(date);
          }
          else {
            let date = this.basicInfo?.applicantInfo?.birthDate?.includes('T') ? this.datePipe.transform((this.basicInfo?.applicantInfo?.birthDate)?.split('T')[0], 'dd-MM-yyyy') : this.basicInfo?.applicantInfo?.birthDate
            this.sumotoFormControlsForTahsildar.dobforsumotoTahsildar.patchValue(date);
          }
          //this.sumotoFormControlsForTahsildar.dobforsumotoTahsildar.patchValue(this.commonService.dobFormatDate(this.certificateInfo.suoMotoApproverData?.riData?.riBirthDate));
        }


         // console.log(responseData);
      },
      error: (error) => {
        this.basicInfo = [];
        this.certificateInfo = [];
        this.fileList = [];
        console.log('client side', error);
      },
      

  });

  }
  fileData: any = {};
  updatesumotoFile(nameofdoc: string,action: any, actionCode: any,targetModal: any,confirmationOTPModal: any, from?:any) {
    
    if(nameofdoc == 'sumotoverificationDoc') {
      this.fileData = {
        FileList : [
          {
            documentType: 'sumotoverificationDoc',
            blobRef: this.sumotoverificationDocBlob.blobRef,
            fileName: this.sumotoverificationDocBlob.fileName
          }
        ],
        RequisitionId: this.sumotoid
      }
    }
    
    if(nameofdoc == 'sumotoverificationDocforRI') {
      if(this.sumotoverificationDocforRILabel.nativeElement.innerText != 'No File Choosen') {
        this.fileData = {
          FileList : [
            {
              documentType: 'sumotoverificationDocforRI',
              blobRef: this.sumotoverificationDocforRIBlob?.blobRef,
              fileName: this.sumotoverificationDocforRIBlob?.fileName
            }
          ],
          RequisitionId: this.sumotoid
        }
        console.log(this.fileData, "if")

      }
      else {
        this.fileData = {
          FileList : [
            {
              documentType: 'sumotoverificationDocforRI',
              blobRef: this.sumotoverificationDocforRIBlob?.blobRef,
              fileName: null
            }
          ],
          RequisitionId: this.sumotoid
        }
        console.log(this.fileData, "else")
      }
    }
    else if(nameofdoc == 'sumotoverificationDocforTahsildar') {
      if(this.sumotoverificationDocforTahsildarLabel.nativeElement.innerText != 'No File Choosen') {
        this.fileData = {
          FileList : [
            {
              documentType: 'sumotoverificationDocforTahsildar',
              blobRef: this.sumotoverificationDocforTahsildarBlob?.blobRef,
              fileName: this.sumotoverificationDocforTahsildarBlob?.fileName
            }
          ],
          RequisitionId: this.sumotoid
        }
      }
      else {
        this.fileData = {
          FileList : [
            {
              documentType: 'sumotoverificationDocforTahsildar',
              blobRef: this.sumotoverificationDocforTahsildarBlob?.blobRef,
              fileName: null
            }
          ],
          RequisitionId: this.sumotoid
        }
      }
    } 
    this.commonService.postRequest(this.commonConstants.updateFileUpload,this.fileData).subscribe({
      next: (resData:any) => { 

        if(resData.result?.updateResult !== 'Success') {
            this.commonService.commonErrorPopup('Alert', 'An error occured while updating file list in the requisition.', 'md');
          } else {
            this.updatebuttonflag = true;
            this.updatebuttonflagforRI = true;
            this.updatebuttonflagforTahsildar = true;
            this.approveRejectNew(action, actionCode,targetModal,confirmationOTPModal,from);
          }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
 

  getSubCaste(caste?: any, from?: any) {
    this.updatebuttonflag = false;
    this.updatebuttonflagforRI = false;
    let districtCode = this.sumotocasteInfo.addresses[0].district;
    let mandalCode = this.sumotocasteInfo.addresses[0].mandalMunicipality;
    
    if(!districtCode){
       districtCode=this.commonFormAddedFields?.presentDistrictCode
    }
    if(!mandalCode){
      mandalCode= this.commonFormAddedFields.presentMandalCode
   }


    
    if(this.certificateName == 'IntegratedCertificate-SuoMoto' && (this.role == 'Village Revenue Officer' || this.role == 'Ward Revenue Secretary')) {
      this.commonService.getRequest(this.commonConstants.getDistrictWiseCasteData + districtCode + '&MandalCode='+ mandalCode +'&CasteCategory=' + this.sumotoFormControls.caste.value?.replace('-','')).subscribe({
        next: (responseData:any) => { 
          if(responseData.result?.isSuccess) {
            let data: any = responseData.result?.subcasteList;
            this.subCasteData = data.sort((a:any,b:any) => (a > b) ? 1 : -1);
         } else {
          this.subCasteData = [];
         }
        },
        error: (error) => {
          this.subCasteData  = [];
          console.log('client side', error);
        }
      })
    }
    else if(this.certificateName == 'IntegratedCertificate-SuoMoto' && (this.role == 'Revenue Inspector' || this.role == 'Tahsildar')) {
      let casteValue;
      if (from == 'RI-onload') {
        casteValue = caste?.replace('-','');
      } 
      else if(from == 'RI-onloadteh') {
        casteValue = caste?.replace('-', '');
      }
      else {
        if(this.role == 'Revenue Inspector') {
          casteValue = this.sumotoFormControlsForRI.casteforsumotoRI.value?.replace('-','');
        }
       else if(this.role == 'Tahsildar') {
        casteValue = this.sumotoFormControlsForTahsildar.casteforsumotoTahsildar.value?.replace('-','');
        }
      }
      this.commonService.getRequest(this.commonConstants.getDistrictWiseCasteData + districtCode + '&MandalCode='+ mandalCode +'&CasteCategory=' + casteValue).subscribe({
        next: (responseData:any) => { 
          if(responseData.result?.isSuccess) {
            let data: any = responseData.result?.subcasteList;
            this.subCasteData = data.sort((a:any,b:any) => (a > b) ? 1 : -1);
            if (from == 'RI-onload') {
              this.sumotoFormControlsForRI.subCasteforsumotoRI.patchValue(this.certificateInfo.suoMotoApproverData?.vroData?.subcaste ? this.certificateInfo.suoMotoApproverData?.vroData?.subcaste : null);
            }
            else if(from == 'RI-onloadteh') {
              this.sumotoFormControlsForTahsildar.subCasteforsumotoTahsildar.patchValue(this.certificateInfo.suoMotoApproverData?.riData?.subcaste ? this.certificateInfo.suoMotoApproverData?.riData?.subcaste : null);
            }
         } else {
          this.subCasteData = [];
         }
        },
        error: (error) => {
          this.subCasteData  = [];
          console.log('client side', error);
        }
      })
    }
    if(caste == 'oncasteChange') {
      this.subCasteData = [];
      if(this.certificateName == 'IntegratedCertificate-SuoMoto' && this.role == 'Revenue Inspector' ) {
        this.sumotoFormControlsForRI.subCasteforsumotoRI.patchValue(null);
      } else if(this.certificateName == 'IntegratedCertificate-SuoMoto' && this.role == 'Tahsildar'){
        this.sumotoFormControlsForTahsildar.subCasteforsumotoTahsildar.patchValue(null)
      }
      else if(this.certificateName == 'IntegratedCertificate-SuoMoto' && (this.role == 'Village Revenue Officer' || this.role == 'Ward Revenue Secretary')) {
        this.sumotoFormControls.subCaste.patchValue(null)
      }
     }  

  }

  getReligions() {
    this.commonService.getRequest(this.commonConstants.getReligions).subscribe({
      next: (responseData: any) => { 
        this.religions =  this.commonService.sortData(responseData.result);
        this.commonService.setMasterData('religions', this.religions);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  getQualifications() {
    this.commonService.getRequest(this.commonConstants.getQualifications).subscribe({
      next: (responseData: any) => { 
        this.qualifications =  this.commonService.sortData(responseData.result);
        this.commonService.setMasterData('qualifications', this.qualifications);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }


  decryptAadharNum(aadhar : string, from?: any){
    // console.log(aadhar,'aadhar')
    this.commonService
      .getRequest(this.commonConstants.cryptoDecryptValue + encodeURIComponent(aadhar))
      .subscribe({
        next: (responseData: any) => {
          console.log(responseData,aadhar, 'aadhar decrypt value respose data');

          if(this.certificateName == 'IntegratedCertificate-SuoMoto') {
            this.getCitizenInfo(responseData.result.rawValue);
          }

          // this.getCitizenInfo(responseData.result.rawValue);
          if(from === 'otp') {
            this.aadharUID = this.commonService.RSADecrypt(responseData.result.rawValue);
            this.maskAadhar(this.aadharUID);
          } else {
            this.commonFormAddedFields.aadharNum  = this.commonService.RSADecrypt(responseData.result.rawValue);
          }
         // console.log(this.commonFormAddedFields.aadharNum,'this.commonFormAddedFields.aadharNo')
          // console.log(this.commonFormAddedFields,'commonFormAddedFields')
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
    }

    getCitizenInfo(aadharNumber?: any) {
      let postData: any = {};
      postData.aadhaarNumber = aadharNumber
      this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, postData, 'citizenInfo').subscribe((data: any) => {
        this.commonService.sumotodata = data.result[0];
    })
  }


    getByEntitycode(entityCode: any, entityType:any, addrType?: any): any {
      return this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode + '&EntityType=' + entityType).pipe(
        tap((responseData: any) => { 
          
            let name =  responseData.result[0]?.name;
            //console.log(entityType,responseData.result[0]?.name,'response names')
            if (entityType === 'District' && addrType === "present") {
              this.commonFormAddedFields.presentDistrict = name;
            }else if (entityType === 'Mandal' && addrType === "present") {
              this.commonFormAddedFields.presentMandal = name; 
            } else if (entityType === 'Secretariat' && addrType === "present") {
              this.commonFormAddedFields.presentVillage = name;
            } else if (entityType === 'District' && addrType === "permanent") {
              this.commonFormAddedFields.permanentDistrict = name;
            }
             else  if (entityType === 'Mandal' && addrType === "permanent") {
              this.commonFormAddedFields.permanentMandal = name;
              
            } 
    
            else if (entityType === 'Secretariat' && addrType === "permanent") {
              this.commonFormAddedFields.permanentVillage = name;
              
            this.VillageId = responseData.result[0]?.id;
            // console.log("villageId",this.VillageId);
              if(this.departmentCode === 'CS' && !this.isFpShopApplication){
                this.getClusterDetails();
              }
            
            } 
            
          },
         
          
        
       ) );
      }

  // getByEntitycode(entityCode: any, entityType:any, addrType?: any): any {
  // return this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode + '&EntityType=' + entityType).subscribe({  
  //   next: (responseData: any) => { 
      
  //       let name =  responseData.result[0]?.name;
  //       //console.log(entityType,responseData.result[0]?.name,'response names')
  //       if (entityType === 'District' && addrType === "present") {
  //         this.commonFormAddedFields.presentDistrict = name;
  //       }else if (entityType === 'Mandal' && addrType === "present") {
  //         this.commonFormAddedFields.presentMandal = name; 
  //       } else if (entityType === 'Secretariat' && addrType === "present") {
  //         this.commonFormAddedFields.presentVillage = name;
  //       } else if (entityType === 'District' && addrType === "permanent") {
  //         this.commonFormAddedFields.permanentDistrict = name;
  //       }
  //        else  if (entityType === 'Mandal' && addrType === "permanent") {
  //         this.commonFormAddedFields.permanentMandal = name;
          
  //       } 

  //       else if (entityType === 'Secretariat' && addrType === "permanent") {
  //         this.commonFormAddedFields.permanentVillage = name;
          
  //       this.VillageId = responseData.result[0]?.id;
  //       // console.log("villageId",this.VillageId);
  //         if(this.departmentCode === 'CS' && !this.isFpShopApplication){
  //           this.getClusterDetails();
  //         }
        
  //       } 
  //       console.log('calling ethtity',this.commonFormAddedFields)
        
  //     },
  //     error: (error) => {
  //       console.log('client side', error);
  //     },
      
    
  // });
  // }



  getDistMandalcode(sectrateCode:any,addrType:any){
   this.commonService.getRequest(this.commonConstants.getDistMandalcode+sectrateCode).subscribe(
      (response:any)=>{
        if (addrType === "permanent") {
        this.commonFormAddedFields.permanentMandal =  !this.commonFormAddedFields.permanentMandal ? response?.result?.getDetails?.mandalName: this.commonFormAddedFields.permanentMandal ;
        this.commonFormAddedFields.permanentDistrict =  !this.commonFormAddedFields.permanentDistrict ?response?.result?.getDetails?.districtName  : this.commonFormAddedFields.permanentDistrict;
        }
        if (addrType === "present") {
        this.commonFormAddedFields.presentMandal =  !this.commonFormAddedFields.presentMandal ?  response.result?.getDetails?.mandalName : this.commonFormAddedFields.presentMandal ;
        this.commonFormAddedFields.presentDistrict = !this.commonFormAddedFields.presentDistrict ? response?.result?.getDetails?.districtName  : this.commonFormAddedFields.presentDistrict ;
        this.commonFormAddedFields.presentMandalCode =  !this.commonFormAddedFields.presentMandalCode ?  response.result?.getDetails?.lgdMandalCode : this.commonFormAddedFields.presentMandalCode ;
        this.commonFormAddedFields.presentDistrictCode = !this.commonFormAddedFields.presentDistrictCode ? response?.result?.getDetails?.lgdDistCode  : this.commonFormAddedFields.presentDistrictCode ;
      }

      })
    
  }




  getMandalCode() {
    let locationCode: any = localStorage.getItem('locationCode');
    this.commonService.getRequest(this.commonConstants.getParentCodeByChildCode + 'Secretariat&EntityCode='+ locationCode +'&LanguageCode=EN-IN').subscribe({
      next: (responseData:any) => { 
        this.loggedInMandalCode = responseData.result[0]?.id;
        this.revenueVillagesForSumoto();
      },
      error: (error) => {
        console.log('client side', error);
      }
    }); 
  }

  revenueVillagesForSumoto() {
    let mandalVillages: any[] = []
    let finalArray: any[] = []
    this.commonService.getRequest(this.commonConstants.getMandalRevenueMandalInEN + this.loggedInMandalCode + '&ParentType=Mandal&LanguageCode=EN-IN').subscribe({
      next: (responseData: any) => { 
        let value  = responseData.result[0]?.entityCode
        this.appVillages = []
        mandalVillages= []
        for (let index = 0; index < responseData.result.length; index++) {
          this.commonService.getRequest(this.commonConstants.getRevenueVillagesInEN + responseData.result[index].entityCode + '&ChildEntityType=RevenueVillage&LanguageCode=EN-IN').subscribe({
            next: (res: any) => { 
              mandalVillages.push(this.commonService.sortData(res.result));
              // this.appVillages = this.commonService.sortData(res.result);
                finalArray = []
                if(index == responseData.result.length-1){

                  mandalVillages.forEach(e => {
                    e.forEach((element: any) => {
                      finalArray.push(element)
                    });
                  });
                  this.appVillages = this.commonService.sortData(finalArray); //mandalVillages[0];
                }
            },
            error: (error) => {
              console.log('client side', error);
            }
          });
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  
  sixStepGridData:any=[];
  
  gridDdata=[]
  public tableData: any[] = [];
 EkycResponse:any;
 EkycDescription:any;
ekycErrorMsg: any;
  // get k() { return this.sixstepForm.controls; }
  ekycStatusCall(){
    this.ekycErrorMsg= ""
    this.commonService.getRequest(this.commonConstants.nicEkycStatus+'?RequisitionId='+this.reqId+'&StatusCode='+6).subscribe({
      next:(responseData:any)=>{
        if(responseData?.result?.isSuccess){
          this.EkycResponse=responseData.result?.deptRequisitionWorkFlowStatus?.statusCode;
          this.EkycDescription= responseData.result?.deptRequisitionWorkFlowStatus?.statusDesc;
          console.log(responseData,this.EkycDescription,'EkycDescription');
          console.log(this.EkycResponse,'EkycResponse');
        }else{
          this.EkycResponse = '';
          this.EkycDescription = '';
          console.log(responseData.result?.error,'responseData.result?.error');
          if(responseData.result?.error.length > 1 )
          console.log(responseData.result?.error,'responseData.result?.error');
          {this.ekycErrorMsg= responseData.result?.error}
            this.commonService.commonErrorPopup("Alert",responseData?.result?.error,"md")
        }
        
        
        // alert(respinse);
      }
    })
  }

  getSixStepVAlidationData(){
    // console.log(this.reqId,'getSixStepValidationData')
    this.sixStepGridData = [];
    let postData = {
      RequisitionId : this.reqId
    }
    
    this.commonService.postRequest(this.commonConstants.getSixStepValidationData, postData, true ).subscribe({
      next: (responseData: any) => { 
        if(responseData?.result && responseData?.result.length !==0){
            responseData.result.forEach((element:any) => { 
            element.citizenId=this.commonService.RSADecrypt(element.citizenId);
           });
         }
        this.sixStepGridData = responseData.result
        //  console.log(this.sixStepGridData,'getSixStepValidationData');


          if(this.sixStepGridData.length > 0){
            // console.log("length", this.tableDatas.length);
            // console.log("six length", this.sixStepGridData.length);
            // console.log("length", this.tableDatas.length);
            
            let j=this.tableDatas.length;
            for (var i=j- 1; i >= 0; i--) {
              this.tableDatas.removeAt(i);
          }
          //  console.log("length", this.tableDatas.length);

            this.sixStepGridData.forEach((element:any,index:any) => {
             this.householdId = element.householdId;
            this.createSixstep();
            });
     

            

            if(this.sixStepGridData.length === this.tableDatas.length){
            this.getSixstep();
            }
            // if(this.sixStepGridData.length > 0){
            

            // }
          
            }
      },
      error: (error) => {
       console.log('client side', error);
      }
   });
  }
  // onRadio(event:any,i:any){
  //   console.log(i);
  //   this.radioClick = i;
  // }
  getSixstep(){
    
      this.sixStepGridData.forEach((element:any,i:any) => {

        this.tableDatas.at(i).patchValue({
          name: element.citizenName,
          mobileNo:element.mobileNumber,
          gender:element.gender,
          // relation:element.relation,
          // migration:element.empStatus,
          // income: element.income, 
          govtEmploye:element.empStatus,
          incomeTax:element.incomeTax,
          fourWheeler:element.fourWheeler,
          
          landHolding: Number(element.dryLand +element.wetLand),
          muncipalProperty:element.cdma,
          electricity:element.energyLast6MonthsUnits,   
          statusIncome:'',
          statusLand:'',
          statusElectricity:'',
          statusEmployeee:'',
          statusMunicipal:'',
          statusWheeler:'',



        })

    

      });
    

      // console.log("electricity",this.electricityFlage);

 

    
  }

  
  // this.commonService.getRequest(this.commonConstants.checkSignedCertData + this.reqId + '&DepartmentCode=' + this.departmentCode).subscribe({
  //   next: (responseData: any) => {
  //      console.log(responseData,'emudhra viewandgetCertificate'); 
  //    // this.downloadCertId = responseData.result.certificateData.certificateDetails.docId
  //   //  console.log(responseData.result.certificateData.certificateDetails.docId,'responseData.result.certificateData.certificateDetails.docId')
  //     this.viewDatainPDF()
  //   },
  //   error: (error) => {
  //     this.isSigning = false;
      
  //     console.log('client side', error);
  //   }
  // });


  // postCertificate(){
  //   var sendPostData={
  //     RequisitionId: this.receivedRequestionId,
  //     Department: this.departmentCode,
  //     CertificateType : this.certificateTypeName
  //   }
  //   //console.log(sendPostData,'post data')
  //   this.commonService.postRequest(this.commonConstants.postforApprovedCertificate, sendPostData).subscribe({
  //     next: (responseData:any) => { 
  //      console.log(responseData,'emudhra postCertificate');
  //      // console.log(responseData.result.certificateId,'CertificateId');
  //     //  if (responseData.status === 5) {
  //        this.getCertificate(responseData.result.certificateId,this.departmentCode)
  //     //  }else{
  //     //   const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
  //     //   modalRef.componentInstance.message = responseData.result;
  //     //  }
  //     },
  //     error: (error) => {
  //       this.isSigning = false;
        
  //       console.log('client side', error);
  //     }
  //   });
  // }


postCertificate(){
    var sendPostData={
      RequisitionId: this.receivedRequestionId,
      Department: this.departmentCode,
      CertificateType : this.certificateTypeName
    }
    //console.log(sendPostData,'post data')

    // this.commonService.getRequest(this.commonConstants.checkSignedCertData + this.reqId + '&DepartmentCode=' + this.departmentCode).subscribe({
    // next: (responseData: any) => {
    //    console.log(responseData,'emudhra viewandgetCertificate'); 
        // if(responseData.CertificateStatus)
        let API :any;
        if(this.isFpShopApplication){
           API=  this.commonConstants.generateCertificateForCivilSupplies
        }else{
           API=  this.commonConstants.postforApprovedCertificate
        }
        this.commonService.postRequest(API, sendPostData).subscribe({
          next: (responseData:any) => { 
          //  console.log(responseData,'emudhra postCertificate');
           // console.log(responseData.result.certificateId,'CertificateId');
          //  if (responseData.status === 5) {
            if(responseData?.result?.isSuccess) {  
              this.getCertificate(responseData.result.certificateId,this.departmentCode)
             } else {
               const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
               modalRef.componentInstance.title = "Alert";
               modalRef.componentInstance.message = responseData.result.error != undefined || null ? responseData.result.error : 'Error Occurs. Please try Again';
             }
          //  }else{
          //   const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          //   modalRef.componentInstance.message = responseData.result;
          //  }
          },
          error: (error) => {
            this.isSigning = false;
            
            console.log('client side', error);
          }
        });
  //   },
  //   error: (error) => {
  //     this.isSigning = false;
      
  //     console.log('client side', error);
  //   }
  // });
    
  }

  viewandgetCertificate(){
    // console.log(this.departmentCode,'this.departmentCode for get certificate ')
    this.commonService.getRequest(this.commonConstants.checkSignedCertData + this.reqId + '&DepartmentCode=' + this.departmentCode).subscribe({
      next: (responseData: any) => {
        //  console.log(responseData,'emudhra viewandgetCertificate'); 
        if(responseData.result?.isSuccess == false || responseData.result?.error != null){
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = "Sign and Approve";
          modalRef.componentInstance.message = responseData.result.error != undefined || null ? responseData.result.error : 'Error Occurs. Please try Again';
        }
       // this.downloadCertId = responseData.result.certificateData.certificateDetails.docId
      //  console.log(responseData.result.certificateData.certificateDetails.docId,'responseData.result.certificateData.certificateDetails.docId')
        else {
          this.viewDatainPDF()
        }
      },
      error: (error) => {
        this.isSigning = false;
        
        console.log('client side', error);
      }
    });
  }

  viewDatainPDF() {
    let isSigned: any;
    if(this.departmentCode === 'HF' || this.certificateName === 'AddressCertificateForAadharEnrollment' || this.certificateName === 'AddressCertificateForAadharEnrollment@1' || this.certificateName === 'AddressCertificateForAadharEnrollment@2' || this.certificateName === 'AddressCertificateForAadharEnrollment@3') {
      isSigned = '&IsSigned=false';
    } else if(this.certificateName == 'IntegratedCertificate' || 
    this.certificateName == 'IntegratedCertificate-RDO' ||
    this.certificateName == 'IncomeCertificate' ) {
      isSigned = '&IsSigned=false';
    } else {
      isSigned = '';
    }

    this.commonService.getRequest(this.commonConstants.getSignedCertificateData + this.reqId + '&DepartmentCode=' + this.departmentCode + isSigned).subscribe({
      next: (responseData: any) => {
        console.log(responseData,'emudhra viewDatainPDF'); 
      //  console.log('responseData.result.fileContents',responseData)
        let fileInfo =  responseData.result.fileContents;
        const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
        modalRef.componentInstance.sourceFile = fileInfo;
        modalRef.componentInstance.fileName = this.downloadCertId;
        modalRef.componentInstance.showPrintDownload = true;
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  pdfPageCount = "1";
//generatecertifictaedata 3parametere
  getCertificate(certificateId: any,departmentName: string) {
    // console.log('certificateId',certificateId,departmentName)

      this.commonService.getRequest(this.commonConstants.checkSignedCertData + this.reqId + '&DepartmentCode=' + this.departmentCode).subscribe({
        next: (responseData: any) => {
          // console.log(responseData,responseData.result.certificateData.status,'emudhra viewandgetCertificate'); 
          if(responseData.result?.isSuccess == false || responseData.result?.error != null){
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = "Sign and Approve";
            modalRef.componentInstance.message = responseData.result.error != undefined || null ? responseData.result.error : 'Error Occurs. Please try Again';
          } else {
              if(responseData.result.certificateData.status == 0){
                this.commonService.getRequest(this.commonConstants.getforApprovedCertificate + certificateId + '&Department=' + departmentName).subscribe({
                  next: (responseData: any) => {
                    
                      // if (responseData.status === 5) {
                      //  console.log(responseData,'get data for showing certificate respose data');
                    if(responseData?.result?.isSuccess) {                         
                      this.downloadCertId  = responseData.result.fileName;  //certificateId;
                      // this.pdfPageCount = responseData.result.pageCount; 
                      if( responseData.result.pageCount > 1){
                        for (let index = 0; index < responseData.result.pageCount; index++) {
                          var countVal = index +1
                          if(index == 0){
                            
                            this.pdfPageCount   = countVal.toString()
                          }else{
                            this.pdfPageCount = this.pdfPageCount +',' + countVal.toString()
                          }
                          
                          
                        }
                      }
                      console.log(this.pdfPageCount,'this.pdfPageCount',responseData.result.pageCount)
                      this.isApproveExist = false;
                      this.commonService.getRequest(this.commonConstants.checkSignedCertData + this.reqId + '&DepartmentCode=' + this.departmentCode).subscribe({
                        next: (responseData: any) => {
                          //  console.log(responseData,'emudhra viewandgetCertificate'); 
                          if(responseData.result?.isSuccess == false || responseData.result?.error != null){
                            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                            modalRef.componentInstance.title = "Sign and Approve";
                            modalRef.componentInstance.message = responseData.result.error != undefined || null ? responseData.result.error : 'Error Occurs. Please try Again';
                          }
                          if(responseData.result.certificateData.status == 1 && this.departmentCode !== 'HF' && this.certificateName !== 'AddressCertificateForAadharEnrollment' && this.certificateName !== 'AddressCertificateForAadharEnrollment@1' && this.certificateName !== 'AddressCertificateForAadharEnrollment@2' && this.certificateName !== 'AddressCertificateForAadharEnrollment@3'){
                            if(this.certificateName == 'IntegratedCertificate' || 
                                this.certificateName == 'IntegratedCertificate-RDO' ||
                                this.certificateName == 'IncomeCertificate' ) {
                                this.UpdateActionStatus();
                            } else {
                              this.signDoc();
                            }
                          }else if(responseData.result.certificateData.status == 1 &&( this.departmentCode === 'HF' || this.certificateName === 'AddressCertificateForAadharEnrollment' || this.certificateName === 'AddressCertificateForAadharEnrollment@1' || this.certificateName === 'AddressCertificateForAadharEnrollment@2' || this.certificateName === 'AddressCertificateForAadharEnrollment@3')){
                            this.UpdateActionStatus();
                          }else{
                            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                            modalRef.componentInstance.title = "Sign and Approve";
                            modalRef.componentInstance.message = "Error - Certificate was not created.Please Try Again";
                          }
                        
                        },
                        error: (error) => {
                          this.isSigning = false;
                          
                          console.log('client side', error);
                        }
                      });
                      
                    console.log(responseData,'emudhra getCertificate');
                    
                        //this.commonService.downloadPdfFile(responseData.result.fileContents, "Signed_Certificate")
                    // }else{
                    //  const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                    //  modalRef.componentInstance.message = responseData.result;
                    // }
                    } else {
                      const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                      modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
                      modalRef.componentInstance.message = responseData?.result?.error != undefined || null ? responseData?.result?.error : 'Error Occurs. Please try Again';
                    }
                  },
                  error: (error) => {
                    this.isSigning = false;
                    
                    console.log('client side', error);
                  }
                });
              }else{
                const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                modalRef.componentInstance.title = "Sign and Approve";
                modalRef.componentInstance.message = "Error in Generating Certificate.Please Try Again";
              }
            }
          },
          error: (error) => {
            this.isSigning = false;

            console.log('client side', error);
          }
          
      });

    
  }
  getClusterDetails(){
    // this.clusterData=[];

    this.commonService.getRequest(this.commonConstants.getCluster + this.VillageId).subscribe({
      next: (responseData: any) => { 

      this.clusterData = responseData.result;
      console.log("ClusterData",this.clusterData);
      console.log("ClusterData 123",this.clusterData[0].entityCode);

      
    
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getfpshopDetails(){
    // console.log("keyValue",this.keyValue.split(':')[1]);
    
    this.clusterDetails = this.keyValue.split(':')[1].trim();
    // console.log("a",this.clusterDetails);
    
    let obj = 
      {
        cluster_id:(this.keyValue.split(':')[1]).trim(),
        createdBy: this.loggedinuserId,
        requestNumber: this.getId,
    }
     console.log('obj',obj);
    
    this.commonService.postRequest(this.commonConstants.getFpshop,obj).subscribe({
      next: (responseData: any) => { 
                // this.clusterData =  this.commonService.sortData(responseData.result);

      this.fpshopData = responseData.result.cluster_details;
      console.log('this.fpshopData',this.fpshopData)
      console.log("fpshop",  this.fpshopData[0].shop_no);
      if(this.fpshopData){
        this.showFields = true;
      }else{
        this.showFields=false;
      }

      // console.log("fpshop",  this.fpshopData);
      
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getGender() {
    this.commonService.getRequest(this.commonConstants.getGender).subscribe({
      next: (responseData: any) => { 
        this.genders =  this.commonService.sortData(responseData.result);
        // console.log(this.genders);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onChangeRemarks() {
    this.f2.otherRemarks.reset();
    this.f2.remarksReasons.reset();
    this.zillasainikwelfareformforJC.reset();
    this.zillasainikwelfareformforJC2.reset();

    if(this.certificateName === 'IntegratedCertificate-SuoMoto') {
      if(this.role == 'Village Revenue Officer' || this.role == 'Ward Revenue Secretary') {
        this.f2.districtforsuomotovro.patchValue(null);
        this.f2.mandalforsuomotovro.patchValue(null);
        this.f2.secretariatforsuomotovro.patchValue(null);
        this.f2.districtforsuomotovro.clearValidators();
        this.f2.mandalforsuomotovro.clearValidators();
        this.f2.secretariatforsuomotovro.clearValidators();
        this.f2.districtforsuomotovro.updateValueAndValidity();
        this.f2.mandalforsuomotovro.updateValueAndValidity();
        this.f2.secretariatforsuomotovro.updateValueAndValidity();
      }
      else if(this.role === 'Revenue Inspector') {
        this.f2.districtforsuomotori.patchValue(null);
        this.f2.mandalforsuomotori.patchValue(null);
        this.f2.secretariatforsuomotori.patchValue(null);
        this.f2.districtforsuomotori.clearValidators();
        this.f2.mandalforsuomotori.clearValidators();
        this.f2.secretariatforsuomotori.clearValidators();
        this.f2.districtforsuomotori.updateValueAndValidity();
        this.f2.mandalforsuomotori.updateValueAndValidity();
        this.f2.secretariatforsuomotori.updateValueAndValidity();
      }
      else if(this.role === 'Revenue Divisonal Officer') {
        this.f2.districtforsuomotordo.patchValue(null);
        this.f2.mandalforsuomotordo.patchValue(null);
        this.f2.secretariatforsuomotordo.patchValue(null);
        this.f2.districtforsuomotordo.clearValidators();
        this.f2.mandalforsuomotordo.clearValidators();
        this.f2.secretariatforsuomotordo.clearValidators();
        this.f2.districtforsuomotordo.updateValueAndValidity();
        this.f2.mandalforsuomotordo.updateValueAndValidity();
        this.f2.secretariatforsuomotordo.updateValueAndValidity();
      }
      else if(this.role == 'Tahsildar') {
        this.f2.districtforsuomototeh.patchValue(null);
        this.f2.mandalforsuomototeh.patchValue(null)
        this.f2.secretariatforsuomototeh.patchValue(null);
        this.f2.districtforsuomototeh.clearValidators();
        this.f2.mandalforsuomototeh.clearValidators();
        this.f2.secretariatforsuomototeh.clearValidators();
        this.f2.districtforsuomototeh.updateValueAndValidity();
        this.f2.mandalforsuomototeh.updateValueAndValidity();
        this.f2.secretariatforsuomototeh.updateValueAndValidity();
      }
      else if(this.role === 'Collector') {
        this.f2.districtforsuomotojc.patchValue(null);
        this.f2.mandalforsuomotojc.patchValue(null);
        this.f2.secretariatforsuomotojc.patchValue(null);
        this.f2.districtforsuomotojc.clearValidators();
        this.f2.mandalforsuomotojc.clearValidators();
        this.f2.secretariatforsuomotojc.clearValidators();
        this.f2.districtforsuomotojc.updateValueAndValidity();
        this.f2.mandalforsuomotojc.updateValueAndValidity();
        this.f2.secretariatforsuomotojc.updateValueAndValidity();
      }
    }



    if(this.f2.remarks.value !== 'Not Recommended') {
      this.f2.remarksReasons.clearValidators();
      this.f2.remarksReasons.updateValueAndValidity();
      this.f2.docName.clearValidators();
      this.f2.docName.updateValueAndValidity();
    }
    this.onChangeChecklist();
  }

  // onChangeCivilRemarks(data:any){
  //   if (data.target.value === 'Others') {
  //     this.k.otherRemarksCivilSupplies.setValidators([Validators.required]);
  //     this.k.otherRemarksCivilSupplies.updateValueAndValidity();

    
  //   }
  //   else {
  //     this.k.otherRemarksCivilSupplies.clearValidators();
  //     this.k.otherRemarksCivilSupplies.updateValueAndValidity();
    
  //   }

  //   this.k.otherRemarksCivilSupplies.reset();

  // }
  // onClick(i:any){
  //   console.log(i);
    
  // }

  tknList:any;
  crtfctList:any;
  tknSerialNo : any;
  getTokenKeyDetails() {
    this.tknList= []
    this.digiSign
      .tokenList()
      .then((resp: any) => {
        if(resp.success){
          this.tknList = resp.result;
          // console.log(this.tknList,'token details')
          if(this.tknList.length > 0){
            this.getTokenKeyDetailsChange()
          }else{
            //method to say unauthorized
          }
          
        }
      })
      .catch((error: any) => {
        this.utils.catchResponse(error);
      });
  }

  getTokenKeyDetailsChange(): void {
    this.crtfctList = []
    this.tknSerialNo= ""
    let certReq = {
      keyStoreDisplayName:  this.tknList[0]?.keyStoreDisplayName, //JSON.parse(),
      certFilter: {
        commonName: '',
        issuerName: '',
        serialNumber: '',
        isNotExpired: '',
      },
    };

    this.digiSign
      .certificateList(certReq)
      .then((resp: any) => {
        if(resp.success){
          this.crtfctList = resp.result;
          // console.log(this.crtfctList,'certificate list')
          if(this.crtfctList.length > 0){
            this.tknSerialNo = this.crtfctList[0]?.SerialNumber
            // console.log(this.tknSerialNo,'this.tknSerialNo')
          }else{
            //method to say unauthorized
          }
        }
      })
      .catch((error: any) => {
        this.utils.catchResponse(error);
      });
  }

  loadTokens(): void {
    this.digiSign
      .tokenList()
      .then((resp: any) => {
        // console.log(resp.result,'resp.result')
        if(resp.success){
          console.log(resp.result,'resp.result')
          this.tokenList = resp.result;
        }
      })
      .catch((error: any) => {
        this.utils.catchResponse(error);
      });
  }

  tokenChange(): void {
    this.certificateList = [];
    //console.log('tokevalue',this.emudhraformGroup.controls.ddTokens.value)
    this.tokenDetails = this.emudhraformGroup.controls.ddTokens.value
    let certReq = {
      keyStoreDisplayName: JSON.parse(this.tokenDetails).keyStoreDisplayName,
      certFilter: {
        commonName: '',
        issuerName: '',
        serialNumber: '',
        isNotExpired: '',
      },
    };

    this.digiSign
      .certificateList(certReq)
      .then((resp: any) => {
        if(resp.success){
          this.certificateList = resp.result;
          this.eMudhradisplayName =   this.certificateList[0].CommonName //userData.displayName
          // console.log(this.certificateList[0].CommonName ,this.certificateList,'certificateList')
        }
      })
      .catch((error: any) => {
        this.utils.catchResponse(error);
      });
  }

  getCertificateName(){
    this.eMudhradisplayName =   this.emudhraformGroup.controls.ddCertificates.value.CommonName
    console.log(this.eMudhradisplayName,'certificate value')
  }

  btnSignDoc() : any {
    this.eMudhraSubmitted = true;
    if (!this.emudhraformGroup.valid )
      return false;
    else if(this.emudhraformGroup.valid ){ 
       
      // if(this.emudhraformGroup.controls.ddPassword.value === "emudhra"){

      this.isSigning = true;
      //console.log(this.emudhraformGroup.controls.ddCertificates.value,'his.emudhraformGroup.controls.ddCertificates.value.SerialNumber')
      if (this.isProdEnv) {
        if(this.emudhraformGroup.controls.ddTokens.value && JSON.parse(this.emudhraformGroup.controls.ddTokens.value).keyStoreID) {
          let certID = JSON.parse(this.emudhraformGroup.controls.ddTokens.value).keyStoreID;
          this.tknSerialNo =  certID //JSON.parse(certID).SerialNumber
        } else {
          this.tknSerialNo = "";
        }
      } else {
        if(this.emudhraformGroup.controls.ddCertificates.value && this.emudhraformGroup.controls.ddCertificates.value.SerialNumber) {
          let certID = this.emudhraformGroup.controls.ddCertificates.value.SerialNumber;
          this.tknSerialNo =  certID //JSON.parse(certID).SerialNumber
        } else {
          this.tknSerialNo = "";
        }
      }
      this.docGenChange.emit();
      //  console.log(this.tknSerialNo,'tknSerialNo',this.verifytknSerialNo,'this.verifytknSerialNo')
      console.log(this.tknSerialNo.length, this.verifytknSerialNo.length, "tknlength", "verifytkn");
      if((this.tknSerialNo.length > 0 && this.verifytknSerialNo.length > 0)){
      if((this.tknSerialNo === this.verifytknSerialNo)){
        // console.log(this.certificateTypeName,'this.certificateTypeName')
        if(this.yActionCode == 4){
          //if(this.certificateName == '22AModification' || this.certificateName == 'FLineApplication' || this.certificateName === 'ManualAdangalCertificate'){
          if(this.certificateName === 'ManualAdangalCertificate'){
            if(this.certificateTypeName.length > 0 && this.workListCount.length > 0){
              this.updateManualAdangalReq();
              // console.log('working ')
            } else if(this.certificateTypeName.length === 0 && this.workListCount.length > 0) {
              let msg = 'Template is not available to generate the certificate.';
              this.commonService.commonErrorPopup('Alert ' + this.alertDateTimeZone, msg, 'md');
            }
          }else{ 
            this.UpdateActionStatus()
          }
        }else if(this.yActionCode == 3){
          // if(this.departmentCode ==='RD'){
          //   this.gettingHouseHoldId();
          // }else{
          if(this.certificateTypeName.length > 0 && this.workListCount.length > 0 && this.certificateName !== 'ManualAdangalCertificate' && (this.certificateName !== 'IntegratedCertificate-SuoMoto')){
            this.postCertificate()
            // console.log('working ')
          } else if(this.certificateTypeName.length === 0 && this.workListCount.length > 0 && this.certificateName !== 'ManualAdangalCertificate' && (this.certificateName !== 'IntegratedCertificate-SuoMoto')) {
            let msg = 'Template is not available to generate the certificate.';
            this.commonService.commonErrorPopup('Alert ' + this.alertDateTimeZone, msg, 'md');
          } else{
            this.UpdateActionStatus()
          }
        // }
        }
      }else{
        const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        modalRef.componentInstance.message = 'Incorrect serial number. Please Try Again.'
      }

        // else if(this.yActionCode == 1){
          
        //     this.UpdateActionStatus()
        // }
       
       
        
      }else{
        this.alertMessageModal('Access Denied !', 'Yor are not the Authorized user to perform this operation')
        this.mainRef.close();
      }
      
    // }
      

    }
  }

  
  updateManualAdangalReq() {
    let postData: any = {};
    postData.requisitionId = this.receivedRequestionId;
    postData.departmentCode = this.departmentCode;
    postData.rejectionReason = this.f2.remarks.value === 'Not Recommended' ? this.f2.remarksReasons.value : null;

    this.commonService.postRequest(this.commonConstants.updateManualAdangalReq, postData).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.postCertificate();
        } else {
          this.commonService.commonErrorPopup('Alert', 'An error occured while updating the requisition. Please try again.', 'md')
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
 
  emBridgeSignerInput : any;

  signDoc(): void {
    //KeyId
    let reasonM = "Approved";
    if(this.yAction == 'approve'){
      reasonM = 'Approved'
    }
    else if(this.yAction == 'reject'){
      reasonM = 'Rejected'
    }
    let certID = this.emudhraformGroup.controls.ddCertificates.value.KeyId
    // console.log(certID,'certID value') 
    this.keyStorePassphrase = this.emudhraformGroup.controls.ddPassword.value
    this.certificateTokenId = certID //JSON.parse(certID).KeyId
    var certReq = {
      keyStoreDisplayName : JSON.parse(this.tokenDetails).keyStoreDisplayName,
      keyStorePassphrase: this.keyStorePassphrase,
      keyId : this.certificateTokenId,
      TempFolder : "",
      bulkInput : []
    };
    this.emBridgeSignerInput = [];
    this.emBridgeSignerInput=
    {
      Location : "Vijayawada", 
      Reason :  reasonM, //"Testing",
      SignedBy : this.capitalizeWords( this.eMudhradisplayName) , //"eMudhra Limited", 
      PageNo : this.pdfPageCount, //"1",
      Coordinates : "75,170,225,230",  //"75,100,225,160",   //"425,100,545,160",
      AppearanceText :"" ,
      IsAcro6Layer : true  ,
      DigitalTokenId : null,   //JSON.parse(this.tokenDetails).keyStoreDisplayName,
      DigitalCertificateId :  null, //this.certificateTokenId,
      TokenIdFromDevice :   null, //JSON.parse(this.tokenDetails).keyStoreID
              
       
    };
    console.log('certificate and password',certReq)
    //console.log('tokenIdFromDevice',JSON.parse(this.tokenDetails).keyStoreID)
    //certReq.file.tokenIdFromDevice = JSON.parse(this.tokenDetails).keyStoreID;
    this.requsitionID =   this.receivedRequestionId  //"RV-IntegratedCertificate-154"
    this.digiSign
      .signDoc(certReq,this.emBridgeSignerInput,this.requsitionID,this.departmentCode)
      .then((resp : any) => {  
        console.log('Sign resp',resp)     
        // if (resp.status === 5) {
         
          if(resp.success){
            if(resp.result[0]?.status){

              const res = {
                certificateId : resp.result[0]?.id,
                baseFile : resp.result[0]?.signedData
              }

              this.commonService.getRequest(this.commonConstants.checkSignedCertData + this.reqId + '&DepartmentCode=' + this.departmentCode).subscribe({
                next: (responseData: any) => {
                  //  console.log(responseData,'emudhra viewandgetCertificate'); 
                  if(responseData.result?.isSuccess == false || responseData.result?.error != null){
                    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                    modalRef.componentInstance.title = "Sign and Approve";
                    modalRef.componentInstance.message = responseData.result.error != undefined || null ? responseData?.result?.error : 'Error Occurs. Please try Again';
                  } else {
                   if(responseData.result.certificateData.status == 2){
                    if(this.departmentCode === 'RD'){
                      this.gettingHouseHoldId();
                    }else{
                      this.UpdateActionStatus()
                    }
                    
                  }else{
                    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                    modalRef.componentInstance.title = "Sign and Approve";
                    modalRef.componentInstance.message = "Error - Certificate was not Signed Digitally.Please Try Again";
                  }
                }
                },
                error: (error) => {
                  this.isSigning = false;
                  
                  console.log('client side', error);
                }
              });
              // this.UpdateActionStatus()
              //this.updateWorkFlowStatus(this.yAction,this.yActionCode ,this.yTargetModal)
              // this.isSigning = true;
              // this.isApproveExist = true
              // this.mainRef.close();
            this.docSubChange.emit(res);
            }else{
              this.isSigning = false;
              alert('Failed to Sign Document, Please try again !!!')
            }
          }  

        // }else {
        //   const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        //   modalRef.componentInstance.message = resp.result;
        // }  
      })
      .catch((error: any) => {
        
        this.utils.catchResponse(error);
      });
  }
  UpdateActionStatus() {
   // console.log(this.statusUpdatePostData,'this.statusUpdatePostData')
    // const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    //commented empty modal popup const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    console.log(this.statusUpdatePostData,'this.statusUpdatePostData')
    
    this.commonService.putRequest(this.commonConstants.approveReject, this.statusUpdatePostData).subscribe({
      next: (resData:any) => { 

        // if (resData.status === 5) {
          // console.log(resData,'approve and reject');
          let msg:string;
          let responseData = resData.result;
          this.isSigning = true;
            
          
        // console.log(responseData,'responseData message')
          if(typeof(responseData) === 'string') {
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.message = resData.result != undefined || null ? resData.result : 'Error Occurs. Please try Again';
          // console.log(this.yActionCode,this.yAction)
          } else if(this.yAction === 'approve' && typeof(responseData) !== 'string') {
            if (this.yActionCode === 1) {
              //  modalRef.close();
              this.isSigning = false;
              this.isVerifiedOTP = false;
              this.otpModalRef.close();
              //  clearInterval(this.timerRun);
              //  this.isApproveExist = true
              // const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
              const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
              modalRef.componentInstance.message = 'Application forwarded to next level.'
              this.getApprovers(this.taskId);
              // this.getWorklist();
                this.isForwardExist = true
            } else if(this.isApprovalAuthority) {
              const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
              if(this.certificateTypeName.length > 0 && this.departmentCode !=='RD'){
                modalRef.close();
                if(this.certificateName === 'ManualAdangalCertificate') {
                  this.commonMsgPopup('Application Approved successfully..');
                }
              }
              else if(this.certificateTypeName.length > 0 && this.departmentCode ==='RD'){
                if(this.ispostedDepartment === false){
                modalRef.componentInstance.message = 'Application Approved successfully..'
                this.mainRef.close();
                // 24-01-2022 pension
                if(this.departmentCode === "RD"){
                  this.getWorklist();
                }
                // 25-11-2021 pension
                // isApproveExist
                this.isApproveExist = false
              }
              else if(this.ispostedDepartment === true){
                  modalRef.componentInstance.message = 'Already Posted To Departement ..'
                  this.mainRef.close();
                    this.getWorklist();
                  this.isApproveExist = false
                }
              }
              else{
                modalRef.componentInstance.message = 'Application Approved successfully..'
                this.mainRef?.close();
                this.isApproveExist = false
              }
            
              // if(this.departmentCode === "RD"){
              //   // this.getWorklist();
              //   this.gettingHouseHoldId();
              // }
              if(this.isApprovalAuthority && this.receivedRequestionId.length > 0 ){
                if (this.role  === 'Revenue Divisonal Officer' && this.certificateName === 'BirthDeathCertificate') {
                  this.getRevenueMandal();
                }
                
              }
              
              if (this.isApprovalAuthority && this.receivedRequestionId.length > 0 && this.certificateTypeName.length > 0 && this.certificateName !== 'ManualAdangalCertificate' && this.departmentCode !== 'HF'){
                this.isApproveExist = true
              }

              if(this.certificateName !== 'IntegratedCertificate-SuoMoto'){
                this.sendSMS('approve');
              }
              
              if(this.departmentCode === 'HF') {
                this.commonMsgPopup('Application Approved successfully..');
              }
              if(this.departmentCode === "RD"){
                this.isApprovalAuthority = false
                this.isForwardExist = true
                }
            }
          } else if(this.yActionCode == 4 && typeof(responseData) !== 'string') {
            // console.log(this.certificateName,'reject this.certificateName')
            if( this.workListCount.length > 0 && this.certificateName === 'ManualAdangalCertificate') {
              this.isApproveExist = true
              console.log('reject this.certificateName')
            } 
            // else if( this.workListCount.length > 1 ) {
            //   this.isApproveExist = true
            // }
            if(this.departmentCode === 'HF') {
              this.commonMsgPopup('Application rejected successfully..');
            } else {
              const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
              modalRef.componentInstance.message = 'Application rejected successfully.'
            }
        
            this.mainRef?.close();
            if(this.certificateName !== 'IntegratedCertificate-SuoMoto'){
              this.sendSMS('reject');
            }
          }
          // modalRef.componentInstance.reqId = '';
          // modalRef.componentInstance.title = '';
        
    

        // }else {
        //   const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        //   modalRef.componentInstance.message = resData.result;
        // }  
      },
      error: (error) => {
        console.log('client side', error);
      },

      complete:()=>{
        if(this.certificateName ==='RectificationOfResurveyedRecords'){
         this.isVerifiedDocument = undefined;
         this.xyz.remarksByVS.clearValidators();
         this.f2.verificationDoc.clearValidators(); 
         this.f2.verificationDoc.updateValueAndValidity();
         this.xyz.remarksByVS.updateValueAndValidity();
        }
      }

    });

    if(this.yActionCode != 1){
      this.mainRef?.close();
    }
    
  }

  updateincomeReqData(){

  }

  UpdateForwardActionStatus() {
    //console.log(this.statusUpdatePostData,'this.statusUpdatePostData')
    if(this.certificateName ==='IncomeCertificate' || this.certificateName ==='IncomeCertificateForStudents'){
      this.updateincomeReqData()
    }
    this.commonService.putRequest(this.commonConstants.approveReject, this.statusUpdatePostData).subscribe({
      next: (resData:any) => { 
        // if (resData.status === 5) {
          // console.log(resData,'approve and reject');
            let msg:string;
            let responseData = resData.result;
            this.isSigning = true;
          // console.log(typeof(responseData),responseData,'responseData message')
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            if(typeof(responseData) !== 'string') {
                modalRef.componentInstance.message = 'Application forwarded to next level.'
                  this.getApprovers(this.taskId);
                  //this.getWorklist();
                  this.isForwardExist = true
              } else if(typeof(responseData) === 'string') {
                modalRef.componentInstance.message = resData.result != undefined || null ? resData.result : 'Error Occurs. Please try Again';
              // console.log(this.yActionCode,this.yAction)
              } 
            // }else {
            //   const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            //   modalRef.componentInstance.message = resData.result;
            // }  
            
          },
          error: (error) => {
            console.log('client side', error);
          },
          complete:()=>{
            if(this.certificateName ==='RectificationOfResurveyedRecords'){
             this.isVerifiedDocument = undefined;
             this.xyz.remarksByVS.clearValidators();
             this.f2.verificationDoc.clearValidators(); 
             this.f2.verificationDoc.updateValueAndValidity();
             this.xyz.remarksByVS.updateValueAndValidity();
            }
          }
        });
        
    if(this.yActionCode != 1){
      this.mainRef.close();
    }
    
  }
  printData(){
    /* let Pagelink = "about:blank";
    var pwa = window.open(Pagelink, "_new");
    pwa?.document.open();
    pwa?.document.write(this.preparePrintFormat());
    pwa?.document.close(); */

    this.shareSixStepDataForPrint.emit(this.sixStepGridData);
  }
  
  preparePrintFormat(){
    
    const printContent: HTMLElement | null = document.getElementById('__printingFrame');
    let htmlData = "";
    let printdata  = '<div class="container-fluid mt-2"><h1 class="text-center">6 STEP VALIDATION PROFORMA</h1> <br><br><br><div class="row mt2 text-center"><div class="col col-12">';
     printdata  += printContent?.innerHTML;
     let bootsratpURL = '<link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">';  
     htmlData =  "<html><head>"+bootsratpURL+"<style type='text/css' media='print'>@media print { @page { size: auto; margin: 0;} body { margin:1.6cm; } table tr td,table tr th{border-top:1px solid #ddd;} }</style><script>function step1(){\n" +
      "setTimeout('step2()', 2);}\n" +
      "function step2(){window.print();window.close()}\n" +
      "</scri" + "pt> </head><body onload='step1()'>\n" + printdata +"</div></div></div></body></html>";
      //console.log(htmlData)
  
      return htmlData;
  }
  selectType() {
  //  console.log('type', this.f.panchayatType.value);
  }
  updateBirthDeathRequisition() {
    let certificateInfo: any = {};
    certificateInfo.panchayatType = this.f.panchayatType.value;
    certificateInfo.panchayatName = this.f.panchayatName.value;
    certificateInfo.LDisH = this.f.DisHeadNo.value;
    certificateInfo.LDisASO = this.f.DisASONo.value;
    certificateInfo.division = this.divisionName;
    certificateInfo.designation = this.f.panchayatType.value === 'Grampanchayat' ? 'Panchayat Secretary' : this.f.panchayatType.value === 'Municipality' ? 'Municipality Commissioner' : 'Corporation Commissioner';
    certificateInfo.requisitionDate = this.requisitionDate;
    let postData: any = {};
    postData.requisitionId = this.receivedRequestionId;
    postData.departmentCode = this.departmentCode;
    postData.certificateDetails = certificateInfo;
   // console.log('update', postData);
    this.commonService.postRequest(this.commonConstants.updateBirthDeathReq, postData).subscribe({
      next: (responseData:any) => { 
      //  console.log('response from update', responseData)
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  getRevenueMandal() {
    let mandal:any = this.certificateInfo?.incidenceAddress?.mandalMunicipality;
    this.commonService.getRequest(this.commonConstants.getChildCodeByParentCode + mandal).subscribe({
      next: (responseData:any) => { 
      //  console.log('response from update', responseData)
       this.getRevenueDivision(responseData?.result[0]?.entityCode)
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  getRevenueDivision(entityCode: any) {
    this.commonService.getRequest(this.commonConstants.getParentCodeByChildCode + entityCode).subscribe({
      next: (responseData:any) => { 
      //  console.log('response from update', responseData)
       this.divisionName = responseData.result[0]?.name;
       this.updateBirthDeathRequisition();
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  fileUpload(file: any, filePath: string, fileName: string, documentType: string, name:string, label:string)  {
    //this.docList = [];
    const formData = new FormData();
      formData.append('File', file, fileName);
      formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
      // console.log( formData,'formData');
    this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload,formData).subscribe({
      next: (responseData:any) => { 
        if(typeof(responseData.result) !== 'string'){
        let index = this.docList.map((item: any) => item.documentType).indexOf(documentType);
        if (index > -1) {
          this.docList.splice(index, 1);
        }
        if(documentType === 'copyOfLicence') {
          this.copyOfLicenceBlob = {fileName: fileName, documentType: documentType,blobRef : responseData['result'].blobRef}
        } else if(documentType === 'copyOfBankEntry') {
          this.copyOfBankEntryBlob = {fileName: fileName, documentType: documentType,blobRef : responseData['result'].blobRef}
        } else if(documentType === 'fdoDocument') {
          this.fdoDocumentBlob = {fileName: fileName, documentType: documentType,blobRef : responseData['result'].blobRef}
        } else if(documentType === 'loomWorkingConditionDocument') {
          this.loomWorkingConditionBlob = {fileName: fileName, documentType: documentType,blobRef : responseData['result'].blobRef}
        } else if(documentType === 'undertakingFromPHWCDocument') {
          this.undertakingFromPHWCBlob = {fileName: fileName, documentType: documentType,blobRef : responseData['result'].blobRef}
        } else if(documentType === 'productionAtPHWCDocument') {
          this.productionAtPHWCBlob = {fileName: fileName, documentType: documentType,blobRef : responseData['result'].blobRef}
        } else if(documentType === 'wagesProofDocument') {
          this.wagesProofBlob = {fileName: fileName, documentType: documentType,blobRef : responseData['result'].blobRef}
        } else if(documentType === 'undertakingMasterDocument') {
          this.undertakingFromMasterBlob = {fileName: fileName, documentType: documentType,blobRef : responseData['result'].blobRef}
        } else if(documentType === 'yarnPurchaseDocument') {
          this.yarnPurchaseBlob = {fileName: fileName, documentType: documentType,blobRef : responseData['result'].blobRef}
        } else if(documentType === 'fpShopPhoto'){
          this.fPShopPhotoBlob = {fileName: fileName, documentType: documentType,blobRef : responseData['result'].blobRef}
        } else if(documentType === 'sumotoverificationDoc') {
          this.sumotoverificationDocBlob = {fileName: fileName, documentType: documentType,blobRef : responseData['result'].blobRef}
        }
        else if(documentType === 'sumotoverificationDocforRI') {
          this.sumotoverificationDocforRIBlob = {fileName: fileName, documentType: documentType,blobRef : responseData['result'].blobRef}
        }
        else if(documentType === 'sumotoverificationDocforTahsildar') {
          this.sumotoverificationDocforTahsildarBlob = {fileName: fileName, documentType: documentType, blobRef : responseData['result'].blobRef}
          // console.log(this.sumotoverificationDocforTahsildarBlob.blobRef);
        }

        if(documentType !== 'copyOfLicence' && documentType !== 'copyOfBankEntry' && documentType !== 'fdoDocument' && documentType !== 'fpShopPhoto' &&
            documentType !== 'loomWorkingConditionDocument' && documentType !== 'undertakingFromPHWCDocument' &&
            documentType !== 'productionAtPHWCDocument' && documentType !== 'wagesProofDocument' &&
            documentType !== 'undertakingMasterDocument' && documentType !== 'yarnPurchaseDocument') {
          this.docList.push({
            fileName: fileName,
            documentType: documentType,
            blobRef : responseData['result'].blobRef
          })
        }
      }else{
          if(name && label) {
            this.clearFileUpload(name, label);
          }
          this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
        }
        // console.log(this.docList,'docList')
      },
      error: (error) => {
        if(name && label) {
          this.clearFileUpload(name, label);
        }
        console.log('client side', error);
      }
    });
  }
  onDocFileSelected(fileEvent: any) {
    const verificationDocFile: File | null = fileEvent.target.files[0];
    this.verificationDocLabel.nativeElement.innerText =
      fileEvent.target.files.length !== 0
        ? fileEvent.target.files[0]?.name
        : 'No File Choosen';
     if(this.isHouseholdSplitApp) {
      this.f2.verificationDoc.setValidators([
        Validators.required,
        fileExtensionValidator('pdf'),
        fileSizeValidator(fileEvent),
      ]);
    } else {
      this.f2.verificationDoc.setValidators([
        fileExtensionValidator('pdf'),
        fileSizeValidator(fileEvent),
      ]);
    }
    this.isVerifiedDocument=fileEvent;
    this.f2.verificationDoc.updateValueAndValidity();
   if (this.f2.verificationDoc.valid)
      this.fileUpload(
        verificationDocFile,
        this.f2.verificationDoc.value,
        fileEvent.target.files[0]?.name,
        'verificationDoc',
        'verificationDoc',
        'verificationDoc'
      );
  }

  onSupportingDocuments(fileEvent: any) {
      const supportingDocumentsFile: File | null = fileEvent.target.files[0];
      this.supportingDocumentsLabel.nativeElement.innerText =
        fileEvent.target.files.length !== 0
          ? fileEvent.target.files[0]?.name
          : 'No File Choosen';
     
      if (this.f2.supportingDocuments.valid)
        this.fileUpload(
          supportingDocumentsFile,
          this.f2.supportingDocuments.value,
          fileEvent.target.files[0]?.name,
          'supportingDocuments','supportingDocuments','supportingDocumentsLabel'
        );
    }

  onCasteVerificationDocSelected(fileEvent: any) {
    const file: File | null = fileEvent.target.files[0];
    this.casteVerificationDocLabel.nativeElement.innerText =
      fileEvent.target.files.length !== 0
        ? fileEvent.target.files[0]?.name
        : 'No File Choosen';
    if(this.departmentCode === 'RD' && (this.role === 'Village Revenue Officer' || this.role === 'Ward Revenue Secretary')) {   
      this.f2.casteVerificationDoc.setValidators([
        Validators.required,
        fileExtensionValidator('pdf'),
        fileSizeValidator(fileEvent),
      ]);
    } else {
      this.f2.casteVerificationDoc.setValidators([
        fileExtensionValidator('pdf'),
        fileSizeValidator(fileEvent),
      ]);
    }
    this.f2.casteVerificationDoc.updateValueAndValidity();
    if (this.f2.casteVerificationDoc.valid)
      this.fileUpload(
        file,
        this.f2.casteVerificationDoc.value,
        fileEvent.target.files[0]?.name,
        'casteVerificationDoc',
        'casteVerificationDoc',
        'casteVerificationDoc'
      );
  }
  clearFileUpload(name: string, label: string) {
    this.isVerifiedDocument =undefined
    if (label === 'verificationDocLabel') {
      this.f2[name].reset();
      this.verificationDocLabel.nativeElement.innerText = 'No File Choosen';
    } else if (label === 'casteVerificationDocLabel') {
      this.f2[name].reset();
      this.casteVerificationDocLabel.nativeElement.innerText = 'No File Choosen';
    } else if(label === 'copyOfLicenceLabel') {
      this.f3[name].reset();
      this.copyOfLicenceLabel.nativeElement.innerText = 'No File Choosen';
    } else if(label === 'copyOfBankEntryLabel') {
      this.f3[name].reset();
      this.copyOfBankEntryLabel.nativeElement.innerText = 'No File Choosen';
    } else if(label === 'fdoDocumentLabel') {
      this.f2[name].reset();
      this.fdoDocumentLabel.nativeElement.innerText = 'No File Choosen';
    } else if(label === 'loomWorkingConditionLabel') {
      this.f5[name].reset();
      this.loomWorkingConditionLabel.nativeElement.innerText = 'No File Choosen';
    } else if(label === 'undertakingFromPHWCLabel') {
      this.f6[name].reset();
      this.undertakingFromPHWCLabel.nativeElement.innerText = 'No File Choosen';
    } else if(label === 'productionAtPHWCLabel') {
      this.f6[name].reset();
      this.productionAtPHWCLabel.nativeElement.innerText = 'No File Choosen';
    } else if(label === 'wagesProofLabel') {
      this.f6[name].reset();
      this.wagesProofLabel.nativeElement.innerText = 'No File Choosen';
    } else if(label === 'undertakingFromMasterLabel') {
      this.f6[name].reset();
      this.undertakingFromMasterLabel.nativeElement.innerText = 'No File Choosen';
    } else if(label === 'yarnPurchaseLabel') {
      this.f6[name].reset();
      this.yarnPurchaseLabel.nativeElement.innerText = 'No File Choosen';
    }
    else if(label == 'supportingDocumentsLabel') {
        if(this.certificateName === 'IntegratedCertificate-SuoMoto' && (this.role == 'Village Revenue Officer' || this.role == 'Ward Revenue Secretary')) {
        this.f2.supportingDocuments.reset();
        this.supportingDocumentsLabel.nativeElement.innerText = 'No File Choosen';
        this.f2.supportingDocuments.setValidators(Validators.required);
        this.f2.supportingDocuments.updateValueAndValidity();
        }
        else {
          this.f2.supportingDocuments.clearValidators();
          this.f2.supportingDocuments.updateValueAndValidity();
        }
        this.f2.supportingDocuments.updateValueAndValidity();
    }
    else if(label === 'fpShopPhotoLabel') {
      this.showImg = false;
      this.fp.fpShopPhoto.reset();
      this.fpShopPhotoLabel.nativeElement.innerText = 'No File Choosen';
      this.fp.fpShopPhoto.setValidators(Validators.required);
    } else {
      this.fp.fpShopPhoto.clearValidators();
    }
      this.fp.fpShopPhoto.updateValueAndValidity();
    //  if(!this.isHouseholdSplitApp) {
    //     this.f2.verificationDoc.clearValidators();
    //     this.f2.verificationDoc.updateValueAndValidity();
    //   }
      if(label === 'sumotoverificationDocLabel') {
        this.sumotoFormControls.sumotoverificationDoc.reset();
        this.sumotoverificationDocLabel.nativeElement.innerText = 'No File Choosen';
        this.sumotoFormControls.sumotoverificationDoc.setValidators(Validators.required);
      }
      else {
        this.sumotoFormControls.sumotoverificationDoc.clearValidators();
      }
      this.sumotoFormControls.sumotoverificationDoc.updateValueAndValidity();
      if(label === 'sumotoverificationDocforRILabel') {
        //this.fileAutoUploaded = false;
        this.sumotoFormControlsForRI.sumotoverificationDocforRI.reset();
        this.sumotoverificationDocforRILabel.nativeElement.innerText = 'No File Choosen';
        // this.sumotoFormControlsForRI.sumotoverificationDocforRI.setValidators(Validators.required);
      }
      else {
        this.sumotoFormControlsForRI.sumotoverificationDocforRI.clearValidators();
      }

      if(label == 'sumotoverificationDocforTahsildarLabel') {
        this.updatebuttonflagforTahsildar = false;
        this.sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.reset();
        this.sumotoverificationDocforTahsildarLabel.nativeElement.innerText = 'No File Choosen';
        // this.sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.setValidators(Validators.required);
        // this.sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.updateValueAndValidity();
      }
      else {
        this.sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.clearValidators();
        this.sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.updateValueAndValidity();
      }
      this.sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.updateValueAndValidity();

      if(this.certificateName == 'IntegratedCertificate-SuoMoto' && (this.role == 'Village Revenue Officer' || this.role == 'Ward Revenue Secretary' || this.role == 'Revenue Inspector' || this.role == 'Tahsildar' || this.role == 'Revenue Divisonal Officer' || this.role == 'Collector')) {
       if(label == 'verificationDocLabel') {
        this.f2.verificationDoc.reset();
        this.verificationDocLabel.nativeElement.innerText = 'No File Choosen';
        this.f2.verificationDoc.setValidators(Validators.required);
        this.f2.verificationDoc.updateValueAndValidity();
       }
      }
      else if(this.certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && ((this.role == 'Tahsildar' && this.workflowstepcode !== 'TEH-Verify') || this.role == 'Revenue Divisional Officer' || this.role == 'Sub Collector' || this.role == 'Village Revenue Officer' || this.role === 'Ward Revenue Secretary' || this.role === 'Joint Collector')) {
        this.f2.verificationDoc.reset();
        this.verificationDocLabel.nativeElement.innerText = 'No File Choosen';
        this.f2.verificationDoc.setValidators(Validators.required);
        this.f2.verificationDoc.updateValueAndValidity();
        }
      this.f2.verificationDoc.updateValueAndValidity();
       }

  toggleMeridian() {
    this.meridian = !this.meridian;
  }

  get z() { return this.dateTimeformGroup.controls; }
   
  get xyz() { return this.rectificationForm.controls; } 

  get sumotoFormControls() {return this.sumotoForm.controls;}

  get sumotoFormControlsForRI() {return this.sumotoFormForRI.controls;}

  get sumotoFormControlsForTahsildar() {return this.sumotoFormForTahsildar.controls;}

  btngenerateDateTime(){
    this.dateTimeFormSubmitted = true;
    if (!this.dateTimeformGroup.valid )
      return false;
    else { 
     return true;
      // this.isSigning = true;
      // this.docGenChange.emit();
      // this.postCertificate()
      
    }
  }

  sendSMS(action: any, SLA?: any) {
    let templateId: any;
    let message: any;
    if (action === 'approve') {
      //this.sendWhatsAppMsg('approve', this.SLA);
      if(this.departmentCode ==='RD') {
        if(this.basicInfo?.isWhatsAppAvailable === 'Yes') {
           //this.sendWhatsAppMsg('approve', this.SLA);
           }
           templateId = '1007607674498622974';
           message = `Dear Applicant, your request for `+this.serviceName?.toUpperCase()+` has been accepted vide Application No: `+ this.reqId +`. Memo No 1357895/GWS01/2021 ప్రకారం గా మీ ధరకాస్తు పరిశీలించి అర్హతను నిర్దారించిన తదుపరి వచ్చే జూన్ / డిసెంబర్ లో మీకు మీ అర్హత ఆధారం గా లబ్ధి అందజేయబడును - GOVTAP.`;
      } 
      else if(this.certificateName === 'RectificationOfResurveyedRecords') {
        templateId = '1307170495776265178';
        message = `Dear `+this.basicInfo?.applicantInfo?.personNames[0]?.firstName+
        `, Your application `+this.getId+` is `+this.f2.remarks.value+` by Tahsildar with Grievance Type `+this.certificateInfo.grievance.value+` and remarks `+this.xyz.remarksByVS.value+ ` .GOVTAP`;
      }     
      else {
        templateId = '1007006835343762956';
        message = `Dear Applicant, Your Request for `+this.serviceName?.toUpperCase()+` has been accepted Vide Application No:`+ this.reqId +`. Please collect/download your certificate/document from nearby secretariat.-GOVTAP`;
      }
      
    } else if (action === 'reject') {
       if(this.basicInfo?.isWhatsAppAvailable === 'Yes') { 
        //this.sendWhatsAppMsg('reject', this.SLA);
        }
      templateId = '1007476839629054740';
      message = `Dear Applicant, Your Request for `+this.serviceName?.toUpperCase()+` has been rejected Vide Application No:`+ this.reqId +`. Please collect/download your acknowledgement from nearby secretariat. We request you to re-apply.-GOVTAP`
    } else if (action === 'forward') {
      //this.sendWhatsAppMsg('forward', this.SLA);
      templateId = '1007633676695007860';
      message = `Dear Applicant, Your request for `+this.serviceName?.toUpperCase()+` has been approved by ` + this.currentRole +` and moved from ` + this.currentRole +` to ` + this.nextRole +`. Your request would be processed in (`+ SLA +`).-GOVTAP`;
    }
    let postData: any = {};
    postData.phoneNo = [this.basicInfo?.applicantInfo?.mobileNumber];
    postData.templateId = templateId;
    postData.message = message;
    postData.department = this.departmentCode;
    postData.referenceId = this.reqId;
    postData.referenceType = this.certificateName;
    postData.isBulkSend = false;
    postData.userId = this.userIdData;
    //console.log('postdata for SMS', postData);
    this.commonService.postRequest(this.commonConstants.sendSMS, postData).subscribe({
      next: (responseData:any) => { 
        //console.log(responseData,'reponse from SMS');
        if (action === 'forward') {
           this.getWorklist();
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  // sendWhatsAppMsg(action:any, SLA:any) {
  //   let templateType:any;
  //   if(action === 'approve') {
  //     templateType = this.isApproveExist ? 'ApplicationApproved': 'ApplicationApprovedWithoutCert';
  //   } else if(action === 'reject') {
  //     templateType = this.isApproveExist ? 'ApplicationRejectedWithCert' : 'ApplicationRejected';
  //   } else if(action === 'forward') {
  //     templateType = 'ApplicationForwarded';
  //   }
  //   let postData:any = {};
  //   let msgVariables: any = {};
  //   msgVariables.applicantName = this.basicInfo?.applicantInfo?.personNames[0]?.firstName.trim();
  //   msgVariables.referenceId = this.reqId;
  //   msgVariables.transactionId = 'T' + this.reqId;
  //   msgVariables.referenceType = this.serviceName;
  //   msgVariables.slaDays = this.SLA;
  //   msgVariables.mediaURL = 'Text';
  //   msgVariables.previousDesignation = action === 'forward' ? this.currentRole : '';
  //   msgVariables.currentDesignation =  action === 'forward' ? this.nextRole : '';
  //   postData.templateType = templateType;
  //   postData.messageVariables = msgVariables;
  //   postData.phoneNo= this.basicInfo?.applicantInfo?.mobileNumber;
  //   postData.department= this.departmentCode;
  //   postData.isBulkSend= false;
  //   postData.userId= this.userIdData;
   
  //   this.commonService.postRequest(this.commonConstants.sendWhatsAppMsg, postData).subscribe({
  //     next: (responseData:any) => { 
  //       console.log(responseData,'reponse from WhatsAPP');
  //     },
  //     error: (error:any) => {
  //       console.log('client side', error);
  //     }
  //   });
  // }
  
  getSLAData(serviceCode:any){
    this.commonService.getRequest(this.commonConstants.getSLAData + "?ServiceCode="+serviceCode).subscribe({
      next: (responseData: any) => {
        let SLA: any;
        if(responseData?.result != ""){
          if(responseData?.result === "No applicable workflow found" || responseData?.result === "No applicable ServiceConfig found"){
            if(serviceCode === 'UpdateAarogyaSriCard') {
              this.SLA = "24 Hours" ;
            } else {
              this.SLA = "15 Minutes" ;
            }
          }else{
            this.SLA = responseData.result;
          }
         
        }
       },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  getApprovers(taskId: any){
   // console.log('get approvers')
    this.commonService.getRequest(this.commonConstants.getNextUserRole + taskId).subscribe({
      next: (responseData: any) => {
       // console.log(responseData, 'get role');
        let response = responseData.result;
        this.nextRole = response.designationCode;
        this.getSLAData(this.certificateName);
        this.sendSMS('forward', this.SLA);
       },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  getServiceNameFromConfig(code: any) {
      this.commonService.getRequest(this.commonConstants.getServiceList).subscribe({
        next: (responseData: any) => {
           let serviceList = responseData.result;
           this.serviceName = serviceList.find((item: any) => item.serviceCode ===  code)?.serviceName;
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  // pensions final sanctions api
  newApplicationsFinalSanctions() {
    // this.getByEntitycode(this.basicInfo.applicantInfo.addresses[0].district, 'District', 'present');
    // this.getByEntitycode(this.basicInfo.applicantInfo.addresses[0].district, 'Mandal', 'present');
    this.getByEntitycode(this.basicInfo.applicantInfo.addresses[0]?.villageWard, 'Secretariat', 'present').subscribe(()=>{});
    if(this.basicInfo?.applicantInfo?.aadhaarNumber.length > 12){
      this.decryptAadharNum(this.basicInfo?.applicantInfo?.aadhaarNumber);
    }else{
      this.maskAadhar(this.basicInfo?.applicantInfo?.aadhaarNumber)
    }
    

    var pensionTypeForDept = this.certificateInfo.typeOfPension;
    //get pension type for dept
    for(let i=0; i < this.commonConstants.pensionTypeForDeptPostingData.length;i++){
     
      if(this.commonConstants.pensionTypeForDeptPostingData[i].GSWS_SERVICE_CODE === this.certificateInfo.typeOfPension){
        pensionTypeForDept = this.commonConstants.pensionTypeForDeptPostingData[i].RD_SERVICE_CODE;
      }
    }


    let obj = {
      Secretariat_Code:  this.basicInfo.applicantInfo.addresses[0]?.villageWard,
      Secretariat_Name: this.commonFormAddedFields.presentVillage,
      DistrictCode: this.certificateInfo.districtCode,
      DistrictName: this.certificateInfo.districtName,
      MandalCode: this.certificateInfo.mandalCode,
      MandalName: this.certificateInfo.mandalName,
      Response_Message: "Test",
      Habitation_Code: this.certificateInfo.habitationCode,
      HouseholdId: this.houseHoldId,
      User_Id: this.loggedinsecid,
      User_Name: this.loggedinDetails.displayName,
      UID_Number:this.commonService.RSAEncrypt(this.commonFormAddedFields.aadharNum, true),
      //SchemeType:  this.certificateInfo.typeOfPension,
      SchemeType:  pensionTypeForDept,
      Password: "APOnline@12345",
      //PensionType: this.certificateInfo.typeOfPension,
      PensionType: pensionTypeForDept,
      SadaramCode: this.certificateInfo.disabled?this.certificateInfo.disabled:'0',
      ClientIP: this.environmentService.externalUrls.clientIp,
      Application_Id: this.certificateResult.id,
      Transaction_Id: 'T'+this.certificateResult.id,
      Pension_Id: "test",
      Remarks: "NEW_APPLICATIONS_FINAL_SANCTIONS",
      API_URL: this.environmentService.externalUrls.pensionFinalSanction
     // API_URL: "https://uat-abdg.aptonline.in:8090/APOL_APSSP_API_SERVICES/api/APOL_GSWS_SERVICES/NEW_APPLICATIONS_FINAL_SANCTIONS"
    }
   // console.log(obj,"finalsanctionsobj");
    this.commonService.postRequestForWebLand(this.commonConstants.NEW_APPLICATIONS_FINAL_SANCTIONS, obj).subscribe({
      next: (responseData: any) => {
        console.log(responseData, "responseData");
        if(responseData.ReturnCode === "100"){
          this.ispostedDepartment === false;
          // responseData.Data.forEach((element:any) => {
            console.log(responseData.Data[0]?.STATUS_DESCRIPTION,"responseData.Data[0].STATUS_DESCRIPTION");
            console.log(responseData.Data[0]?.PENSIONID,"PensionId");
            this.pensionId=responseData.Data[0]?.PENSIONID;
            console.log(' this.pensionId',  this.pensionId); 
          if(responseData.Data[0].STATUS_CODE == "200")  //"Application Inserted and Pensionid Generated SuccessFully")

          {
           console.log(this.certificateTypeName,'type name postCertificate ')
           const modalRef = this.modalService.open(CommonMsgModalComponent, {

            size: 'md',

            });

            modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;

            modalRef.componentInstance.message = responseData.Data[0].STATUS_DESCRIPTION != undefined || null ? responseData.Data[0].STATUS_DESCRIPTION : 'Error Occurs. Please try Again';
          // if(this.certificateTypeName.length > 0){
          //   console.log(this.certificateTypeName,'type name post on progress ')
          // this.postCertificate()

          // }
          // else{
          // this.UpdateActionStatus()
          console.log(' this.pensionId',  this.pensionId);
         this.updatePensionRequisition();

          // }

          }else if (responseData.Data[0].STATUS_CODE =="103"){
            console.log(' this.pensionId',  this.pensionId);
            // const modalRef = this.modalService.open(CommonMsgModalComponent, {

            // size: 'md',

            // });

            // modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;

            // modalRef.componentInstance.message = responseData.Data[0].STATUS_DESCRIPTION
            this.ispostedDepartment =true;
            //this.UpdateActionStatus();
             this.updatePensionRequisition();
          }
         
          // });

        }else{
          const modalRef = this.modalService.open(CommonMsgModalComponent, {

            size: 'md',

            });

            modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;

            modalRef.componentInstance.message = responseData.ReturnMessage 
        }
        // if(responseData.ReturnCode === "100"){
        
        //   // responseData.Data.forEach((element:any) => {
        //     console.log(responseData.Data[0].STATUS_DESCRIPTION,"responseData.Data[0].STATUS_DESCRIPTION");
        //   if(responseData.Data[0].STATUS_DESCRIPTION ==="Application Inserted and Pensionid Generated SuccessFully")
        //   {
        //   if(this.certificateTypeName.length > 0){
        //   this.postCertificate()
        //   }else{
        //   this.UpdateActionStatus()
        //   }
        //   }else if (responseData.Data[0].STATUS_DESCRIPTION !="Application Inserted and Pensionid Generated SuccessFully"){
        //     const modalRef = this.modalService.open(CommonMsgModalComponent, {
        //     size: 'md',
        //     });
        //     modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
        //     modalRef.componentInstance.message = responseData.Data[0].STATUS_DESCRIPTION
        //   }
        //   // });
        // }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
updatePensionRequisition() {
    console.log(' this.pensionId if',  this.pensionId);
    let certificateInfo: any = {};
    certificateInfo.pensionId =this.pensionId.toString();
    certificateInfo.aadharNumber = this.basicInfo?.applicantInfo?.aadhaarNumber;
    certificateInfo.personName = this.basicInfo?.applicantInfo?.personNames[0]?.firstName;
    let obj ={
      requisitionId: this.certificateResult.id,
      departmentCode: "RD",
      certificateDetails:certificateInfo
    }
  console.log(obj);
  this.commonService.postRequest(this.commonConstants.UpdatePensionCetificateInfo,obj).subscribe({
    next: (responseData: any) => {
      console.log(responseData, 'PensionUpdatePensionCetificateInfo');
      if(responseData.result?.isSuccess) {
        this.statusUpdatePostData.comment = JSON.parse(this.statusUpdatePostData.comment);
        this.statusUpdatePostData.comment.pensionId = this.pensionId;
        this.statusUpdatePostData.comment = JSON.stringify(this.statusUpdatePostData.comment);
        this.UpdateActionStatus();
      } else {
        this.commonService.commonErrorPopup('Alert', 'An error occured while updating the requisition. Please try again.', 'md')
      }
    },
    error: (error) => {
      console.log('client side', error);
    }
  });
}

  // pensions final sanctions api
  gettingHouseHoldId() {
  let postData: any = {};
  postData.aadhaarNumber = this.commonService.RSAEncrypt(this.commonFormAddedFields.aadharNum, true);
  console.log(postData,"postDATA")
   this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, postData, 'citizenInfo').subscribe({
    next: (responseData: any) => { 
      if(responseData?.result &&responseData?.result.length !==0)
       { 
        responseData.result.forEach((item: any) => {
          item.aadhaarNumber = item.aadhaarNumber;
        });
        this.houseHoldId = responseData?.result[0]?.id;
       }
       if(this.houseHoldId){
         this.newApplicationsFinalSanctions();
        }
      
    },
    error: (error) => {
      console.log('client side', error);
    }
});
   
  }

 

  getDocList() {
    console.log(this.f2.remarksReasons.value)
    if(this.certificateName === 'IntegratedCertificate-SuoMoto') {
      if(this.role == 'Village Revenue Officer' || this.role == 'Ward Revenue Secretary') {
        this.f2.districtforsuomotovro.patchValue(null);
        this.f2.mandalforsuomotovro.patchValue(null);
        this.f2.secretariatforsuomotovro.patchValue(null);
        this.f2.districtforsuomotovro.clearValidators();
        this.f2.mandalforsuomotovro.clearValidators();
        this.f2.secretariatforsuomotovro.clearValidators();
        this.f2.districtforsuomotovro.updateValueAndValidity();
        this.f2.mandalforsuomotovro.updateValueAndValidity();
        this.f2.secretariatforsuomotovro.updateValueAndValidity();
      }
      else if(this.role === 'Revenue Inspector') {
        this.f2.districtforsuomotori.patchValue(null);
        this.f2.mandalforsuomotori.patchValue(null);
        this.f2.secretariatforsuomotori.patchValue(null);
        this.f2.districtforsuomotori.clearValidators();
        this.f2.mandalforsuomotori.clearValidators();
        this.f2.secretariatforsuomotori.clearValidators();
        this.f2.districtforsuomotori.updateValueAndValidity();
        this.f2.mandalforsuomotori.updateValueAndValidity();
        this.f2.secretariatforsuomotori.updateValueAndValidity();
      }
      else if(this.role === 'Revenue Divisonal Officer') {
        this.f2.districtforsuomotordo.patchValue(null);
        this.f2.mandalforsuomotordo.patchValue(null);
        this.f2.secretariatforsuomotordo.patchValue(null);
        this.f2.districtforsuomotordo.clearValidators();
        this.f2.mandalforsuomotordo.clearValidators();
        this.f2.secretariatforsuomotordo.clearValidators();
        this.f2.districtforsuomotordo.updateValueAndValidity();
        this.f2.mandalforsuomotordo.updateValueAndValidity();
        this.f2.secretariatforsuomotordo.updateValueAndValidity();
      }
      else if(this.role == 'Tahsildar') {
        this.f2.districtforsuomototeh.patchValue(null);
        this.f2.mandalforsuomototeh.patchValue(null)
        this.f2.secretariatforsuomototeh.patchValue(null);
        this.f2.districtforsuomototeh.clearValidators();
        this.f2.mandalforsuomototeh.clearValidators();
        this.f2.secretariatforsuomototeh.clearValidators();
        this.f2.districtforsuomototeh.updateValueAndValidity();
        this.f2.mandalforsuomototeh.updateValueAndValidity();
        this.f2.secretariatforsuomototeh.updateValueAndValidity();
      }
      else if(this.role === 'Collector') {
        this.f2.districtforsuomotojc.patchValue(null);
        this.f2.mandalforsuomotojc.patchValue(null);
        this.f2.secretariatforsuomotojc.patchValue(null);
        this.f2.districtforsuomotojc.clearValidators();
        this.f2.mandalforsuomotojc.clearValidators();
        this.f2.secretariatforsuomotojc.clearValidators();
        this.f2.districtforsuomotojc.updateValueAndValidity();
        this.f2.mandalforsuomotojc.updateValueAndValidity();
        this.f2.secretariatforsuomotojc.updateValueAndValidity();
      }
    }

    if(this.certificateName === 'IntegratedCertificate-SuoMoto' && this.f2.remarksReasons.value === 'Not Traceable' && (this.role === 'Village Revenue Officer' || this.role === 'Ward Revenue Secretary')) {
      this.sumotoverificationDocLabel.nativeElement.innerText = '';
      this.clearFileUpload('sumotoverificationDoc', 'sumotoverificationDocLabel');
      this.sumotoFormControls.sumotoverificationDoc.reset();
    }
    else if(this.certificateName === 'IntegratedCertificate-SuoMoto' && this.f2.remarksReasons.value === 'Not Traceable' && this.role === 'Revenue Inspector') {
      this.sumotoFormControlsForRI.sumotoverificationDocforRI.reset();
      this.clearFileUpload('sumotoverificationDocforRI', 'sumotoverificationDocforRILabel');
      this.sumotoverificationDocforRILabel.nativeElement.innerText = ''
    }
     else if(this.certificateName === 'IntegratedCertificate-SuoMoto' && this.f2.remarksReasons.value === 'Not Traceable' && this.role === 'Tahsildar') {
      this.sumotoverificationDocforTahsildarLabel.nativeElement.innerText = '';
      this.clearFileUpload('sumotoverificationDocforTahsildar', 'sumotoverificationDocforTahsildarLabel');
      this.sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.reset();
     }

    //if(this.f2.remarksReasons.value !== 'Documents Not Available' || this.f2.remarksReasons.value !== 'Invalid Document submitted') {
    if(this.f2.remarksReasons.value !== 'Documents Not Available')  {
      console.log('if')
      console.log(this.f2.remarksReasons.value,'ccccccccc')

    
      
    if(this.router.url === '/suomoto'){
      if(this.f2.remarksReasons.value === 'Migrated to another place in Andhra Pradesh' && (this.role == 'Village Revenue Officer' || this.role == 'Revenue Inspector' || this.role == 'Tahsildar' || this.role == 'Revenue Divisonal Officer' || this.role === 'Collector')) {
        this.getDistricts();
      }
    }
      this.f2.docName.reset();
     this.f2.docName.clearValidators();
      this.f2.docName.updateValueAndValidity();
    } else {
      this.commonService.getRequest(this.commonConstants.getDocumentList + this.certificateName).subscribe({
        next: (responseData: any) => { 
          console.log('responseData for not doc submitted', responseData.result);
          this.reasonDocList = this.commonService.sortItems(responseData.result, 'documentName');
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    }
  }
  getSelectedDoc(): any {

    console.log(this.f2.remarksReasons.value)
    let selectedDoc: any;
    if (this.f2.remarksReasons.value === 'Documents Not Available') {
      console.log('if 1')
      let docs = this.f2.docName.value;
      let temp: any = [];
      docs.forEach((item: any) => {
       temp.push(item.documentName);
      });
      selectedDoc = temp.join(', ')
    } else {
      selectedDoc = '';
    }
    return selectedDoc;
  }
  verifySignExist() {
    let queryParams: Params = {exist: 'false'};

    this.commonService.getRequest(this.commonConstants.verifySignatureExist + this.userIdData).subscribe({
      next: (responseData: any) => {
        console.log(responseData);
        if(!responseData.result?.exist) {
          this.router.navigate(['/signature-upload'], {
            relativeTo: this.currentRoute,
            queryParams: queryParams,
            queryParamsHandling: '',
          });
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  getVRODashBoardDetails(){
    /* this.dashbardVroCounts = {
      toT_APPS : 0,
      closeD_WITH : 0,
      closeD_BEYOND : 0,
      opeN_WITH : 0,
      opeN_BEYOND : 0,
    }; */
    this.dashbardVroCounts = [];
    this.commonService
    .getRequest( this.commonConstants.getVRODashBoardDetails + 'UserId=' + this.loggedinuserId  + '&AppStatus=""&SlaStatus=""&Flag=S')
    .subscribe({
      next: (responseData: any) => {

        if(responseData?.result){
          this.dashbardVroCounts = responseData.result[0];
          // console.log(responseData.result)
        }

      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  pageChanged(title:any){
    console.log("pageChanged", this.popupPage)
    this.getVRODashBoardDetailsList('', '', '', title);
  }
  getVRODashBoardDetailsList(showPopup:any,appstatus:any,slaStatus:any, title?:any){
    this.transactionID="";
    this.servicesData = [];
    if(title === 'Closed Within SLA') {
      appstatus = 'Closed';
      slaStatus = 'Within-SubSLA';
    } else if(title === 'Closed Beyond SLA') {
      appstatus = 'Closed';
      slaStatus = 'Beyond-SubSLA'
    } else {
      this.popupPage = 1;
      this.dashbardpopupTitle = "";
      if( slaStatus == 'Within-SubSLA') {
        this.dashbardpopupTitle = appstatus+" Within SLA";
      } else if( slaStatus == 'Beyond-SubSLA') {
        this.dashbardpopupTitle = appstatus+" Beyond SLA";
      }
    }
    let inputStr = 'UserId='+ this.loggedinuserId + "&AppStatus="+appstatus+"&SlaStatus="+slaStatus+'&pageSize='+this.popupPageSize + '&pageIndex='+this.popupPage;
    this.commonService
    .getRequest( this.commonConstants.getVRODashBoardDetailsList + inputStr )
    .subscribe({
      next: (responseData: any) => {
        if(responseData?.result){
         // this.servicesData = responseData?.result;
          this.totalPageCount = responseData?.totalPages * this.popupPageSize;
          console.log('this.totalPageCount', this.totalPageCount);
          for (let obj of responseData?.result) {
            obj.serviceFullName = this.getServiceName(obj.requisitioN_TYPE);
            this.servicesData.push(obj);
          }
        }
        if(showPopup !== '') {
          this.showDashBoardPopup(showPopup);
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  
  getServiceListData(){
    this.serviceAllList = [];

    this.commonService
    .getRequest(this.commonConstants.getServiceList )
    .subscribe({
      next: (responseData: any) => {
        console.log(responseData,'responseData abi')
            this.serviceAllList = responseData.result;
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  showDashBoardPopup(content:any){
    // console.log(this.servicesData, "servicesData")
   
    this.modalService
    .open(content, {
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'app-modal-window',
    })
    .result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getStatusOfService(item: any) {
    this.commonService.getRequest(this.commonConstants.getWorkItemHistory + item.ServiceCode + '&WorkItemReference=' + item.requisitionId).subscribe({
      next: (responseData:any) => { 
         let historyItems = responseData?.result?.items;
         if(historyItems?.length > 0 && historyItems[historyItems?.length - 1].actionTaken === 3) {
            this.approveFlag = true;
            this.commonMsgPopup('This service request - ' + item.requisitionId +' is already approved.');
         } else if(historyItems?.length > 0 && historyItems[historyItems?.length - 1].actionTaken === 4) {
            this.rejectFlag = true;
            this.commonMsgPopup('This service request - ' + item.requisitionId +' is already rejected.');
         } else {
          this.approveFlag = false;
          this.rejectFlag = false;
         }
         this.getTaskDetails();
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
   }
   commonMsgPopup(msg: any) {
    const modalRef = this.modalService.open(CommonMsgModalComponent, {size: 'md' });
    modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
    modalRef.componentInstance.message = msg;
    modalRef.result.then((result: any) => {
      this.getWorklist();
    });
   }

   getRejectedReason(serviceCode:any){
     this.remarksReasonsOptionsDynamic = [];
     if(this.departmentCode ==='RD'){
      this.remarksReasonsOptionsDynamic =  ['Invalid Documents  submitted','Applicant Not Available/Not Traced','Not Eligible as per the Service Request','Incorrect Information Filed'];
    }
    else{
     this.commonService.getRequest(this.commonConstants.remarksReasonsOptionsApi + serviceCode).subscribe({ 
      next: (responseData: any) => { 
        console.log(responseData, "testt")
        if(responseData.result.length === 0 ){
          this.remarksReasonsOptionsDynamic =  ['Documents Not Available','Applicant Not Available/Not Traced','Not Eligible as per the Service Request','Incorrect Information Filed'];
       
        }else{
          responseData.result.forEach((element: any) => {
            let temp: any = {};
            if( element.rejection === 'Invalid Document submitted'){
              temp = "Documents Not Available";
            }else{
              temp = element.rejection;
            }
            this.remarksReasonsOptionsDynamic.push(temp);
          })
        }
      }
    })
   }
  }
  addAccountDetails(mandal:any, district:any) {
    let officerInfo: any = {};
    officerInfo.district = district
    officerInfo.mandal = mandal
    officerInfo.designation = this.role
    officerInfo.name = this.officerData?.userName
    officerInfo.aadharNo = (this.officerData?.aadhaarNo)? this.officerData?.aadhaarNo: '-'
    officerInfo.mobileNo = (this.officerData?.phoneNo)? this.officerData?.phoneNo : '-'
    officerInfo.userId = this.officerData?.userId
    let modalRef: any = this.modalService.open(AccountDetailsPopupComponent, {size: 'lg' });
    modalRef.componentInstance.officerData = officerInfo;
  }
  getOfficerLocations() {
    if(this.role === 'Tahsildar') {
    this.commonService.getRequest(this.commonConstants.getByEntityCodes + '?EntityCode=' + this.officerData?.locationScope?.locationCode + '&LanguageCode=EN-IN&EntityType=REVENUEMANDAL').subscribe({
      next: (responseData: any) => {
        let mandalresult = responseData.result[0];
        this.commonService.getRequest(this.commonConstants.getParentCodeByChildCode + 'REVENUEMandal' + '&EntityCode=' + mandalresult?.entityCode + '&LanguageCode=EN-IN').subscribe({
          next: (responseData: any) => {
            let divisioncode = responseData.result[0].entityCode;
            this.commonService.getRequest(this.commonConstants.getParentCodeByChildCode + 'RevenueDivision' + '&EntityCode=' + divisioncode + '&LanguageCode=EN-IN').subscribe({
              next: (responseData: any) => {
                let district = responseData.result[0].name;
                this.addAccountDetails(mandalresult?.name, district);
              },
              error: (error) => {
                console.log('client side', error);
              }
            });
          },
          error: (error) => {
            console.log('client side', error);
          }
        });
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  } else if(this.role === 'Municipal Commissioner' || this.role === 'Mandal Parishad Development Officers') {
    this.commonService.getRequest(this.commonConstants.getByEntityCodes + '?EntityCode=' + this.officerData?.locationScope?.locationCode + '&LanguageCode=EN-IN&EntityType=MANDAL').subscribe({
      next: (responseData: any) => {
        let mandalresult = responseData.result[0];
        this.commonService.getRequest(this.commonConstants.getParentCodeByChildCode + 'Mandal' + '&EntityCode=' + mandalresult?.entityCode + '&LanguageCode=EN-IN').subscribe({
          next: (responseData: any) => {
            let district = responseData.result[0].name;
            this.addAccountDetails(mandalresult?.name, district);
          },
          error: (error) => {
            console.log('client side', error);
          }
        });
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  }

  IsTidcoSelected(option:any){
    console.log(option,"selectedOptionForHousesite")
    this.isTidcoOptionValue = false;

    if(option === 'TIDCO'){
      this.isTidcoOptionValue = true;
    }

    
  }
  biometricBtn(from?:any): any {
    // this.commonService.getRequest(this.commonConstants.cryptoEncryptValue + encodeURIComponent(this.commonService.RSAEncrypt(this.aadharUID))).subscribe({
      // next: (responseData: any) => {
        // if (responseData.result?.isSuccess) {
        // this.encrtyptedAadhar =  responseData.result?.encryptedValue;
        this.encrtyptedAadhar =  this.commonService.RSAEncrypt(this.aadharUID);
      let postData: any = {};
      postData.autoBiometricId = 0;
      postData.aadharNumber = this.encrtyptedAadhar;
      postData.responseStatus = '';
      postData.userId = this.loggedinuserId;
      this.commonService.postRequestForWebLand(this.commonConstants.getSNoForBiometric, postData).subscribe({
        next: (responseData:any) => { 
        console.log('response from biometric', responseData)
        this.autoBiometricId = responseData?.result?.autoBiometricId;
        if (this.autoBiometricId !== undefined && this.autoBiometricId !== null) {
          this.showBiometricPopup = true;
          // let url: any = 'https://gramawardsachivalayam.ap.gov.in/GSWS/#!/AadhaarAuthentication?uid=' +this.encrtyptedAadhar+ '&SNo=' + this.autoBiometricId;
          // this.windowObjectReference = window.open(url, "BiometricAuthentication", 'popup=1;left=500,top=100,width=700,height=700');
          let url: any = this.environmentService.externalUrls.biometricRedirectionPage + encodeURIComponent(this.encrtyptedAadhar) +'&SNo='+ this.autoBiometricId +'&DoEkyc=false&ReqPhoto=false';
          this.windowObjectReference = window.open(url, "BiometricAuthentication", 'popup=1;left=500,top=100,width=780,height=700');
        } else {
          this.showBiometricPopup = false;
        }
      },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }
    
    checkBiometricStatus() {
      this.windowObjectReference.close();
      let postData: any = {};
      postData.autoBiometricId = this.autoBiometricId;
      postData.aadharNumber = this.encrtyptedAadhar;
      postData.responseStatus = '';
      postData.userId = this.loggedinuserId;
      this.commonService.postRequestForWebLand(this.commonConstants.getSNoForBiometric, postData).subscribe({
        next: (responseData:any) => { 
       // console.log('response from biometric', responseData)
       // this.autoBiometricId = responseData?.result?.autoBiometricId;
       let status = responseData?.result?.responseStatus;
       if (status === 'Success') {
        this.showBiometricPopup = true;
       // this.commonService.commonErrorPopup('Alert', 'Biometric Authentication Success.', 'md');
        this.UpdateActionStatus();
       } else {
        this.showBiometricPopup = false;
        this.commonService.commonErrorPopup('Alert', 'Biometric Authentication failed. Please try again!!', 'md');
      }
       
      },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }
    getCSCaste(caste:any): any {
      if(caste === "BC-A") {
        return "11";
      } else if(caste === "BC-B") {
        return "12";
      } else if(caste === "BC-C") {
        return "13";
      } else if(caste === "BC-D") {
        return "14";
      } else if(caste === "BC-E") {
        return "15";
      } else if(caste === "OC") {
        return "1";
      } else if(caste === "Others") {
        return "16";
      } else if(caste === "SC") {
        return "3";
      } else if (caste === "ST") {
        return "4";
      }
    }
    // getCSGender(gender: any): any {
    //   if(gender === 'MALE' || gender === 'Male' || gender === 'male'){
    //      return "1";
    //   }
    //   else if(gender ===  'FEMALE' || gender ===  'Female' || gender ===  'female'  ){
    //     return "2";
    //   }
    // }
    // getCSOccupation(occupation: any): any {
    //   if(occupation === "AGRICULTURE LABOUR"){
    //     return "1";               
    //   }
    //   else if(occupation === "AUTO/TAXI/BUS/PRIVATE DRIVER"){
    //     return "54";               
    //   }
    //   else if(occupation === "BARBER"){
        
    //     return "15";
    //   }
    //    else if(occupation === "BEGGAR"){
    //     return "55"
    //   }
    //   else if(occupation === "BLACKSMITH"){
    //     return "16"
    //   }
    //   else if(occupation === "BUSINESS"){
    //     return "2"
    //   }
    //   else if(occupation === "COBBLER"){
    //     return"17"
    //   }
    //   else if(occupation === "CONSTRUCTION TECHNICIAN"){
    //     occType= "18"
    //   }
    //   else if(occupation === "CONTRACTOR"){
    //     occType= "19"
    //   }
    //   else if(occupation === "COOK"){
    //     occType= "20"
    //   }
    //    else if(occupation === "DHOBI / WASHERMAN"){
    //     occType= "21"
    //   }
    //   else if(occupation === "ENTERPRENEOUR"){
    //     occType= "22"
    //   }
    //   else if(occupation === "EX-NRI"){
    //     occType= "23"
    //   }
    //   else if(occupation === "FACTORY OWNER/ INDUSTRIALIST "){
    //     occType= "24"
    //   }
    //   else if(occupation === "FARMER/ZAMINDAR/Small/ Marginal Farmer"){
    //     occType= "3"
    //   }
    //   else if(occupation === "FISHERMAN"){
    //     occType= "25"
    //   }
    //   else if(occupation === "FREEDOM FIGHTER PENSIONER"){
    //     occType= "26"
    //   }
    //    else if(occupation === "GARDENER "){
    //     occType= "27"
    //   }
    //   else if(occupation === "GOLDSMITH"){
    //     occType= "28"
    //   }
    //   else if(occupation === "GOVT CASUAL LABOURER"){
    //     occType= "29"
    //   }
    //   else if(occupation === "GOVT PENSIONER"){
    //     occType= "30"
    //   }
    //   else if(occupation === "GOVT. SERVICE"){
    //     occType= "4"
    //   }
    //   else if(occupation === "HANDICRAFT ARTISANS/Technician"){
    //     occType= "31"
    //   }
      
    //   else if(occupation === "HOUSE MAID"){
    //     occType= "32"
    //   }
    //   else if(occupation === "HOUSE Welse ifE"){
    //     occType= "5"
    //   }
    //   else if(occupation === "HOUSE/SHOP LENDER"){
    //     occType= "33"
    //   }
    //   else if(occupation === "JOURNALIST/ MEDIA EMPLOYEE"){
    //     occType= "34"
    //   }
    //   else if(occupation === "LABOUR/Cooli/Porter/ Hand Card Puller"){
    //     occType= "6"
    //   }
      
    //   else if(occupation === "LOCAL VENDOR"){
    //     occType= "56"
    //   }
    //   else if(occupation === "MECHANIC"){
    //     occType= "35"
    //   }
    //   else if(occupation === "MONEY LENDER"){
    //     occType= "57"
    //   }
    //   else if(occupation === "NRI"){
    //     occType= "36"
    //   }
    //   else if(occupation === "OTHER"){
    //     occType= "14"
    //   }
    
    //   else if(occupation === "PHOTOGRAPHER"){
    //     occType= "37"
    //   }
    //   else if(occupation === "PRIEST"){
    //     occType= "38"
    //   }
    //   else if(occupation === "PRIVATE CONTRACT LABOURER"){
    //     occType= "39"
    //   }
    //   else if(occupation === "PRIVATE SERVICE"){
    //     occType= "7"
    //   }
    //   else if(occupation === "PROFESSIONALIST"){
    //     occType= "40"
    //   }
      
    //   else if(occupation === "PSU EMPLOYEE"){
    //     occType= "41"
    //   }
    //   else if(occupation === "REAL ESTATE"){
    //     occType= "42"
    //   }
    //   else if(occupation === "RELIGIOUS EMPLOYEE"){
    //     occType= "43"
    //   }
    //   else if(occupation === "RETD. PSU EMPLOYEE"){
    //     occType= "44"
    //   }
    //   else if(occupation === "RETD. PVT. COMPANY"){
    //     occType= "45"
    //   }
    //   else if(occupation === "RETIRED"){
    //     occType= "8"
    //   }
    //   else if(occupation === "RICKSHAW PULLER"){
    //     occType= "46"
    //   }
    //   else if(occupation === "SALESMAN"){
    //     occType= "47"
    //   }
    //   else if(occupation === "SCAVENGER"){
    //     occType= "58"
    //   }
    //   else if(occupation === "SECURITY / WATCHMAN"){
    //     occType= "48"
    //   }
    //   else if(occupation === "SELF EMPLOYED"){
    //     occType= "9"
    //   }
    //   else if(occupation === "SHOP KEEPER"){
    //     occType= "10"
    //   }
    //   else if(occupation === "STUDENT"){
    //     occType= "49"
    //   }
    //   else if(occupation === "TAILOR"){
    //     occType= "50"
    //   }
    //   else if(occupation === "TEACHER"){
    //     occType= "11"
    //   }
    //   else if(occupation === "TRANSPORT LABOURER"){
    //     occType= "51"
    //   }
    //   else if(occupation === "TRANSPORT OWNER"){
    //     occType= "52"
    //   }
    //   else if(occupation === "UNEMPLOYED"){
    //     occType= "12"
    //   }
    //   else if(occupation === "WEAVER"){
    //     occType= "53"
    //   }
    // }
    uhidGenerationForHealthCard(telugunames: string[]){
      let postData: any = {};
      postData.Requisitionid=this.receivedRequestionId;     
      this.commonService.postRequest(this.commonConstants.generateAarogyaSriUHID, postData, true).subscribe({
    next: (responseData: any) => {
      if(responseData?.result?.isSuccess === true){
        this.UHID=responseData?.result?.result;       
        this.updateRequisition(telugunames, this.UHID);
      }else{
        this.commonService.commonErrorPopup('Alert', 'An error occured while processing.Please try one more time', 'md');
      }
     
        },
    error: (error) => {     
      
      console.log('client side', error);
    }
  });
    }
    saveUHID(confirmationOTPModal: any){
      let postData:any={};
      postData.Requisitionid=this.receivedRequestionId;
      postData.departmentCode='HF';
      postData.uhid=this.UHID;
      this.commonService.postRequest(this.commonConstants.saveUHID,postData).subscribe({
        next: (responseData:any) => { 
          if(responseData.result.isSuccess === true){
            this.updateWorkFlowStatus(this.yAction,this.yActionCode ,this.yTargetModal, confirmationOTPModal)
          }else{
            this.commonService.commonErrorPopup('Alert', 'An error occured while processing.Please try one more time', 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }
    getUpdatedData(data: any){ 
      console.log("called getUpdatedData"); 
      if(data.actionName == "updateNewAarogyasri"){
        console.log("actual called getUpdatedData"); 
        if(this.certificateName === "UpdateAarogyaSriCardForImage"){
          this.updateRequisition(data.telugunames, this.certificateInfo?.uhid);
        }else if(this.certificateName === "AarogyaSriNewCard"){
          this.uhidGenerationForHealthCard(data.telugunames);
        }
      } 
      
    }

    updateRequisition(telugunames: any[], uhid: any){
      let postData: any  = {};
      postData.RequisitionId= this.reqId;;
      postData.famaddress=this.certificateInfo?.familyAddress;
      postData.RequisitionData=this.certificateInfo;  
      postData.RequisitionData?.familyDetails?.forEach((item: any) => {
        item.familyAadharNo = this.commonService.RSAEncrypt(item.familyAadharNo);
      });      
      postData.RequisitionData.uhid=uhid;
      for(var i = 0; i<postData.RequisitionData?.familyDetails.length ; i++){
        let currtelguName: any = telugunames.find(x=> x.familyAadharNo == postData.RequisitionData.familyDetails[i].familyAadharNo);
        if(currtelguName){
          postData.RequisitionData.familyDetails[i].teluguName = currtelguName.telguName;
        }
      }
      this.commonService.postRequest(this.commonConstants.UpdateRequisitionDataForArogyasri,postData)
      .subscribe({
        next: (responseData: any) => {
          if(responseData.result.isSuccess ===  true){
            this.updateWorkFlowStatus(this.aarogyaSriAction,this.aarogyaSriActionCode ,this.aarogySriTargetModal, this.aarogyaSriconfirmationOTPModal)
          }else{
            const errmsg = responseData.result.error != undefined || null ? responseData.result.error : 'Error Occurs. Please try Again'; 
            this.commonService.commonErrorPopup('Alert', errmsg, 'md'); 
          }
          console.log(responseData,'Aarogya sri update health card respose data');
          console.log(responseData.status, 'stats');      
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
       
    } 
    checkRuralUrban() {
      this.commonService
        .getRequest(this.commonConstants.checkRuralUrban + this.secCode)
        .subscribe({
          next: (responseData: any) => {
            if(responseData.result?.isSuccess) {
              this.isUrban = responseData.result?.ruralUrbanFlag === 'U' ? true : false;
              // For testing purpose commented
              // this.checkSecretariat();
            } else {
              this.commonService.commonErrorPopup('Alert', 'An error occured while getting rural/urban details.', 'md');
            }
          },
          error: (error) => {
            console.log('client side', error);
          },
        });
    }
    validExt = '';    
    @ViewChild('fpShopPhotoLabel')
    fpShopPhotoLabel!: ElementRef ;
    showImg:Boolean=false;
    isPhoto:Boolean=false;
    onfpShopPhotoSelected(fileEvent:any){
        const fPPhotoFile: File | null = fileEvent.target.files[0];
        const fileExtensionType = fileEvent.target.files[0]?.name.split('.').pop();
        console.log(fileExtensionType);
        this.imgSrc = fileExtensionType=== 'pdf' ? this.imgSrc="" : this.imgSrc;
        if (fileExtensionType.toLowerCase() === 'jpg') {
          this.validExt = 'jpg';
        } else if (fileExtensionType.toLowerCase() === 'jpeg') {
          this.validExt = 'jpeg';
        } else{
          this.validExt = 'png';
        }
        this.fpShopPhotoLabel.nativeElement.innerText = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name: 'No File Choosen';
        this.fpShopForm.controls['fpShopPhoto'].setValidators([
         fileExtensionValidator(this.validExt), fileSizeValidator(fileEvent)
        ])
        this.fpShopForm.controls['fpShopPhoto'].updateValueAndValidity();
        if (this.fpShopForm.controls.fpShopPhoto.valid){
          this.onFileChange(fileEvent)
          this.fileUpload(fPPhotoFile,this.fpShopForm.controls.fpShopPhoto.value,fileEvent.target.files[0].name,'fpShopPhoto', 'fpShopPhoto', 'fpShopPhotoLabel');
      }
      else if(fileExtensionType=== 'pdf'){
        this.imgSrc=""
        this.fp.fpShopPhoto.patchValue('');
        this.isPhoto  = false;
        this.fpShopPhotoLabel.nativeElement.innerText = 'No File Choosen';
        this.fpShopForm.controls['fpShopPhoto'].setValidators([
          Validators.required
        ]);
        this.fpShopForm.controls['fpShopPhoto'].updateValueAndValidity();
        const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        mdRef.componentInstance.message = 'Please upload image in PNG/JPG/JPEG Format'
       return;
      }
        else{
        this.imgSrc=""
        this.fp.fpShopPhoto.patchValue('');
        this.isPhoto  = false;
        this.fpShopPhotoLabel.nativeElement.innerText = 'No File Choosen';
        this.fpShopForm.controls['fpShopPhoto'].setValidators([
          Validators.required
        ]);
        this.fpShopForm.controls['fpShopPhoto'].updateValueAndValidity();
        const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        mdRef.componentInstance.message = 'File size exceeding 1MB.Please compress the image and reupload!'
       return;
      }   
    
    }
    onFileChange(event: any) {
      this.imgSrc='';
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.imgSrc = e.target.result;
          this.showImg = true;
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    }

//     viewImage(){
//     this.fpShopVROHistory;
//     let blobRef=this.fpShopVROHistory[0].fpShopData.fpShopPhoto.blobRef;
  
//     this.commonService.getRequest(this.commonConstants.viewFile + blobRef).subscribe({
//     next: (responseData: any) => {
//       let fileInfo =  responseData.result.fileContents;
//         this.imgSrc = 'data:image/png;base64,' +fileInfo;
//         this.showImg=true;
      
//     },
//     error: (error) => {
//       console.log('client side', error);
//     }
// });
// }
generateFPShopValidity(confirmationOTPModal:any){
  let postDataForFp: any = {};
  postDataForFp.requisitionId=this.reqId;   
  postDataForFp.existingValidity= this.commonService.formatDate(this.fpShopValiditydate);  
  this.commonService.postRequest(this.commonConstants.generateFPShopValidity , postDataForFp).subscribe({
next: (responseData: any) => {
  if(responseData?.result?.isSuccess === true){
    this.updateWorkFlowStatus(this.yAction,this.yActionCode ,this.yTargetModal, confirmationOTPModal);
  }else{
    this.commonService.commonErrorPopup('Alert', 'An error occured while processing.Please try one more time', 'md');
  }
 
    },
error: (error) => {     
  
  console.log('client side', error);
}
});

}

districtsforsuomoto: any[] = [];

getDistricts() {
  this.commonService.getRequest(this.commonConstants.getCSPDistricts).subscribe({
    next: (responseData: any) => {
      if(responseData.result?.isSuccess) {
        this.districtsforsuomoto = this.commonService.sortItems(responseData.result?.getDistrictDetails, 'districtName');
        console.log(this.districtsforsuomoto);
      } else {
        this.districtsforsuomoto = [];
        this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
      }
    },
    error: (error) => {
      console.log('client side', error);
    }
});
}

mandaloptions: any[] = [];
villageoptions: any[] = [];
districtCode: any;
onDistrictChange() {
  this.mandaloptions = [];
  this.secretariatoptions = [];
  if(this.role  == 'Village Revenue Officer') {
    this.f2.mandalforsuomotovro.patchValue(null);
    this.f2.secretariatforsuomotovro.patchValue(null)
    this.districtCode = this.f2.districtforsuomotovro.value.districtCode;
  }
  else if(this.role == 'Revenue Inspector') {
    this.f2.mandalforsuomotori.patchValue(null)
    this.f2.secretariatforsuomotori.patchValue(null)

    this.districtCode = this.f2.districtforsuomotori.value.districtCode;
  }
  else if(this.role == 'Tahsildar') {
    this.f2.mandalforsuomototeh.patchValue(null)
    this.f2.secretariatforsuomototeh.patchValue(null)

    this.districtCode = this.f2.districtforsuomototeh.value.districtCode;
  }
  else if(this.role == 'Revenue Divisonal Officer') {
    this.f2.mandalforsuomotordo.patchValue(null)
    this.f2.secretariatforsuomotordo.patchValue(null)

    this.districtCode = this.f2.districtforsuomotordo.value.districtCode
  }
  else if(this.role == 'Collector') {
    this.f2.mandalforsuomotojc.patchValue(null)
    this.f2.secretariatforsuomotojc.patchValue(null)

    this.districtCode = this.f2.districtforsuomotojc.value.districtCode
  }
  this.commonService.getRequest(this.commonConstants.getCSPMandalsRuralUrban + '?DistrictCode=' + this.districtCode).subscribe({
    next: (responseData: any) => {
      console.log(responseData,'mandals')
      if(responseData.result?.isSuccess) {
        this.mandaloptions = this.commonService.sortItems(responseData.result?.getMandalDetails, 'mandalName');
      } else {
        this.mandaloptions = [];
        this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
      }
    },
    error: (error) => {
      console.log('client side', error);
    }
});
}

mandalCode: any = [];
secretariatoptions: any = [];

onMandalChange() {
  this.secretariatoptions = [];

  if(this.role  == 'Village Revenue Officer') {
    this.f2.secretariatforsuomotovro.patchValue(null)
    this.mandalCode = this.f2.mandalforsuomotovro.value.mandalCode;
  }
  else if(this.role == 'Revenue Inspector') {
    this.f2.secretariatforsuomotori.patchValue(null)
    this.mandalCode = this.f2.mandalforsuomotori.value.mandalCode;
  }
  else if(this.role == 'Tahsildar') {
    this.f2.secretariatforsuomototeh.patchValue(null)
    this.mandalCode = this.f2.mandalforsuomototeh.value.mandalCode;
  }
  else if(this.role == 'Revenue Divisonal Officer') {
    this.f2.secretariatforsuomotordo.patchValue(null)
    this.mandalCode = this.f2.mandalforsuomotordo.value.mandalCode;
  }
  else if(this.role == 'Collector') {
    this.f2.secretariatforsuomotojc.patchValue(null)
    this.mandalCode = this.f2.mandalforsuomotojc.value.mandalCode;
  }
  this.commonService.getRequest(this.commonConstants.getCSPSecretariat + '?MandalCode=' + this.mandalCode).subscribe({
    next: (responseData: any) => {
      console.log(responseData,'villages')
      if(responseData.result?.isSuccess) {
        this.secretariatoptions = this.commonService.sortItems(responseData.result?.getSecretariatDetails, 'secretariatName');
      } else {
        this.secretariatoptions = [];
        this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
      }
    },
    error: (error) => {
      console.log('client side', error);
    }
});
}

}

