import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Location, formatNumber } from "@angular/common";
import { Router,ActivatedRoute} from "@angular/router";
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';  
import { SwiperOptions } from 'swiper';
import { CommonService } from '../../shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal, NgbModalConfig,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { parseI18nMeta } from '@angular/compiler/src/render3/view/i18n/meta';
import { PoliciesPopupComponent } from '../../shared/policies-popup/policies-popup.component';
import { EnvironmentService } from 'src/shared/environment.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @Output() onLogin: any = new EventEmitter();
  aarogyaSurakshaConceptNoteDownloadInEN: any;
  aarogyaSurakshaConceptNoteDownloadInTE: any;
  loginDisplay: Boolean = false;
  role: string | undefined;
  commonConstants = CommonConstants;
  videoSrc: any;
  cSSid=["Integrated-certificate-box","income-certificate-box","ROR-1B-box","marc-box","computer-box","electricity-box","house-box","pension-box","rice-box","splitting-box"];
  showAadudam: Boolean = true;
  // public configdata: SwiperOptions = {
  //   a11y: { enabled: true },
  //   direction: 'horizontal',
  //   slidesPerView: 4,
  //   keyboard: true,
  //   mousewheel: true,
  //   scrollbar: false,
  //   navigation: true,
  //   pagination: false,
  //   autoplay: {
  //     delay: 2500,
  //     disableOnInteraction: false,
  //     },
  // };

  // public videoSlider: SwiperOptions = {
  //   a11y: { enabled: true },
  //   direction: 'horizontal',
  //   slidesPerView: 2,
  //   keyboard: true,
  //   mousewheel: true,
  //   scrollbar: false,
  //   navigation: true,
  //   pagination: false,
    
  //   autoplay: {
  //     delay: 2500,
  //     disableOnInteraction: false,
  //     }, 
  //   //spaceBetween: 10,        
  // };
  testmonialOptions: OwlOptions = {
    loop: true,
    margin: 25,
    dots: false,
    autoplay: false,
    smartSpeed: 3000,
    autoplayHoverPause: true,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      500: {
        items: 2
      }
    },
    nav: true
  }
  mainOptions: OwlOptions = {
    loop: true,
    margin: 25,
    dots: true,
    autoplay: false,
    smartSpeed: 3000,
    autoplayHoverPause: true,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      }
    },
    nav: true
  }
  videoOptions: OwlOptions = {
    loop: true,
    margin: 25,
    dots: false,
    autoplay: false,
    smartSpeed: 3000,
    autoplayHoverPause: true,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      }
    },
    nav: true
  }
  customOptions: OwlOptions = {
    loop: true,
    margin: 20,
  //  mouseDrag: false,
   // touchDrag: false,
   // pullDrag: false,
    dots: false,
    autoplay: true,
    //navSpeed: 3000,
    smartSpeed: 3000,
    autoplayHoverPause: true,
    navText: ['', ''],
    responsive: {
      0:{
        items:1,
    },
    300:{
        items:2,
    },
    600:{
        items:3
    }
    },
    nav: true
  }
  route: any;
  selectedPage: any = "/home";

  queryParmData:any ={} ; 
  topTenServices: any = [];
  colors:any = ['orange', 'red', 'green', 'blue', 'purple', 'blue', 'purple','orange', 'red', 'green']
  constructor(private authService: MsalService, config: NgbCarouselConfig,picture_slider: NgbCarouselConfig, private commonService: CommonService,location: Location, private currentRoute: ActivatedRoute,
    private modalService: NgbModal, modalConfig: NgbModalConfig, private router: Router, private environmentService: EnvironmentService) { 
    
    modalConfig.backdrop = 'static';
    modalConfig.keyboard = false;
  
    router.events.subscribe(val => {
    
      //console.log("location",location.path())
      
      if (location.path() === "/about-us") {
        this.selectedPage = location.path();
      // } else if(location.path() === '/jagananna-schedule-search') {
      //   this.selectedPage = "/jagananna-schedule-search";
      // }
      }
      else if(location.path() === '/csp-service-report') {
        this.selectedPage = "/csp-service-report";
      } else if(location.path() === '/csp-payment-report') {
        this.selectedPage = "/csp-payment-report";
      } else if(location.path().split("?")[0] === '/apseva-feedback') {
        this.selectedPage = "/apseva-feedback";
      } else if(location.path().split("?")[0] === '/meeseva-feedback') {
        this.selectedPage = "/meeseva-feedback";
      } else if(location.path().split("?")[0] === '/feedback-status') {
        this.selectedPage = "/feedback-status";
      } else if(location.path() === '/save-designations-positions') {
        this.selectedPage = "/save-designations-positions";
      } else if(location.path().split("?")[0] === '/external-payment-gateway') {
        this.selectedPage = "/external-payment-gateway";
        this.queryParmData = location.path().split("?")[1];
     } else if(location.path().split("?")[0] === '/external-payment-response') {
       this.queryParmData = location.path().split("?")[1];
       this.selectedPage = "/external-payment-response";
      } else if(location.path().split("?")[0] === '/Epayment') {
       this.queryParmData = location.path().split("?")[1];
       this.selectedPage = "/Epayment";
      } else if(location.path().split("?")[0] === '/download-file') {
        this.queryParmData = location.path().split("?")[1];
        this.selectedPage = "/download-file";
       } else {
        this.selectedPage = "/home";
      }

      this.aarogyaSurakshaConceptNoteDownloadInEN = this.environmentService.apiUrl + this.commonConstants.aarogyaSurakshaConceptNoteDownloadInEN;
      this.aarogyaSurakshaConceptNoteDownloadInTE = this.environmentService.apiUrl + this.commonConstants.aarogyaSurakshaConceptNoteDownloadInTE;

    });

    // config.interval = 3000;  
    // config.wrap = true;  
    // config.keyboard = false;  
    // config.pauseOnHover = false  ;  
    // config.showNavigationArrows = true;
    // config.showNavigationIndicators = false;

    picture_slider.interval = 3000;  
    picture_slider.wrap = true;  
    picture_slider.keyboard = false;  
    picture_slider.pauseOnHover = true;  
    picture_slider.showNavigationIndicators = false;
    picture_slider.animation = false;
    picture_slider.showNavigationArrows = false;
  }

  ngOnInit(): void {
    this.setLoginDisplay();    
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if(this.loginDisplay) {
      this.getRole();
    }
    if(!this.loginDisplay) {
      this.getTopTenServices();
    }
  }

  images = [
    {id: 1, img: "../assets/images/navarathnalu_logo.png",content:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed doeiudr incididunt utlabore et dolore magna aliqua. Ut enim a minim eniam, quis nostrud exercitation ullamcolaboris nisi ut liquip ex ea cmmodo consequat."},
    {id: 2, img: "../assets/images/navarathnalu_logo.png", content:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed doeiudr incididunt utlabore et dolore magna aliqua. Ut enim a minim eniam, quis nostrud exercitation ullamcolaboris nisi ut liquip ex ea cmmodo consequat."},
    {id: 3, img: "../assets/images/navarathnalu_logo.png",content:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed doeiudr incididunt utlabore et dolore magna aliqua. Ut enim a minim eniam, quis nostrud exercitation ullamcolaboris nisi ut liquip ex ea cmmodo consequat."},
  ];

  scrollToTop(){
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }
  getRole() {
    this.commonService.getRequestWithoutBaseUrl(this.commonConstants.graphAPI).subscribe({
      next: (responseData: any) => { 
        this.role = responseData.jobTitle?.trim();
        // if(this.role === 'Zilla Sainik Welfare Officer'){
        //   this.router.navigate(['/revenue/land-assignment-to-soldiers']);
        // }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  scrollhome() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
}
scrollabus() {
  window.scrollTo({
    top: 600,
    left: 0,
    behavior: 'smooth'
  });
}
  login() {
    this.onLogin.emit();
  }
  goToTop() {
    window.scrollTo(0, 0);
  }
  openVideoPopup(videoModal: any, videoSrc: any){
    this.videoSrc = 'https://gswsattendance.ap.gov.in/PortalUploads/Others/' + videoSrc;
    this.modalService.open(videoModal,  { size: 'lg', centered: true, backdrop: 'static'})
  }
  showPoliciesPopup(type:any){
    console.log('type on open', type);
      let modalRef: any = this.modalService.open(PoliciesPopupComponent, { size: 'lg', centered: true, backdrop: 'static'});
      modalRef.componentInstance.popup = type;
  }
  getTopTenServices() {
    this.commonService.getRequest(this.commonConstants.getTopTenServices).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.topTenServices = responseData.result.servicesRankDetails;
          this.topTenServices = this.topTenServices.map((item: any) => {
            item.toT_REQ = new Intl.NumberFormat("en-IN").format(Number(item.toT_REQ));
            return item;
          });
        } else {
          this.topTenServices = [];
        }
        console.log('top ten services', responseData);
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
}
