import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute,Params } from '@angular/router';

import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest, EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { MenuConstants, MenuConstantsCitizen, MenuConstantsMeeseva } from '../constants/menu.constants';
import { CommonService, WhitespaceValidator } from '../shared/common.service';
import { CommonConstants } from '../constants/common-constants.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnvironmentService } from '../shared/environment.service';
import { LocationStrategy } from '@angular/common';
import { HostListener } from '@angular/core';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'gsws';
  public menuConstants: any = MenuConstants;
  commonConstants = CommonConstants;
  isIframe = false;
  loginDisplay = false;
  menuDisplay = true;
  dbMenuDisplay: Boolean = false;
  menuClass: Boolean = false;
  isDashboard: Boolean = false;
  showHeader: Boolean = false;
  intervalId: any;
  time: any;
  userInfo: any = [];
  userIdData : string = '';
  loginUserName : string = '';
  showUserDetails: Boolean = false;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: any = null;
  sessionMsg: any;
  @ViewChild('sessionModal')
  sessionModal!: ElementRef ;
  counter: any;
  countdownVal:any;
  stepper:any;
  timeoutStart: Boolean = false;
  profileForm: FormGroup;
  submitted: Boolean = false;
  showChallan: Boolean = false;
  showReports: Boolean = false;
  showReportsInDB: Boolean = false;
  showOTSChallan: Boolean = true;
  sourceFile : any;
  role: any;
  count: number = 0;
  userName: any;
  isCivilSuppliesStatus :Boolean=false;
  loggedinUserMobileNo: any;
 // isDocUrl: Boolean = false;
  private readonly _destroying$ = new Subject<void>();
  allowApplication: boolean=false;

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private broadcastService: MsalBroadcastService, private authService: MsalService,
   private router: Router, private activatedRoute: ActivatedRoute, private commonService: CommonService, private modalService: NgbModal, modalConfig: NgbModalConfig,
   private idle: Idle, private keepalive: Keepalive, private formBuilder: FormBuilder, private environmentService: EnvironmentService, private location: LocationStrategy) { 
    
   /* history.pushState(null, null, window.location.href);
   this.location.onPopState(() => {
        history.pushState(null, null, window.location.href);
        this.stepper.previous();
        console.log('Brower back button not allowed !!')
    }); */
    
    modalConfig.backdrop = 'static';
    modalConfig.keyboard = false;
    this.profileForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern),  WhitespaceValidator.whiteSpaceValidate]],
      lastName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern),  WhitespaceValidator.whiteSpaceValidate]],
      mobileNo: ['',[Validators.required]]
    });
     // sets an idle timeout of 5 seconds, for testing purposes.
     idle.setIdle(1800); // 30 min inactivity - 30x60
     // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
     idle.setTimeout(5);
     // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
     idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
     idle.onIdleEnd.subscribe(() => { 
       this.idleState = 'No longer idle.'
      // console.log(this.idleState);
        this.reset();
     });
   
     idle.onTimeout.subscribe(() => {
       this.idleState = 'Timed out!';
       this.timedOut = true;
       this.countdownVal = 60;
       this.modalService.open(this.sessionModal, { size: 'md', centered: true, backdrop: 'static'});
       this.counter = setInterval(() => {
           this.countdownVal--;
           this.sessionMsg = 'Your session is timed out. You will be logged out in ' + this.countdownVal + ' seconds!';
           if (this.countdownVal === 0) {
            this.modalService.dismissAll('logged out');
            this.logout();
           }
       },1000);
      // console.log(this.idleState);
     });
    
     idle.onIdleStart.subscribe(() => {
         this.idleState = 'You\'ve gone idle!'
        // console.log(this.idleState);
     });
    
     idle.onTimeoutWarning.subscribe((countdown) => {
       //console.log('timeout started');
     });
     // sets the ping interval to 15 seconds
     keepalive.interval(15);
     keepalive.onPing.subscribe(() => this.lastPing = new Date());
     let userData = localStorage.getItem('userInfo');
      if(userData && userData !== null) {
        idle.watch()
        this.timedOut = false;
      } else {
        idle.stop();
      }
   }
   get f(){ return this.profileForm.controls; }

  ngOnInit() {
    // let userData = localStorage.getItem('userInfo');
    //   if(userData && userData !== null) {
    //     this.userInfo = JSON.parse(userData);
    //     if(this.userInfo.jobTitle === 'Citizen') {
    //       this.menuConstants = MenuConstantsCitizen;
    //     } else {
    //       this.menuConstants = MenuConstants;
    //     }
    //   }  
    if (
      /^vswsonline.ap.gov.in/.test(
        window && window.location && window.location.hostname
      )
    ) {
      this.showOTSChallan = false;
     }else{
      this.showOTSChallan = true;
    }
    

   
       
    this.router.events.pipe(
      filter(events=>events instanceof NavigationEnd),
      map(item => item = this.activatedRoute),
      map(route => {
        while(route.firstChild) {
          route = route.firstChild;
        }
        console.log('route', route);
        return route;
      })
      )
      .pipe(
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      ).subscribe(item => {
        item.showMenu ? this.menuDisplay = true : this.menuDisplay = false;
        item.showDashboardMenu ? this.isDashboard = true : this.isDashboard = false;
        this.showHeader = item.showHeader ? true: false;
       // this.isDocUrl = item.isDocument ? true : false;
      //  (this.userInfo.jobTitle === 'Digital Assistant')? this.dbMenuDisplay = true : this.dbMenuDisplay = false;
      //   if (this.menuDisplay) {
      //     this.menuClass = true;
      //   } else if (this.userInfo.jobTitle === 'Digital Assistant') {
      //     this.menuClass = true;
      //   } else {
      //     this.menuClass = false;
      //   }
       });
    this.isIframe = window !== window.parent && !window.opener;
    this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setLoginDisplay();
    })
    this.broadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    )
    .subscribe((result: EventMessage) => {
      this.getUserDetails(true);
    });

    this.intervalId = setInterval(() => {
      this.time = new Date();
    }, 1000);
  }

 /*  @HostListener('window:popstate', ['$event'])
  onPopState(event:any) {
 
    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    modalRef.componentInstance.title = 'Alert';
    modalRef.componentInstance.message = 'Back Button Is Disabled !!';

  } */

  login() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      //this.router.navigate(['/revenue/integrated-certificate'])
    } else {
      this.authService.loginRedirect();
    }
  }

  openLogoutModal(logoutModal: any) { // Add log out function here
  this.modalService.open(logoutModal, { size: 'md', centered: true, backdrop: 'static'});
  }
  logout() {
    let userData = localStorage.getItem('userInfo');
    if(userData && userData !== null) {
      this.userInfo = JSON.parse(userData);
      //Call Audit log API for Login
      let loginAuditPayload = {
        userName: this.userInfo.userPrincipalName,
        activity: "Sign-out"
      };
      this.commonService.postRequest(this.commonConstants.SaveLoginLogoutLogAPI, loginAuditPayload).subscribe({
        next: (logData: any) => { 
          localStorage.clear();
          this.authService.logoutRedirect({
            postLogoutRedirectUri: location.protocol+'//'+location.host
          });
        },
        error: (error) => {
          console.log('client side', error);
        }
      });      
    } 
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if(this.loginDisplay) {
      this.getUserDetails(false);
      this.reset();
    }
    //console.log('login', this.loginDisplay);
  }
  ngOnDestroy() {
    clearInterval(this.intervalId);
    clearInterval(this.counter);
}

getCitizenTransactionsCount(userId:any){
 
   this.commonService.getRequest(this.commonConstants.GetCitizenDailyTransactionsCount, '', true).subscribe({
    next: (responseData: any) => {
      localStorage.setItem('isSuccess',responseData.result?.isSuccess);
      let isSuccess = localStorage.getItem('isSuccess');
      console.log(isSuccess, "done");
      if(responseData.result?.isSuccess){
       this.allowApplication = true;
      }
      else {
        this.allowApplication = false;
      // alert('You have already completed ten transactions for today.');
  }
    },
    error: (error:any)=> {  
      console.log('client side', error);
    }
  });
}

getUserDetails(callLogAPI: boolean) {
 
  this.commonService.getRequestWithoutBaseUrl(this.commonConstants.graphAPI).subscribe({
    next: (responseData: any) => { 
      // responseData.jobTitle='Citizen'
      if(responseData.jobTitle === "Citizen") {
        responseData.userPrincipalName = responseData.mail;
        this.getCitizenTransactionsCount(responseData.userPrincipalName);
      }
      if(callLogAPI === true){
        //Call Audit log API for Login
        let loginAuditPayload = {
          userName: responseData.userPrincipalName,
          activity: "Sign-In"
        };
        this.commonService.postRequest(this.commonConstants.SaveLoginLogoutLogAPI, loginAuditPayload).subscribe({
          next: (logData: any) => { 
          },
          error: (error) => {
            console.log('client side', error);
          }
        });
      }
     responseData.jobTitle = responseData.jobTitle?.trim();
   
     localStorage.setItem('userInfo', JSON.stringify(responseData));
     let userData = localStorage.getItem('userInfo');
      this.count = this.count + 1;
      if(userData && userData !== null) {
        this.userInfo = JSON.parse(userData);
        if(this.userInfo){
          if(this.userInfo.userPrincipalName === "12345678-DA@apgsws.onmicrosoft.com"){
            this.isCivilSuppliesStatus=true;
          }else{
            this.isCivilSuppliesStatus=false;
          }
        }
        (this.userInfo.jobTitle === 'Digital Assistant' || this.userInfo.jobTitle === 'Ward Education Secretary'  || this.userInfo.jobTitle === 'Citizen' || this.userInfo.jobTitle === 'Meeseva' || this.userInfo.jobTitle === 'IT Minister')? this.dbMenuDisplay = true : this.dbMenuDisplay = false;
        if (this.menuDisplay) {
          this.menuClass = true;
        } else if (this.userInfo.jobTitle === 'Digital Assistant' || this.userInfo.jobTitle === 'Ward Education Secretary' || this.userInfo.jobTitle === 'Citizen' || this.userInfo.jobTitle === 'Meeseva' || this.userInfo.jobTitle === 'IT Minister') {
          this.menuClass = true;
        } else {
          this.menuClass = false;
        }
      }
      if (this.count === 1) {
        this.getRole();
        if(this.userInfo.jobTitle === 'Citizen') {
          this.menuConstants = MenuConstantsCitizen;
          this.userName = this.userInfo.displayName;
        } else if(this.userInfo.jobTitle === 'Meeseva') {
          this.menuConstants = MenuConstantsMeeseva;
          this.userName = this.userInfo.displayName;
          this.getUserName();
        } else {
          this.menuConstants = MenuConstants;
          this.getUserName();
        }
        // this.menuConstants = this.commonService.sortItems(this.menuConstants, 'module');
        // this.menuConstants = this.menuConstants.map((item: any) => {
        //   item.services = this.commonService.sortData(item.services);
        //   return item;
        // })
        let signatureData = localStorage.getItem('signature_'+this.userInfo.userPrincipalName)
        this.userIdData = this.userInfo.userPrincipalName
        this.loginUserName = this.userInfo.displayName
        if(signatureData && signatureData !== null)
        {
          let data: any = JSON.parse(signatureData);
          let user = 'signature_'+this.userInfo.userPrincipalName;
          this.sourceFile = data;
          // console.log(data , "data")
          // console.log(user , "user")
          // console.log()
          // console.log(this.sourceFile , "source file if")
        }
        else
        { 
          // console.log("else signature")
          if(this.userInfo.jobTitle === 'Tahsildar' || this.userInfo.jobTitle === 'Deputy Tahsildar' || this.userInfo.jobTitle === 'Revenue Divisonal Officer') {
            this.getSignature()
          }
        }
      }
    },
    error: (error) => {
      this.userInfo = [];
      console.log('client side', error);
    }
});
}

getRole() {
  if(this.userInfo.jobTitle === 'Digital Assistant') {
    this.role = 'Panchayat Secretary Grade-VI (DA)';
  } else if (this.userInfo.streetAddress !== null && this.userInfo.streetAddress === 'Sub Collector') {
    this.role = 'Sub Collector';
  } else {
    this.role = this.userInfo.jobTitle;
  }
}
getUserName() {
  this.commonService.getRequest(this.commonConstants.getUserIdDetails).subscribe({
    next: (responseData: any) => {  
      if(this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva') {
        this.userName = responseData.result[0]?.userName;
      } 
     this.loggedinUserMobileNo = responseData.result[0]?.phoneNo;
     let locationCode='';
     locationCode= responseData?.result[0]?.locationScope?.locationCode;
     localStorage.setItem('locationCode', locationCode);
     localStorage.setItem('userName', this.userName);
    },
    error: (error) => {  
      console.log('client side', error);
    }
  });
}
getSignature()
{
  // console.log("userinfo" , this.userInfo)
  var designation1 = this.userInfo.userPrincipalName.split('-')[1];
  var finalDes = designation1.split('@')[0];
  // console.log("designation" , finalDes)
  this.commonService.getRequest(this.commonConstants.getUserSignature + this.userInfo.userPrincipalName + '&Designation=' + finalDes).subscribe({
    next: (responseData: any) => {
      this.sourceFile = 'data:image/png;base64,' + responseData.result
      localStorage.setItem('signature_'+this.userInfo.userPrincipalName, JSON.stringify(this.sourceFile));
      // console.log(this.sourceFile , "sourceFile first time")
    },
    error: (error) => {  
      console.log('client side', error);
    }
  });
}
reset() {
  this.idle.watch();
  //xthis.idleState = 'Started.';
  this.timedOut = false;
  this.timeoutStart = false;
}
cancelLogout() {
  this.modalService.dismissAll();
  clearInterval(this.counter);
  this.reset();
}
redirectToRTS() {
  let secretariatCode: any;
  let userId = this.userInfo?.userPrincipalName?.split('-');
    if (userId?.length > 0) {
       secretariatCode = userId[0]?.charAt(0)?.toUpperCase() === 'U' ? userId[0]?.split('U')[1] : userId[0];
    }
  let postData: any = {};
  let url: any;
  const hostname = window && window.location && window.location.hostname;
    postData.userMobileNumber = this.userInfo?.mobilePhone;
    postData.secretariatCode = secretariatCode;
    postData.userId = this.userIdData;
    postData.userName = this.loginUserName;
    url = this.environmentService.externalUrls.RTS;
    // if (/^.*localhost.*/.test(hostname) || /^gswswebdev.z29.web.core.windows.net/.test(hostname)) {
    //   url = 'http://uat.gramawardsachivalayam.ap.gov.in/GSWSRTS/UI/RTS_SSO.aspx'; //RTS
    // } else {
    //   url = 'https://gramawardsachivalayam.ap.gov.in/GSWSRTS/UI/RTS_SSO.aspx'; //RTS
    // }
    this.commonService.postRequest(this.commonConstants.redirectToExisting , postData).subscribe({
      next: (responseData: any) => {
          let transactionId: any = responseData.result?.transactionId;
         // console.log('on redirect', responseData.result);
          window.open(url + '?ReqID='+ transactionId, '_blank');
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
}

openUpdateProfile(profileModal: any) {
  this.modalService.open(profileModal, { size: 'md', centered: true, backdrop: 'static'});
}
updateProfile(): any {
  this.submitted = true;
  if (!this.profileForm.valid)
    return false;
  else { 
    let url: any = 'https://graph.microsoft.com/v1.0/me' //users/'+ 'U10690667-DA@apgsws.onmicrosoft.com';//this.userInfo?.userPrincipalName;
    let postData: any = {};
    postData.givenName = this.f.firstName.value + ' ' + this.f.lastName.value; //'test';
    postData.mobilePhone = this.f.mobileNo.value; //'9999999999';
    postData.displayName = this.f.firstName.value + ' ' + this.f.lastName.value;
    postData.surname = this.f.lastName.value;
   // console.log('user data', postData);
    this.commonService.postRequestWithoutBaseUrl(url, postData).subscribe({
      next: (responseData: any) => { 
       // console.log('update')
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
   }
  
}

redirectChallan(typeChanllan:any){
  const queryParams: Params = { type: typeChanllan};
    this.router.navigate(['/view-challan'], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: '',
    });
}
}
