import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService, WhitespaceValidator } from '../../shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
import { NgbNavChangeEvent, NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EnvironmentService } from 'src/shared/environment.service';

@Component({
  selector: 'app-print-service',
  templateUrl: './print-service.component.html',
  styleUrls: ['./print-service.component.css'],
  providers: [
    NgbNavConfig,
  ]
})
export class PrintServiceComponent implements OnInit {
  printForm: FormGroup;
  public submitted: Boolean = false;
  public commonFormSubmitted: Boolean = false;
  commonConstants: any = CommonConstants;
  subCaste: any;
  @ViewChild('nav')
  nav: any;
  showCertificateInfo: Boolean = false;
  showAuthentication: Boolean = false;
  showBiometricPopup: Boolean = false;
  isOtpValidated: Boolean = false;
  otpSubmitted: Boolean = false;
  aadharTransactionID: any;
  loggedInUserId: any;
  autoBiometricId: any;
  windowObjectReference: any;
  services:any=[];
  listOfCatAServices :any =[];
  constructor(private formBuilder: FormBuilder, private commonService: CommonService,private config: NgbNavConfig, private activatedRoute: ActivatedRoute, private router: Router, private environmentService: EnvironmentService,) { 
    let userInfo = localStorage.getItem('userInfo');
    if (userInfo && userInfo !== null) {
      let userData = JSON.parse(userInfo);
      this.loggedInUserId = userData.userPrincipalName
    }
    this.printForm = this.formBuilder.group({
        commonForm: [],
        aadhaarNumberBio: [null],
        aadhaarNumberOTP: [null],
        authenticationType: ['rbOTP', [Validators.required]],
        otp: [null, [Validators.required]],        
        department: [null, Validators.required],
        serviceName: [null, Validators.required],
        applicationNo: ['', [Validators.required, WhitespaceValidator.whiteSpaceValidate,  Validators.pattern(this.commonConstants.alphanumericwithoutspace)]],
        uhid:[null],
        noOfCopies: [null, Validators.required],
    });
    config.destroyOnHide = false;
    config.roles = false;
  }

  ngOnInit(): void {
  }
  get f() {
    return this.printForm.controls;
  }
  getSubCaste(data: any) {
    this.subCaste = data;
  }
  goToNextTab() {
    this.commonFormSubmitted = true;
    this.f.aadhaarNumberBio.patchValue(this.f['commonForm'].value['aadharNo']);
    this.f.aadhaarNumberOTP.patchValue(this.f['commonForm'].value['aadharNo']);
    if (this.f.commonForm.valid) {
      this.nav.select(2);
    }
  }
  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.commonFormSubmitted = true;
    this.f.aadhaarNumberBio.patchValue(this.f['commonForm'].value['aadharNo']);
    this.f.aadhaarNumberOTP.patchValue(this.f['commonForm'].value['aadharNo']);
    if (changeEvent.nextId === 2 && !this.f.commonForm.valid) {
      changeEvent.preventDefault();
    };
  }
  submit(): any {
    if(this.f.department?.value === 'HF'){
      this.f.noOfCopies.patchValue(1);
    }
    if(!this.printForm.valid){
      this.submitted = true;
      return false;
    }     

    else {
     
       // this.getStatusOfService();
       this.submitted = false;
        this.getGSWSCertificateDetails(this.f.department.value, this.f.applicationNo.value?.trim());
       
      
      
      
 
    }
       
  }
  getStatusOfService() {
    //this.getTokenFromMeeseva();
    //let serviceType: any = 'IntegratedCertificate';
    if(this.f.department.value === 'HF'){
      this.f.serviceName.patchValue('AarogyaSriNewCard')
     }
    this.commonService.getRequest(this.commonConstants.getWorkItemHistory + this.f.serviceName.value + '&WorkItemReference=' + this.f.applicationNo.value.trim()).subscribe({
      next: (responseData:any) => { 
        console.log(responseData,'Integrated Certificate respose data');
         let historyItems = responseData?.result?.items;
         let prefix='';
         prefix = this.f.applicationNo.value?.substring(0,3)
         if(historyItems?.length > 0 && historyItems[historyItems?.length - 1].actionTaken === 3) {
           // this.getGSWSCertificateDetails(this.f.department.value, this.f.applicationNo.value?.trim());
        //  if(this.f.serviceName.value ==='UpdateAarogyaSriCard' || this.f.serviceName.value ==='AarogyaSriNewCard' ){
        //       this.checkPaymentStatus();
        //     }else{
              this.saveRequisition();
         //   }
          } else if (historyItems?.length > 0 && historyItems[historyItems?.length - 1].actionTaken !== 3 &&  historyItems[historyItems?.length - 1].actionTaken !== 4 ) {
          this.commonService.commonErrorPopup('Alert', 'This service request is not approved yet.', 'md')
         } else if( historyItems?.length > 0 && historyItems[historyItems?.length - 1].actionTaken === 4){
          this.commonService.commonErrorPopup('Alert', 'This Application is rejected', 'md')
        
        }
          else {
          this.commonService.commonErrorPopup('Alert', 'This application number is not found.', 'md')
         }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
   }
   checkPaymentStatus(){
    this.commonService.getRequest(this.commonConstants.CheckPaymentStatusForCatACertificateById +  this.f.applicationNo.value.trim()).subscribe({
      next: (responseData:any) => { 
       console.log(responseData + 'response from CheckPaymentStatusForCatACertificateById')
       if(responseData.result.isSuccess ===  true){
       let checkPayment :any ='';
       checkPayment = this.commonService.RSADecrypt(responseData.result.encryptedResponse);
      
       if(JSON.parse(checkPayment).IsPaymentCompleted === true){
       
          this.saveRequisition();
        

        
       }else{
        this.commonService.commonErrorPopup('Alert', 'Payment Failed for this Application.', 'md')
       }
       }else{
        this.commonService.commonErrorPopup('Alert', 'This application number is not found.', 'md')
       }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
   }
   getGSWSCertificateDetails(deptCode:any, reqId: any) {
    this.commonService.getRequest(this.commonConstants.getCertificateDetails + deptCode + '&IncludeFileUploadInfo=true&IncludePaymentDetails=false&RequisitionId='+ reqId).subscribe({
      next: (responseData: any) => { 
        if(typeof(responseData?.result) !== 'string') {
          let citizenAadharNumber = responseData.result?.requisitionHeader?.applicantInfo?.aadhaarNumber;
          if(citizenAadharNumber?.length > 12){
            this.decryptAadharNum(citizenAadharNumber);
          } else if(citizenAadharNumber !== this.f['commonForm'].value['aadharNo']?.trim()) {
              this.commonService.commonErrorPopup('Alert', 'Aadhar number provided at the basic details is not matching with the Aadhar Number of the application number:' + this.f.applicationNo.value, 'md')
          }
        } else {
            this.commonService.commonErrorPopup('Alert', responseData?.result, 'md');
        } 
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
   }
   decryptAadharNum(aadhar : string){
    this.commonService.getRequest(this.commonConstants.cryptoDecryptValue + encodeURIComponent(aadhar))
      .subscribe({
        next: (responseData: any) => {
          let aadharNum = this.commonService.RSADecrypt(responseData.result.rawValue);
          if(aadharNum !== this.f['commonForm'].value['aadharNo']?.trim()) {
            this.commonService.commonErrorPopup('Alert', 'Aadhar number provided at the basic details is not matching with the Aadhar Number of the application number:' + this.f.applicationNo.value, 'md')
          } else {
            this.listOfCatAServices =this.commonConstants.categoryAServices;
            let catA = this.listOfCatAServices.filter((c:any) => c.serviceCode === this.f.serviceName.value);
            let prefix='';
            prefix = this.f.applicationNo.value?.substring(0,3)
            if(catA.length >0 ||  (prefix === 'UHC'  && this.f.serviceName.value === 'PrintCertificateForAarogyaSri') ){
              this.checkPaymentStatus();
            }else{
              this.getStatusOfService();
            }
            
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
    }
    saveRequisition() {
      let dateFormat = this.commonService.formatDate(this.f['commonForm'].value['dob']);
      let postData: any = {
        requisitionHeader: {
          requisitionType: this.f.department.value !== 'HF' ? 'PrintCertificate' : 'PrintCertificateForAarogyaSri',
          department: this.f.department.value,
          applicantInfo: {
            id: '',
            aadhaarNumber: this.f['commonForm'].value['encryptedAadharNo'].trim(), // CryptoJS.AES.encrypt(this.f['commonForm'].value['aadharNo'].trim(), this.commonConstants.encryptDecryptPassword.trim()).toString(), //this.f['commonForm'].value['aadharNo'],
            personNames: [
              {
                languageCode: 'en-in',
                firstName: this.f['commonForm'].value['firstName'],
                middleName: this.f['commonForm'].value['middleName'],
                lastName: this.f['commonForm'].value['lastName'],
                fatherHusbandName: this.f['commonForm'].value['fatherHusName'],
              },
            ],
            gender: this.f['commonForm'].value['gender'],
            birthDate: dateFormat,
            caste: this.f['commonForm'].value['caste'].name,
            religion: this.f['commonForm'].value['religion'].name,
            qualification: this.f['commonForm'].value['qualification'].name,
            maritalStatus: this.f['commonForm'].value['maritalStatus'],
            addresses: [
              {
                addressType: 'present',
                doorNo: this.f['commonForm'].value['preDoorNo'],
                streetName: this.f['commonForm'].value['preStreet'],
                villageWard: this.f['commonForm'].value['preVillage'].entityCode,
                mandalMunicipality:
                  this.f['commonForm'].value['preMandal'].entityCode,
                district: this.f['commonForm'].value['preDistrict'].entityCode,
                pinCode: this.f['commonForm'].value['prePinCode'],
                postalOfficeCode: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].entityCode : "",
                postalOfficeName: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].name : "",
                postalVillageCode: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].entityCode : "",
                postalVillageName: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].name : ""
              },
              {
                addressType: 'permanent',
                doorNo: this.f['commonForm'].value['permDoorNo'],
                streetName: this.f['commonForm'].value['permStreet'],
                villageWard: this.f['commonForm'].value['permVillage'].entityCode,
                mandalMunicipality:
                  this.f['commonForm'].value['permMandal'].entityCode,
                district: this.f['commonForm'].value['permDistrict'].entityCode,
                pinCode: this.f['commonForm'].value['permPinCode'],
                postalOfficeCode: this.f['commonForm'].value['permPostalOffice'] !==  null ? this.f['commonForm'].value['permPostalOffice'].entityCode : "",
                postalOfficeName: this.f['commonForm'].value['permPostalOffice'] !== null ? this.f['commonForm'].value['permPostalOffice'].name : "",
                postalVillageCode: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].entityCode : "",
                postalVillageName: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].name : ""
              },
            ],
            rationCardNo: '',
            deliveryType: this.f['commonForm'].value['deliveryType'],
            emailAddress: this.f['commonForm'].value['email'],
            mobileNumber: this.f['commonForm'].value['mobileNo'],
            isWhatsAppAvailable: this.f['commonForm'].value['isWhatsAppAvailable'],
            isDataFromAadhar: this.f['commonForm'].value['isDataFromAadhar']
          },
          remarks: '',
        },
        requisitionData: {
          department: this.f.department.value,
          serviceName: this.f.serviceName.value, 
          applicationNo: this.f.applicationNo.value,
          noOfCopies: Number(this.f.noOfCopies.value),
          uhid:this.f.department.value === 'HF' ? this.f.uhid.value?.toString() : ''
        }
      };

      this.commonService.postRequest(this.commonConstants.printCertificate, postData).subscribe({
          next: (responseData: any) => {
            this.showPayment(responseData['result'].id);
          },
          error: (error) => {
            console.log('client side', error);
          },
        });
    }
    showPayment(requisionId: any) {
      const queryParams: Params = { id: requisionId,department: this.f.department.value ,type:this.f.department.value !== 'HF' ? 'PrintCertificate' : 'PrintCertificateForAarogyaSri'};
      this.router.navigate(['/payment-gateway'], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
    }

  encrtyptedAadhar: any;
  biometricBtn() {
    let postData: any = {};
    postData.autoBiometricId = 0;
    this.encrtyptedAadhar = this.commonService.RSAEncrypt(this.f['aadhaarNumberBio'].value);
    postData.aadharNumber = this.encrtyptedAadhar;
    postData.responseStatus = '';
    postData.userId = this.loggedInUserId;
    this.commonService.postRequestForWebLand(this.commonConstants.getSNoForBiometric, postData).subscribe({
      next: (responseData:any) => { 
      // console.log('response from biometric', responseData)
      this.autoBiometricId = responseData?.result?.autoBiometricId;
      if (this.autoBiometricId !== undefined && this.autoBiometricId !== null) {
        this.showBiometricPopup = true;
        let url: any = this.environmentService.externalUrls.biometricRedirectionPage + encodeURIComponent(this.encrtyptedAadhar) +'&SNo='+ this.autoBiometricId +'&DoEkyc=false&ReqPhoto=false';
        this.windowObjectReference = window.open(url, "BiometricAuthentication", 'popup=1;left=500,top=100,width=780,height=700');
      } else {
        this.showBiometricPopup = false;
      }
    },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  checkBiometricStatus() {
    this.windowObjectReference.close();
    let postData: any = {};
    postData.autoBiometricId = this.autoBiometricId;
    postData.aadharNumber = this.encrtyptedAadhar;
    postData.responseStatus = '';
    postData.userId = this.loggedInUserId;
    this.commonService.postRequestForWebLand(this.commonConstants.getSNoForBiometric, postData).subscribe({
      next: (responseData:any) => { 
     // console.log('response from biometric', responseData)
     // this.autoBiometricId = responseData?.result?.autoBiometricId;
     let status = responseData?.result?.responseStatus;
     if (status === 'Success') {
      this.showCertificateInfo = true;
      this.showBiometricPopup = true;
      this.commonService.commonErrorPopup('Alert', 'Biometric Authentication Success.', 'md');
     } else {
      this.showCertificateInfo = false;
      this.showBiometricPopup = false;
      this.commonService.commonErrorPopup('Alert', 'Biometric Authentication failed. Please try again!!', 'md');
     }
     
    },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  otpChange(event:any) {
   this.showBiometricPopup = false;
   this.showCertificateInfo = false;
    if(event.target.value === "rbOTP") {
      this.f.otp.setValidators([Validators.required]);
      this.f.otp.updateValueAndValidity();
    } else {
      this.f.otp.clearValidators();
      this.f.otp.updateValueAndValidity();
      this.f.otp.reset();
    }
 }
  btnSendOTPandTimer(from?: any): any{
    var sendPostData={
      uid: this.f.aadhaarNumberOTP.value,
      authentication_type: "OTPGENERATE"
    }
    //let postData: any = btoa(JSON.stringify(sendPostData));
    this.commonService.postRequestForAadhar(this.commonConstants.generateAadharOTP, sendPostData).subscribe({
      next: (responseData:any) => { 
        if(responseData?.reason == 'OTP Sent Successfully'){
          this.aadharTransactionID = responseData?.result
        } else {
          this.commonService.commonErrorPopup('Alert', responseData?.result, 'md');
        }
      },
      error: (error: any) => {
        console.log('client side', error);
      }
    });
  }
  otpBtn(){
    this.otpSubmitted = true;
    if(this.f.otp.valid) {
        var sendPostData={
          PIDXML: this.aadharTransactionID,
          otp : this.f.otp.value,
          uid: this.f.aadhaarNumberOTP.value,
          authentication_type: "OTPVALIDATE"
        }
        //let postData: any = btoa(JSON.stringify(sendPostData));
        this.commonService.postRequestForAadhar(this.commonConstants.generateAadharOTP, sendPostData).subscribe({
          next: (responseData:any) => { 
           if(responseData?.reason === 'OTP Verified Successfully'){
            this.isOtpValidated = true;
            this.showCertificateInfo = true;
          } else if(responseData?.reason === 'OTP validation failed' || responseData?.reason === 'Invalid Auth XML format' || responseData?.reason === 'Auth XSD Validation Failed.') {
            this.showCertificateInfo = false;
            this.commonService.commonErrorPopup('Alert', 'Invalid OTP entered. Please Enter Valid OTP.' , 'md')
          } else {
            this.showCertificateInfo = false;
            this.commonService.commonErrorPopup('Alert', responseData?.reason , 'md')
          }
          },
          error: (error: any) => {
            console.log('client side', error);
          }
        });
    }
  }
  onDepartmentChange(){
    this.f.serviceName.patchValue(null);
    this.f.applicationNo.patchValue('');
    this.f.noOfCopies.patchValue(null);
    this.f.uhid.patchValue('');
    if(this.f.department.value === 'HF'){
      this.printForm.controls['uhid'].setValidators([ Validators.required,Validators.minLength(11)]);
    }else{
      this.printForm.controls['uhid'].clearValidators();
    }
    this.printForm.controls['uhid'].updateValueAndValidity();


      this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + this.f.department.value + '&EntityType=' + 'Reprint').subscribe({  
      next: (responseData: any) => { 
          let name =  responseData.result;
         
       if(name.length >0){

            this.commonService.getRequest(this.commonConstants.getByParentId + 'ReprintServices&ParentId='+ name[0]?.id +'&ParentType=' + name[0]?.entityType +'&LanguageCode=EN-IN').subscribe({
                 next: (responseData: any) => { 
                 this.services  = this.commonService.sortData(responseData.result);
             },
                 error: (error) => {
                console.log('client side', error);
             }
        });
      }else{
        this.services =[];
      }
         
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    

  }
  onKeyup(event:any){
    
    if(event.target.value.length == 11){
    let id =event.target.value;
    let postData = {
      Id: id,
      idtype: 'uhid'
    }
        this.commonService.postRequest(this.commonConstants.GetReferenceIdFromAadharOrUHID, postData, true).subscribe({
          next: (responseData: any) => { 
            let reqData = responseData?.result;  
         if(reqData.dataFlag == 'E'){
          this.commonService.commonErrorPopup("Alert",'No Records Found' , "md")
         }
         else if(reqData.dataFlag == 'L'){
          this.commonService.commonErrorPopup("Alert", 'This UHID is from Legacy Data, Please apply Update YSR Aarogyasri Card Service', "md")
         }
         else if(reqData.dataFlag == 'R'){
        this.f.applicationNo.patchValue(reqData?.requisitionId);
         }
           
          }
        })  
      }else{
        this.f.applicationNo.patchValue('');
      }
    }
  
}
