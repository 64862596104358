import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  reportType: any = null;
  submitted: Boolean = false;
  userInfo: any;
  constructor(private router: Router, private activatedRoute:ActivatedRoute) {
    let userData = localStorage.getItem('userInfo');
    if(userData && userData !== null) {
        this.userInfo = JSON.parse(userData);
    }
   }

  ngOnInit(): void {
  }
  getReport(): any {
    this.submitted = true;
    if (this.reportType === null)
      return false
    if(this.reportType === 'analyticsDashboard') 
        window.open('https://app.powerbi.com/view?r=eyJrIjoiMGNjMDkyZjAtMWMzOS00MTNmLTllODctNWVjYTY5Zjk5NzYwIiwidCI6IjI3ZjAxMGQwLTk2YWQtNGNhNC1iNjJkLWFlNzhhNGEyYTQzMCJ9&pageName=ReportSection', '_blank');
    else if (this.reportType === 'subSLADashboard')  {
      window.open('https://app.powerbi.com/view?r=eyJrIjoiZGZjNzkwMDctZjhmMy00MTVlLTlkNTUtZWQwOTAyM2Q0YzA0IiwidCI6IjI3ZjAxMGQwLTk2YWQtNGNhNC1iNjJkLWFlNzhhNGEyYTQzMCJ9', '_blank');
    } else if(this.reportType === 'servicesDashboard') {
      this.router.navigate(['/csp-service-report']);
    } else if(this.reportType === 'paymentDashboard') {
      this.router.navigate(['/csp-payment-report']);
    }else if(this.reportType === 'paymentReport') {
      this.router.navigate(['/payment-report']);
    }else if(this.reportType === 'OTSPaymentReport') {
      this.router.navigate(['/ots-payment-report']);
    } else if(this.reportType === 'ArogyaSurakshaReport') {
      this.router.navigate(['/aarogya-suraksha-report']);
    } 
    else if(this.reportType === 'WelfareSchemeProgramReport') {
      this.router.navigate(['/welfare-scheme-program']);
    }else if(this.reportType === 'ArogyaSurakshaDashboard') {
      this.router.navigate(['/aarogya-suraksha-dashboard']);
    }else if(this.reportType === 'mutForCorrections') {
      this.router.navigate(['/mutation-for-correction']);
    }else if(this.reportType === 'mutForTransactions') {
      this.router.navigate(['/mutation-for-transactions']);
    }else if(this.reportType === 'failedTransactions') {
      this.router.navigate(['/failedtransactions']);
    // }else if(this.reportType === 'jaganAnnaSuraksha') {
    //   this.router.navigate(['/jagananna-suraksha-report']);
    }
    // else if(this.reportType === 'jaganAnnaDashboard') {
    //   this.router.navigate(['/jagananna-suraksha-dashboard']);
    // }
    else if(this.reportType === 'teamScheduleReport') {
      this.router.navigate(['/schedule-report']);
    }
    // else if(this.reportType === 'campStatusReport') {
    //   this.router.navigate(['/jagananna-suraksha-camp-status-report']);
    // } 
    else if(this.reportType === 'tokenGenerationReport') {
      this.router.navigate(['/jagananna-aarogya-suraksha-token-generation-report']);
    } 
    else if(this.reportType === 'pallekupodam') {
      this.router.navigate(['/palleku-podam']);
    } 
    else if(this.reportType === 'gswsDynamicSurveyReport') {
      this.router.navigate(['/gsws-dynamic-survey-report']);
    }
    else if(this.reportType === 'passbookServiceReport') {
      this.router.navigate(['/passbook-service-report']);
    }
    else if(this.reportType === 'pensionReport') {
      this.router.navigate(['/pension-report']);
    }
    else if(this.reportType === 'PANCollectionReport') {
      this.router.navigate(['/monitoring-dashboard-for-pan-collection']);
    }else if(this.reportType  === 'DayNodalOfficerIECDistributionReport'){
      this.router.navigate(['/vbd-report']);
    }else if(this.reportType  === 'districtlevelstationarybalanceReport'){
      this.router.navigate(['/viksit-bharat-report']);
    }
    else if(this.reportType  === 'UtilizationCertificateReport'){
      this.router.navigate(['/utilization-certificate-report']);
    }
    else if(this.reportType  === 'AadharChallanGenerationReport'){
      this.router.navigate(['/aadhar-challan-generation-report']);
    }
    else if(this.reportType  === 'volunteerappreciationreport'){
      this.router.navigate(['/volunteer-appreciation-report']);
    }else if(this.reportType === 'secretariatPANUpdatedReport'){
      this.router.navigate(['/secreteriate-pan-updated-report']);
    }else if(this.reportType === 'secretariatRevenueVillageMappingReport'){
      this.router.navigate(['/secreteriate-revVillage-mapping-report']);
    }else if(this.reportType === 'SuomotoCasteVerificationReport'){
      this.router.navigate(['/suomoto-caste-verification-report']);
    }
    // else if(this.reportType === 'download') {
    //   const queryParams: Params = { range: false };
    //   this.router.navigate(['/excel-report'], {
    //     relativeTo: this.activatedRoute,
    //     queryParams: queryParams,
    //     queryParamsHandling: 'merge'
    //   });
    // } 
    
  }
  goToReshedule() {
    this.router.navigate(['/reshedule-welfare-schemes']);
  }
}
